<lefty-dialog
  [(visible)]="visible"
  class="small"
  [showScrollBorder]="true"
  (closedWithCloseButtonOrDismiss$)="closedWithCloseButtonOrDismiss$.emit()"
  [autoDismissable]="true"
>
  <ng-content
    select="[title]"
    title
    ><h3
      title
      i18n
    >
      Select a program
    </h3></ng-content
  >

  @if (hasDescription()) {
    <p description>
      {{ description() }}
    </p>
  }

  <div description>
    <lefty-form-input
      placeholder="Search by name"
      i18n-placeholder
      (valueChange)="searchValue.set($event)"
      [value]="searchValue()"
    ></lefty-form-input>
  </div>

  <div class="programs-list">
    @if (loading()) {
      <lefty-spinner />
    } @else {
      @for (program of filteredPrograms(); track program.programId) {
        <alfred-affiliation-program-card
          (click)="selectProgram(program)"
          [isSelected]="isSelected(program)"
          [selectable]="true"
          [program]="program"
        ></alfred-affiliation-program-card>
      } @empty {
        <p
          i18n
          class="empty"
        >
          No results
        </p>
      }
    }
  </div>

  <ng-container footer>
    @if (withAddProgramButton) {
      <span class="fill-space">
        <button
          leftyButton="outline"
          i18n
          (click)="addProgram()"
        >
          Add a program
        </button>
      </span>
    }

    <button
      i18n
      leftyButton="secondary"
      (click)="cancel()"
    >
      {{ cancelButtonText }}
    </button>
    <button
      i18n
      leftyButton="primary"
      (click)="next()"
      [disabled]="!hasSelection()"
    >
      {{ selectButtonText }}
    </button>
  </ng-container>
</lefty-dialog>
