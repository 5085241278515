<method-selection-dialog
  (methodSelected$)="openDialogForSelectedMethod($event)"
  (dialogDismissed$)="closedWithoutSave$.emit()"
></method-selection-dialog>
<search-creator-method-dialog
  (saved$)="influencersSaved($event)"
  (back$)="openMethodSelectionDialog()"
  [submitButtonText]="submitButtonText()"
  (dialogDismissed$)="closedWithoutSave$.emit()"
></search-creator-method-dialog>
<plain-text-method-dialog
  (saved$)="usernamesSaved($event)"
  (back$)="openMethodSelectionDialog()"
  [submitButtonText]="submitButtonText()"
  (dialogDismissed$)="closedWithoutSave$.emit()"
></plain-text-method-dialog>
<select-influencers-from-directory-dialog
  title="Add influencers"
  i18n-title
  (back$)="openMethodSelectionDialog()"
  (saved$)="directoryInfluencerSaved($event)"
  [submitButtonText]="submitButtonText()"
  (dialogDismissed$)="closedWithoutSave$.emit()"
></select-influencers-from-directory-dialog>

<!-- TODO: all dialogs for other methods -->
