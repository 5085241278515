import {
  ChangeDetectionStrategy,
  Component,
  computed,
  input,
} from '@angular/core';
import {
  CreatorCardSnippet,
  CreatorTiniestCard,
} from '@frontend2/proto/librarian/proto/creators_pb';
import { ProfilePictureComponent } from '../image/profile-pic.component';
import { LeftyTooltipDirective } from '../lefty-tooltip/lefty-tooltip.directive';

@Component({
  selector: 'lefty-influencers-profile-pictures-stack',
  templateUrl: './lefty-influencers-profile-pictures-stack.component.html',
  styleUrls: ['./lefty-influencers-profile-pictures-stack.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [ProfilePictureComponent, LeftyTooltipDirective],
})
export class InfluencerProfilePicturesComponent {
  readonly defaultItemsToShow = 3;

  readonly itemsToShow = input(this.defaultItemsToShow);

  readonly influencers = input<CreatorCardSnippet[] | CreatorTiniestCard[]>([]);

  readonly size = input<InfluencerProfilePicturesItemSize>('medium');

  readonly withTooltip = input(false);

  readonly influencersToShow = computed(() => {
    const maxCount = Math.min(this.itemsToShow(), this.influencers().length);
    return this.influencers().slice(0, maxCount);
  });

  readonly moreItemsCount = computed(
    () => this.influencers().length - this.itemsToShow(),
  );

  readonly moreItemsTooltip = computed(() => {
    if (this.moreItemsCount() > 0) {
      return this.influencers()
        .slice(this.itemsToShow())
        .map((i) => i.userName)
        .join(', ');
    }
    return '';
  });
}

export type InfluencerProfilePicturesItemSize = 'medium' | 'large';
