import {
  ChangeDetectionStrategy,
  Component,
  computed,
  input,
  output,
} from '@angular/core';
import {
  isGhostAffiliationProgram,
  readableCookieDuration,
  readableProgramCommission,
} from '@frontend2/core';
import { Program } from '@frontend2/proto/librarian/proto/affiliation_pb';
import { LeftyButtonDirective } from '../../lefty-button-directive/lefty-button.directive';
import { LeftyCardComponent } from '../../lefty-card/lefty-card.component';

@Component({
  selector: 'alfred-affiliation-program-card',
  templateUrl: 'program-card-component.html',
  styleUrls: ['program-card-component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [LeftyCardComponent, LeftyButtonDirective],
})
export class AlfredProgramCardComponent {
  readonly program = input(new Program());
  readonly isSelected = input(false);
  readonly isDisabled = input(false);
  readonly selectable = input(false);

  readonly withChangeButton = input(false);

  readonly change$ = output<void>();

  readonly isGhost = computed(() => isGhostAffiliationProgram(this.program()));

  readonly formattedCommission = computed(() =>
    readableProgramCommission(this.program()),
  );

  readonly formattedCookieDuration = computed(() =>
    readableCookieDuration(this.program().cookieDurationMinutes),
  );
}
