// This file defines messages to persist user actions on the site.

// @generated by protoc-gen-es v1.10.0 with parameter "target=ts"
// @generated from file common/proto/nightwatch.proto (package common.proto, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { BoolValue, Int32Value, Int64Value, Message, proto3, protoInt64, StringValue, Timestamp } from "@bufbuild/protobuf";
import { CampaignKeyword, CampaignStatus, VisualEditedPerf } from "./campaign_pb";
import { MetaIdPb } from "./creator_pb";
import { MediaIdPb, Network, PostType, SourceType } from "./common_pb";

/**
 * @generated from enum common.proto.NightwatchItemType
 */
export enum NightwatchItemType {
  /**
   * @generated from enum value: NIGHTWATCH_ITEM_TYPE_UNKNOWN = 0;
   */
  NIGHTWATCH_ITEM_TYPE_UNKNOWN = 0,

  /**
   * For this type and the body is an InfluencerAddedItem.
   *
   * @generated from enum value: INFLUENCER_ADDED_TO_DIRECTORY = 22;
   */
  INFLUENCER_ADDED_TO_DIRECTORY = 22,

  /**
   * For this type the body is an InfluencerDeletedItem.
   *
   * @generated from enum value: INFLUENCER_REMOVED_FROM_DIRECTORY = 23;
   */
  INFLUENCER_REMOVED_FROM_DIRECTORY = 23,

  /**
   * For this type and the body is an InfluencerAddedItem.
   *
   * @generated from enum value: INFLUENCER_ADDED_TO_CAMPAIGN = 1;
   */
  INFLUENCER_ADDED_TO_CAMPAIGN = 1,

  /**
   * For this type the body is an InfluencerDeletedItem.
   *
   * @generated from enum value: INFLUENCER_REMOVED_FROM_CAMPAIGN = 2;
   */
  INFLUENCER_REMOVED_FROM_CAMPAIGN = 2,

  /**
   * For this type and the one below the body is a MediaIdPb
   *
   * @generated from enum value: POST_MARKED_MANUALLY = 3;
   */
  POST_MARKED_MANUALLY = 3,

  /**
   * @generated from enum value: POST_UNMARKED_MANUALLY = 4;
   */
  POST_UNMARKED_MANUALLY = 4,

  /**
   * @generated from enum value: CAMPAIGN_CREATED = 8;
   */
  CAMPAIGN_CREATED = 8,

  /**
   * @generated from enum value: CAMPAIGN_MOVED = 11;
   */
  CAMPAIGN_MOVED = 11,

  /**
   * @generated from enum value: POST_UPLOADED_MANUALLY = 14;
   */
  POST_UPLOADED_MANUALLY = 14,

  /**
   * For this type the body is the CampaignSettingsItem below.
   *
   * @generated from enum value: CAMPAIGN_SETTINGS_EDITED = 5;
   */
  CAMPAIGN_SETTINGS_EDITED = 5,

  /**
   * @generated from enum value: CAMPAIGN_STATUS_UPDATED = 6;
   */
  CAMPAIGN_STATUS_UPDATED = 6,

  /**
   * @generated from enum value: CAMPAIGN_PINNED = 13;
   */
  CAMPAIGN_PINNED = 13,

  /**
   * @generated from enum value: CAMPAIGN_LABELS_UPDATED = 12;
   */
  CAMPAIGN_LABELS_UPDATED = 12,

  /**
   * @generated from enum value: ACCOUNT_CREATED = 7;
   */
  ACCOUNT_CREATED = 7,

  /**
   * For this type the body is an TeamCreatedItem.
   *
   * @generated from enum value: TEAM_CREATED = 24;
   */
  TEAM_CREATED = 24,

  /**
   * @generated from enum value: CPM_UPDATED = 10;
   */
  CPM_UPDATED = 10,

  /**
   * For this type the body is VisualPerfEditionItem.
   *
   * @generated from enum value: VISUAL_PERF_MANUAL_EDITION = 9;
   */
  VISUAL_PERF_MANUAL_EDITION = 9,

  /**
   * @generated from enum value: CW_REPORT_CREATED = 15;
   */
  CW_REPORT_CREATED = 15,

  /**
   * For this type the body is CWReportSettingsItem.
   *
   * @generated from enum value: CW_REPORT_SETTINGS_EDITED = 16;
   */
  CW_REPORT_SETTINGS_EDITED = 16,

  /**
   * @generated from enum value: CW_REPORT_BRANDS_UPDATED = 17;
   */
  CW_REPORT_BRANDS_UPDATED = 17,

  /**
   * @generated from enum value: CW_BRAND_CREATED = 18;
   */
  CW_BRAND_CREATED = 18,

  /**
   * @generated from enum value: CW_BRAND_UPDATED = 19;
   */
  CW_BRAND_UPDATED = 19,

  /**
   * @generated from enum value: CW_SET_CREATED = 20;
   */
  CW_SET_CREATED = 20,

  /**
   * For this type the body is CWSetUpdatedItem.
   *
   * @generated from enum value: CW_SET_UPDATED = 21;
   */
  CW_SET_UPDATED = 21,
}
// Retrieve enum metadata with: proto3.getEnumType(NightwatchItemType)
proto3.util.setEnumType(NightwatchItemType, "common.proto.NightwatchItemType", [
  { no: 0, name: "NIGHTWATCH_ITEM_TYPE_UNKNOWN" },
  { no: 22, name: "INFLUENCER_ADDED_TO_DIRECTORY" },
  { no: 23, name: "INFLUENCER_REMOVED_FROM_DIRECTORY" },
  { no: 1, name: "INFLUENCER_ADDED_TO_CAMPAIGN" },
  { no: 2, name: "INFLUENCER_REMOVED_FROM_CAMPAIGN" },
  { no: 3, name: "POST_MARKED_MANUALLY" },
  { no: 4, name: "POST_UNMARKED_MANUALLY" },
  { no: 8, name: "CAMPAIGN_CREATED" },
  { no: 11, name: "CAMPAIGN_MOVED" },
  { no: 14, name: "POST_UPLOADED_MANUALLY" },
  { no: 5, name: "CAMPAIGN_SETTINGS_EDITED" },
  { no: 6, name: "CAMPAIGN_STATUS_UPDATED" },
  { no: 13, name: "CAMPAIGN_PINNED" },
  { no: 12, name: "CAMPAIGN_LABELS_UPDATED" },
  { no: 7, name: "ACCOUNT_CREATED" },
  { no: 24, name: "TEAM_CREATED" },
  { no: 10, name: "CPM_UPDATED" },
  { no: 9, name: "VISUAL_PERF_MANUAL_EDITION" },
  { no: 15, name: "CW_REPORT_CREATED" },
  { no: 16, name: "CW_REPORT_SETTINGS_EDITED" },
  { no: 17, name: "CW_REPORT_BRANDS_UPDATED" },
  { no: 18, name: "CW_BRAND_CREATED" },
  { no: 19, name: "CW_BRAND_UPDATED" },
  { no: 20, name: "CW_SET_CREATED" },
  { no: 21, name: "CW_SET_UPDATED" },
]);

/**
 * @generated from enum common.proto.InfluencerAdditionDetails
 */
export enum InfluencerAdditionDetails {
  /**
   * @generated from enum value: INFLUENCER_ADDITION_DETAILS_UNKNOWN = 0;
   */
  INFLUENCER_ADDITION_DETAILS_UNKNOWN = 0,

  /**
   * to campaign
   *
   * @generated from enum value: ADDED_MANUALLY = 1;
   */
  ADDED_MANUALLY = 1,

  /**
   * @generated from enum value: UPLOADED_MANUALLY = 2;
   */
  UPLOADED_MANUALLY = 2,

  /**
   * @generated from enum value: ADDED_BECAUSE_POST_MARKED = 3;
   */
  ADDED_BECAUSE_POST_MARKED = 3,

  /**
   * @generated from enum value: UPLOADED_BY_CLI_TOOL = 4;
   */
  UPLOADED_BY_CLI_TOOL = 4,

  /**
   * @generated from enum value: ADDED_BECAUSE_AFFILIATION_LINK_CREATED = 5;
   */
  ADDED_BECAUSE_AFFILIATION_LINK_CREATED = 5,

  /**
   * @generated from enum value: ADDED_BECAUSE_INVITED = 6;
   */
  ADDED_BECAUSE_INVITED = 6,

  /**
   * @generated from enum value: ADDED_BECAUSE_CASTING_CONVERTED_CAMPAIGN = 7;
   */
  ADDED_BECAUSE_CASTING_CONVERTED_CAMPAIGN = 7,

  /**
   * to directory
   *
   * i.e. from any influencer listing (campaign, affiliation, casting...) or profile
   *
   * @generated from enum value: ADDED_MANUALLY_TO_DIRECTORY = 8;
   */
  ADDED_MANUALLY_TO_DIRECTORY = 8,

  /**
   * @generated from enum value: UPLOADED_MANUALLY_FROM_DIRECTORY = 9;
   */
  UPLOADED_MANUALLY_FROM_DIRECTORY = 9,

  /**
   * @generated from enum value: UPLOADED_FROM_FILE_FROM_DIRECTORY = 10;
   */
  UPLOADED_FROM_FILE_FROM_DIRECTORY = 10,

  /**
   * @generated from enum value: ADDED_MANUALLY_TO_CASTING = 11;
   */
  ADDED_MANUALLY_TO_CASTING = 11,

  /**
   * @generated from enum value: UPLOADED_MANUALLY_FROM_CASTING = 12;
   */
  UPLOADED_MANUALLY_FROM_CASTING = 12,

  /**
   * @generated from enum value: UPLOADED_MANUALLY_FROM_AFFILIATION = 13;
   */
  UPLOADED_MANUALLY_FROM_AFFILIATION = 13,

  /**
   * @generated from enum value: ADDED_BECAUSE_SET_LABELS = 14;
   */
  ADDED_BECAUSE_SET_LABELS = 14,

  /**
   * @generated from enum value: ADDED_BECAUSE_SET_CREATOR_FIELDS = 15;
   */
  ADDED_BECAUSE_SET_CREATOR_FIELDS = 15,
}
// Retrieve enum metadata with: proto3.getEnumType(InfluencerAdditionDetails)
proto3.util.setEnumType(InfluencerAdditionDetails, "common.proto.InfluencerAdditionDetails", [
  { no: 0, name: "INFLUENCER_ADDITION_DETAILS_UNKNOWN" },
  { no: 1, name: "ADDED_MANUALLY" },
  { no: 2, name: "UPLOADED_MANUALLY" },
  { no: 3, name: "ADDED_BECAUSE_POST_MARKED" },
  { no: 4, name: "UPLOADED_BY_CLI_TOOL" },
  { no: 5, name: "ADDED_BECAUSE_AFFILIATION_LINK_CREATED" },
  { no: 6, name: "ADDED_BECAUSE_INVITED" },
  { no: 7, name: "ADDED_BECAUSE_CASTING_CONVERTED_CAMPAIGN" },
  { no: 8, name: "ADDED_MANUALLY_TO_DIRECTORY" },
  { no: 9, name: "UPLOADED_MANUALLY_FROM_DIRECTORY" },
  { no: 10, name: "UPLOADED_FROM_FILE_FROM_DIRECTORY" },
  { no: 11, name: "ADDED_MANUALLY_TO_CASTING" },
  { no: 12, name: "UPLOADED_MANUALLY_FROM_CASTING" },
  { no: 13, name: "UPLOADED_MANUALLY_FROM_AFFILIATION" },
  { no: 14, name: "ADDED_BECAUSE_SET_LABELS" },
  { no: 15, name: "ADDED_BECAUSE_SET_CREATOR_FIELDS" },
]);

/**
 * Note that this is very similar to the actual edit CampaignRequest but we trimmed down useless stuff for clarity.
 *
 * @generated from message common.proto.CampaignSettings
 */
export class CampaignSettings extends Message<CampaignSettings> {
  /**
   * @generated from field: google.protobuf.StringValue name = 1;
   */
  name?: string;

  /**
   * @generated from field: google.protobuf.StringValue public_name = 2 [deprecated = true];
   * @deprecated
   */
  publicName?: string;

  /**
   * @generated from field: google.protobuf.StringValue brand_name = 3 [deprecated = true];
   * @deprecated
   */
  brandName?: string;

  /**
   * @generated from field: google.protobuf.StringValue description = 4 [deprecated = true];
   * @deprecated
   */
  description?: string;

  /**
   * @generated from field: google.protobuf.Int64Value cost = 5;
   */
  cost?: bigint;

  /**
   * @generated from field: google.protobuf.Timestamp start = 6;
   */
  start?: Timestamp;

  /**
   * @generated from field: google.protobuf.Timestamp end = 7;
   */
  end?: Timestamp;

  /**
   * @generated from field: repeated common.proto.CampaignKeyword keywords = 8;
   */
  keywords: CampaignKeyword[] = [];

  /**
   * @generated from field: google.protobuf.BoolValue exclude_performance = 9;
   */
  excludePerformance?: boolean;

  constructor(data?: PartialMessage<CampaignSettings>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.CampaignSettings";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "name", kind: "message", T: StringValue },
    { no: 2, name: "public_name", kind: "message", T: StringValue },
    { no: 3, name: "brand_name", kind: "message", T: StringValue },
    { no: 4, name: "description", kind: "message", T: StringValue },
    { no: 5, name: "cost", kind: "message", T: Int64Value },
    { no: 6, name: "start", kind: "message", T: Timestamp },
    { no: 7, name: "end", kind: "message", T: Timestamp },
    { no: 8, name: "keywords", kind: "message", T: CampaignKeyword, repeated: true },
    { no: 9, name: "exclude_performance", kind: "message", T: BoolValue },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CampaignSettings {
    return new CampaignSettings().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CampaignSettings {
    return new CampaignSettings().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CampaignSettings {
    return new CampaignSettings().fromJsonString(jsonString, options);
  }

  static equals(a: CampaignSettings | PlainMessage<CampaignSettings> | undefined, b: CampaignSettings | PlainMessage<CampaignSettings> | undefined): boolean {
    return proto3.util.equals(CampaignSettings, a, b);
  }
}

/**
 * @generated from message common.proto.CampaignSettingsItem
 */
export class CampaignSettingsItem extends Message<CampaignSettingsItem> {
  /**
   * @generated from field: common.proto.CampaignSettings previous_settings = 1;
   */
  previousSettings?: CampaignSettings;

  /**
   * @generated from field: common.proto.CampaignSettings new_settings = 2;
   */
  newSettings?: CampaignSettings;

  constructor(data?: PartialMessage<CampaignSettingsItem>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.CampaignSettingsItem";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "previous_settings", kind: "message", T: CampaignSettings },
    { no: 2, name: "new_settings", kind: "message", T: CampaignSettings },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CampaignSettingsItem {
    return new CampaignSettingsItem().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CampaignSettingsItem {
    return new CampaignSettingsItem().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CampaignSettingsItem {
    return new CampaignSettingsItem().fromJsonString(jsonString, options);
  }

  static equals(a: CampaignSettingsItem | PlainMessage<CampaignSettingsItem> | undefined, b: CampaignSettingsItem | PlainMessage<CampaignSettingsItem> | undefined): boolean {
    return proto3.util.equals(CampaignSettingsItem, a, b);
  }
}

/**
 * @generated from message common.proto.InfluencerAddedItem
 */
export class InfluencerAddedItem extends Message<InfluencerAddedItem> {
  /**
   * @generated from field: common.proto.MetaIdPb id = 1;
   */
  id?: MetaIdPb;

  /**
   * @generated from field: common.proto.InfluencerAdditionDetails details = 2;
   */
  details = InfluencerAdditionDetails.INFLUENCER_ADDITION_DETAILS_UNKNOWN;

  constructor(data?: PartialMessage<InfluencerAddedItem>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.InfluencerAddedItem";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "message", T: MetaIdPb },
    { no: 2, name: "details", kind: "enum", T: proto3.getEnumType(InfluencerAdditionDetails) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): InfluencerAddedItem {
    return new InfluencerAddedItem().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): InfluencerAddedItem {
    return new InfluencerAddedItem().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): InfluencerAddedItem {
    return new InfluencerAddedItem().fromJsonString(jsonString, options);
  }

  static equals(a: InfluencerAddedItem | PlainMessage<InfluencerAddedItem> | undefined, b: InfluencerAddedItem | PlainMessage<InfluencerAddedItem> | undefined): boolean {
    return proto3.util.equals(InfluencerAddedItem, a, b);
  }
}

/**
 * as of now there is nothing else then the id but we don't want to write the id directly
 * because it would make it impossible to ever evolve the proto with details in the future.
 *
 * @generated from message common.proto.InfluencerDeletedItem
 */
export class InfluencerDeletedItem extends Message<InfluencerDeletedItem> {
  /**
   * @generated from field: common.proto.MetaIdPb id = 1;
   */
  id?: MetaIdPb;

  constructor(data?: PartialMessage<InfluencerDeletedItem>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.InfluencerDeletedItem";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "message", T: MetaIdPb },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): InfluencerDeletedItem {
    return new InfluencerDeletedItem().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): InfluencerDeletedItem {
    return new InfluencerDeletedItem().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): InfluencerDeletedItem {
    return new InfluencerDeletedItem().fromJsonString(jsonString, options);
  }

  static equals(a: InfluencerDeletedItem | PlainMessage<InfluencerDeletedItem> | undefined, b: InfluencerDeletedItem | PlainMessage<InfluencerDeletedItem> | undefined): boolean {
    return proto3.util.equals(InfluencerDeletedItem, a, b);
  }
}

/**
 * @generated from message common.proto.AccountCreatedItem
 */
export class AccountCreatedItem extends Message<AccountCreatedItem> {
  /**
   * @generated from field: int64 user_account_id = 1;
   */
  userAccountId = protoInt64.zero;

  /**
   * Keep email at creation for the record
   *
   * @generated from field: string email = 2;
   */
  email = "";

  constructor(data?: PartialMessage<AccountCreatedItem>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.AccountCreatedItem";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "user_account_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AccountCreatedItem {
    return new AccountCreatedItem().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AccountCreatedItem {
    return new AccountCreatedItem().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AccountCreatedItem {
    return new AccountCreatedItem().fromJsonString(jsonString, options);
  }

  static equals(a: AccountCreatedItem | PlainMessage<AccountCreatedItem> | undefined, b: AccountCreatedItem | PlainMessage<AccountCreatedItem> | undefined): boolean {
    return proto3.util.equals(AccountCreatedItem, a, b);
  }
}

/**
 * @generated from message common.proto.TeamCreatedItem
 */
export class TeamCreatedItem extends Message<TeamCreatedItem> {
  /**
   * @generated from field: int64 team_id = 1;
   */
  teamId = protoInt64.zero;

  /**
   * @generated from field: string name = 2;
   */
  name = "";

  /**
   * @generated from field: int64 owner_id = 3;
   */
  ownerId = protoInt64.zero;

  /**
   * @generated from field: int64 owner_account_id = 4;
   */
  ownerAccountId = protoInt64.zero;

  /**
   * @generated from field: repeated int64 members_account_id = 5;
   */
  membersAccountId: bigint[] = [];

  /**
   * @generated from field: bool is_canary = 6;
   */
  isCanary = false;

  constructor(data?: PartialMessage<TeamCreatedItem>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.TeamCreatedItem";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "team_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "owner_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 4, name: "owner_account_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 5, name: "members_account_id", kind: "scalar", T: 3 /* ScalarType.INT64 */, repeated: true },
    { no: 6, name: "is_canary", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TeamCreatedItem {
    return new TeamCreatedItem().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TeamCreatedItem {
    return new TeamCreatedItem().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TeamCreatedItem {
    return new TeamCreatedItem().fromJsonString(jsonString, options);
  }

  static equals(a: TeamCreatedItem | PlainMessage<TeamCreatedItem> | undefined, b: TeamCreatedItem | PlainMessage<TeamCreatedItem> | undefined): boolean {
    return proto3.util.equals(TeamCreatedItem, a, b);
  }
}

/**
 * @generated from message common.proto.CampaignCreatedItem
 */
export class CampaignCreatedItem extends Message<CampaignCreatedItem> {
  /**
   * @generated from field: int64 campaign_id = 1;
   */
  campaignId = protoInt64.zero;

  /**
   * @generated from field: common.proto.CampaignSettings settings = 2;
   */
  settings?: CampaignSettings;

  constructor(data?: PartialMessage<CampaignCreatedItem>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.CampaignCreatedItem";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "campaign_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "settings", kind: "message", T: CampaignSettings },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CampaignCreatedItem {
    return new CampaignCreatedItem().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CampaignCreatedItem {
    return new CampaignCreatedItem().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CampaignCreatedItem {
    return new CampaignCreatedItem().fromJsonString(jsonString, options);
  }

  static equals(a: CampaignCreatedItem | PlainMessage<CampaignCreatedItem> | undefined, b: CampaignCreatedItem | PlainMessage<CampaignCreatedItem> | undefined): boolean {
    return proto3.util.equals(CampaignCreatedItem, a, b);
  }
}

/**
 * @generated from message common.proto.CampaignStatusUpdatedItem
 */
export class CampaignStatusUpdatedItem extends Message<CampaignStatusUpdatedItem> {
  /**
   * @generated from field: int64 campaign_id = 1;
   */
  campaignId = protoInt64.zero;

  /**
   * @generated from field: common.proto.CampaignStatus campaign_status = 2;
   */
  campaignStatus = CampaignStatus.CAMPAIGN_UNDEFINED;

  constructor(data?: PartialMessage<CampaignStatusUpdatedItem>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.CampaignStatusUpdatedItem";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "campaign_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "campaign_status", kind: "enum", T: proto3.getEnumType(CampaignStatus) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CampaignStatusUpdatedItem {
    return new CampaignStatusUpdatedItem().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CampaignStatusUpdatedItem {
    return new CampaignStatusUpdatedItem().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CampaignStatusUpdatedItem {
    return new CampaignStatusUpdatedItem().fromJsonString(jsonString, options);
  }

  static equals(a: CampaignStatusUpdatedItem | PlainMessage<CampaignStatusUpdatedItem> | undefined, b: CampaignStatusUpdatedItem | PlainMessage<CampaignStatusUpdatedItem> | undefined): boolean {
    return proto3.util.equals(CampaignStatusUpdatedItem, a, b);
  }
}

/**
 * @generated from message common.proto.CampaignPinnedItem
 */
export class CampaignPinnedItem extends Message<CampaignPinnedItem> {
  /**
   * @generated from field: int64 campaign_id = 1;
   */
  campaignId = protoInt64.zero;

  /**
   * @generated from field: bool pinned = 2;
   */
  pinned = false;

  constructor(data?: PartialMessage<CampaignPinnedItem>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.CampaignPinnedItem";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "campaign_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "pinned", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CampaignPinnedItem {
    return new CampaignPinnedItem().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CampaignPinnedItem {
    return new CampaignPinnedItem().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CampaignPinnedItem {
    return new CampaignPinnedItem().fromJsonString(jsonString, options);
  }

  static equals(a: CampaignPinnedItem | PlainMessage<CampaignPinnedItem> | undefined, b: CampaignPinnedItem | PlainMessage<CampaignPinnedItem> | undefined): boolean {
    return proto3.util.equals(CampaignPinnedItem, a, b);
  }
}

/**
 * @generated from message common.proto.CampaignMovedItem
 */
export class CampaignMovedItem extends Message<CampaignMovedItem> {
  /**
   * @generated from field: int64 campaign_id = 1;
   */
  campaignId = protoInt64.zero;

  /**
   * @generated from field: int64 previous_user_id = 2;
   */
  previousUserId = protoInt64.zero;

  /**
   * @generated from field: int64 new_user_id = 3;
   */
  newUserId = protoInt64.zero;

  constructor(data?: PartialMessage<CampaignMovedItem>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.CampaignMovedItem";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "campaign_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "previous_user_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "new_user_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CampaignMovedItem {
    return new CampaignMovedItem().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CampaignMovedItem {
    return new CampaignMovedItem().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CampaignMovedItem {
    return new CampaignMovedItem().fromJsonString(jsonString, options);
  }

  static equals(a: CampaignMovedItem | PlainMessage<CampaignMovedItem> | undefined, b: CampaignMovedItem | PlainMessage<CampaignMovedItem> | undefined): boolean {
    return proto3.util.equals(CampaignMovedItem, a, b);
  }
}

/**
 * @generated from message common.proto.CampaignLabelsUpdatedItem
 */
export class CampaignLabelsUpdatedItem extends Message<CampaignLabelsUpdatedItem> {
  /**
   * @generated from field: int64 campaign_id = 1;
   */
  campaignId = protoInt64.zero;

  /**
   * @generated from field: repeated common.proto.CampaignLabelsUpdatedItem.CampaignLabelItem previous_labels_items = 2;
   */
  previousLabelsItems: CampaignLabelsUpdatedItem_CampaignLabelItem[] = [];

  /**
   * @generated from field: repeated common.proto.CampaignLabelsUpdatedItem.CampaignLabelItem new_labels_items = 3;
   */
  newLabelsItems: CampaignLabelsUpdatedItem_CampaignLabelItem[] = [];

  constructor(data?: PartialMessage<CampaignLabelsUpdatedItem>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.CampaignLabelsUpdatedItem";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "campaign_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "previous_labels_items", kind: "message", T: CampaignLabelsUpdatedItem_CampaignLabelItem, repeated: true },
    { no: 3, name: "new_labels_items", kind: "message", T: CampaignLabelsUpdatedItem_CampaignLabelItem, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CampaignLabelsUpdatedItem {
    return new CampaignLabelsUpdatedItem().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CampaignLabelsUpdatedItem {
    return new CampaignLabelsUpdatedItem().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CampaignLabelsUpdatedItem {
    return new CampaignLabelsUpdatedItem().fromJsonString(jsonString, options);
  }

  static equals(a: CampaignLabelsUpdatedItem | PlainMessage<CampaignLabelsUpdatedItem> | undefined, b: CampaignLabelsUpdatedItem | PlainMessage<CampaignLabelsUpdatedItem> | undefined): boolean {
    return proto3.util.equals(CampaignLabelsUpdatedItem, a, b);
  }
}

/**
 * @generated from message common.proto.CampaignLabelsUpdatedItem.CampaignLabelItem
 */
export class CampaignLabelsUpdatedItem_CampaignLabelItem extends Message<CampaignLabelsUpdatedItem_CampaignLabelItem> {
  /**
   * @generated from field: int64 campaign_label_id = 1;
   */
  campaignLabelId = protoInt64.zero;

  /**
   * @generated from field: int64 user_tag_id = 2;
   */
  userTagId = protoInt64.zero;

  /**
   * @generated from field: string name = 3;
   */
  name = "";

  constructor(data?: PartialMessage<CampaignLabelsUpdatedItem_CampaignLabelItem>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.CampaignLabelsUpdatedItem.CampaignLabelItem";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "campaign_label_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "user_tag_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CampaignLabelsUpdatedItem_CampaignLabelItem {
    return new CampaignLabelsUpdatedItem_CampaignLabelItem().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CampaignLabelsUpdatedItem_CampaignLabelItem {
    return new CampaignLabelsUpdatedItem_CampaignLabelItem().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CampaignLabelsUpdatedItem_CampaignLabelItem {
    return new CampaignLabelsUpdatedItem_CampaignLabelItem().fromJsonString(jsonString, options);
  }

  static equals(a: CampaignLabelsUpdatedItem_CampaignLabelItem | PlainMessage<CampaignLabelsUpdatedItem_CampaignLabelItem> | undefined, b: CampaignLabelsUpdatedItem_CampaignLabelItem | PlainMessage<CampaignLabelsUpdatedItem_CampaignLabelItem> | undefined): boolean {
    return proto3.util.equals(CampaignLabelsUpdatedItem_CampaignLabelItem, a, b);
  }
}

/**
 * @generated from message common.proto.VisualPerfEditionItem
 */
export class VisualPerfEditionItem extends Message<VisualPerfEditionItem> {
  /**
   * @generated from field: common.proto.MediaIdPb id = 1;
   */
  id?: MediaIdPb;

  /**
   * @generated from field: common.proto.VisualEditedPerf perf = 2;
   */
  perf?: VisualEditedPerf;

  constructor(data?: PartialMessage<VisualPerfEditionItem>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.VisualPerfEditionItem";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "message", T: MediaIdPb },
    { no: 2, name: "perf", kind: "message", T: VisualEditedPerf },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): VisualPerfEditionItem {
    return new VisualPerfEditionItem().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): VisualPerfEditionItem {
    return new VisualPerfEditionItem().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): VisualPerfEditionItem {
    return new VisualPerfEditionItem().fromJsonString(jsonString, options);
  }

  static equals(a: VisualPerfEditionItem | PlainMessage<VisualPerfEditionItem> | undefined, b: VisualPerfEditionItem | PlainMessage<VisualPerfEditionItem> | undefined): boolean {
    return proto3.util.equals(VisualPerfEditionItem, a, b);
  }
}

/**
 * @generated from message common.proto.VisualsMarkedOrUnmarkedItem
 */
export class VisualsMarkedOrUnmarkedItem extends Message<VisualsMarkedOrUnmarkedItem> {
  /**
   * @generated from field: int64 campaign_id = 1;
   */
  campaignId = protoInt64.zero;

  /**
   * @generated from field: repeated common.proto.MediaIdPb ids = 2;
   */
  ids: MediaIdPb[] = [];

  /**
   * @generated from field: bool unmarked = 3;
   */
  unmarked = false;

  constructor(data?: PartialMessage<VisualsMarkedOrUnmarkedItem>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.VisualsMarkedOrUnmarkedItem";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "campaign_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "ids", kind: "message", T: MediaIdPb, repeated: true },
    { no: 3, name: "unmarked", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): VisualsMarkedOrUnmarkedItem {
    return new VisualsMarkedOrUnmarkedItem().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): VisualsMarkedOrUnmarkedItem {
    return new VisualsMarkedOrUnmarkedItem().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): VisualsMarkedOrUnmarkedItem {
    return new VisualsMarkedOrUnmarkedItem().fromJsonString(jsonString, options);
  }

  static equals(a: VisualsMarkedOrUnmarkedItem | PlainMessage<VisualsMarkedOrUnmarkedItem> | undefined, b: VisualsMarkedOrUnmarkedItem | PlainMessage<VisualsMarkedOrUnmarkedItem> | undefined): boolean {
    return proto3.util.equals(VisualsMarkedOrUnmarkedItem, a, b);
  }
}

/**
 * @generated from message common.proto.PostUploadedManuallyItem
 */
export class PostUploadedManuallyItem extends Message<PostUploadedManuallyItem> {
  /**
   * @generated from field: int64 campaign_id = 1;
   */
  campaignId = protoInt64.zero;

  /**
   * @generated from field: common.proto.MediaIdPb id = 2;
   */
  id?: MediaIdPb;

  constructor(data?: PartialMessage<PostUploadedManuallyItem>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.PostUploadedManuallyItem";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "campaign_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "id", kind: "message", T: MediaIdPb },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): PostUploadedManuallyItem {
    return new PostUploadedManuallyItem().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): PostUploadedManuallyItem {
    return new PostUploadedManuallyItem().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): PostUploadedManuallyItem {
    return new PostUploadedManuallyItem().fromJsonString(jsonString, options);
  }

  static equals(a: PostUploadedManuallyItem | PlainMessage<PostUploadedManuallyItem> | undefined, b: PostUploadedManuallyItem | PlainMessage<PostUploadedManuallyItem> | undefined): boolean {
    return proto3.util.equals(PostUploadedManuallyItem, a, b);
  }
}

/**
 * @generated from message common.proto.CpmItem
 */
export class CpmItem extends Message<CpmItem> {
  /**
   * @generated from field: common.proto.PostType post_type = 1;
   */
  postType = PostType.POST_TYPE_UNDEFINED;

  /**
   * @generated from oneof common.proto.CpmItem.the_value
   */
  theValue: {
    /**
     * @generated from field: float value = 2;
     */
    value: number;
    case: "value";
  } | {
    /**
     * @generated from field: bool reset_to_default = 3;
     */
    value: boolean;
    case: "resetToDefault";
  } | { case: undefined; value?: undefined } = { case: undefined };

  constructor(data?: PartialMessage<CpmItem>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.CpmItem";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "post_type", kind: "enum", T: proto3.getEnumType(PostType) },
    { no: 2, name: "value", kind: "scalar", T: 2 /* ScalarType.FLOAT */, oneof: "the_value" },
    { no: 3, name: "reset_to_default", kind: "scalar", T: 8 /* ScalarType.BOOL */, oneof: "the_value" },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CpmItem {
    return new CpmItem().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CpmItem {
    return new CpmItem().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CpmItem {
    return new CpmItem().fromJsonString(jsonString, options);
  }

  static equals(a: CpmItem | PlainMessage<CpmItem> | undefined, b: CpmItem | PlainMessage<CpmItem> | undefined): boolean {
    return proto3.util.equals(CpmItem, a, b);
  }
}

/**
 * @generated from message common.proto.CpmItems
 */
export class CpmItems extends Message<CpmItems> {
  /**
   * @generated from field: repeated common.proto.CpmItem cpms_items = 1;
   */
  cpmsItems: CpmItem[] = [];

  constructor(data?: PartialMessage<CpmItems>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.CpmItems";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "cpms_items", kind: "message", T: CpmItem, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CpmItems {
    return new CpmItems().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CpmItems {
    return new CpmItems().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CpmItems {
    return new CpmItems().fromJsonString(jsonString, options);
  }

  static equals(a: CpmItems | PlainMessage<CpmItems> | undefined, b: CpmItems | PlainMessage<CpmItems> | undefined): boolean {
    return proto3.util.equals(CpmItems, a, b);
  }
}

/**
 * @generated from message common.proto.CpmSettingsItem
 */
export class CpmSettingsItem extends Message<CpmSettingsItem> {
  /**
   * @generated from field: common.proto.CpmItems previous_cpms = 1;
   */
  previousCpms?: CpmItems;

  /**
   * @generated from field: common.proto.CpmItems new_cpms = 2;
   */
  newCpms?: CpmItems;

  constructor(data?: PartialMessage<CpmSettingsItem>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.CpmSettingsItem";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "previous_cpms", kind: "message", T: CpmItems },
    { no: 2, name: "new_cpms", kind: "message", T: CpmItems },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CpmSettingsItem {
    return new CpmSettingsItem().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CpmSettingsItem {
    return new CpmSettingsItem().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CpmSettingsItem {
    return new CpmSettingsItem().fromJsonString(jsonString, options);
  }

  static equals(a: CpmSettingsItem | PlainMessage<CpmSettingsItem> | undefined, b: CpmSettingsItem | PlainMessage<CpmSettingsItem> | undefined): boolean {
    return proto3.util.equals(CpmSettingsItem, a, b);
  }
}

/**
 * @generated from message common.proto.CWReportSettings
 */
export class CWReportSettings extends Message<CWReportSettings> {
  /**
   * @generated from field: google.protobuf.StringValue name = 1;
   */
  name?: string;

  /**
   * @generated from field: repeated int64 team_owner_ids = 10;
   */
  teamOwnerIds: bigint[] = [];

  /**
   * @generated from field: google.protobuf.Int64Value reference_brand = 3;
   */
  referenceBrand?: bigint;

  /**
   * @generated from field: repeated int64 brand_competitors = 11;
   */
  brandCompetitors: bigint[] = [];

  /**
   * @generated from field: repeated common.proto.Network networks = 4;
   */
  networks: Network[] = [];

  /**
   * @generated from field: repeated int64 campaign_ids = 5;
   */
  campaignIds: bigint[] = [];

  /**
   * @generated from field: google.protobuf.Timestamp start_date = 6;
   */
  startDate?: Timestamp;

  /**
   * @generated from field: google.protobuf.Timestamp expiry_date = 7;
   */
  expiryDate?: Timestamp;

  /**
   * @generated from field: google.protobuf.Int32Value min_followers = 8;
   */
  minFollowers?: number;

  /**
   * @generated from field: google.protobuf.BoolValue categorize_influencers = 9;
   */
  categorizeInfluencers?: boolean;

  constructor(data?: PartialMessage<CWReportSettings>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.CWReportSettings";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "name", kind: "message", T: StringValue },
    { no: 10, name: "team_owner_ids", kind: "scalar", T: 3 /* ScalarType.INT64 */, repeated: true },
    { no: 3, name: "reference_brand", kind: "message", T: Int64Value },
    { no: 11, name: "brand_competitors", kind: "scalar", T: 3 /* ScalarType.INT64 */, repeated: true },
    { no: 4, name: "networks", kind: "enum", T: proto3.getEnumType(Network), repeated: true },
    { no: 5, name: "campaign_ids", kind: "scalar", T: 3 /* ScalarType.INT64 */, repeated: true },
    { no: 6, name: "start_date", kind: "message", T: Timestamp },
    { no: 7, name: "expiry_date", kind: "message", T: Timestamp },
    { no: 8, name: "min_followers", kind: "message", T: Int32Value },
    { no: 9, name: "categorize_influencers", kind: "message", T: BoolValue },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CWReportSettings {
    return new CWReportSettings().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CWReportSettings {
    return new CWReportSettings().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CWReportSettings {
    return new CWReportSettings().fromJsonString(jsonString, options);
  }

  static equals(a: CWReportSettings | PlainMessage<CWReportSettings> | undefined, b: CWReportSettings | PlainMessage<CWReportSettings> | undefined): boolean {
    return proto3.util.equals(CWReportSettings, a, b);
  }
}

/**
 * @generated from message common.proto.CWReportCreatedItem
 */
export class CWReportCreatedItem extends Message<CWReportCreatedItem> {
  /**
   * @generated from field: int64 report_id = 1;
   */
  reportId = protoInt64.zero;

  /**
   * @generated from field: common.proto.CWReportSettings settings = 2;
   */
  settings?: CWReportSettings;

  constructor(data?: PartialMessage<CWReportCreatedItem>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.CWReportCreatedItem";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "report_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "settings", kind: "message", T: CWReportSettings },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CWReportCreatedItem {
    return new CWReportCreatedItem().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CWReportCreatedItem {
    return new CWReportCreatedItem().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CWReportCreatedItem {
    return new CWReportCreatedItem().fromJsonString(jsonString, options);
  }

  static equals(a: CWReportCreatedItem | PlainMessage<CWReportCreatedItem> | undefined, b: CWReportCreatedItem | PlainMessage<CWReportCreatedItem> | undefined): boolean {
    return proto3.util.equals(CWReportCreatedItem, a, b);
  }
}

/**
 * @generated from message common.proto.CWReportSettingsItem
 */
export class CWReportSettingsItem extends Message<CWReportSettingsItem> {
  /**
   * @generated from field: common.proto.CWReportSettings previous_settings = 1;
   */
  previousSettings?: CWReportSettings;

  /**
   * @generated from field: common.proto.CWReportSettings new_settings = 2;
   */
  newSettings?: CWReportSettings;

  constructor(data?: PartialMessage<CWReportSettingsItem>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.CWReportSettingsItem";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "previous_settings", kind: "message", T: CWReportSettings },
    { no: 2, name: "new_settings", kind: "message", T: CWReportSettings },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CWReportSettingsItem {
    return new CWReportSettingsItem().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CWReportSettingsItem {
    return new CWReportSettingsItem().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CWReportSettingsItem {
    return new CWReportSettingsItem().fromJsonString(jsonString, options);
  }

  static equals(a: CWReportSettingsItem | PlainMessage<CWReportSettingsItem> | undefined, b: CWReportSettingsItem | PlainMessage<CWReportSettingsItem> | undefined): boolean {
    return proto3.util.equals(CWReportSettingsItem, a, b);
  }
}

/**
 * @generated from message common.proto.CWReportBrandsUpdatedItem
 */
export class CWReportBrandsUpdatedItem extends Message<CWReportBrandsUpdatedItem> {
  /**
   * @generated from field: int64 report_id = 1;
   */
  reportId = protoInt64.zero;

  /**
   * @generated from oneof common.proto.CWReportBrandsUpdatedItem.brand_id
   */
  brandId: {
    /**
     * @generated from field: int64 added = 2;
     */
    value: bigint;
    case: "added";
  } | {
    /**
     * @generated from field: int64 removed = 3;
     */
    value: bigint;
    case: "removed";
  } | { case: undefined; value?: undefined } = { case: undefined };

  constructor(data?: PartialMessage<CWReportBrandsUpdatedItem>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.CWReportBrandsUpdatedItem";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "report_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "added", kind: "scalar", T: 3 /* ScalarType.INT64 */, oneof: "brand_id" },
    { no: 3, name: "removed", kind: "scalar", T: 3 /* ScalarType.INT64 */, oneof: "brand_id" },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CWReportBrandsUpdatedItem {
    return new CWReportBrandsUpdatedItem().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CWReportBrandsUpdatedItem {
    return new CWReportBrandsUpdatedItem().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CWReportBrandsUpdatedItem {
    return new CWReportBrandsUpdatedItem().fromJsonString(jsonString, options);
  }

  static equals(a: CWReportBrandsUpdatedItem | PlainMessage<CWReportBrandsUpdatedItem> | undefined, b: CWReportBrandsUpdatedItem | PlainMessage<CWReportBrandsUpdatedItem> | undefined): boolean {
    return proto3.util.equals(CWReportBrandsUpdatedItem, a, b);
  }
}

/**
 * @generated from message common.proto.CWBrandSettings
 */
export class CWBrandSettings extends Message<CWBrandSettings> {
  /**
   * @generated from field: google.protobuf.StringValue name = 1;
   */
  name?: string;

  /**
   * @generated from field: repeated common.proto.CampaignKeyword keywords = 2;
   */
  keywords: CampaignKeyword[] = [];

  /**
   * @generated from field: repeated common.proto.CampaignKeyword keywordFilters = 3;
   */
  keywordFilters: CampaignKeyword[] = [];

  constructor(data?: PartialMessage<CWBrandSettings>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.CWBrandSettings";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "name", kind: "message", T: StringValue },
    { no: 2, name: "keywords", kind: "message", T: CampaignKeyword, repeated: true },
    { no: 3, name: "keywordFilters", kind: "message", T: CampaignKeyword, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CWBrandSettings {
    return new CWBrandSettings().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CWBrandSettings {
    return new CWBrandSettings().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CWBrandSettings {
    return new CWBrandSettings().fromJsonString(jsonString, options);
  }

  static equals(a: CWBrandSettings | PlainMessage<CWBrandSettings> | undefined, b: CWBrandSettings | PlainMessage<CWBrandSettings> | undefined): boolean {
    return proto3.util.equals(CWBrandSettings, a, b);
  }
}

/**
 * @generated from message common.proto.CWBrandCreatedItem
 */
export class CWBrandCreatedItem extends Message<CWBrandCreatedItem> {
  /**
   * @generated from field: int64 brand_id = 1;
   */
  brandId = protoInt64.zero;

  /**
   * @generated from field: common.proto.CWBrandSettings settings = 2;
   */
  settings?: CWBrandSettings;

  constructor(data?: PartialMessage<CWBrandCreatedItem>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.CWBrandCreatedItem";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "brand_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "settings", kind: "message", T: CWBrandSettings },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CWBrandCreatedItem {
    return new CWBrandCreatedItem().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CWBrandCreatedItem {
    return new CWBrandCreatedItem().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CWBrandCreatedItem {
    return new CWBrandCreatedItem().fromJsonString(jsonString, options);
  }

  static equals(a: CWBrandCreatedItem | PlainMessage<CWBrandCreatedItem> | undefined, b: CWBrandCreatedItem | PlainMessage<CWBrandCreatedItem> | undefined): boolean {
    return proto3.util.equals(CWBrandCreatedItem, a, b);
  }
}

/**
 * @generated from message common.proto.CWBrandUpdatedItem
 */
export class CWBrandUpdatedItem extends Message<CWBrandUpdatedItem> {
  /**
   * @generated from field: common.proto.CWBrandSettings previous_settings = 1;
   */
  previousSettings?: CWBrandSettings;

  /**
   * @generated from field: common.proto.CWBrandSettings new_settings = 2;
   */
  newSettings?: CWBrandSettings;

  constructor(data?: PartialMessage<CWBrandUpdatedItem>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.CWBrandUpdatedItem";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "previous_settings", kind: "message", T: CWBrandSettings },
    { no: 2, name: "new_settings", kind: "message", T: CWBrandSettings },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CWBrandUpdatedItem {
    return new CWBrandUpdatedItem().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CWBrandUpdatedItem {
    return new CWBrandUpdatedItem().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CWBrandUpdatedItem {
    return new CWBrandUpdatedItem().fromJsonString(jsonString, options);
  }

  static equals(a: CWBrandUpdatedItem | PlainMessage<CWBrandUpdatedItem> | undefined, b: CWBrandUpdatedItem | PlainMessage<CWBrandUpdatedItem> | undefined): boolean {
    return proto3.util.equals(CWBrandUpdatedItem, a, b);
  }
}

/**
 * @generated from message common.proto.CWSetSettings
 */
export class CWSetSettings extends Message<CWSetSettings> {
  /**
   * @generated from field: google.protobuf.StringValue name = 1;
   */
  name?: string;

  /**
   * @generated from field: google.protobuf.StringValue description = 2;
   */
  description?: string;

  /**
   * @generated from field: google.protobuf.Int64Value market_id = 4;
   */
  marketId?: bigint;

  /**
   * @generated from field: google.protobuf.Int64Value industry_id = 5;
   */
  industryId?: bigint;

  /**
   * @generated from field: repeated common.proto.CWSetSettings.CWSetSource sources = 3;
   */
  sources: CWSetSettings_CWSetSource[] = [];

  constructor(data?: PartialMessage<CWSetSettings>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.CWSetSettings";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "name", kind: "message", T: StringValue },
    { no: 2, name: "description", kind: "message", T: StringValue },
    { no: 4, name: "market_id", kind: "message", T: Int64Value },
    { no: 5, name: "industry_id", kind: "message", T: Int64Value },
    { no: 3, name: "sources", kind: "message", T: CWSetSettings_CWSetSource, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CWSetSettings {
    return new CWSetSettings().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CWSetSettings {
    return new CWSetSettings().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CWSetSettings {
    return new CWSetSettings().fromJsonString(jsonString, options);
  }

  static equals(a: CWSetSettings | PlainMessage<CWSetSettings> | undefined, b: CWSetSettings | PlainMessage<CWSetSettings> | undefined): boolean {
    return proto3.util.equals(CWSetSettings, a, b);
  }
}

/**
 * @generated from message common.proto.CWSetSettings.CWSetSource
 */
export class CWSetSettings_CWSetSource extends Message<CWSetSettings_CWSetSource> {
  /**
   * @generated from field: int64 source_id = 1;
   */
  sourceId = protoInt64.zero;

  /**
   * @generated from field: common.proto.Network network = 2;
   */
  network = Network.NETWORK_UNKNOWN;

  /**
   * @generated from field: common.proto.SourceType type = 3;
   */
  type = SourceType.SOURCE_UNKNOWN;

  constructor(data?: PartialMessage<CWSetSettings_CWSetSource>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.CWSetSettings.CWSetSource";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "source_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "network", kind: "enum", T: proto3.getEnumType(Network) },
    { no: 3, name: "type", kind: "enum", T: proto3.getEnumType(SourceType) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CWSetSettings_CWSetSource {
    return new CWSetSettings_CWSetSource().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CWSetSettings_CWSetSource {
    return new CWSetSettings_CWSetSource().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CWSetSettings_CWSetSource {
    return new CWSetSettings_CWSetSource().fromJsonString(jsonString, options);
  }

  static equals(a: CWSetSettings_CWSetSource | PlainMessage<CWSetSettings_CWSetSource> | undefined, b: CWSetSettings_CWSetSource | PlainMessage<CWSetSettings_CWSetSource> | undefined): boolean {
    return proto3.util.equals(CWSetSettings_CWSetSource, a, b);
  }
}

/**
 * @generated from message common.proto.CWSetCreatedItem
 */
export class CWSetCreatedItem extends Message<CWSetCreatedItem> {
  /**
   * @generated from field: int64 set_id = 1;
   */
  setId = protoInt64.zero;

  /**
   * @generated from field: common.proto.CWSetSettings settings = 2;
   */
  settings?: CWSetSettings;

  constructor(data?: PartialMessage<CWSetCreatedItem>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.CWSetCreatedItem";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "set_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "settings", kind: "message", T: CWSetSettings },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CWSetCreatedItem {
    return new CWSetCreatedItem().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CWSetCreatedItem {
    return new CWSetCreatedItem().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CWSetCreatedItem {
    return new CWSetCreatedItem().fromJsonString(jsonString, options);
  }

  static equals(a: CWSetCreatedItem | PlainMessage<CWSetCreatedItem> | undefined, b: CWSetCreatedItem | PlainMessage<CWSetCreatedItem> | undefined): boolean {
    return proto3.util.equals(CWSetCreatedItem, a, b);
  }
}

/**
 * @generated from message common.proto.CWSetUpdatedItem
 */
export class CWSetUpdatedItem extends Message<CWSetUpdatedItem> {
  /**
   * @generated from field: common.proto.CWSetSettings previous_settings = 1;
   */
  previousSettings?: CWSetSettings;

  /**
   * @generated from field: common.proto.CWSetSettings new_settings = 2;
   */
  newSettings?: CWSetSettings;

  constructor(data?: PartialMessage<CWSetUpdatedItem>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.CWSetUpdatedItem";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "previous_settings", kind: "message", T: CWSetSettings },
    { no: 2, name: "new_settings", kind: "message", T: CWSetSettings },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CWSetUpdatedItem {
    return new CWSetUpdatedItem().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CWSetUpdatedItem {
    return new CWSetUpdatedItem().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CWSetUpdatedItem {
    return new CWSetUpdatedItem().fromJsonString(jsonString, options);
  }

  static equals(a: CWSetUpdatedItem | PlainMessage<CWSetUpdatedItem> | undefined, b: CWSetUpdatedItem | PlainMessage<CWSetUpdatedItem> | undefined): boolean {
    return proto3.util.equals(CWSetUpdatedItem, a, b);
  }
}

/**
 * one item is inserted for each action that we want to log.
 *
 * @generated from message common.proto.NightwatchItem
 */
export class NightwatchItem extends Message<NightwatchItem> {
  /**
   * The key can be made up of elements in any way that makes it unique, for instance any item associated with a
   * campaign will be "campaign_12345". The keys are managed all in the same place (io.lefty.nightwatch.Keys).
   *
   * @generated from field: string key = 1;
   */
  key = "";

  /**
   * @generated from field: google.protobuf.Timestamp created = 2;
   */
  created?: Timestamp;

  /**
   * @generated from field: common.proto.NightwatchItemType type = 3;
   */
  type = NightwatchItemType.NIGHTWATCH_ITEM_TYPE_UNKNOWN;

  /**
   * This can be null (eg: 0) if the call is programmatic.
   *
   * @generated from field: int64 team_owner_id = 4;
   */
  teamOwnerId = protoInt64.zero;

  /**
   * @generated from field: int64 real_user_id = 5;
   */
  realUserId = protoInt64.zero;

  /**
   * @generated from field: bytes body = 6;
   */
  body = new Uint8Array(0);

  constructor(data?: PartialMessage<NightwatchItem>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.NightwatchItem";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "key", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "created", kind: "message", T: Timestamp },
    { no: 3, name: "type", kind: "enum", T: proto3.getEnumType(NightwatchItemType) },
    { no: 4, name: "team_owner_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 5, name: "real_user_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 6, name: "body", kind: "scalar", T: 12 /* ScalarType.BYTES */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): NightwatchItem {
    return new NightwatchItem().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): NightwatchItem {
    return new NightwatchItem().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): NightwatchItem {
    return new NightwatchItem().fromJsonString(jsonString, options);
  }

  static equals(a: NightwatchItem | PlainMessage<NightwatchItem> | undefined, b: NightwatchItem | PlainMessage<NightwatchItem> | undefined): boolean {
    return proto3.util.equals(NightwatchItem, a, b);
  }
}

/**
 * This is used when we need to carry around RPC calls the real user in charge of an action, as well as any other
 * items that may come up later. More typesafe than a naked long and it carries the intent that this is only for
 * nightwatch logging.
 *
 * @generated from message common.proto.NightwatchCtx
 */
export class NightwatchCtx extends Message<NightwatchCtx> {
  /**
   * @generated from field: int64 real_user_id = 1;
   */
  realUserId = protoInt64.zero;

  /**
   * @generated from field: common.proto.InfluencerAdditionDetails addition_details = 2;
   */
  additionDetails = InfluencerAdditionDetails.INFLUENCER_ADDITION_DETAILS_UNKNOWN;

  constructor(data?: PartialMessage<NightwatchCtx>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.NightwatchCtx";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "real_user_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "addition_details", kind: "enum", T: proto3.getEnumType(InfluencerAdditionDetails) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): NightwatchCtx {
    return new NightwatchCtx().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): NightwatchCtx {
    return new NightwatchCtx().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): NightwatchCtx {
    return new NightwatchCtx().fromJsonString(jsonString, options);
  }

  static equals(a: NightwatchCtx | PlainMessage<NightwatchCtx> | undefined, b: NightwatchCtx | PlainMessage<NightwatchCtx> | undefined): boolean {
    return proto3.util.equals(NightwatchCtx, a, b);
  }
}

/**
 * @generated from message common.proto.NightwatchDebugReq
 */
export class NightwatchDebugReq extends Message<NightwatchDebugReq> {
  /**
   * @generated from field: google.protobuf.Timestamp start = 1;
   */
  start?: Timestamp;

  /**
   * @generated from field: google.protobuf.Timestamp end = 2;
   */
  end?: Timestamp;

  /**
   * @generated from oneof common.proto.NightwatchDebugReq.key
   */
  key: {
    /**
     * @generated from field: int64 campaign_id = 3;
     */
    value: bigint;
    case: "campaignId";
  } | {
    /**
     * @generated from field: int64 user_account_id = 4;
     */
    value: bigint;
    case: "userAccountId";
  } | {
    /**
     * @generated from field: string media_id = 5;
     */
    value: string;
    case: "mediaId";
  } | {
    /**
     * @generated from field: int64 report_id = 6;
     */
    value: bigint;
    case: "reportId";
  } | {
    /**
     * @generated from field: int64 brand_id = 7;
     */
    value: bigint;
    case: "brandId";
  } | {
    /**
     * @generated from field: int64 set_id = 8;
     */
    value: bigint;
    case: "setId";
  } | {
    /**
     * @generated from field: int64 team_owner_id = 9;
     */
    value: bigint;
    case: "teamOwnerId";
  } | { case: undefined; value?: undefined } = { case: undefined };

  constructor(data?: PartialMessage<NightwatchDebugReq>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.NightwatchDebugReq";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "start", kind: "message", T: Timestamp },
    { no: 2, name: "end", kind: "message", T: Timestamp },
    { no: 3, name: "campaign_id", kind: "scalar", T: 3 /* ScalarType.INT64 */, oneof: "key" },
    { no: 4, name: "user_account_id", kind: "scalar", T: 3 /* ScalarType.INT64 */, oneof: "key" },
    { no: 5, name: "media_id", kind: "scalar", T: 9 /* ScalarType.STRING */, oneof: "key" },
    { no: 6, name: "report_id", kind: "scalar", T: 3 /* ScalarType.INT64 */, oneof: "key" },
    { no: 7, name: "brand_id", kind: "scalar", T: 3 /* ScalarType.INT64 */, oneof: "key" },
    { no: 8, name: "set_id", kind: "scalar", T: 3 /* ScalarType.INT64 */, oneof: "key" },
    { no: 9, name: "team_owner_id", kind: "scalar", T: 3 /* ScalarType.INT64 */, oneof: "key" },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): NightwatchDebugReq {
    return new NightwatchDebugReq().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): NightwatchDebugReq {
    return new NightwatchDebugReq().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): NightwatchDebugReq {
    return new NightwatchDebugReq().fromJsonString(jsonString, options);
  }

  static equals(a: NightwatchDebugReq | PlainMessage<NightwatchDebugReq> | undefined, b: NightwatchDebugReq | PlainMessage<NightwatchDebugReq> | undefined): boolean {
    return proto3.util.equals(NightwatchDebugReq, a, b);
  }
}

/**
 * @generated from message common.proto.NightwatchDebugResp
 */
export class NightwatchDebugResp extends Message<NightwatchDebugResp> {
  /**
   * @generated from field: repeated common.proto.NightwatchDebugResp.NightwatchDebugItem items = 1;
   */
  items: NightwatchDebugResp_NightwatchDebugItem[] = [];

  constructor(data?: PartialMessage<NightwatchDebugResp>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.NightwatchDebugResp";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "items", kind: "message", T: NightwatchDebugResp_NightwatchDebugItem, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): NightwatchDebugResp {
    return new NightwatchDebugResp().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): NightwatchDebugResp {
    return new NightwatchDebugResp().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): NightwatchDebugResp {
    return new NightwatchDebugResp().fromJsonString(jsonString, options);
  }

  static equals(a: NightwatchDebugResp | PlainMessage<NightwatchDebugResp> | undefined, b: NightwatchDebugResp | PlainMessage<NightwatchDebugResp> | undefined): boolean {
    return proto3.util.equals(NightwatchDebugResp, a, b);
  }
}

/**
 * a nightwatch item with minimal rendering, suitable for putting in a debug page.
 *
 * @generated from message common.proto.NightwatchDebugResp.NightwatchDebugItem
 */
export class NightwatchDebugResp_NightwatchDebugItem extends Message<NightwatchDebugResp_NightwatchDebugItem> {
  /**
   * @generated from field: google.protobuf.Timestamp created = 1;
   */
  created?: Timestamp;

  /**
   * @generated from field: common.proto.NightwatchItemType type = 2;
   */
  type = NightwatchItemType.NIGHTWATCH_ITEM_TYPE_UNKNOWN;

  /**
   * @generated from field: string team_owner_email = 3;
   */
  teamOwnerEmail = "";

  /**
   * @generated from field: string real_user_email = 4;
   */
  realUserEmail = "";

  /**
   * @generated from field: string team_name = 6;
   */
  teamName = "";

  /**
   * The front could insert this in the doc directly. It is rudimentary formatted HTML.
   *
   * @generated from field: string text = 5;
   */
  text = "";

  constructor(data?: PartialMessage<NightwatchDebugResp_NightwatchDebugItem>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.NightwatchDebugResp.NightwatchDebugItem";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "created", kind: "message", T: Timestamp },
    { no: 2, name: "type", kind: "enum", T: proto3.getEnumType(NightwatchItemType) },
    { no: 3, name: "team_owner_email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "real_user_email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "team_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "text", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): NightwatchDebugResp_NightwatchDebugItem {
    return new NightwatchDebugResp_NightwatchDebugItem().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): NightwatchDebugResp_NightwatchDebugItem {
    return new NightwatchDebugResp_NightwatchDebugItem().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): NightwatchDebugResp_NightwatchDebugItem {
    return new NightwatchDebugResp_NightwatchDebugItem().fromJsonString(jsonString, options);
  }

  static equals(a: NightwatchDebugResp_NightwatchDebugItem | PlainMessage<NightwatchDebugResp_NightwatchDebugItem> | undefined, b: NightwatchDebugResp_NightwatchDebugItem | PlainMessage<NightwatchDebugResp_NightwatchDebugItem> | undefined): boolean {
    return proto3.util.equals(NightwatchDebugResp_NightwatchDebugItem, a, b);
  }
}

