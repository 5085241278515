// @generated by protoc-gen-es v1.10.0 with parameter "target=ts"
// @generated from file librarian/proto/products.proto (package librarian.proto, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { DoubleValue, Int64Value, Message, proto3, protoInt64, StringValue, Timestamp } from "@bufbuild/protobuf";
import { BasicPagination, CurrencyEnum, FileProto, FloatRange, SortDirection } from "../../common/proto/common_pb";
import { EcommerceStorePb, ManualStorePb } from "./ecommerce_stores_pb";

/**
 * @generated from enum librarian.proto.VariantAttributeName
 */
export enum VariantAttributeName {
  /**
   * @generated from enum value: NAME_UNSPECIFIED = 0;
   */
  NAME_UNSPECIFIED = 0,

  /**
   * @generated from enum value: COLOR = 1;
   */
  COLOR = 1,

  /**
   * @generated from enum value: SIZE = 2;
   */
  SIZE = 2,

  /**
   * @generated from enum value: MATERIAL = 3;
   */
  MATERIAL = 3,

  /**
   * @generated from enum value: STYLE = 4;
   */
  STYLE = 4,
}
// Retrieve enum metadata with: proto3.getEnumType(VariantAttributeName)
proto3.util.setEnumType(VariantAttributeName, "librarian.proto.VariantAttributeName", [
  { no: 0, name: "NAME_UNSPECIFIED" },
  { no: 1, name: "COLOR" },
  { no: 2, name: "SIZE" },
  { no: 3, name: "MATERIAL" },
  { no: 4, name: "STYLE" },
]);

/**
 * @generated from enum librarian.proto.ProductState
 */
export enum ProductState {
  /**
   * @generated from enum value: STATE_UNSPECIFIED = 0;
   */
  STATE_UNSPECIFIED = 0,

  /**
   * @generated from enum value: LIVE = 1;
   */
  LIVE = 1,

  /**
   * @generated from enum value: ARCHIVED = 2;
   */
  ARCHIVED = 2,

  /**
   * @generated from enum value: DELETED = 3;
   */
  DELETED = 3,
}
// Retrieve enum metadata with: proto3.getEnumType(ProductState)
proto3.util.setEnumType(ProductState, "librarian.proto.ProductState", [
  { no: 0, name: "STATE_UNSPECIFIED" },
  { no: 1, name: "LIVE" },
  { no: 2, name: "ARCHIVED" },
  { no: 3, name: "DELETED" },
]);

/**
 * @generated from enum librarian.proto.ProductsSort
 */
export enum ProductsSort {
  /**
   * @generated from enum value: PRODUCT_SORT_UNKNOWN = 0;
   */
  PRODUCT_SORT_UNKNOWN = 0,

  /**
   * @generated from enum value: PRODUCT_NAME = 1;
   */
  PRODUCT_NAME = 1,

  /**
   * @generated from enum value: PRODUCT_AVAILABLE = 2;
   */
  PRODUCT_AVAILABLE = 2,

  /**
   * @generated from enum value: PRODUCT_GIFTED = 3;
   */
  PRODUCT_GIFTED = 3,

  /**
   * @generated from enum value: PRODUCT_PRICE = 4;
   */
  PRODUCT_PRICE = 4,

  /**
   * @generated from enum value: PRODUCT_CREATED_AT = 5;
   */
  PRODUCT_CREATED_AT = 5,

  /**
   * @generated from enum value: PRODUCT_STORE_NAME = 6;
   */
  PRODUCT_STORE_NAME = 6,
}
// Retrieve enum metadata with: proto3.getEnumType(ProductsSort)
proto3.util.setEnumType(ProductsSort, "librarian.proto.ProductsSort", [
  { no: 0, name: "PRODUCT_SORT_UNKNOWN" },
  { no: 1, name: "PRODUCT_NAME" },
  { no: 2, name: "PRODUCT_AVAILABLE" },
  { no: 3, name: "PRODUCT_GIFTED" },
  { no: 4, name: "PRODUCT_PRICE" },
  { no: 5, name: "PRODUCT_CREATED_AT" },
  { no: 6, name: "PRODUCT_STORE_NAME" },
]);

/**
 * @generated from message librarian.proto.ProductPb
 */
export class ProductPb extends Message<ProductPb> {
  /**
   * @generated from field: int64 id = 1;
   */
  id = protoInt64.zero;

  /**
   * @generated from field: string name = 2;
   */
  name = "";

  /**
   * @generated from field: string description = 3;
   */
  description = "";

  /**
   * @generated from field: string external_product_id = 4;
   */
  externalProductId = "";

  /**
   * @generated from field: librarian.proto.ProductState state = 5;
   */
  state = ProductState.STATE_UNSPECIFIED;

  /**
   * @generated from field: repeated librarian.proto.ProductVariantPb variants = 6;
   */
  variants: ProductVariantPb[] = [];

  /**
   * @generated from field: google.protobuf.Timestamp created_at = 7;
   */
  createdAt?: Timestamp;

  /**
   * @generated from field: google.protobuf.Int64Value asset_id = 8;
   */
  assetId?: bigint;

  /**
   * @generated from oneof librarian.proto.ProductPb.ecommerce_store
   */
  ecommerceStore: {
    /**
     * @generated from field: int64 shopify_store_id = 9 [deprecated = true];
     * @deprecated
     */
    value: bigint;
    case: "shopifyStoreId";
  } | {
    /**
     * @generated from field: string manual_store_name = 10 [deprecated = true];
     * @deprecated
     */
    value: string;
    case: "manualStoreName";
  } | { case: undefined; value?: undefined } = { case: undefined };

  /**
   * @generated from field: google.protobuf.Int64Value ecommerce_store_id = 11;
   */
  ecommerceStoreId?: bigint;

  /**
   * @generated from field: string asset_url = 12;
   */
  assetUrl = "";

  constructor(data?: PartialMessage<ProductPb>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.ProductPb";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "external_product_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "state", kind: "enum", T: proto3.getEnumType(ProductState) },
    { no: 6, name: "variants", kind: "message", T: ProductVariantPb, repeated: true },
    { no: 7, name: "created_at", kind: "message", T: Timestamp },
    { no: 8, name: "asset_id", kind: "message", T: Int64Value },
    { no: 9, name: "shopify_store_id", kind: "scalar", T: 3 /* ScalarType.INT64 */, oneof: "ecommerce_store" },
    { no: 10, name: "manual_store_name", kind: "scalar", T: 9 /* ScalarType.STRING */, oneof: "ecommerce_store" },
    { no: 11, name: "ecommerce_store_id", kind: "message", T: Int64Value },
    { no: 12, name: "asset_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ProductPb {
    return new ProductPb().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ProductPb {
    return new ProductPb().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ProductPb {
    return new ProductPb().fromJsonString(jsonString, options);
  }

  static equals(a: ProductPb | PlainMessage<ProductPb> | undefined, b: ProductPb | PlainMessage<ProductPb> | undefined): boolean {
    return proto3.util.equals(ProductPb, a, b);
  }
}

/**
 * @generated from message librarian.proto.ProductVariantPb
 */
export class ProductVariantPb extends Message<ProductVariantPb> {
  /**
   * @generated from field: int64 id = 1;
   */
  id = protoInt64.zero;

  /**
   * @generated from field: string sku = 2;
   */
  sku = "";

  /**
   * @generated from field: int32 quantity = 3;
   */
  quantity = 0;

  /**
   * @generated from field: google.protobuf.DoubleValue price = 4;
   */
  price?: number;

  /**
   * @generated from field: common.proto.CurrencyEnum currency = 5;
   */
  currency = CurrencyEnum.NA;

  /**
   * @generated from field: repeated librarian.proto.ProductVariantAttributePb attributes = 6;
   */
  attributes: ProductVariantAttributePb[] = [];

  /**
   * @generated from field: google.protobuf.StringValue external_product_variant_id = 7;
   */
  externalProductVariantId?: string;

  /**
   * @generated from field: string product_name = 8;
   */
  productName = "";

  /**
   * @generated from field: bool soft_delete = 9;
   */
  softDelete = false;

  /**
   * @generated from field: google.protobuf.Int64Value image_asset_id = 10;
   */
  imageAssetId?: bigint;

  /**
   * @generated from field: google.protobuf.StringValue image_asset_url = 11;
   */
  imageAssetUrl?: string;

  /**
   * @generated from field: google.protobuf.StringValue external_image_url = 12;
   */
  externalImageUrl?: string;

  constructor(data?: PartialMessage<ProductVariantPb>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.ProductVariantPb";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "sku", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "quantity", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "price", kind: "message", T: DoubleValue },
    { no: 5, name: "currency", kind: "enum", T: proto3.getEnumType(CurrencyEnum) },
    { no: 6, name: "attributes", kind: "message", T: ProductVariantAttributePb, repeated: true },
    { no: 7, name: "external_product_variant_id", kind: "message", T: StringValue },
    { no: 8, name: "product_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "soft_delete", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 10, name: "image_asset_id", kind: "message", T: Int64Value },
    { no: 11, name: "image_asset_url", kind: "message", T: StringValue },
    { no: 12, name: "external_image_url", kind: "message", T: StringValue },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ProductVariantPb {
    return new ProductVariantPb().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ProductVariantPb {
    return new ProductVariantPb().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ProductVariantPb {
    return new ProductVariantPb().fromJsonString(jsonString, options);
  }

  static equals(a: ProductVariantPb | PlainMessage<ProductVariantPb> | undefined, b: ProductVariantPb | PlainMessage<ProductVariantPb> | undefined): boolean {
    return proto3.util.equals(ProductVariantPb, a, b);
  }
}

/**
 * @generated from message librarian.proto.ProductVariantAttributePb
 */
export class ProductVariantAttributePb extends Message<ProductVariantAttributePb> {
  /**
   * @generated from field: string name = 1;
   */
  name = "";

  /**
   * @generated from field: string value = 2;
   */
  value = "";

  constructor(data?: PartialMessage<ProductVariantAttributePb>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.ProductVariantAttributePb";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "value", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ProductVariantAttributePb {
    return new ProductVariantAttributePb().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ProductVariantAttributePb {
    return new ProductVariantAttributePb().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ProductVariantAttributePb {
    return new ProductVariantAttributePb().fromJsonString(jsonString, options);
  }

  static equals(a: ProductVariantAttributePb | PlainMessage<ProductVariantAttributePb> | undefined, b: ProductVariantAttributePb | PlainMessage<ProductVariantAttributePb> | undefined): boolean {
    return proto3.util.equals(ProductVariantAttributePb, a, b);
  }
}

/**
 * @generated from message librarian.proto.ProductsFilter
 */
export class ProductsFilter extends Message<ProductsFilter> {
  /**
   * @generated from field: string name = 1;
   */
  name = "";

  /**
   * @generated from field: librarian.proto.ProductState state = 2;
   */
  state = ProductState.STATE_UNSPECIFIED;

  /**
   * @generated from field: repeated int64 ecommerce_store_ids = 3;
   */
  ecommerceStoreIds: bigint[] = [];

  /**
   * @generated from field: common.proto.FloatRange price = 4;
   */
  price?: FloatRange;

  /**
   * @generated from field: google.protobuf.Timestamp create_date_start = 5;
   */
  createDateStart?: Timestamp;

  /**
   * @generated from field: google.protobuf.Timestamp create_date_end = 6;
   */
  createDateEnd?: Timestamp;

  constructor(data?: PartialMessage<ProductsFilter>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.ProductsFilter";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "state", kind: "enum", T: proto3.getEnumType(ProductState) },
    { no: 3, name: "ecommerce_store_ids", kind: "scalar", T: 3 /* ScalarType.INT64 */, repeated: true },
    { no: 4, name: "price", kind: "message", T: FloatRange },
    { no: 5, name: "create_date_start", kind: "message", T: Timestamp },
    { no: 6, name: "create_date_end", kind: "message", T: Timestamp },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ProductsFilter {
    return new ProductsFilter().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ProductsFilter {
    return new ProductsFilter().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ProductsFilter {
    return new ProductsFilter().fromJsonString(jsonString, options);
  }

  static equals(a: ProductsFilter | PlainMessage<ProductsFilter> | undefined, b: ProductsFilter | PlainMessage<ProductsFilter> | undefined): boolean {
    return proto3.util.equals(ProductsFilter, a, b);
  }
}

/**
 * @generated from message librarian.proto.GetProductsRequest
 */
export class GetProductsRequest extends Message<GetProductsRequest> {
  /**
   * @generated from field: librarian.proto.ProductsFilter filter = 1;
   */
  filter?: ProductsFilter;

  /**
   * @generated from field: librarian.proto.ProductsSort sort = 2;
   */
  sort = ProductsSort.PRODUCT_SORT_UNKNOWN;

  /**
   * @generated from field: common.proto.SortDirection sort_direction = 3;
   */
  sortDirection = SortDirection.DESC;

  /**
   * @generated from field: common.proto.BasicPagination pagination = 4;
   */
  pagination?: BasicPagination;

  constructor(data?: PartialMessage<GetProductsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.GetProductsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "filter", kind: "message", T: ProductsFilter },
    { no: 2, name: "sort", kind: "enum", T: proto3.getEnumType(ProductsSort) },
    { no: 3, name: "sort_direction", kind: "enum", T: proto3.getEnumType(SortDirection) },
    { no: 4, name: "pagination", kind: "message", T: BasicPagination },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetProductsRequest {
    return new GetProductsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetProductsRequest {
    return new GetProductsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetProductsRequest {
    return new GetProductsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetProductsRequest | PlainMessage<GetProductsRequest> | undefined, b: GetProductsRequest | PlainMessage<GetProductsRequest> | undefined): boolean {
    return proto3.util.equals(GetProductsRequest, a, b);
  }
}

/**
 * @generated from message librarian.proto.ProductWithInfoPb
 */
export class ProductWithInfoPb extends Message<ProductWithInfoPb> {
  /**
   * @generated from field: librarian.proto.ProductPb product = 1;
   */
  product?: ProductPb;

  /**
   * @generated from field: int64 available = 2;
   */
  available = protoInt64.zero;

  /**
   * @generated from field: google.protobuf.DoubleValue min_price = 3;
   */
  minPrice?: number;

  /**
   * @generated from field: google.protobuf.DoubleValue max_price = 4;
   */
  maxPrice?: number;

  /**
   * @generated from field: int64 gifted = 5;
   */
  gifted = protoInt64.zero;

  /**
   * @generated from field: string asset_url = 6;
   */
  assetUrl = "";

  /**
   * @generated from oneof librarian.proto.ProductWithInfoPb.ecommerce_store
   */
  ecommerceStore: {
    /**
     * @generated from field: librarian.proto.ProductWithInfoPb.ProductShopifyStore shopify_store = 7 [deprecated = true];
     * @deprecated
     */
    value: ProductWithInfoPb_ProductShopifyStore;
    case: "shopifyStore";
  } | {
    /**
     * @generated from field: librarian.proto.ProductWithInfoPb.ProductManualStore manual_store = 8 [deprecated = true];
     * @deprecated
     */
    value: ProductWithInfoPb_ProductManualStore;
    case: "manualStore";
  } | { case: undefined; value?: undefined } = { case: undefined };

  /**
   * @generated from field: librarian.proto.EcommerceStorePb store = 9;
   */
  store?: EcommerceStorePb;

  constructor(data?: PartialMessage<ProductWithInfoPb>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.ProductWithInfoPb";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "product", kind: "message", T: ProductPb },
    { no: 2, name: "available", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "min_price", kind: "message", T: DoubleValue },
    { no: 4, name: "max_price", kind: "message", T: DoubleValue },
    { no: 5, name: "gifted", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 6, name: "asset_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "shopify_store", kind: "message", T: ProductWithInfoPb_ProductShopifyStore, oneof: "ecommerce_store" },
    { no: 8, name: "manual_store", kind: "message", T: ProductWithInfoPb_ProductManualStore, oneof: "ecommerce_store" },
    { no: 9, name: "store", kind: "message", T: EcommerceStorePb },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ProductWithInfoPb {
    return new ProductWithInfoPb().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ProductWithInfoPb {
    return new ProductWithInfoPb().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ProductWithInfoPb {
    return new ProductWithInfoPb().fromJsonString(jsonString, options);
  }

  static equals(a: ProductWithInfoPb | PlainMessage<ProductWithInfoPb> | undefined, b: ProductWithInfoPb | PlainMessage<ProductWithInfoPb> | undefined): boolean {
    return proto3.util.equals(ProductWithInfoPb, a, b);
  }
}

/**
 * @generated from message librarian.proto.ProductWithInfoPb.ProductShopifyStore
 */
export class ProductWithInfoPb_ProductShopifyStore extends Message<ProductWithInfoPb_ProductShopifyStore> {
  /**
   * @generated from field: string domain = 1;
   */
  domain = "";

  /**
   * @generated from field: string name = 2;
   */
  name = "";

  constructor(data?: PartialMessage<ProductWithInfoPb_ProductShopifyStore>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.ProductWithInfoPb.ProductShopifyStore";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "domain", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ProductWithInfoPb_ProductShopifyStore {
    return new ProductWithInfoPb_ProductShopifyStore().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ProductWithInfoPb_ProductShopifyStore {
    return new ProductWithInfoPb_ProductShopifyStore().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ProductWithInfoPb_ProductShopifyStore {
    return new ProductWithInfoPb_ProductShopifyStore().fromJsonString(jsonString, options);
  }

  static equals(a: ProductWithInfoPb_ProductShopifyStore | PlainMessage<ProductWithInfoPb_ProductShopifyStore> | undefined, b: ProductWithInfoPb_ProductShopifyStore | PlainMessage<ProductWithInfoPb_ProductShopifyStore> | undefined): boolean {
    return proto3.util.equals(ProductWithInfoPb_ProductShopifyStore, a, b);
  }
}

/**
 * @generated from message librarian.proto.ProductWithInfoPb.ProductManualStore
 */
export class ProductWithInfoPb_ProductManualStore extends Message<ProductWithInfoPb_ProductManualStore> {
  /**
   * @generated from field: string name = 1;
   */
  name = "";

  constructor(data?: PartialMessage<ProductWithInfoPb_ProductManualStore>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.ProductWithInfoPb.ProductManualStore";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ProductWithInfoPb_ProductManualStore {
    return new ProductWithInfoPb_ProductManualStore().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ProductWithInfoPb_ProductManualStore {
    return new ProductWithInfoPb_ProductManualStore().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ProductWithInfoPb_ProductManualStore {
    return new ProductWithInfoPb_ProductManualStore().fromJsonString(jsonString, options);
  }

  static equals(a: ProductWithInfoPb_ProductManualStore | PlainMessage<ProductWithInfoPb_ProductManualStore> | undefined, b: ProductWithInfoPb_ProductManualStore | PlainMessage<ProductWithInfoPb_ProductManualStore> | undefined): boolean {
    return proto3.util.equals(ProductWithInfoPb_ProductManualStore, a, b);
  }
}

/**
 * @generated from message librarian.proto.GetProductsResponse
 */
export class GetProductsResponse extends Message<GetProductsResponse> {
  /**
   * @generated from field: repeated librarian.proto.ProductWithInfoPb products = 1;
   */
  products: ProductWithInfoPb[] = [];

  /**
   * @generated from field: int32 total_hits = 2;
   */
  totalHits = 0;

  constructor(data?: PartialMessage<GetProductsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.GetProductsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "products", kind: "message", T: ProductWithInfoPb, repeated: true },
    { no: 2, name: "total_hits", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetProductsResponse {
    return new GetProductsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetProductsResponse {
    return new GetProductsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetProductsResponse {
    return new GetProductsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetProductsResponse | PlainMessage<GetProductsResponse> | undefined, b: GetProductsResponse | PlainMessage<GetProductsResponse> | undefined): boolean {
    return proto3.util.equals(GetProductsResponse, a, b);
  }
}

/**
 * @generated from message librarian.proto.DeleteProductsRequest
 */
export class DeleteProductsRequest extends Message<DeleteProductsRequest> {
  /**
   * @generated from field: repeated int64 product_ids = 1;
   */
  productIds: bigint[] = [];

  constructor(data?: PartialMessage<DeleteProductsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.DeleteProductsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "product_ids", kind: "scalar", T: 3 /* ScalarType.INT64 */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DeleteProductsRequest {
    return new DeleteProductsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DeleteProductsRequest {
    return new DeleteProductsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DeleteProductsRequest {
    return new DeleteProductsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: DeleteProductsRequest | PlainMessage<DeleteProductsRequest> | undefined, b: DeleteProductsRequest | PlainMessage<DeleteProductsRequest> | undefined): boolean {
    return proto3.util.equals(DeleteProductsRequest, a, b);
  }
}

/**
 * @generated from message librarian.proto.ProductManualUpdates
 */
export class ProductManualUpdates extends Message<ProductManualUpdates> {
  /**
   * @generated from field: bool is_name_updated = 1 [deprecated = true];
   * @deprecated
   */
  isNameUpdated = false;

  /**
   * @generated from field: bool is_description_updated = 2 [deprecated = true];
   * @deprecated
   */
  isDescriptionUpdated = false;

  /**
   * @generated from field: bool is_state_updated = 3 [deprecated = true];
   * @deprecated
   */
  isStateUpdated = false;

  /**
   * @generated from field: bool is_asset_id_updated = 4 [deprecated = true];
   * @deprecated
   */
  isAssetIdUpdated = false;

  /**
   * Used to store the image url of the product in shopify (if exists) so that we know if the image is changed on shopify or not.
   * If the image changed and `is_asset_id_updated` is false, we import the image again. Otherwise, we do not import it.
   * UPDATE: this field will store any external store image for now and it is not specific to shopify
   *
   * @generated from field: google.protobuf.StringValue shopify_image_url = 5;
   */
  shopifyImageUrl?: string;

  constructor(data?: PartialMessage<ProductManualUpdates>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.ProductManualUpdates";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "is_name_updated", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 2, name: "is_description_updated", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 3, name: "is_state_updated", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 4, name: "is_asset_id_updated", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 5, name: "shopify_image_url", kind: "message", T: StringValue },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ProductManualUpdates {
    return new ProductManualUpdates().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ProductManualUpdates {
    return new ProductManualUpdates().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ProductManualUpdates {
    return new ProductManualUpdates().fromJsonString(jsonString, options);
  }

  static equals(a: ProductManualUpdates | PlainMessage<ProductManualUpdates> | undefined, b: ProductManualUpdates | PlainMessage<ProductManualUpdates> | undefined): boolean {
    return proto3.util.equals(ProductManualUpdates, a, b);
  }
}

/**
 * @generated from message librarian.proto.ProductVariantManualUpdates
 */
export class ProductVariantManualUpdates extends Message<ProductVariantManualUpdates> {
  /**
   * @generated from field: bool is_sku_updated = 1;
   */
  isSkuUpdated = false;

  /**
   * @generated from field: bool is_quantity_updated = 2;
   */
  isQuantityUpdated = false;

  /**
   * @generated from field: bool is_price_updated = 3;
   */
  isPriceUpdated = false;

  constructor(data?: PartialMessage<ProductVariantManualUpdates>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.ProductVariantManualUpdates";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "is_sku_updated", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 2, name: "is_quantity_updated", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 3, name: "is_price_updated", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ProductVariantManualUpdates {
    return new ProductVariantManualUpdates().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ProductVariantManualUpdates {
    return new ProductVariantManualUpdates().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ProductVariantManualUpdates {
    return new ProductVariantManualUpdates().fromJsonString(jsonString, options);
  }

  static equals(a: ProductVariantManualUpdates | PlainMessage<ProductVariantManualUpdates> | undefined, b: ProductVariantManualUpdates | PlainMessage<ProductVariantManualUpdates> | undefined): boolean {
    return proto3.util.equals(ProductVariantManualUpdates, a, b);
  }
}

/**
 * @generated from message librarian.proto.UploadProductsExcelRequest
 */
export class UploadProductsExcelRequest extends Message<UploadProductsExcelRequest> {
  /**
   * @generated from field: common.proto.FileProto file = 1;
   */
  file?: FileProto;

  constructor(data?: PartialMessage<UploadProductsExcelRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.UploadProductsExcelRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "file", kind: "message", T: FileProto },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UploadProductsExcelRequest {
    return new UploadProductsExcelRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UploadProductsExcelRequest {
    return new UploadProductsExcelRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UploadProductsExcelRequest {
    return new UploadProductsExcelRequest().fromJsonString(jsonString, options);
  }

  static equals(a: UploadProductsExcelRequest | PlainMessage<UploadProductsExcelRequest> | undefined, b: UploadProductsExcelRequest | PlainMessage<UploadProductsExcelRequest> | undefined): boolean {
    return proto3.util.equals(UploadProductsExcelRequest, a, b);
  }
}

/**
 * @generated from message librarian.proto.UploadProductsExcelResponse
 */
export class UploadProductsExcelResponse extends Message<UploadProductsExcelResponse> {
  /**
   * @generated from oneof librarian.proto.UploadProductsExcelResponse.result
   */
  result: {
    /**
     * @generated from field: librarian.proto.UploadProductsExcelResponse.UploadProductsExcelSuccess success = 1;
     */
    value: UploadProductsExcelResponse_UploadProductsExcelSuccess;
    case: "success";
  } | {
    /**
     * @generated from field: librarian.proto.UploadProductsExcelResponse.UploadProductsExcelError error = 2;
     */
    value: UploadProductsExcelResponse_UploadProductsExcelError;
    case: "error";
  } | { case: undefined; value?: undefined } = { case: undefined };

  constructor(data?: PartialMessage<UploadProductsExcelResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.UploadProductsExcelResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "success", kind: "message", T: UploadProductsExcelResponse_UploadProductsExcelSuccess, oneof: "result" },
    { no: 2, name: "error", kind: "message", T: UploadProductsExcelResponse_UploadProductsExcelError, oneof: "result" },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UploadProductsExcelResponse {
    return new UploadProductsExcelResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UploadProductsExcelResponse {
    return new UploadProductsExcelResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UploadProductsExcelResponse {
    return new UploadProductsExcelResponse().fromJsonString(jsonString, options);
  }

  static equals(a: UploadProductsExcelResponse | PlainMessage<UploadProductsExcelResponse> | undefined, b: UploadProductsExcelResponse | PlainMessage<UploadProductsExcelResponse> | undefined): boolean {
    return proto3.util.equals(UploadProductsExcelResponse, a, b);
  }
}

/**
 * @generated from message librarian.proto.UploadProductsExcelResponse.UploadProductsExcelSuccess
 */
export class UploadProductsExcelResponse_UploadProductsExcelSuccess extends Message<UploadProductsExcelResponse_UploadProductsExcelSuccess> {
  /**
   * @generated from field: string uploaded_excel_uuid = 1;
   */
  uploadedExcelUuid = "";

  /**
   * @generated from field: librarian.proto.UploadProductsExcelResponse.UploadProductsExcelPreview preview = 2;
   */
  preview?: UploadProductsExcelResponse_UploadProductsExcelPreview;

  constructor(data?: PartialMessage<UploadProductsExcelResponse_UploadProductsExcelSuccess>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.UploadProductsExcelResponse.UploadProductsExcelSuccess";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "uploaded_excel_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "preview", kind: "message", T: UploadProductsExcelResponse_UploadProductsExcelPreview },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UploadProductsExcelResponse_UploadProductsExcelSuccess {
    return new UploadProductsExcelResponse_UploadProductsExcelSuccess().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UploadProductsExcelResponse_UploadProductsExcelSuccess {
    return new UploadProductsExcelResponse_UploadProductsExcelSuccess().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UploadProductsExcelResponse_UploadProductsExcelSuccess {
    return new UploadProductsExcelResponse_UploadProductsExcelSuccess().fromJsonString(jsonString, options);
  }

  static equals(a: UploadProductsExcelResponse_UploadProductsExcelSuccess | PlainMessage<UploadProductsExcelResponse_UploadProductsExcelSuccess> | undefined, b: UploadProductsExcelResponse_UploadProductsExcelSuccess | PlainMessage<UploadProductsExcelResponse_UploadProductsExcelSuccess> | undefined): boolean {
    return proto3.util.equals(UploadProductsExcelResponse_UploadProductsExcelSuccess, a, b);
  }
}

/**
 * @generated from message librarian.proto.UploadProductsExcelResponse.UploadProductsExcelPreview
 */
export class UploadProductsExcelResponse_UploadProductsExcelPreview extends Message<UploadProductsExcelResponse_UploadProductsExcelPreview> {
  /**
   * @generated from field: int32 products_count = 1;
   */
  productsCount = 0;

  /**
   * @generated from field: int32 sku_count = 2;
   */
  skuCount = 0;

  /**
   * @generated from field: int32 images_count = 3;
   */
  imagesCount = 0;

  /**
   * @generated from field: librarian.proto.UploadProductsExcelResponse.PreviewProduct preview_product = 4;
   */
  previewProduct?: UploadProductsExcelResponse_PreviewProduct;

  constructor(data?: PartialMessage<UploadProductsExcelResponse_UploadProductsExcelPreview>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.UploadProductsExcelResponse.UploadProductsExcelPreview";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "products_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "sku_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "images_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "preview_product", kind: "message", T: UploadProductsExcelResponse_PreviewProduct },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UploadProductsExcelResponse_UploadProductsExcelPreview {
    return new UploadProductsExcelResponse_UploadProductsExcelPreview().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UploadProductsExcelResponse_UploadProductsExcelPreview {
    return new UploadProductsExcelResponse_UploadProductsExcelPreview().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UploadProductsExcelResponse_UploadProductsExcelPreview {
    return new UploadProductsExcelResponse_UploadProductsExcelPreview().fromJsonString(jsonString, options);
  }

  static equals(a: UploadProductsExcelResponse_UploadProductsExcelPreview | PlainMessage<UploadProductsExcelResponse_UploadProductsExcelPreview> | undefined, b: UploadProductsExcelResponse_UploadProductsExcelPreview | PlainMessage<UploadProductsExcelResponse_UploadProductsExcelPreview> | undefined): boolean {
    return proto3.util.equals(UploadProductsExcelResponse_UploadProductsExcelPreview, a, b);
  }
}

/**
 * @generated from message librarian.proto.UploadProductsExcelResponse.PreviewProduct
 */
export class UploadProductsExcelResponse_PreviewProduct extends Message<UploadProductsExcelResponse_PreviewProduct> {
  /**
   * @generated from field: string title = 1;
   */
  title = "";

  /**
   * @generated from field: string description = 2;
   */
  description = "";

  /**
   * @generated from field: string asset_url = 3;
   */
  assetUrl = "";

  /**
   * This map has keys of type `int32` representing the variant's row and values of type `PreviewVariant`
   * representing the variants themselves. We need to store the variants as a map not just list because
   * the variant's row is needed while constructing the preview product from the csv in backend.
   * The frontend can just iterate and display the values (PreviewVariant) only in the map.
   *
   * @generated from field: map<int32, librarian.proto.UploadProductsExcelResponse.PreviewProduct.PreviewVariant> variants = 4;
   */
  variants: { [key: number]: UploadProductsExcelResponse_PreviewProduct_PreviewVariant } = {};

  /**
   * @generated from field: common.proto.CurrencyEnum currency = 5;
   */
  currency = CurrencyEnum.NA;

  constructor(data?: PartialMessage<UploadProductsExcelResponse_PreviewProduct>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.UploadProductsExcelResponse.PreviewProduct";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "asset_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "variants", kind: "map", K: 5 /* ScalarType.INT32 */, V: {kind: "message", T: UploadProductsExcelResponse_PreviewProduct_PreviewVariant} },
    { no: 5, name: "currency", kind: "enum", T: proto3.getEnumType(CurrencyEnum) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UploadProductsExcelResponse_PreviewProduct {
    return new UploadProductsExcelResponse_PreviewProduct().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UploadProductsExcelResponse_PreviewProduct {
    return new UploadProductsExcelResponse_PreviewProduct().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UploadProductsExcelResponse_PreviewProduct {
    return new UploadProductsExcelResponse_PreviewProduct().fromJsonString(jsonString, options);
  }

  static equals(a: UploadProductsExcelResponse_PreviewProduct | PlainMessage<UploadProductsExcelResponse_PreviewProduct> | undefined, b: UploadProductsExcelResponse_PreviewProduct | PlainMessage<UploadProductsExcelResponse_PreviewProduct> | undefined): boolean {
    return proto3.util.equals(UploadProductsExcelResponse_PreviewProduct, a, b);
  }
}

/**
 * @generated from message librarian.proto.UploadProductsExcelResponse.PreviewProduct.PreviewVariant
 */
export class UploadProductsExcelResponse_PreviewProduct_PreviewVariant extends Message<UploadProductsExcelResponse_PreviewProduct_PreviewVariant> {
  /**
   * @generated from field: repeated string options = 1;
   */
  options: string[] = [];

  /**
   * @generated from field: string sku = 2;
   */
  sku = "";

  /**
   * @generated from field: int32 quantity = 3;
   */
  quantity = 0;

  /**
   * @generated from field: google.protobuf.DoubleValue price = 4;
   */
  price?: number;

  constructor(data?: PartialMessage<UploadProductsExcelResponse_PreviewProduct_PreviewVariant>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.UploadProductsExcelResponse.PreviewProduct.PreviewVariant";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "options", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 2, name: "sku", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "quantity", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "price", kind: "message", T: DoubleValue },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UploadProductsExcelResponse_PreviewProduct_PreviewVariant {
    return new UploadProductsExcelResponse_PreviewProduct_PreviewVariant().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UploadProductsExcelResponse_PreviewProduct_PreviewVariant {
    return new UploadProductsExcelResponse_PreviewProduct_PreviewVariant().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UploadProductsExcelResponse_PreviewProduct_PreviewVariant {
    return new UploadProductsExcelResponse_PreviewProduct_PreviewVariant().fromJsonString(jsonString, options);
  }

  static equals(a: UploadProductsExcelResponse_PreviewProduct_PreviewVariant | PlainMessage<UploadProductsExcelResponse_PreviewProduct_PreviewVariant> | undefined, b: UploadProductsExcelResponse_PreviewProduct_PreviewVariant | PlainMessage<UploadProductsExcelResponse_PreviewProduct_PreviewVariant> | undefined): boolean {
    return proto3.util.equals(UploadProductsExcelResponse_PreviewProduct_PreviewVariant, a, b);
  }
}

/**
 * @generated from message librarian.proto.UploadProductsExcelResponse.UploadProductsExcelError
 */
export class UploadProductsExcelResponse_UploadProductsExcelError extends Message<UploadProductsExcelResponse_UploadProductsExcelError> {
  /**
   * @generated from field: repeated string messages = 1;
   */
  messages: string[] = [];

  constructor(data?: PartialMessage<UploadProductsExcelResponse_UploadProductsExcelError>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.UploadProductsExcelResponse.UploadProductsExcelError";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "messages", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UploadProductsExcelResponse_UploadProductsExcelError {
    return new UploadProductsExcelResponse_UploadProductsExcelError().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UploadProductsExcelResponse_UploadProductsExcelError {
    return new UploadProductsExcelResponse_UploadProductsExcelError().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UploadProductsExcelResponse_UploadProductsExcelError {
    return new UploadProductsExcelResponse_UploadProductsExcelError().fromJsonString(jsonString, options);
  }

  static equals(a: UploadProductsExcelResponse_UploadProductsExcelError | PlainMessage<UploadProductsExcelResponse_UploadProductsExcelError> | undefined, b: UploadProductsExcelResponse_UploadProductsExcelError | PlainMessage<UploadProductsExcelResponse_UploadProductsExcelError> | undefined): boolean {
    return proto3.util.equals(UploadProductsExcelResponse_UploadProductsExcelError, a, b);
  }
}

/**
 * @generated from message librarian.proto.UploadProductsExcelResponse.UploadProductsExcelContentValidation
 */
export class UploadProductsExcelResponse_UploadProductsExcelContentValidation extends Message<UploadProductsExcelResponse_UploadProductsExcelContentValidation> {
  /**
   * @generated from oneof librarian.proto.UploadProductsExcelResponse.UploadProductsExcelContentValidation.result
   */
  result: {
    /**
     * @generated from field: librarian.proto.UploadProductsExcelResponse.UploadProductsExcelPreview preview = 1;
     */
    value: UploadProductsExcelResponse_UploadProductsExcelPreview;
    case: "preview";
  } | {
    /**
     * @generated from field: librarian.proto.UploadProductsExcelResponse.UploadProductsExcelError error = 2;
     */
    value: UploadProductsExcelResponse_UploadProductsExcelError;
    case: "error";
  } | { case: undefined; value?: undefined } = { case: undefined };

  constructor(data?: PartialMessage<UploadProductsExcelResponse_UploadProductsExcelContentValidation>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.UploadProductsExcelResponse.UploadProductsExcelContentValidation";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "preview", kind: "message", T: UploadProductsExcelResponse_UploadProductsExcelPreview, oneof: "result" },
    { no: 2, name: "error", kind: "message", T: UploadProductsExcelResponse_UploadProductsExcelError, oneof: "result" },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UploadProductsExcelResponse_UploadProductsExcelContentValidation {
    return new UploadProductsExcelResponse_UploadProductsExcelContentValidation().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UploadProductsExcelResponse_UploadProductsExcelContentValidation {
    return new UploadProductsExcelResponse_UploadProductsExcelContentValidation().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UploadProductsExcelResponse_UploadProductsExcelContentValidation {
    return new UploadProductsExcelResponse_UploadProductsExcelContentValidation().fromJsonString(jsonString, options);
  }

  static equals(a: UploadProductsExcelResponse_UploadProductsExcelContentValidation | PlainMessage<UploadProductsExcelResponse_UploadProductsExcelContentValidation> | undefined, b: UploadProductsExcelResponse_UploadProductsExcelContentValidation | PlainMessage<UploadProductsExcelResponse_UploadProductsExcelContentValidation> | undefined): boolean {
    return proto3.util.equals(UploadProductsExcelResponse_UploadProductsExcelContentValidation, a, b);
  }
}

/**
 * @generated from message librarian.proto.UploadProductsExcelResponse.UploadProductsRowValidation
 */
export class UploadProductsExcelResponse_UploadProductsRowValidation extends Message<UploadProductsExcelResponse_UploadProductsRowValidation> {
  /**
   * @generated from oneof librarian.proto.UploadProductsExcelResponse.UploadProductsRowValidation.result
   */
  result: {
    /**
     * @generated from field: librarian.proto.UploadProductsExcelResponse.UploadProductsRowValidation.UploadProductsRowSuccess success = 1;
     */
    value: UploadProductsExcelResponse_UploadProductsRowValidation_UploadProductsRowSuccess;
    case: "success";
  } | {
    /**
     * @generated from field: librarian.proto.UploadProductsExcelResponse.UploadProductsExcelError error = 2;
     */
    value: UploadProductsExcelResponse_UploadProductsExcelError;
    case: "error";
  } | { case: undefined; value?: undefined } = { case: undefined };

  constructor(data?: PartialMessage<UploadProductsExcelResponse_UploadProductsRowValidation>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.UploadProductsExcelResponse.UploadProductsRowValidation";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "success", kind: "message", T: UploadProductsExcelResponse_UploadProductsRowValidation_UploadProductsRowSuccess, oneof: "result" },
    { no: 2, name: "error", kind: "message", T: UploadProductsExcelResponse_UploadProductsExcelError, oneof: "result" },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UploadProductsExcelResponse_UploadProductsRowValidation {
    return new UploadProductsExcelResponse_UploadProductsRowValidation().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UploadProductsExcelResponse_UploadProductsRowValidation {
    return new UploadProductsExcelResponse_UploadProductsRowValidation().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UploadProductsExcelResponse_UploadProductsRowValidation {
    return new UploadProductsExcelResponse_UploadProductsRowValidation().fromJsonString(jsonString, options);
  }

  static equals(a: UploadProductsExcelResponse_UploadProductsRowValidation | PlainMessage<UploadProductsExcelResponse_UploadProductsRowValidation> | undefined, b: UploadProductsExcelResponse_UploadProductsRowValidation | PlainMessage<UploadProductsExcelResponse_UploadProductsRowValidation> | undefined): boolean {
    return proto3.util.equals(UploadProductsExcelResponse_UploadProductsRowValidation, a, b);
  }
}

/**
 * @generated from message librarian.proto.UploadProductsExcelResponse.UploadProductsRowValidation.UploadProductsRowSuccess
 */
export class UploadProductsExcelResponse_UploadProductsRowValidation_UploadProductsRowSuccess extends Message<UploadProductsExcelResponse_UploadProductsRowValidation_UploadProductsRowSuccess> {
  /**
   * @generated from field: int32 products_count = 1;
   */
  productsCount = 0;

  /**
   * @generated from field: int32 sku_count = 2;
   */
  skuCount = 0;

  /**
   * @generated from field: int32 images_count = 3;
   */
  imagesCount = 0;

  constructor(data?: PartialMessage<UploadProductsExcelResponse_UploadProductsRowValidation_UploadProductsRowSuccess>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.UploadProductsExcelResponse.UploadProductsRowValidation.UploadProductsRowSuccess";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "products_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "sku_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "images_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UploadProductsExcelResponse_UploadProductsRowValidation_UploadProductsRowSuccess {
    return new UploadProductsExcelResponse_UploadProductsRowValidation_UploadProductsRowSuccess().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UploadProductsExcelResponse_UploadProductsRowValidation_UploadProductsRowSuccess {
    return new UploadProductsExcelResponse_UploadProductsRowValidation_UploadProductsRowSuccess().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UploadProductsExcelResponse_UploadProductsRowValidation_UploadProductsRowSuccess {
    return new UploadProductsExcelResponse_UploadProductsRowValidation_UploadProductsRowSuccess().fromJsonString(jsonString, options);
  }

  static equals(a: UploadProductsExcelResponse_UploadProductsRowValidation_UploadProductsRowSuccess | PlainMessage<UploadProductsExcelResponse_UploadProductsRowValidation_UploadProductsRowSuccess> | undefined, b: UploadProductsExcelResponse_UploadProductsRowValidation_UploadProductsRowSuccess | PlainMessage<UploadProductsExcelResponse_UploadProductsRowValidation_UploadProductsRowSuccess> | undefined): boolean {
    return proto3.util.equals(UploadProductsExcelResponse_UploadProductsRowValidation_UploadProductsRowSuccess, a, b);
  }
}

/**
 * @generated from message librarian.proto.ImportProductsExcelRequest
 */
export class ImportProductsExcelRequest extends Message<ImportProductsExcelRequest> {
  /**
   * @generated from field: string uploaded_excel_uuid = 1;
   */
  uploadedExcelUuid = "";

  /**
   * @generated from field: bool overwrite_products = 2;
   */
  overwriteProducts = false;

  /**
   * @generated from field: int64 ecommerce_store_id = 3;
   */
  ecommerceStoreId = protoInt64.zero;

  constructor(data?: PartialMessage<ImportProductsExcelRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.ImportProductsExcelRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "uploaded_excel_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "overwrite_products", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 3, name: "ecommerce_store_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ImportProductsExcelRequest {
    return new ImportProductsExcelRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ImportProductsExcelRequest {
    return new ImportProductsExcelRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ImportProductsExcelRequest {
    return new ImportProductsExcelRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ImportProductsExcelRequest | PlainMessage<ImportProductsExcelRequest> | undefined, b: ImportProductsExcelRequest | PlainMessage<ImportProductsExcelRequest> | undefined): boolean {
    return proto3.util.equals(ImportProductsExcelRequest, a, b);
  }
}

/**
 * @generated from message librarian.proto.ImportProductsExcelTask
 */
export class ImportProductsExcelTask extends Message<ImportProductsExcelTask> {
  /**
   * @generated from field: int64 user_id = 1;
   */
  userId = protoInt64.zero;

  /**
   * @generated from field: librarian.proto.ImportProductsExcelRequest request = 2;
   */
  request?: ImportProductsExcelRequest;

  constructor(data?: PartialMessage<ImportProductsExcelTask>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.ImportProductsExcelTask";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "user_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "request", kind: "message", T: ImportProductsExcelRequest },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ImportProductsExcelTask {
    return new ImportProductsExcelTask().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ImportProductsExcelTask {
    return new ImportProductsExcelTask().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ImportProductsExcelTask {
    return new ImportProductsExcelTask().fromJsonString(jsonString, options);
  }

  static equals(a: ImportProductsExcelTask | PlainMessage<ImportProductsExcelTask> | undefined, b: ImportProductsExcelTask | PlainMessage<ImportProductsExcelTask> | undefined): boolean {
    return proto3.util.equals(ImportProductsExcelTask, a, b);
  }
}

/**
 * @generated from message librarian.proto.ExportProductsRequest
 */
export class ExportProductsRequest extends Message<ExportProductsRequest> {
  /**
   * @generated from field: librarian.proto.ProductsFilter filter = 1;
   */
  filter?: ProductsFilter;

  /**
   * Filled by backend
   *
   * @generated from field: int64 user_id = 2;
   */
  userId = protoInt64.zero;

  constructor(data?: PartialMessage<ExportProductsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.ExportProductsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "filter", kind: "message", T: ProductsFilter },
    { no: 2, name: "user_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ExportProductsRequest {
    return new ExportProductsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ExportProductsRequest {
    return new ExportProductsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ExportProductsRequest {
    return new ExportProductsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ExportProductsRequest | PlainMessage<ExportProductsRequest> | undefined, b: ExportProductsRequest | PlainMessage<ExportProductsRequest> | undefined): boolean {
    return proto3.util.equals(ExportProductsRequest, a, b);
  }
}

/**
 * @generated from message librarian.proto.CreateProductProgrammaticallyRequest
 */
export class CreateProductProgrammaticallyRequest extends Message<CreateProductProgrammaticallyRequest> {
  /**
   * @generated from field: int64 user_id = 1;
   */
  userId = protoInt64.zero;

  /**
   * @generated from field: librarian.proto.ProductPb product = 2;
   */
  product?: ProductPb;

  constructor(data?: PartialMessage<CreateProductProgrammaticallyRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.CreateProductProgrammaticallyRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "user_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "product", kind: "message", T: ProductPb },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateProductProgrammaticallyRequest {
    return new CreateProductProgrammaticallyRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateProductProgrammaticallyRequest {
    return new CreateProductProgrammaticallyRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateProductProgrammaticallyRequest {
    return new CreateProductProgrammaticallyRequest().fromJsonString(jsonString, options);
  }

  static equals(a: CreateProductProgrammaticallyRequest | PlainMessage<CreateProductProgrammaticallyRequest> | undefined, b: CreateProductProgrammaticallyRequest | PlainMessage<CreateProductProgrammaticallyRequest> | undefined): boolean {
    return proto3.util.equals(CreateProductProgrammaticallyRequest, a, b);
  }
}

/**
 * @generated from message librarian.proto.CreateProductProgrammaticallyResponse
 */
export class CreateProductProgrammaticallyResponse extends Message<CreateProductProgrammaticallyResponse> {
  /**
   * @generated from field: librarian.proto.ProductPb product = 1;
   */
  product?: ProductPb;

  constructor(data?: PartialMessage<CreateProductProgrammaticallyResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.CreateProductProgrammaticallyResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "product", kind: "message", T: ProductPb },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateProductProgrammaticallyResponse {
    return new CreateProductProgrammaticallyResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateProductProgrammaticallyResponse {
    return new CreateProductProgrammaticallyResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateProductProgrammaticallyResponse {
    return new CreateProductProgrammaticallyResponse().fromJsonString(jsonString, options);
  }

  static equals(a: CreateProductProgrammaticallyResponse | PlainMessage<CreateProductProgrammaticallyResponse> | undefined, b: CreateProductProgrammaticallyResponse | PlainMessage<CreateProductProgrammaticallyResponse> | undefined): boolean {
    return proto3.util.equals(CreateProductProgrammaticallyResponse, a, b);
  }
}

/**
 * @generated from message librarian.proto.UpdateProductProgrammaticallyRequest
 */
export class UpdateProductProgrammaticallyRequest extends Message<UpdateProductProgrammaticallyRequest> {
  /**
   * @generated from field: int64 user_id = 1;
   */
  userId = protoInt64.zero;

  /**
   * @generated from field: librarian.proto.ProductPb product = 2;
   */
  product?: ProductPb;

  constructor(data?: PartialMessage<UpdateProductProgrammaticallyRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.UpdateProductProgrammaticallyRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "user_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "product", kind: "message", T: ProductPb },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateProductProgrammaticallyRequest {
    return new UpdateProductProgrammaticallyRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateProductProgrammaticallyRequest {
    return new UpdateProductProgrammaticallyRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateProductProgrammaticallyRequest {
    return new UpdateProductProgrammaticallyRequest().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateProductProgrammaticallyRequest | PlainMessage<UpdateProductProgrammaticallyRequest> | undefined, b: UpdateProductProgrammaticallyRequest | PlainMessage<UpdateProductProgrammaticallyRequest> | undefined): boolean {
    return proto3.util.equals(UpdateProductProgrammaticallyRequest, a, b);
  }
}

/**
 * @generated from message librarian.proto.UpdateProductProgrammaticallyResponse
 */
export class UpdateProductProgrammaticallyResponse extends Message<UpdateProductProgrammaticallyResponse> {
  /**
   * @generated from field: librarian.proto.ProductPb product = 1;
   */
  product?: ProductPb;

  constructor(data?: PartialMessage<UpdateProductProgrammaticallyResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.UpdateProductProgrammaticallyResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "product", kind: "message", T: ProductPb },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateProductProgrammaticallyResponse {
    return new UpdateProductProgrammaticallyResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateProductProgrammaticallyResponse {
    return new UpdateProductProgrammaticallyResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateProductProgrammaticallyResponse {
    return new UpdateProductProgrammaticallyResponse().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateProductProgrammaticallyResponse | PlainMessage<UpdateProductProgrammaticallyResponse> | undefined, b: UpdateProductProgrammaticallyResponse | PlainMessage<UpdateProductProgrammaticallyResponse> | undefined): boolean {
    return proto3.util.equals(UpdateProductProgrammaticallyResponse, a, b);
  }
}

/**
 * @generated from message librarian.proto.GetProductsProgrammaticallyRequest
 */
export class GetProductsProgrammaticallyRequest extends Message<GetProductsProgrammaticallyRequest> {
  /**
   * @generated from field: int64 user_id = 1;
   */
  userId = protoInt64.zero;

  /**
   * @generated from field: librarian.proto.GetProductsRequest request = 2;
   */
  request?: GetProductsRequest;

  constructor(data?: PartialMessage<GetProductsProgrammaticallyRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.GetProductsProgrammaticallyRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "user_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "request", kind: "message", T: GetProductsRequest },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetProductsProgrammaticallyRequest {
    return new GetProductsProgrammaticallyRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetProductsProgrammaticallyRequest {
    return new GetProductsProgrammaticallyRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetProductsProgrammaticallyRequest {
    return new GetProductsProgrammaticallyRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetProductsProgrammaticallyRequest | PlainMessage<GetProductsProgrammaticallyRequest> | undefined, b: GetProductsProgrammaticallyRequest | PlainMessage<GetProductsProgrammaticallyRequest> | undefined): boolean {
    return proto3.util.equals(GetProductsProgrammaticallyRequest, a, b);
  }
}

/**
 * @generated from message librarian.proto.GetProductsProgrammaticallyResponse
 */
export class GetProductsProgrammaticallyResponse extends Message<GetProductsProgrammaticallyResponse> {
  /**
   * @generated from field: librarian.proto.GetProductsResponse response = 1;
   */
  response?: GetProductsResponse;

  constructor(data?: PartialMessage<GetProductsProgrammaticallyResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.GetProductsProgrammaticallyResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "response", kind: "message", T: GetProductsResponse },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetProductsProgrammaticallyResponse {
    return new GetProductsProgrammaticallyResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetProductsProgrammaticallyResponse {
    return new GetProductsProgrammaticallyResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetProductsProgrammaticallyResponse {
    return new GetProductsProgrammaticallyResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetProductsProgrammaticallyResponse | PlainMessage<GetProductsProgrammaticallyResponse> | undefined, b: GetProductsProgrammaticallyResponse | PlainMessage<GetProductsProgrammaticallyResponse> | undefined): boolean {
    return proto3.util.equals(GetProductsProgrammaticallyResponse, a, b);
  }
}

/**
 * @generated from message librarian.proto.ProductExcelUploaderTaskOutput
 */
export class ProductExcelUploaderTaskOutput extends Message<ProductExcelUploaderTaskOutput> {
  /**
   * @generated from field: repeated librarian.proto.ProductExcelUploaderTaskOutput.ProductExcelUploaderTaskError errors = 1;
   */
  errors: ProductExcelUploaderTaskOutput_ProductExcelUploaderTaskError[] = [];

  /**
   * @generated from field: int32 products_uploaded_count = 2;
   */
  productsUploadedCount = 0;

  /**
   * @generated from field: int32 skus_uploaded_count = 3;
   */
  skusUploadedCount = 0;

  /**
   * @generated from field: int32 images_uploaded_count = 4;
   */
  imagesUploadedCount = 0;

  /**
   * @generated from field: bool overwrite = 5;
   */
  overwrite = false;

  constructor(data?: PartialMessage<ProductExcelUploaderTaskOutput>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.ProductExcelUploaderTaskOutput";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "errors", kind: "message", T: ProductExcelUploaderTaskOutput_ProductExcelUploaderTaskError, repeated: true },
    { no: 2, name: "products_uploaded_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "skus_uploaded_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "images_uploaded_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: "overwrite", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ProductExcelUploaderTaskOutput {
    return new ProductExcelUploaderTaskOutput().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ProductExcelUploaderTaskOutput {
    return new ProductExcelUploaderTaskOutput().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ProductExcelUploaderTaskOutput {
    return new ProductExcelUploaderTaskOutput().fromJsonString(jsonString, options);
  }

  static equals(a: ProductExcelUploaderTaskOutput | PlainMessage<ProductExcelUploaderTaskOutput> | undefined, b: ProductExcelUploaderTaskOutput | PlainMessage<ProductExcelUploaderTaskOutput> | undefined): boolean {
    return proto3.util.equals(ProductExcelUploaderTaskOutput, a, b);
  }
}

/**
 * @generated from message librarian.proto.ProductExcelUploaderTaskOutput.ProductExcelUploaderTaskError
 */
export class ProductExcelUploaderTaskOutput_ProductExcelUploaderTaskError extends Message<ProductExcelUploaderTaskOutput_ProductExcelUploaderTaskError> {
  /**
   * @generated from field: string external_product_id = 1;
   */
  externalProductId = "";

  /**
   * @generated from field: string error = 2;
   */
  error = "";

  constructor(data?: PartialMessage<ProductExcelUploaderTaskOutput_ProductExcelUploaderTaskError>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.ProductExcelUploaderTaskOutput.ProductExcelUploaderTaskError";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "external_product_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "error", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ProductExcelUploaderTaskOutput_ProductExcelUploaderTaskError {
    return new ProductExcelUploaderTaskOutput_ProductExcelUploaderTaskError().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ProductExcelUploaderTaskOutput_ProductExcelUploaderTaskError {
    return new ProductExcelUploaderTaskOutput_ProductExcelUploaderTaskError().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ProductExcelUploaderTaskOutput_ProductExcelUploaderTaskError {
    return new ProductExcelUploaderTaskOutput_ProductExcelUploaderTaskError().fromJsonString(jsonString, options);
  }

  static equals(a: ProductExcelUploaderTaskOutput_ProductExcelUploaderTaskError | PlainMessage<ProductExcelUploaderTaskOutput_ProductExcelUploaderTaskError> | undefined, b: ProductExcelUploaderTaskOutput_ProductExcelUploaderTaskError | PlainMessage<ProductExcelUploaderTaskOutput_ProductExcelUploaderTaskError> | undefined): boolean {
    return proto3.util.equals(ProductExcelUploaderTaskOutput_ProductExcelUploaderTaskError, a, b);
  }
}

/**
 * @generated from message librarian.proto.GetManualStoresRequest
 */
export class GetManualStoresRequest extends Message<GetManualStoresRequest> {
  constructor(data?: PartialMessage<GetManualStoresRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.GetManualStoresRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetManualStoresRequest {
    return new GetManualStoresRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetManualStoresRequest {
    return new GetManualStoresRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetManualStoresRequest {
    return new GetManualStoresRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetManualStoresRequest | PlainMessage<GetManualStoresRequest> | undefined, b: GetManualStoresRequest | PlainMessage<GetManualStoresRequest> | undefined): boolean {
    return proto3.util.equals(GetManualStoresRequest, a, b);
  }
}

/**
 * @generated from message librarian.proto.GetManualStoresResponse
 */
export class GetManualStoresResponse extends Message<GetManualStoresResponse> {
  /**
   * @generated from field: repeated librarian.proto.ManualStorePb manual_stores = 1;
   */
  manualStores: ManualStorePb[] = [];

  constructor(data?: PartialMessage<GetManualStoresResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.GetManualStoresResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "manual_stores", kind: "message", T: ManualStorePb, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetManualStoresResponse {
    return new GetManualStoresResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetManualStoresResponse {
    return new GetManualStoresResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetManualStoresResponse {
    return new GetManualStoresResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetManualStoresResponse | PlainMessage<GetManualStoresResponse> | undefined, b: GetManualStoresResponse | PlainMessage<GetManualStoresResponse> | undefined): boolean {
    return proto3.util.equals(GetManualStoresResponse, a, b);
  }
}

/**
 * @generated from message librarian.proto.GetQuantityByProductVariantRequest
 */
export class GetQuantityByProductVariantRequest extends Message<GetQuantityByProductVariantRequest> {
  /**
   * @generated from field: repeated int64 product_variants_ids = 1;
   */
  productVariantsIds: bigint[] = [];

  /**
   * @generated from field: int64 ecommerce_store_id = 2;
   */
  ecommerceStoreId = protoInt64.zero;

  /**
   * @generated from field: bool do_in_archivist = 3;
   */
  doInArchivist = false;

  constructor(data?: PartialMessage<GetQuantityByProductVariantRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.GetQuantityByProductVariantRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "product_variants_ids", kind: "scalar", T: 3 /* ScalarType.INT64 */, repeated: true },
    { no: 2, name: "ecommerce_store_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "do_in_archivist", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetQuantityByProductVariantRequest {
    return new GetQuantityByProductVariantRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetQuantityByProductVariantRequest {
    return new GetQuantityByProductVariantRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetQuantityByProductVariantRequest {
    return new GetQuantityByProductVariantRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetQuantityByProductVariantRequest | PlainMessage<GetQuantityByProductVariantRequest> | undefined, b: GetQuantityByProductVariantRequest | PlainMessage<GetQuantityByProductVariantRequest> | undefined): boolean {
    return proto3.util.equals(GetQuantityByProductVariantRequest, a, b);
  }
}

/**
 * @generated from message librarian.proto.GetQuantityByProductVariantTask
 */
export class GetQuantityByProductVariantTask extends Message<GetQuantityByProductVariantTask> {
  /**
   * @generated from field: int64 user_id = 1;
   */
  userId = protoInt64.zero;

  /**
   * @generated from field: librarian.proto.GetQuantityByProductVariantRequest request = 2;
   */
  request?: GetQuantityByProductVariantRequest;

  constructor(data?: PartialMessage<GetQuantityByProductVariantTask>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.GetQuantityByProductVariantTask";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "user_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "request", kind: "message", T: GetQuantityByProductVariantRequest },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetQuantityByProductVariantTask {
    return new GetQuantityByProductVariantTask().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetQuantityByProductVariantTask {
    return new GetQuantityByProductVariantTask().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetQuantityByProductVariantTask {
    return new GetQuantityByProductVariantTask().fromJsonString(jsonString, options);
  }

  static equals(a: GetQuantityByProductVariantTask | PlainMessage<GetQuantityByProductVariantTask> | undefined, b: GetQuantityByProductVariantTask | PlainMessage<GetQuantityByProductVariantTask> | undefined): boolean {
    return proto3.util.equals(GetQuantityByProductVariantTask, a, b);
  }
}

/**
 * @generated from message librarian.proto.GetQuantityByProductVariantTaskOutput
 */
export class GetQuantityByProductVariantTaskOutput extends Message<GetQuantityByProductVariantTaskOutput> {
  /**
   * @generated from field: map<int64, int32> quantity_by_product_variant_id = 1;
   */
  quantityByProductVariantId: { [key: string]: number } = {};

  constructor(data?: PartialMessage<GetQuantityByProductVariantTaskOutput>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.GetQuantityByProductVariantTaskOutput";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "quantity_by_product_variant_id", kind: "map", K: 3 /* ScalarType.INT64 */, V: {kind: "scalar", T: 5 /* ScalarType.INT32 */} },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetQuantityByProductVariantTaskOutput {
    return new GetQuantityByProductVariantTaskOutput().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetQuantityByProductVariantTaskOutput {
    return new GetQuantityByProductVariantTaskOutput().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetQuantityByProductVariantTaskOutput {
    return new GetQuantityByProductVariantTaskOutput().fromJsonString(jsonString, options);
  }

  static equals(a: GetQuantityByProductVariantTaskOutput | PlainMessage<GetQuantityByProductVariantTaskOutput> | undefined, b: GetQuantityByProductVariantTaskOutput | PlainMessage<GetQuantityByProductVariantTaskOutput> | undefined): boolean {
    return proto3.util.equals(GetQuantityByProductVariantTaskOutput, a, b);
  }
}

/**
 * @generated from message librarian.proto.GetQuantityByProductVariantResponse
 */
export class GetQuantityByProductVariantResponse extends Message<GetQuantityByProductVariantResponse> {
  /**
   * @generated from field: map<int64, int32> quantity_by_product_variant_id = 1 [deprecated = true];
   * @deprecated
   */
  quantityByProductVariantId: { [key: string]: number } = {};

  /**
   * @generated from field: string task_handle = 2;
   */
  taskHandle = "";

  constructor(data?: PartialMessage<GetQuantityByProductVariantResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.GetQuantityByProductVariantResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "quantity_by_product_variant_id", kind: "map", K: 3 /* ScalarType.INT64 */, V: {kind: "scalar", T: 5 /* ScalarType.INT32 */} },
    { no: 2, name: "task_handle", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetQuantityByProductVariantResponse {
    return new GetQuantityByProductVariantResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetQuantityByProductVariantResponse {
    return new GetQuantityByProductVariantResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetQuantityByProductVariantResponse {
    return new GetQuantityByProductVariantResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetQuantityByProductVariantResponse | PlainMessage<GetQuantityByProductVariantResponse> | undefined, b: GetQuantityByProductVariantResponse | PlainMessage<GetQuantityByProductVariantResponse> | undefined): boolean {
    return proto3.util.equals(GetQuantityByProductVariantResponse, a, b);
  }
}

/**
 * @generated from message librarian.proto.UpdateProductVariantsQuantityRequest
 */
export class UpdateProductVariantsQuantityRequest extends Message<UpdateProductVariantsQuantityRequest> {
  /**
   * @generated from field: map<int64, int32> quantity_by_product_variant_id = 1;
   */
  quantityByProductVariantId: { [key: string]: number } = {};

  /**
   * @generated from field: int64 ecommerce_store_id = 2;
   */
  ecommerceStoreId = protoInt64.zero;

  constructor(data?: PartialMessage<UpdateProductVariantsQuantityRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.UpdateProductVariantsQuantityRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "quantity_by_product_variant_id", kind: "map", K: 3 /* ScalarType.INT64 */, V: {kind: "scalar", T: 5 /* ScalarType.INT32 */} },
    { no: 2, name: "ecommerce_store_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateProductVariantsQuantityRequest {
    return new UpdateProductVariantsQuantityRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateProductVariantsQuantityRequest {
    return new UpdateProductVariantsQuantityRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateProductVariantsQuantityRequest {
    return new UpdateProductVariantsQuantityRequest().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateProductVariantsQuantityRequest | PlainMessage<UpdateProductVariantsQuantityRequest> | undefined, b: UpdateProductVariantsQuantityRequest | PlainMessage<UpdateProductVariantsQuantityRequest> | undefined): boolean {
    return proto3.util.equals(UpdateProductVariantsQuantityRequest, a, b);
  }
}

/**
 * @generated from message librarian.proto.UpdateProductVariantsQuantityResponse
 */
export class UpdateProductVariantsQuantityResponse extends Message<UpdateProductVariantsQuantityResponse> {
  /**
   * @generated from field: string task_handle = 1;
   */
  taskHandle = "";

  constructor(data?: PartialMessage<UpdateProductVariantsQuantityResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.UpdateProductVariantsQuantityResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "task_handle", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateProductVariantsQuantityResponse {
    return new UpdateProductVariantsQuantityResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateProductVariantsQuantityResponse {
    return new UpdateProductVariantsQuantityResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateProductVariantsQuantityResponse {
    return new UpdateProductVariantsQuantityResponse().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateProductVariantsQuantityResponse | PlainMessage<UpdateProductVariantsQuantityResponse> | undefined, b: UpdateProductVariantsQuantityResponse | PlainMessage<UpdateProductVariantsQuantityResponse> | undefined): boolean {
    return proto3.util.equals(UpdateProductVariantsQuantityResponse, a, b);
  }
}

/**
 * @generated from message librarian.proto.UpdateProductVariantsQuantityTask
 */
export class UpdateProductVariantsQuantityTask extends Message<UpdateProductVariantsQuantityTask> {
  /**
   * @generated from field: int64 user_id = 1;
   */
  userId = protoInt64.zero;

  /**
   * @generated from field: librarian.proto.UpdateProductVariantsQuantityRequest request = 2;
   */
  request?: UpdateProductVariantsQuantityRequest;

  constructor(data?: PartialMessage<UpdateProductVariantsQuantityTask>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.UpdateProductVariantsQuantityTask";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "user_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "request", kind: "message", T: UpdateProductVariantsQuantityRequest },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateProductVariantsQuantityTask {
    return new UpdateProductVariantsQuantityTask().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateProductVariantsQuantityTask {
    return new UpdateProductVariantsQuantityTask().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateProductVariantsQuantityTask {
    return new UpdateProductVariantsQuantityTask().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateProductVariantsQuantityTask | PlainMessage<UpdateProductVariantsQuantityTask> | undefined, b: UpdateProductVariantsQuantityTask | PlainMessage<UpdateProductVariantsQuantityTask> | undefined): boolean {
    return proto3.util.equals(UpdateProductVariantsQuantityTask, a, b);
  }
}

/**
 * @generated from message librarian.proto.InitGetProductsListsResponse
 */
export class InitGetProductsListsResponse extends Message<InitGetProductsListsResponse> {
  /**
   * @generated from field: repeated librarian.proto.EcommerceStorePb stores = 1;
   */
  stores: EcommerceStorePb[] = [];

  constructor(data?: PartialMessage<InitGetProductsListsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.InitGetProductsListsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "stores", kind: "message", T: EcommerceStorePb, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): InitGetProductsListsResponse {
    return new InitGetProductsListsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): InitGetProductsListsResponse {
    return new InitGetProductsListsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): InitGetProductsListsResponse {
    return new InitGetProductsListsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: InitGetProductsListsResponse | PlainMessage<InitGetProductsListsResponse> | undefined, b: InitGetProductsListsResponse | PlainMessage<InitGetProductsListsResponse> | undefined): boolean {
    return proto3.util.equals(InitGetProductsListsResponse, a, b);
  }
}

