<lefty-form
  [label]="label()"
  [required]="required()"
  [helpText]="helpText()"
  [externalHelpLink]="externalHelpLink()"
  [helpLink]="helpLink()"
  [hintText]="hintText()"
  [errorMessage]="errorMessage"
>
  <div class="input-container">
    <lefty-form-number-input
      class="number-input"
      focusableElement
      [(value)]="durationValue"
      [disabled]="disabled()"
    >
    </lefty-form-number-input>

    <lefty-form-select
      class="duration-select"
      [(selection)]="durationUnit"
      [options]="supportedDurationUnits"
      [itemRenderer]="durationUnitRenderer"
      [disabled]="disabled()"
    >
    </lefty-form-select>
  </div>
</lefty-form>
