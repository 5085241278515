import { ChangeDetectionStrategy, Component } from '@angular/core';
import { LeftyButtonDirective } from '../../lefty-button-directive/lefty-button.directive';
import { LeftyDialogComponent } from '../../lefty-dialog/lefty-dialog.component';
import { DialogBase } from '../../lefty-dialog/lefty-dialog.helpers';

@Component({
  selector: 'affiliates-limitation-dialog',
  templateUrl: 'affiliates-limitation-dialog.component.html',
  styleUrls: ['affiliates-limitation-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [LeftyDialogComponent, LeftyButtonDirective],
})
export class LeftyAffiliatesLimitationDialogComponent extends DialogBase {
  readonly WARNING_ICON_SVG = 'lefty-images/icons/ico-warning.svg';
}
