<lefty-card
  class="program-card"
  [withBorder]="true"
  [withPadding]="true"
  [isSelected]="isSelected()"
  [selectable]="selectable()"
  [isCompact]="true"
  [class.disabled]="isDisabled()"
>
  <div class="fill-space body-1">
    <div class="name">{{ program().name }}</div>
    <div class="detail">
      <span i18n>Commission: </span>{{ formattedCommission() }}
    </div>
    <div class="detail">
      <span i18n>Cookie duration: </span>{{ formattedCookieDuration() }}
    </div>
  </div>

  @if (withChangeButton()) {
    <button
      leftyButton="plain"
      (click)="change$.emit()"
      i18n
    >
      Change
    </button>
  }
</lefty-card>
