// @generated by protoc-gen-es v1.10.0 with parameter "target=ts"
// @generated from file librarian/proto/affiliation.proto (package librarian.proto, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Duration, FloatValue, Int64Value, Message, proto3, protoInt64, StringValue, Timestamp } from "@bufbuild/protobuf";
import { BasicPagination, CurrencyEnum, Int64List, Network, SortDirection, StringList } from "../../common/proto/common_pb";
import { CreatorCardSnippet, CreatorProfileCard, GenerikInfoCard } from "./creators_pb";
import { AffiliateFilter, AffiliationPixelProto, DestinationUrl, FlexibleCreatorSelector, TimeRestriction } from "./common_pb";
import { NightwatchCtx } from "../../common/proto/nightwatch_pb";

/**
 * @generated from enum librarian.proto.AffiliationProgramCommissionType
 */
export enum AffiliationProgramCommissionType {
  /**
   * @generated from enum value: COMMISSION_NONE = 0;
   */
  COMMISSION_NONE = 0,

  /**
   * @generated from enum value: COMMISSION_PERCENTAGE = 1;
   */
  COMMISSION_PERCENTAGE = 1,

  /**
   * @generated from enum value: COMMISSION_FLAT_FEE = 2;
   */
  COMMISSION_FLAT_FEE = 2,
}
// Retrieve enum metadata with: proto3.getEnumType(AffiliationProgramCommissionType)
proto3.util.setEnumType(AffiliationProgramCommissionType, "librarian.proto.AffiliationProgramCommissionType", [
  { no: 0, name: "COMMISSION_NONE" },
  { no: 1, name: "COMMISSION_PERCENTAGE" },
  { no: 2, name: "COMMISSION_FLAT_FEE" },
]);

/**
 * @generated from enum librarian.proto.AffiliateSort
 */
export enum AffiliateSort {
  /**
   * @generated from enum value: AFFILIATE_ADDED_AT = 0;
   */
  AFFILIATE_ADDED_AT = 0,

  /**
   * @generated from enum value: AFFILIATE_USERNAME = 1;
   */
  AFFILIATE_USERNAME = 1,

  /**
   * @generated from enum value: AFFILIATE_PROGRAM_NAME = 2;
   */
  AFFILIATE_PROGRAM_NAME = 2,

  /**
   * @generated from enum value: AFFILIATE_LINKS_COUNT = 3;
   */
  AFFILIATE_LINKS_COUNT = 3,

  /**
   * @generated from enum value: AFFILIATE_CLICKS_COUNT = 4;
   */
  AFFILIATE_CLICKS_COUNT = 4,

  /**
   * @generated from enum value: AFFILIATE_ORDERS_COUNT = 5;
   */
  AFFILIATE_ORDERS_COUNT = 5,

  /**
   * @generated from enum value: AFFILIATE_TOTAL_SALES = 6;
   */
  AFFILIATE_TOTAL_SALES = 6,

  /**
   * @generated from enum value: AFFILIATE_AVG_SALES = 7;
   */
  AFFILIATE_AVG_SALES = 7,

  /**
   * @generated from enum value: AFFILIATE_AVG_TIME_TO_SALES = 8;
   */
  AFFILIATE_AVG_TIME_TO_SALES = 8,

  /**
   * @generated from enum value: AFFILIATE_COMMISSION = 9;
   */
  AFFILIATE_COMMISSION = 9,
}
// Retrieve enum metadata with: proto3.getEnumType(AffiliateSort)
proto3.util.setEnumType(AffiliateSort, "librarian.proto.AffiliateSort", [
  { no: 0, name: "AFFILIATE_ADDED_AT" },
  { no: 1, name: "AFFILIATE_USERNAME" },
  { no: 2, name: "AFFILIATE_PROGRAM_NAME" },
  { no: 3, name: "AFFILIATE_LINKS_COUNT" },
  { no: 4, name: "AFFILIATE_CLICKS_COUNT" },
  { no: 5, name: "AFFILIATE_ORDERS_COUNT" },
  { no: 6, name: "AFFILIATE_TOTAL_SALES" },
  { no: 7, name: "AFFILIATE_AVG_SALES" },
  { no: 8, name: "AFFILIATE_AVG_TIME_TO_SALES" },
  { no: 9, name: "AFFILIATE_COMMISSION" },
]);

/**
 * @generated from enum librarian.proto.LinkSort
 */
export enum LinkSort {
  /**
   * @generated from enum value: LINK_AFFILIATE_USERNAME = 0;
   */
  LINK_AFFILIATE_USERNAME = 0,

  /**
   * @generated from enum value: LINK_DESTINATION_URL = 1;
   */
  LINK_DESTINATION_URL = 1,

  /**
   * @generated from enum value: LINK_PROGRAM = 2;
   */
  LINK_PROGRAM = 2,

  /**
   * @generated from enum value: LINK_CAMPAIGN = 3;
   */
  LINK_CAMPAIGN = 3,

  /**
   * @generated from enum value: LINK_CLICKS_COUNT = 4;
   */
  LINK_CLICKS_COUNT = 4,

  /**
   * @generated from enum value: LINK_AVG_TIME_TO_SALES = 5;
   */
  LINK_AVG_TIME_TO_SALES = 5,

  /**
   * @generated from enum value: LINK_ORDERS_COUNT = 6;
   */
  LINK_ORDERS_COUNT = 6,

  /**
   * @generated from enum value: LINK_TOTAL_SALES = 7;
   */
  LINK_TOTAL_SALES = 7,

  /**
   * @generated from enum value: LINK_CREATION_DATE = 8;
   */
  LINK_CREATION_DATE = 8,
}
// Retrieve enum metadata with: proto3.getEnumType(LinkSort)
proto3.util.setEnumType(LinkSort, "librarian.proto.LinkSort", [
  { no: 0, name: "LINK_AFFILIATE_USERNAME" },
  { no: 1, name: "LINK_DESTINATION_URL" },
  { no: 2, name: "LINK_PROGRAM" },
  { no: 3, name: "LINK_CAMPAIGN" },
  { no: 4, name: "LINK_CLICKS_COUNT" },
  { no: 5, name: "LINK_AVG_TIME_TO_SALES" },
  { no: 6, name: "LINK_ORDERS_COUNT" },
  { no: 7, name: "LINK_TOTAL_SALES" },
  { no: 8, name: "LINK_CREATION_DATE" },
]);

/**
 * @generated from enum librarian.proto.OrderSort
 */
export enum OrderSort {
  /**
   * @generated from enum value: ORDER_ID = 0;
   */
  ORDER_ID = 0,

  /**
   * @generated from enum value: ORDER_DATE = 1;
   */
  ORDER_DATE = 1,

  /**
   * @generated from enum value: ORDER_AMOUNT = 2;
   */
  ORDER_AMOUNT = 2,

  /**
   * @generated from enum value: ORDER_TIME_TO_SALE = 3;
   */
  ORDER_TIME_TO_SALE = 3,

  /**
   * @generated from enum value: ORDER_AFFILIATE_USERNAME = 4;
   */
  ORDER_AFFILIATE_USERNAME = 4,

  /**
   * @generated from enum value: ORDER_COMMISSION = 5;
   */
  ORDER_COMMISSION = 5,

  /**
   * @generated from enum value: ORDER_PIXEL = 6;
   */
  ORDER_PIXEL = 6,
}
// Retrieve enum metadata with: proto3.getEnumType(OrderSort)
proto3.util.setEnumType(OrderSort, "librarian.proto.OrderSort", [
  { no: 0, name: "ORDER_ID" },
  { no: 1, name: "ORDER_DATE" },
  { no: 2, name: "ORDER_AMOUNT" },
  { no: 3, name: "ORDER_TIME_TO_SALE" },
  { no: 4, name: "ORDER_AFFILIATE_USERNAME" },
  { no: 5, name: "ORDER_COMMISSION" },
  { no: 6, name: "ORDER_PIXEL" },
]);

/**
 * The "reason" the conversion was ingested into Lefty. Ie: how we matched it.
 *
 * @generated from enum librarian.proto.OrderReason
 */
export enum OrderReason {
  /**
   * @generated from enum value: ORDER_REASON_UNKNOWN = 0;
   */
  UNKNOWN = 0,

  /**
   * @generated from enum value: ORDER_REASON_COOKIE = 1;
   */
  COOKIE = 1,

  /**
   * @generated from enum value: ORDER_REASON_USER_AGENT_IP = 2;
   */
  USER_AGENT_IP = 2,

  /**
   * @generated from enum value: ORDER_REASON_IP = 3;
   */
  IP = 3,

  /**
   * @generated from enum value: ORDER_REASON_SHOPIFY_PROMO_CODE = 4;
   */
  SHOPIFY_PROMO_CODE = 4,
}
// Retrieve enum metadata with: proto3.getEnumType(OrderReason)
proto3.util.setEnumType(OrderReason, "librarian.proto.OrderReason", [
  { no: 0, name: "ORDER_REASON_UNKNOWN" },
  { no: 1, name: "ORDER_REASON_COOKIE" },
  { no: 2, name: "ORDER_REASON_USER_AGENT_IP" },
  { no: 3, name: "ORDER_REASON_IP" },
  { no: 4, name: "ORDER_REASON_SHOPIFY_PROMO_CODE" },
]);

/**
 * Note: never change index/order or remove values, it's stored in DB using Java enum ORDINAL.
 *
 * @generated from enum librarian.proto.AffiliationOrderStatus
 */
export enum AffiliationOrderStatus {
  /**
   * @generated from enum value: AFFILIATION_ORDER_STATUS_UNKNOWN = 0;
   */
  UNKNOWN = 0,

  /**
   * @generated from enum value: AFFILIATION_ORDER_STATUS_PAID = 1;
   */
  PAID = 1,

  /**
   * @generated from enum value: AFFILIATION_ORDER_STATUS_REFUNDED = 2;
   */
  REFUNDED = 2,
}
// Retrieve enum metadata with: proto3.getEnumType(AffiliationOrderStatus)
proto3.util.setEnumType(AffiliationOrderStatus, "librarian.proto.AffiliationOrderStatus", [
  { no: 0, name: "AFFILIATION_ORDER_STATUS_UNKNOWN" },
  { no: 1, name: "AFFILIATION_ORDER_STATUS_PAID" },
  { no: 2, name: "AFFILIATION_ORDER_STATUS_REFUNDED" },
]);

/**
 * @generated from enum librarian.proto.AffiliationReportTimelineType
 */
export enum AffiliationReportTimelineType {
  /**
   * Don't use
   *
   * @generated from enum value: TIMELINE_TYPE_UNKNOWN = 0;
   */
  TIMELINE_TYPE_UNKNOWN = 0,

  /**
   * @generated from enum value: TIMELINE_CLICKS = 1;
   */
  TIMELINE_CLICKS = 1,

  /**
   * @generated from enum value: TIMELINE_CONVERSIONS = 2;
   */
  TIMELINE_CONVERSIONS = 2,

  /**
   * @generated from enum value: TIMELINE_SALES = 3;
   */
  TIMELINE_SALES = 3,
}
// Retrieve enum metadata with: proto3.getEnumType(AffiliationReportTimelineType)
proto3.util.setEnumType(AffiliationReportTimelineType, "librarian.proto.AffiliationReportTimelineType", [
  { no: 0, name: "TIMELINE_TYPE_UNKNOWN" },
  { no: 1, name: "TIMELINE_CLICKS" },
  { no: 2, name: "TIMELINE_CONVERSIONS" },
  { no: 3, name: "TIMELINE_SALES" },
]);

/**
 * @generated from enum librarian.proto.AffiliationReportProductsSort
 */
export enum AffiliationReportProductsSort {
  /**
   * Don't use
   *
   * @generated from enum value: PRODUCTS_REPORT_UNKNOWN = 0;
   */
  PRODUCTS_REPORT_UNKNOWN = 0,

  /**
   * @generated from enum value: PRODUCTS_REPORT_AMOUNT = 1;
   */
  PRODUCTS_REPORT_AMOUNT = 1,

  /**
   * @generated from enum value: PRODUCTS_REPORT_QUANTITY = 2;
   */
  PRODUCTS_REPORT_QUANTITY = 2,
}
// Retrieve enum metadata with: proto3.getEnumType(AffiliationReportProductsSort)
proto3.util.setEnumType(AffiliationReportProductsSort, "librarian.proto.AffiliationReportProductsSort", [
  { no: 0, name: "PRODUCTS_REPORT_UNKNOWN" },
  { no: 1, name: "PRODUCTS_REPORT_AMOUNT" },
  { no: 2, name: "PRODUCTS_REPORT_QUANTITY" },
]);

/**
 * @generated from message librarian.proto.Program
 */
export class Program extends Message<Program> {
  /**
   * @generated from field: google.protobuf.Int64Value program_id = 1;
   */
  programId?: bigint;

  /**
   * @generated from field: string name = 2;
   */
  name = "";

  /**
   * @generated from field: int32 cookie_duration_minutes = 3;
   */
  cookieDurationMinutes = 0;

  /**
   * @generated from field: librarian.proto.AffiliationProgramCommissionType commission_type = 4;
   */
  commissionType = AffiliationProgramCommissionType.COMMISSION_NONE;

  /**
   * @generated from field: google.protobuf.FloatValue commission_value = 5;
   */
  commissionValue?: number;

  /**
   * @generated from field: common.proto.CurrencyEnum currency = 6;
   */
  currency = CurrencyEnum.NA;

  /**
   * @generated from field: bool archived = 7;
   */
  archived = false;

  constructor(data?: PartialMessage<Program>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.Program";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "program_id", kind: "message", T: Int64Value },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "cookie_duration_minutes", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "commission_type", kind: "enum", T: proto3.getEnumType(AffiliationProgramCommissionType) },
    { no: 5, name: "commission_value", kind: "message", T: FloatValue },
    { no: 6, name: "currency", kind: "enum", T: proto3.getEnumType(CurrencyEnum) },
    { no: 7, name: "archived", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Program {
    return new Program().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Program {
    return new Program().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Program {
    return new Program().fromJsonString(jsonString, options);
  }

  static equals(a: Program | PlainMessage<Program> | undefined, b: Program | PlainMessage<Program> | undefined): boolean {
    return proto3.util.equals(Program, a, b);
  }
}

/**
 * @generated from message librarian.proto.AffiliationCreatorPoolResponse
 */
export class AffiliationCreatorPoolResponse extends Message<AffiliationCreatorPoolResponse> {
  /**
   * @generated from field: librarian.proto.CreatorCardSnippet base_snippet = 1;
   */
  baseSnippet?: CreatorCardSnippet;

  /**
   * @generated from field: repeated librarian.proto.GenerikInfoCard network_info = 3;
   */
  networkInfo: GenerikInfoCard[] = [];

  /**
   * @generated from field: bool is_affiliates_for_team = 2;
   */
  isAffiliatesForTeam = false;

  constructor(data?: PartialMessage<AffiliationCreatorPoolResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.AffiliationCreatorPoolResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "base_snippet", kind: "message", T: CreatorCardSnippet },
    { no: 3, name: "network_info", kind: "message", T: GenerikInfoCard, repeated: true },
    { no: 2, name: "is_affiliates_for_team", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AffiliationCreatorPoolResponse {
    return new AffiliationCreatorPoolResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AffiliationCreatorPoolResponse {
    return new AffiliationCreatorPoolResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AffiliationCreatorPoolResponse {
    return new AffiliationCreatorPoolResponse().fromJsonString(jsonString, options);
  }

  static equals(a: AffiliationCreatorPoolResponse | PlainMessage<AffiliationCreatorPoolResponse> | undefined, b: AffiliationCreatorPoolResponse | PlainMessage<AffiliationCreatorPoolResponse> | undefined): boolean {
    return proto3.util.equals(AffiliationCreatorPoolResponse, a, b);
  }
}

/**
 * @generated from message librarian.proto.AffiliationCreatorPoolResponses
 */
export class AffiliationCreatorPoolResponses extends Message<AffiliationCreatorPoolResponses> {
  /**
   * @generated from field: repeated librarian.proto.AffiliationCreatorPoolResponse responses = 1;
   */
  responses: AffiliationCreatorPoolResponse[] = [];

  /**
   * @generated from field: int64 total_hits = 2;
   */
  totalHits = protoInt64.zero;

  /**
   * Pagination flag - We need to signal if there is a pagination
   * because we might return less results than expected if a user has gone private.
   *
   * @generated from field: bool has_pagination = 3;
   */
  hasPagination = false;

  constructor(data?: PartialMessage<AffiliationCreatorPoolResponses>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.AffiliationCreatorPoolResponses";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "responses", kind: "message", T: AffiliationCreatorPoolResponse, repeated: true },
    { no: 2, name: "total_hits", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "has_pagination", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AffiliationCreatorPoolResponses {
    return new AffiliationCreatorPoolResponses().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AffiliationCreatorPoolResponses {
    return new AffiliationCreatorPoolResponses().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AffiliationCreatorPoolResponses {
    return new AffiliationCreatorPoolResponses().fromJsonString(jsonString, options);
  }

  static equals(a: AffiliationCreatorPoolResponses | PlainMessage<AffiliationCreatorPoolResponses> | undefined, b: AffiliationCreatorPoolResponses | PlainMessage<AffiliationCreatorPoolResponses> | undefined): boolean {
    return proto3.util.equals(AffiliationCreatorPoolResponses, a, b);
  }
}

/**
 * @generated from message librarian.proto.AddAffiliatesRequest
 */
export class AddAffiliatesRequest extends Message<AddAffiliatesRequest> {
  /**
   * @generated from field: librarian.proto.FlexibleCreatorSelector selector = 5;
   */
  selector?: FlexibleCreatorSelector;

  /**
   * @generated from field: int64 program_id = 2;
   */
  programId = protoInt64.zero;

  /**
   * Not set by front, only by inner grpc calls
   *
   * @generated from field: google.protobuf.Int64Value team_owner_id = 3;
   */
  teamOwnerId?: bigint;

  /**
   * @generated from field: repeated int64 tags_to_add = 6;
   */
  tagsToAdd: bigint[] = [];

  /**
   * @generated from field: common.proto.NightwatchCtx nightwatch_ctx = 4;
   */
  nightwatchCtx?: NightwatchCtx;

  constructor(data?: PartialMessage<AddAffiliatesRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.AddAffiliatesRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 5, name: "selector", kind: "message", T: FlexibleCreatorSelector },
    { no: 2, name: "program_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "team_owner_id", kind: "message", T: Int64Value },
    { no: 6, name: "tags_to_add", kind: "scalar", T: 3 /* ScalarType.INT64 */, repeated: true },
    { no: 4, name: "nightwatch_ctx", kind: "message", T: NightwatchCtx },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AddAffiliatesRequest {
    return new AddAffiliatesRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AddAffiliatesRequest {
    return new AddAffiliatesRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AddAffiliatesRequest {
    return new AddAffiliatesRequest().fromJsonString(jsonString, options);
  }

  static equals(a: AddAffiliatesRequest | PlainMessage<AddAffiliatesRequest> | undefined, b: AddAffiliatesRequest | PlainMessage<AddAffiliatesRequest> | undefined): boolean {
    return proto3.util.equals(AddAffiliatesRequest, a, b);
  }
}

/**
 * @generated from message librarian.proto.EditAffiliateRequest
 */
export class EditAffiliateRequest extends Message<EditAffiliateRequest> {
  /**
   * @generated from field: librarian.proto.FlexibleCreatorSelector selector = 3;
   */
  selector?: FlexibleCreatorSelector;

  /**
   * @generated from field: int64 program_id = 2;
   */
  programId = protoInt64.zero;

  constructor(data?: PartialMessage<EditAffiliateRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.EditAffiliateRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 3, name: "selector", kind: "message", T: FlexibleCreatorSelector },
    { no: 2, name: "program_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): EditAffiliateRequest {
    return new EditAffiliateRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): EditAffiliateRequest {
    return new EditAffiliateRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): EditAffiliateRequest {
    return new EditAffiliateRequest().fromJsonString(jsonString, options);
  }

  static equals(a: EditAffiliateRequest | PlainMessage<EditAffiliateRequest> | undefined, b: EditAffiliateRequest | PlainMessage<EditAffiliateRequest> | undefined): boolean {
    return proto3.util.equals(EditAffiliateRequest, a, b);
  }
}

/**
 * @generated from message librarian.proto.GetAffiliatesRequest
 */
export class GetAffiliatesRequest extends Message<GetAffiliatesRequest> {
  /**
   * @generated from field: librarian.proto.AffiliateFilter filter = 1;
   */
  filter?: AffiliateFilter;

  /**
   * @generated from field: librarian.proto.AffiliateSort sort = 2;
   */
  sort = AffiliateSort.AFFILIATE_ADDED_AT;

  /**
   * @generated from field: common.proto.SortDirection sort_direction = 3;
   */
  sortDirection = SortDirection.DESC;

  /**
   * @generated from field: common.proto.BasicPagination pagination = 4;
   */
  pagination?: BasicPagination;

  constructor(data?: PartialMessage<GetAffiliatesRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.GetAffiliatesRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "filter", kind: "message", T: AffiliateFilter },
    { no: 2, name: "sort", kind: "enum", T: proto3.getEnumType(AffiliateSort) },
    { no: 3, name: "sort_direction", kind: "enum", T: proto3.getEnumType(SortDirection) },
    { no: 4, name: "pagination", kind: "message", T: BasicPagination },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetAffiliatesRequest {
    return new GetAffiliatesRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetAffiliatesRequest {
    return new GetAffiliatesRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetAffiliatesRequest {
    return new GetAffiliatesRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetAffiliatesRequest | PlainMessage<GetAffiliatesRequest> | undefined, b: GetAffiliatesRequest | PlainMessage<GetAffiliatesRequest> | undefined): boolean {
    return proto3.util.equals(GetAffiliatesRequest, a, b);
  }
}

/**
 * @generated from message librarian.proto.Affiliate
 */
export class Affiliate extends Message<Affiliate> {
  /**
   * @generated from field: int64 affiliate_id = 3;
   */
  affiliateId = protoInt64.zero;

  /**
   * @generated from field: librarian.proto.CreatorProfileCard profile = 4;
   */
  profile?: CreatorProfileCard;

  /**
   * @generated from field: google.protobuf.Timestamp added_at = 2;
   */
  addedAt?: Timestamp;

  constructor(data?: PartialMessage<Affiliate>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.Affiliate";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 3, name: "affiliate_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 4, name: "profile", kind: "message", T: CreatorProfileCard },
    { no: 2, name: "added_at", kind: "message", T: Timestamp },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Affiliate {
    return new Affiliate().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Affiliate {
    return new Affiliate().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Affiliate {
    return new Affiliate().fromJsonString(jsonString, options);
  }

  static equals(a: Affiliate | PlainMessage<Affiliate> | undefined, b: Affiliate | PlainMessage<Affiliate> | undefined): boolean {
    return proto3.util.equals(Affiliate, a, b);
  }
}

/**
 * @generated from message librarian.proto.GetAffiliatesResponses
 */
export class GetAffiliatesResponses extends Message<GetAffiliatesResponses> {
  /**
   * @generated from field: repeated librarian.proto.GetAffiliatesResponses.AffiliateResponse affiliates = 1;
   */
  affiliates: GetAffiliatesResponses_AffiliateResponse[] = [];

  /**
   * @generated from field: int32 total_hits = 2;
   */
  totalHits = 0;

  constructor(data?: PartialMessage<GetAffiliatesResponses>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.GetAffiliatesResponses";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "affiliates", kind: "message", T: GetAffiliatesResponses_AffiliateResponse, repeated: true },
    { no: 2, name: "total_hits", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetAffiliatesResponses {
    return new GetAffiliatesResponses().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetAffiliatesResponses {
    return new GetAffiliatesResponses().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetAffiliatesResponses {
    return new GetAffiliatesResponses().fromJsonString(jsonString, options);
  }

  static equals(a: GetAffiliatesResponses | PlainMessage<GetAffiliatesResponses> | undefined, b: GetAffiliatesResponses | PlainMessage<GetAffiliatesResponses> | undefined): boolean {
    return proto3.util.equals(GetAffiliatesResponses, a, b);
  }
}

/**
 * @generated from message librarian.proto.GetAffiliatesResponses.AffiliateResponse
 */
export class GetAffiliatesResponses_AffiliateResponse extends Message<GetAffiliatesResponses_AffiliateResponse> {
  /**
   * @generated from field: librarian.proto.Affiliate affiliate = 1;
   */
  affiliate?: Affiliate;

  /**
   * @generated from field: librarian.proto.Program program = 2;
   */
  program?: Program;

  /**
   * @generated from field: int32 links_count = 3;
   */
  linksCount = 0;

  /**
   * @generated from field: int32 clicks_count = 4;
   */
  clicksCount = 0;

  /**
   * @generated from field: int32 orders_count = 5;
   */
  ordersCount = 0;

  /**
   * @generated from field: google.protobuf.FloatValue total_sales = 6;
   */
  totalSales?: number;

  /**
   * @generated from field: google.protobuf.FloatValue avg_sales = 7;
   */
  avgSales?: number;

  /**
   * @generated from field: google.protobuf.Duration avg_time_to_sales = 8;
   */
  avgTimeToSales?: Duration;

  /**
   * @generated from field: google.protobuf.FloatValue commission = 9;
   */
  commission?: number;

  /**
   * @generated from field: common.proto.CurrencyEnum currency = 10;
   */
  currency = CurrencyEnum.NA;

  /**
   * @generated from field: repeated librarian.proto.PixelPerformance pixels_performances = 11;
   */
  pixelsPerformances: PixelPerformance[] = [];

  constructor(data?: PartialMessage<GetAffiliatesResponses_AffiliateResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.GetAffiliatesResponses.AffiliateResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "affiliate", kind: "message", T: Affiliate },
    { no: 2, name: "program", kind: "message", T: Program },
    { no: 3, name: "links_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "clicks_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: "orders_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 6, name: "total_sales", kind: "message", T: FloatValue },
    { no: 7, name: "avg_sales", kind: "message", T: FloatValue },
    { no: 8, name: "avg_time_to_sales", kind: "message", T: Duration },
    { no: 9, name: "commission", kind: "message", T: FloatValue },
    { no: 10, name: "currency", kind: "enum", T: proto3.getEnumType(CurrencyEnum) },
    { no: 11, name: "pixels_performances", kind: "message", T: PixelPerformance, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetAffiliatesResponses_AffiliateResponse {
    return new GetAffiliatesResponses_AffiliateResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetAffiliatesResponses_AffiliateResponse {
    return new GetAffiliatesResponses_AffiliateResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetAffiliatesResponses_AffiliateResponse {
    return new GetAffiliatesResponses_AffiliateResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetAffiliatesResponses_AffiliateResponse | PlainMessage<GetAffiliatesResponses_AffiliateResponse> | undefined, b: GetAffiliatesResponses_AffiliateResponse | PlainMessage<GetAffiliatesResponses_AffiliateResponse> | undefined): boolean {
    return proto3.util.equals(GetAffiliatesResponses_AffiliateResponse, a, b);
  }
}

/**
 * @generated from message librarian.proto.UploadAffiliatesRequest
 */
export class UploadAffiliatesRequest extends Message<UploadAffiliatesRequest> {
  /**
   * @generated from field: repeated string names = 1;
   */
  names: string[] = [];

  /**
   * @generated from field: common.proto.Network network = 2;
   */
  network = Network.NETWORK_UNKNOWN;

  /**
   * @generated from field: int64 program_id = 3;
   */
  programId = protoInt64.zero;

  /**
   * Not set by front
   *
   * @generated from field: int64 team_owner_id = 4;
   */
  teamOwnerId = protoInt64.zero;

  /**
   * @generated from field: repeated int64 tags_to_add = 6;
   */
  tagsToAdd: bigint[] = [];

  /**
   * TODO(mateo) remove when everything has been deployed
   *
   * @generated from field: common.proto.NightwatchCtx nightwatch_ctx = 5 [deprecated = true];
   * @deprecated
   */
  nightwatchCtx?: NightwatchCtx;

  constructor(data?: PartialMessage<UploadAffiliatesRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.UploadAffiliatesRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "names", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 2, name: "network", kind: "enum", T: proto3.getEnumType(Network) },
    { no: 3, name: "program_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 4, name: "team_owner_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 6, name: "tags_to_add", kind: "scalar", T: 3 /* ScalarType.INT64 */, repeated: true },
    { no: 5, name: "nightwatch_ctx", kind: "message", T: NightwatchCtx },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UploadAffiliatesRequest {
    return new UploadAffiliatesRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UploadAffiliatesRequest {
    return new UploadAffiliatesRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UploadAffiliatesRequest {
    return new UploadAffiliatesRequest().fromJsonString(jsonString, options);
  }

  static equals(a: UploadAffiliatesRequest | PlainMessage<UploadAffiliatesRequest> | undefined, b: UploadAffiliatesRequest | PlainMessage<UploadAffiliatesRequest> | undefined): boolean {
    return proto3.util.equals(UploadAffiliatesRequest, a, b);
  }
}

/**
 * @generated from message librarian.proto.ArchiveAffiliatesRequest
 */
export class ArchiveAffiliatesRequest extends Message<ArchiveAffiliatesRequest> {
  /**
   * @generated from field: common.proto.Int64List affiliates_ids = 1 [deprecated = true];
   * @deprecated
   */
  affiliatesIds?: Int64List;

  /**
   * @generated from field: librarian.proto.FlexibleCreatorSelector selector = 2;
   */
  selector?: FlexibleCreatorSelector;

  constructor(data?: PartialMessage<ArchiveAffiliatesRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.ArchiveAffiliatesRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "affiliates_ids", kind: "message", T: Int64List },
    { no: 2, name: "selector", kind: "message", T: FlexibleCreatorSelector },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ArchiveAffiliatesRequest {
    return new ArchiveAffiliatesRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ArchiveAffiliatesRequest {
    return new ArchiveAffiliatesRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ArchiveAffiliatesRequest {
    return new ArchiveAffiliatesRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ArchiveAffiliatesRequest | PlainMessage<ArchiveAffiliatesRequest> | undefined, b: ArchiveAffiliatesRequest | PlainMessage<ArchiveAffiliatesRequest> | undefined): boolean {
    return proto3.util.equals(ArchiveAffiliatesRequest, a, b);
  }
}

/**
 * @generated from message librarian.proto.GetProgramsResponse
 */
export class GetProgramsResponse extends Message<GetProgramsResponse> {
  /**
   * @generated from field: repeated librarian.proto.Program programs = 1;
   */
  programs: Program[] = [];

  constructor(data?: PartialMessage<GetProgramsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.GetProgramsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "programs", kind: "message", T: Program, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetProgramsResponse {
    return new GetProgramsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetProgramsResponse {
    return new GetProgramsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetProgramsResponse {
    return new GetProgramsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetProgramsResponse | PlainMessage<GetProgramsResponse> | undefined, b: GetProgramsResponse | PlainMessage<GetProgramsResponse> | undefined): boolean {
    return proto3.util.equals(GetProgramsResponse, a, b);
  }
}

/**
 * @generated from message librarian.proto.AddLinksRequest
 */
export class AddLinksRequest extends Message<AddLinksRequest> {
  /**
   * @generated from field: librarian.proto.FlexibleCreatorSelector selector = 9;
   */
  selector?: FlexibleCreatorSelector;

  /**
   * @generated from field: int64 destination_url_id = 8;
   */
  destinationUrlId = protoInt64.zero;

  /**
   * @generated from field: google.protobuf.Int64Value campaign_id = 3;
   */
  campaignId?: bigint;

  /**
   * @generated from field: google.protobuf.Int64Value custom_program_id = 4;
   */
  customProgramId?: bigint;

  constructor(data?: PartialMessage<AddLinksRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.AddLinksRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 9, name: "selector", kind: "message", T: FlexibleCreatorSelector },
    { no: 8, name: "destination_url_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "campaign_id", kind: "message", T: Int64Value },
    { no: 4, name: "custom_program_id", kind: "message", T: Int64Value },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AddLinksRequest {
    return new AddLinksRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AddLinksRequest {
    return new AddLinksRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AddLinksRequest {
    return new AddLinksRequest().fromJsonString(jsonString, options);
  }

  static equals(a: AddLinksRequest | PlainMessage<AddLinksRequest> | undefined, b: AddLinksRequest | PlainMessage<AddLinksRequest> | undefined): boolean {
    return proto3.util.equals(AddLinksRequest, a, b);
  }
}

/**
 * @generated from message librarian.proto.EditLinksRequest
 */
export class EditLinksRequest extends Message<EditLinksRequest> {
  /**
   * @generated from oneof librarian.proto.EditLinksRequest.links
   */
  links: {
    /**
     * @generated from field: common.proto.Int64List links_ids = 1;
     */
    value: Int64List;
    case: "linksIds";
  } | {
    /**
     * @generated from field: librarian.proto.GetLinksRequest links_request = 2;
     */
    value: GetLinksRequest;
    case: "linksRequest";
  } | { case: undefined; value?: undefined } = { case: undefined };

  /**
   * @generated from oneof librarian.proto.EditLinksRequest.campaign
   */
  campaign: {
    /**
     * @generated from field: int64 campaign_id = 3;
     */
    value: bigint;
    case: "campaignId";
  } | {
    /**
     * @generated from field: bool reset_campaign = 4;
     */
    value: boolean;
    case: "resetCampaign";
  } | { case: undefined; value?: undefined } = { case: undefined };

  /**
   * @generated from oneof librarian.proto.EditLinksRequest.program
   */
  program: {
    /**
     * @generated from field: int64 program_id = 5;
     */
    value: bigint;
    case: "programId";
  } | {
    /**
     * @generated from field: bool reset_program = 6;
     */
    value: boolean;
    case: "resetProgram";
  } | { case: undefined; value?: undefined } = { case: undefined };

  constructor(data?: PartialMessage<EditLinksRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.EditLinksRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "links_ids", kind: "message", T: Int64List, oneof: "links" },
    { no: 2, name: "links_request", kind: "message", T: GetLinksRequest, oneof: "links" },
    { no: 3, name: "campaign_id", kind: "scalar", T: 3 /* ScalarType.INT64 */, oneof: "campaign" },
    { no: 4, name: "reset_campaign", kind: "scalar", T: 8 /* ScalarType.BOOL */, oneof: "campaign" },
    { no: 5, name: "program_id", kind: "scalar", T: 3 /* ScalarType.INT64 */, oneof: "program" },
    { no: 6, name: "reset_program", kind: "scalar", T: 8 /* ScalarType.BOOL */, oneof: "program" },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): EditLinksRequest {
    return new EditLinksRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): EditLinksRequest {
    return new EditLinksRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): EditLinksRequest {
    return new EditLinksRequest().fromJsonString(jsonString, options);
  }

  static equals(a: EditLinksRequest | PlainMessage<EditLinksRequest> | undefined, b: EditLinksRequest | PlainMessage<EditLinksRequest> | undefined): boolean {
    return proto3.util.equals(EditLinksRequest, a, b);
  }
}

/**
 * @generated from message librarian.proto.ArchiveLinksRequest
 */
export class ArchiveLinksRequest extends Message<ArchiveLinksRequest> {
  /**
   * @generated from field: common.proto.Int64List links_ids = 1;
   */
  linksIds?: Int64List;

  constructor(data?: PartialMessage<ArchiveLinksRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.ArchiveLinksRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "links_ids", kind: "message", T: Int64List },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ArchiveLinksRequest {
    return new ArchiveLinksRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ArchiveLinksRequest {
    return new ArchiveLinksRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ArchiveLinksRequest {
    return new ArchiveLinksRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ArchiveLinksRequest | PlainMessage<ArchiveLinksRequest> | undefined, b: ArchiveLinksRequest | PlainMessage<ArchiveLinksRequest> | undefined): boolean {
    return proto3.util.equals(ArchiveLinksRequest, a, b);
  }
}

/**
 * @generated from message librarian.proto.LinkFilter
 */
export class LinkFilter extends Message<LinkFilter> {
  /**
   * @generated from field: string affiliate_name_prefix = 1;
   */
  affiliateNamePrefix = "";

  /**
   * @generated from field: string url = 2;
   */
  url = "";

  /**
   * Search approx url
   *
   * @generated from field: string destination_url = 3;
   */
  destinationUrl = "";

  /**
   * Search exact urls
   *
   * @generated from field: repeated librarian.proto.DestinationUrl destinations_urls = 18;
   */
  destinationsUrls: DestinationUrl[] = [];

  /**
   * @generated from field: google.protobuf.StringValue influencer_id = 10;
   */
  influencerId?: string;

  /**
   * @generated from field: repeated int64 programs_ids = 15;
   */
  programsIds: bigint[] = [];

  /**
   * @generated from field: repeated int64 campaigns_ids = 16;
   */
  campaignsIds: bigint[] = [];

  /**
   * @generated from field: repeated int64 pixels_ids = 19;
   */
  pixelsIds: bigint[] = [];

  /**
   * @generated from field: librarian.proto.TimeRestriction time_restriction = 11;
   */
  timeRestriction?: TimeRestriction;

  /**
   * @generated from field: repeated int64 influencer_labels_ids = 12;
   */
  influencerLabelsIds: bigint[] = [];

  /**
   * @generated from field: bool archived = 17;
   */
  archived = false;

  constructor(data?: PartialMessage<LinkFilter>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.LinkFilter";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "affiliate_name_prefix", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "destination_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 18, name: "destinations_urls", kind: "message", T: DestinationUrl, repeated: true },
    { no: 10, name: "influencer_id", kind: "message", T: StringValue },
    { no: 15, name: "programs_ids", kind: "scalar", T: 3 /* ScalarType.INT64 */, repeated: true },
    { no: 16, name: "campaigns_ids", kind: "scalar", T: 3 /* ScalarType.INT64 */, repeated: true },
    { no: 19, name: "pixels_ids", kind: "scalar", T: 3 /* ScalarType.INT64 */, repeated: true },
    { no: 11, name: "time_restriction", kind: "message", T: TimeRestriction },
    { no: 12, name: "influencer_labels_ids", kind: "scalar", T: 3 /* ScalarType.INT64 */, repeated: true },
    { no: 17, name: "archived", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): LinkFilter {
    return new LinkFilter().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): LinkFilter {
    return new LinkFilter().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): LinkFilter {
    return new LinkFilter().fromJsonString(jsonString, options);
  }

  static equals(a: LinkFilter | PlainMessage<LinkFilter> | undefined, b: LinkFilter | PlainMessage<LinkFilter> | undefined): boolean {
    return proto3.util.equals(LinkFilter, a, b);
  }
}

/**
 * @generated from message librarian.proto.GetLinksRequest
 */
export class GetLinksRequest extends Message<GetLinksRequest> {
  /**
   * @generated from field: librarian.proto.LinkFilter filter = 1;
   */
  filter?: LinkFilter;

  /**
   * @generated from field: librarian.proto.LinkSort sort = 2;
   */
  sort = LinkSort.LINK_AFFILIATE_USERNAME;

  /**
   * @generated from field: common.proto.SortDirection sort_direction = 3;
   */
  sortDirection = SortDirection.DESC;

  /**
   * @generated from field: common.proto.BasicPagination pagination = 4;
   */
  pagination?: BasicPagination;

  constructor(data?: PartialMessage<GetLinksRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.GetLinksRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "filter", kind: "message", T: LinkFilter },
    { no: 2, name: "sort", kind: "enum", T: proto3.getEnumType(LinkSort) },
    { no: 3, name: "sort_direction", kind: "enum", T: proto3.getEnumType(SortDirection) },
    { no: 4, name: "pagination", kind: "message", T: BasicPagination },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetLinksRequest {
    return new GetLinksRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetLinksRequest {
    return new GetLinksRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetLinksRequest {
    return new GetLinksRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetLinksRequest | PlainMessage<GetLinksRequest> | undefined, b: GetLinksRequest | PlainMessage<GetLinksRequest> | undefined): boolean {
    return proto3.util.equals(GetLinksRequest, a, b);
  }
}

/**
 * @generated from message librarian.proto.GetLinksResponses
 */
export class GetLinksResponses extends Message<GetLinksResponses> {
  /**
   * @generated from field: repeated librarian.proto.GetLinksResponses.LinkResponse links = 1;
   */
  links: GetLinksResponses_LinkResponse[] = [];

  /**
   * @generated from field: int32 total_hits = 2;
   */
  totalHits = 0;

  constructor(data?: PartialMessage<GetLinksResponses>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.GetLinksResponses";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "links", kind: "message", T: GetLinksResponses_LinkResponse, repeated: true },
    { no: 2, name: "total_hits", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetLinksResponses {
    return new GetLinksResponses().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetLinksResponses {
    return new GetLinksResponses().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetLinksResponses {
    return new GetLinksResponses().fromJsonString(jsonString, options);
  }

  static equals(a: GetLinksResponses | PlainMessage<GetLinksResponses> | undefined, b: GetLinksResponses | PlainMessage<GetLinksResponses> | undefined): boolean {
    return proto3.util.equals(GetLinksResponses, a, b);
  }
}

/**
 * @generated from message librarian.proto.GetLinksResponses.LinkResponse
 */
export class GetLinksResponses_LinkResponse extends Message<GetLinksResponses_LinkResponse> {
  /**
   * @generated from field: int64 link_id = 1;
   */
  linkId = protoInt64.zero;

  /**
   * @generated from field: string url = 2;
   */
  url = "";

  /**
   * @generated from field: librarian.proto.Affiliate affiliate = 4;
   */
  affiliate?: Affiliate;

  /**
   * @generated from field: librarian.proto.Program program = 5;
   */
  program?: Program;

  /**
   * @generated from field: google.protobuf.Int64Value campaign_id = 18;
   */
  campaignId?: bigint;

  /**
   * @generated from field: google.protobuf.StringValue campaign_name = 6;
   */
  campaignName?: string;

  /**
   * @generated from field: int32 clicks_count = 7;
   */
  clicksCount = 0;

  /**
   * @generated from field: google.protobuf.Duration avg_time_to_sales = 8;
   */
  avgTimeToSales?: Duration;

  /**
   * @generated from field: common.proto.CurrencyEnum currency = 11;
   */
  currency = CurrencyEnum.NA;

  /**
   * @generated from field: int32 orders_count = 9;
   */
  ordersCount = 0;

  /**
   * @generated from field: google.protobuf.FloatValue total_sales = 10;
   */
  totalSales?: number;

  /**
   * @generated from field: google.protobuf.Timestamp created = 12;
   */
  created?: Timestamp;

  /**
   * @generated from field: bool archived = 13;
   */
  archived = false;

  /**
   * @generated from field: librarian.proto.DestinationUrl link_destination_url = 16;
   */
  linkDestinationUrl?: DestinationUrl;

  /**
   * @generated from field: repeated librarian.proto.PixelPerformance pixels_performances = 17;
   */
  pixelsPerformances: PixelPerformance[] = [];

  constructor(data?: PartialMessage<GetLinksResponses_LinkResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.GetLinksResponses.LinkResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "link_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "affiliate", kind: "message", T: Affiliate },
    { no: 5, name: "program", kind: "message", T: Program },
    { no: 18, name: "campaign_id", kind: "message", T: Int64Value },
    { no: 6, name: "campaign_name", kind: "message", T: StringValue },
    { no: 7, name: "clicks_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 8, name: "avg_time_to_sales", kind: "message", T: Duration },
    { no: 11, name: "currency", kind: "enum", T: proto3.getEnumType(CurrencyEnum) },
    { no: 9, name: "orders_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 10, name: "total_sales", kind: "message", T: FloatValue },
    { no: 12, name: "created", kind: "message", T: Timestamp },
    { no: 13, name: "archived", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 16, name: "link_destination_url", kind: "message", T: DestinationUrl },
    { no: 17, name: "pixels_performances", kind: "message", T: PixelPerformance, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetLinksResponses_LinkResponse {
    return new GetLinksResponses_LinkResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetLinksResponses_LinkResponse {
    return new GetLinksResponses_LinkResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetLinksResponses_LinkResponse {
    return new GetLinksResponses_LinkResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetLinksResponses_LinkResponse | PlainMessage<GetLinksResponses_LinkResponse> | undefined, b: GetLinksResponses_LinkResponse | PlainMessage<GetLinksResponses_LinkResponse> | undefined): boolean {
    return proto3.util.equals(GetLinksResponses_LinkResponse, a, b);
  }
}

/**
 * @generated from message librarian.proto.DestinationUrlList
 */
export class DestinationUrlList extends Message<DestinationUrlList> {
  /**
   * @generated from field: repeated librarian.proto.DestinationUrl destination_urls = 1;
   */
  destinationUrls: DestinationUrl[] = [];

  constructor(data?: PartialMessage<DestinationUrlList>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.DestinationUrlList";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "destination_urls", kind: "message", T: DestinationUrl, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DestinationUrlList {
    return new DestinationUrlList().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DestinationUrlList {
    return new DestinationUrlList().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DestinationUrlList {
    return new DestinationUrlList().fromJsonString(jsonString, options);
  }

  static equals(a: DestinationUrlList | PlainMessage<DestinationUrlList> | undefined, b: DestinationUrlList | PlainMessage<DestinationUrlList> | undefined): boolean {
    return proto3.util.equals(DestinationUrlList, a, b);
  }
}

/**
 * @generated from message librarian.proto.TrackLinkRequest
 */
export class TrackLinkRequest extends Message<TrackLinkRequest> {
  /**
   * @generated from field: string link_short_code = 1;
   */
  linkShortCode = "";

  /**
   * Maybe no cookie
   *
   * @generated from field: google.protobuf.StringValue cookie_uuid = 2;
   */
  cookieUuid?: string;

  /**
   * It can happen ip address is null in the request
   *
   * @generated from field: google.protobuf.StringValue ip_adress = 3;
   */
  ipAdress?: string;

  /**
   * It can happen user agent is null in the request
   *
   * @generated from field: google.protobuf.StringValue user_agent = 4;
   */
  userAgent?: string;

  /**
   * @generated from field: google.protobuf.StringValue http_referer = 5;
   */
  httpReferer?: string;

  constructor(data?: PartialMessage<TrackLinkRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.TrackLinkRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "link_short_code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "cookie_uuid", kind: "message", T: StringValue },
    { no: 3, name: "ip_adress", kind: "message", T: StringValue },
    { no: 4, name: "user_agent", kind: "message", T: StringValue },
    { no: 5, name: "http_referer", kind: "message", T: StringValue },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TrackLinkRequest {
    return new TrackLinkRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TrackLinkRequest {
    return new TrackLinkRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TrackLinkRequest {
    return new TrackLinkRequest().fromJsonString(jsonString, options);
  }

  static equals(a: TrackLinkRequest | PlainMessage<TrackLinkRequest> | undefined, b: TrackLinkRequest | PlainMessage<TrackLinkRequest> | undefined): boolean {
    return proto3.util.equals(TrackLinkRequest, a, b);
  }
}

/**
 * @generated from message librarian.proto.TrackLinkResponse
 */
export class TrackLinkResponse extends Message<TrackLinkResponse> {
  /**
   * Maybe no link
   *
   * @generated from field: google.protobuf.Int64Value link_id = 1;
   */
  linkId?: bigint;

  /**
   * Full destination url with utm if any
   *
   * @generated from field: string destination_url = 2;
   */
  destinationUrl = "";

  /**
   * @generated from field: string cookie_uuid = 3;
   */
  cookieUuid = "";

  constructor(data?: PartialMessage<TrackLinkResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.TrackLinkResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "link_id", kind: "message", T: Int64Value },
    { no: 2, name: "destination_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "cookie_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TrackLinkResponse {
    return new TrackLinkResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TrackLinkResponse {
    return new TrackLinkResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TrackLinkResponse {
    return new TrackLinkResponse().fromJsonString(jsonString, options);
  }

  static equals(a: TrackLinkResponse | PlainMessage<TrackLinkResponse> | undefined, b: TrackLinkResponse | PlainMessage<TrackLinkResponse> | undefined): boolean {
    return proto3.util.equals(TrackLinkResponse, a, b);
  }
}

/**
 * @generated from message librarian.proto.OrderFilter
 */
export class OrderFilter extends Message<OrderFilter> {
  /**
   * @generated from field: string external_order_id = 1;
   */
  externalOrderId = "";

  /**
   * @generated from field: librarian.proto.TimeRestriction time_restriction = 2;
   */
  timeRestriction?: TimeRestriction;

  /**
   * @generated from field: string affiliate_name_prefix = 5;
   */
  affiliateNamePrefix = "";

  /**
   * @generated from field: string link_short_code = 6;
   */
  linkShortCode = "";

  /**
   * @generated from field: repeated librarian.proto.DestinationUrl destinations_urls = 13;
   */
  destinationsUrls: DestinationUrl[] = [];

  /**
   * @generated from field: repeated int64 influencer_labels_ids = 9;
   */
  influencerLabelsIds: bigint[] = [];

  /**
   * @generated from field: repeated int64 programs_ids = 11;
   */
  programsIds: bigint[] = [];

  /**
   * @generated from field: repeated int64 campaigns_ids = 12;
   */
  campaignsIds: bigint[] = [];

  /**
   * @generated from field: repeated int64 pixels_ids = 14;
   */
  pixelsIds: bigint[] = [];

  constructor(data?: PartialMessage<OrderFilter>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.OrderFilter";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "external_order_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "time_restriction", kind: "message", T: TimeRestriction },
    { no: 5, name: "affiliate_name_prefix", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "link_short_code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 13, name: "destinations_urls", kind: "message", T: DestinationUrl, repeated: true },
    { no: 9, name: "influencer_labels_ids", kind: "scalar", T: 3 /* ScalarType.INT64 */, repeated: true },
    { no: 11, name: "programs_ids", kind: "scalar", T: 3 /* ScalarType.INT64 */, repeated: true },
    { no: 12, name: "campaigns_ids", kind: "scalar", T: 3 /* ScalarType.INT64 */, repeated: true },
    { no: 14, name: "pixels_ids", kind: "scalar", T: 3 /* ScalarType.INT64 */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): OrderFilter {
    return new OrderFilter().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): OrderFilter {
    return new OrderFilter().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): OrderFilter {
    return new OrderFilter().fromJsonString(jsonString, options);
  }

  static equals(a: OrderFilter | PlainMessage<OrderFilter> | undefined, b: OrderFilter | PlainMessage<OrderFilter> | undefined): boolean {
    return proto3.util.equals(OrderFilter, a, b);
  }
}

/**
 * @generated from message librarian.proto.GetOrdersRequest
 */
export class GetOrdersRequest extends Message<GetOrdersRequest> {
  /**
   * @generated from field: librarian.proto.OrderFilter filter = 1;
   */
  filter?: OrderFilter;

  /**
   * @generated from field: librarian.proto.OrderSort sort = 2;
   */
  sort = OrderSort.ORDER_ID;

  /**
   * @generated from field: common.proto.SortDirection sort_direction = 3;
   */
  sortDirection = SortDirection.DESC;

  /**
   * @generated from field: common.proto.BasicPagination pagination = 4;
   */
  pagination?: BasicPagination;

  constructor(data?: PartialMessage<GetOrdersRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.GetOrdersRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "filter", kind: "message", T: OrderFilter },
    { no: 2, name: "sort", kind: "enum", T: proto3.getEnumType(OrderSort) },
    { no: 3, name: "sort_direction", kind: "enum", T: proto3.getEnumType(SortDirection) },
    { no: 4, name: "pagination", kind: "message", T: BasicPagination },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetOrdersRequest {
    return new GetOrdersRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetOrdersRequest {
    return new GetOrdersRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetOrdersRequest {
    return new GetOrdersRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetOrdersRequest | PlainMessage<GetOrdersRequest> | undefined, b: GetOrdersRequest | PlainMessage<GetOrdersRequest> | undefined): boolean {
    return proto3.util.equals(GetOrdersRequest, a, b);
  }
}

/**
 * @generated from message librarian.proto.OrderProduct
 */
export class OrderProduct extends Message<OrderProduct> {
  /**
   * @generated from field: string external_product_id = 1;
   */
  externalProductId = "";

  /**
   * @generated from field: string name = 2;
   */
  name = "";

  constructor(data?: PartialMessage<OrderProduct>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.OrderProduct";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "external_product_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): OrderProduct {
    return new OrderProduct().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): OrderProduct {
    return new OrderProduct().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): OrderProduct {
    return new OrderProduct().fromJsonString(jsonString, options);
  }

  static equals(a: OrderProduct | PlainMessage<OrderProduct> | undefined, b: OrderProduct | PlainMessage<OrderProduct> | undefined): boolean {
    return proto3.util.equals(OrderProduct, a, b);
  }
}

/**
 * @generated from message librarian.proto.OrderItem
 */
export class OrderItem extends Message<OrderItem> {
  /**
   * Maybe we won't have that one
   *
   * @generated from field: google.protobuf.StringValue external_order_item_id = 1;
   */
  externalOrderItemId?: string;

  /**
   * @generated from field: librarian.proto.OrderProduct product = 2;
   */
  product?: OrderProduct;

  /**
   * @generated from field: int32 quantity = 3;
   */
  quantity = 0;

  /**
   * @generated from field: float total_amount = 4;
   */
  totalAmount = 0;

  constructor(data?: PartialMessage<OrderItem>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.OrderItem";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "external_order_item_id", kind: "message", T: StringValue },
    { no: 2, name: "product", kind: "message", T: OrderProduct },
    { no: 3, name: "quantity", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "total_amount", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): OrderItem {
    return new OrderItem().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): OrderItem {
    return new OrderItem().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): OrderItem {
    return new OrderItem().fromJsonString(jsonString, options);
  }

  static equals(a: OrderItem | PlainMessage<OrderItem> | undefined, b: OrderItem | PlainMessage<OrderItem> | undefined): boolean {
    return proto3.util.equals(OrderItem, a, b);
  }
}

/**
 * @generated from message librarian.proto.GetOrdersResponses
 */
export class GetOrdersResponses extends Message<GetOrdersResponses> {
  /**
   * @generated from field: repeated librarian.proto.GetOrdersResponses.OrderResponse orders = 1;
   */
  orders: GetOrdersResponses_OrderResponse[] = [];

  /**
   * @generated from field: int32 total_hits = 2;
   */
  totalHits = 0;

  constructor(data?: PartialMessage<GetOrdersResponses>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.GetOrdersResponses";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "orders", kind: "message", T: GetOrdersResponses_OrderResponse, repeated: true },
    { no: 2, name: "total_hits", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetOrdersResponses {
    return new GetOrdersResponses().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetOrdersResponses {
    return new GetOrdersResponses().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetOrdersResponses {
    return new GetOrdersResponses().fromJsonString(jsonString, options);
  }

  static equals(a: GetOrdersResponses | PlainMessage<GetOrdersResponses> | undefined, b: GetOrdersResponses | PlainMessage<GetOrdersResponses> | undefined): boolean {
    return proto3.util.equals(GetOrdersResponses, a, b);
  }
}

/**
 * @generated from message librarian.proto.GetOrdersResponses.OrderResponse
 */
export class GetOrdersResponses_OrderResponse extends Message<GetOrdersResponses_OrderResponse> {
  /**
   * @generated from field: int64 order_id = 1;
   */
  orderId = protoInt64.zero;

  /**
   * @generated from field: string external_order_id = 2;
   */
  externalOrderId = "";

  /**
   * @generated from field: repeated librarian.proto.OrderItem items = 10;
   */
  items: OrderItem[] = [];

  /**
   * @generated from field: google.protobuf.Timestamp order_date = 4;
   */
  orderDate?: Timestamp;

  /**
   * @generated from field: float amount = 5;
   */
  amount = 0;

  /**
   * @generated from field: common.proto.CurrencyEnum currency = 11;
   */
  currency = CurrencyEnum.NA;

  /**
   * @generated from field: google.protobuf.Duration time_to_sale = 6;
   */
  timeToSale?: Duration;

  /**
   * @generated from field: librarian.proto.Affiliate affiliate = 7;
   */
  affiliate?: Affiliate;

  /**
   * @generated from field: librarian.proto.AffiliationPixelProto pixel = 12;
   */
  pixel?: AffiliationPixelProto;

  /**
   * @generated from field: google.protobuf.StringValue referral_link = 8;
   */
  referralLink?: string;

  /**
   * @generated from field: google.protobuf.FloatValue commission = 9;
   */
  commission?: number;

  /**
   * @generated from field: google.protobuf.StringValue http_referer = 13;
   */
  httpReferer?: string;

  constructor(data?: PartialMessage<GetOrdersResponses_OrderResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.GetOrdersResponses.OrderResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "order_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "external_order_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 10, name: "items", kind: "message", T: OrderItem, repeated: true },
    { no: 4, name: "order_date", kind: "message", T: Timestamp },
    { no: 5, name: "amount", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 11, name: "currency", kind: "enum", T: proto3.getEnumType(CurrencyEnum) },
    { no: 6, name: "time_to_sale", kind: "message", T: Duration },
    { no: 7, name: "affiliate", kind: "message", T: Affiliate },
    { no: 12, name: "pixel", kind: "message", T: AffiliationPixelProto },
    { no: 8, name: "referral_link", kind: "message", T: StringValue },
    { no: 9, name: "commission", kind: "message", T: FloatValue },
    { no: 13, name: "http_referer", kind: "message", T: StringValue },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetOrdersResponses_OrderResponse {
    return new GetOrdersResponses_OrderResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetOrdersResponses_OrderResponse {
    return new GetOrdersResponses_OrderResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetOrdersResponses_OrderResponse {
    return new GetOrdersResponses_OrderResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetOrdersResponses_OrderResponse | PlainMessage<GetOrdersResponses_OrderResponse> | undefined, b: GetOrdersResponses_OrderResponse | PlainMessage<GetOrdersResponses_OrderResponse> | undefined): boolean {
    return proto3.util.equals(GetOrdersResponses_OrderResponse, a, b);
  }
}

/**
 * Check whether we could match or not a call from this browser for a Lefty conversion
 *
 * @generated from message librarian.proto.CheckMatchableRequest
 */
export class CheckMatchableRequest extends Message<CheckMatchableRequest> {
  /**
   * @generated from field: string referring_domain = 1;
   */
  referringDomain = "";

  /**
   * Maybe no cookie
   *
   * @generated from field: google.protobuf.StringValue cookie_uuid = 2;
   */
  cookieUuid?: string;

  /**
   * It can happen ip address is null in the request
   *
   * @generated from field: google.protobuf.StringValue ip_adress = 3;
   */
  ipAdress?: string;

  /**
   * It can happen user agent is null in the request
   *
   * @generated from field: google.protobuf.StringValue user_agent = 4;
   */
  userAgent?: string;

  /**
   * Legacy setups don't have pixel id
   *
   * @generated from field: google.protobuf.StringValue pixel_id = 5;
   */
  pixelId?: string;

  constructor(data?: PartialMessage<CheckMatchableRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.CheckMatchableRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "referring_domain", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "cookie_uuid", kind: "message", T: StringValue },
    { no: 3, name: "ip_adress", kind: "message", T: StringValue },
    { no: 4, name: "user_agent", kind: "message", T: StringValue },
    { no: 5, name: "pixel_id", kind: "message", T: StringValue },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CheckMatchableRequest {
    return new CheckMatchableRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CheckMatchableRequest {
    return new CheckMatchableRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CheckMatchableRequest {
    return new CheckMatchableRequest().fromJsonString(jsonString, options);
  }

  static equals(a: CheckMatchableRequest | PlainMessage<CheckMatchableRequest> | undefined, b: CheckMatchableRequest | PlainMessage<CheckMatchableRequest> | undefined): boolean {
    return proto3.util.equals(CheckMatchableRequest, a, b);
  }
}

/**
 * @generated from message librarian.proto.TrackOrderRequest
 */
export class TrackOrderRequest extends Message<TrackOrderRequest> {
  /**
   * @generated from field: string external_order_id = 1;
   */
  externalOrderId = "";

  /**
   * We may want to retrieve order that weren't initiated from Lefty
   *
   * @generated from field: google.protobuf.StringValue cookie_uuid = 2;
   */
  cookieUuid?: string;

  /**
   * Legacy setups don't have pixel id
   *
   * @generated from field: google.protobuf.StringValue pixel_id = 11;
   */
  pixelId?: string;

  /**
   * @generated from field: google.protobuf.FloatValue amount = 3;
   */
  amount?: number;

  /**
   * @generated from field: google.protobuf.StringValue currency_code = 4;
   */
  currencyCode?: string;

  /**
   * This is the real http referer we might have sniffed from http headers
   *
   * @generated from field: google.protobuf.StringValue http_referer = 12;
   */
  httpReferer?: string;

  /**
   * This is the referring domain the might have been set up by the client (it was mandatory when we started affiliation)
   *
   * @generated from field: string referring_domain = 5;
   */
  referringDomain = "";

  /**
   * @generated from field: repeated librarian.proto.OrderItem items = 6;
   */
  items: OrderItem[] = [];

  /**
   * @generated from field: common.proto.StringList integration_reports = 7;
   */
  integrationReports?: StringList;

  /**
   * It can happen ip address is null in the request
   *
   * @generated from field: google.protobuf.StringValue ip_adress = 8;
   */
  ipAdress?: string;

  /**
   * It can happen user agent is null in the request
   *
   * @generated from field: google.protobuf.StringValue user_agent = 9;
   */
  userAgent?: string;

  /**
   * Filled by process automatically
   *
   * @generated from field: librarian.proto.OrderReason reason = 10;
   */
  reason = OrderReason.UNKNOWN;

  constructor(data?: PartialMessage<TrackOrderRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.TrackOrderRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "external_order_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "cookie_uuid", kind: "message", T: StringValue },
    { no: 11, name: "pixel_id", kind: "message", T: StringValue },
    { no: 3, name: "amount", kind: "message", T: FloatValue },
    { no: 4, name: "currency_code", kind: "message", T: StringValue },
    { no: 12, name: "http_referer", kind: "message", T: StringValue },
    { no: 5, name: "referring_domain", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "items", kind: "message", T: OrderItem, repeated: true },
    { no: 7, name: "integration_reports", kind: "message", T: StringList },
    { no: 8, name: "ip_adress", kind: "message", T: StringValue },
    { no: 9, name: "user_agent", kind: "message", T: StringValue },
    { no: 10, name: "reason", kind: "enum", T: proto3.getEnumType(OrderReason) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TrackOrderRequest {
    return new TrackOrderRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TrackOrderRequest {
    return new TrackOrderRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TrackOrderRequest {
    return new TrackOrderRequest().fromJsonString(jsonString, options);
  }

  static equals(a: TrackOrderRequest | PlainMessage<TrackOrderRequest> | undefined, b: TrackOrderRequest | PlainMessage<TrackOrderRequest> | undefined): boolean {
    return proto3.util.equals(TrackOrderRequest, a, b);
  }
}

/**
 * @generated from message librarian.proto.AffiliationReportFilter
 */
export class AffiliationReportFilter extends Message<AffiliationReportFilter> {
  /**
   * @generated from field: librarian.proto.TimeRestriction time_restriction = 1;
   */
  timeRestriction?: TimeRestriction;

  /**
   * @generated from field: repeated librarian.proto.DestinationUrl destinations_urls = 6;
   */
  destinationsUrls: DestinationUrl[] = [];

  /**
   * @generated from field: repeated int64 programs_ids = 3;
   */
  programsIds: bigint[] = [];

  /**
   * @generated from field: repeated int64 influencer_labels_ids = 4;
   */
  influencerLabelsIds: bigint[] = [];

  /**
   * @generated from field: repeated int64 campaigns_ids = 5;
   */
  campaignsIds: bigint[] = [];

  /**
   * @generated from field: repeated int64 pixels_ids = 7;
   */
  pixelsIds: bigint[] = [];

  constructor(data?: PartialMessage<AffiliationReportFilter>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.AffiliationReportFilter";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "time_restriction", kind: "message", T: TimeRestriction },
    { no: 6, name: "destinations_urls", kind: "message", T: DestinationUrl, repeated: true },
    { no: 3, name: "programs_ids", kind: "scalar", T: 3 /* ScalarType.INT64 */, repeated: true },
    { no: 4, name: "influencer_labels_ids", kind: "scalar", T: 3 /* ScalarType.INT64 */, repeated: true },
    { no: 5, name: "campaigns_ids", kind: "scalar", T: 3 /* ScalarType.INT64 */, repeated: true },
    { no: 7, name: "pixels_ids", kind: "scalar", T: 3 /* ScalarType.INT64 */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AffiliationReportFilter {
    return new AffiliationReportFilter().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AffiliationReportFilter {
    return new AffiliationReportFilter().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AffiliationReportFilter {
    return new AffiliationReportFilter().fromJsonString(jsonString, options);
  }

  static equals(a: AffiliationReportFilter | PlainMessage<AffiliationReportFilter> | undefined, b: AffiliationReportFilter | PlainMessage<AffiliationReportFilter> | undefined): boolean {
    return proto3.util.equals(AffiliationReportFilter, a, b);
  }
}

/**
 * @generated from message librarian.proto.AffiliationReportStats
 */
export class AffiliationReportStats extends Message<AffiliationReportStats> {
  /**
   * @generated from field: int32 affiliates = 1;
   */
  affiliates = 0;

  /**
   * @generated from field: int32 links = 2;
   */
  links = 0;

  /**
   * @generated from field: int32 clicks = 3;
   */
  clicks = 0;

  /**
   * @generated from field: int32 conversions = 4;
   */
  conversions = 0;

  /**
   * @generated from field: google.protobuf.Duration avg_time_to_sales = 5;
   */
  avgTimeToSales?: Duration;

  /**
   * @generated from field: float total_sales = 6;
   */
  totalSales = 0;

  constructor(data?: PartialMessage<AffiliationReportStats>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.AffiliationReportStats";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "affiliates", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "links", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "clicks", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "conversions", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: "avg_time_to_sales", kind: "message", T: Duration },
    { no: 6, name: "total_sales", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AffiliationReportStats {
    return new AffiliationReportStats().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AffiliationReportStats {
    return new AffiliationReportStats().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AffiliationReportStats {
    return new AffiliationReportStats().fromJsonString(jsonString, options);
  }

  static equals(a: AffiliationReportStats | PlainMessage<AffiliationReportStats> | undefined, b: AffiliationReportStats | PlainMessage<AffiliationReportStats> | undefined): boolean {
    return proto3.util.equals(AffiliationReportStats, a, b);
  }
}

/**
 * @generated from message librarian.proto.AffiliationReportTimelineRequest
 */
export class AffiliationReportTimelineRequest extends Message<AffiliationReportTimelineRequest> {
  /**
   * @generated from field: librarian.proto.AffiliationReportTimelineType type = 1;
   */
  type = AffiliationReportTimelineType.TIMELINE_TYPE_UNKNOWN;

  /**
   * @generated from field: librarian.proto.AffiliationReportFilter filter = 2;
   */
  filter?: AffiliationReportFilter;

  constructor(data?: PartialMessage<AffiliationReportTimelineRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.AffiliationReportTimelineRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "type", kind: "enum", T: proto3.getEnumType(AffiliationReportTimelineType) },
    { no: 2, name: "filter", kind: "message", T: AffiliationReportFilter },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AffiliationReportTimelineRequest {
    return new AffiliationReportTimelineRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AffiliationReportTimelineRequest {
    return new AffiliationReportTimelineRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AffiliationReportTimelineRequest {
    return new AffiliationReportTimelineRequest().fromJsonString(jsonString, options);
  }

  static equals(a: AffiliationReportTimelineRequest | PlainMessage<AffiliationReportTimelineRequest> | undefined, b: AffiliationReportTimelineRequest | PlainMessage<AffiliationReportTimelineRequest> | undefined): boolean {
    return proto3.util.equals(AffiliationReportTimelineRequest, a, b);
  }
}

/**
 * @generated from message librarian.proto.AffiliationReportTimeline
 */
export class AffiliationReportTimeline extends Message<AffiliationReportTimeline> {
  /**
   * @generated from field: repeated librarian.proto.AffiliationReportTimepoint points = 1;
   */
  points: AffiliationReportTimepoint[] = [];

  constructor(data?: PartialMessage<AffiliationReportTimeline>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.AffiliationReportTimeline";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "points", kind: "message", T: AffiliationReportTimepoint, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AffiliationReportTimeline {
    return new AffiliationReportTimeline().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AffiliationReportTimeline {
    return new AffiliationReportTimeline().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AffiliationReportTimeline {
    return new AffiliationReportTimeline().fromJsonString(jsonString, options);
  }

  static equals(a: AffiliationReportTimeline | PlainMessage<AffiliationReportTimeline> | undefined, b: AffiliationReportTimeline | PlainMessage<AffiliationReportTimeline> | undefined): boolean {
    return proto3.util.equals(AffiliationReportTimeline, a, b);
  }
}

/**
 * @generated from message librarian.proto.AffiliationReportTimepoint
 */
export class AffiliationReportTimepoint extends Message<AffiliationReportTimepoint> {
  /**
   * @generated from field: google.protobuf.Timestamp timestamp = 1;
   */
  timestamp?: Timestamp;

  /**
   * @generated from field: float value = 2;
   */
  value = 0;

  constructor(data?: PartialMessage<AffiliationReportTimepoint>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.AffiliationReportTimepoint";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "timestamp", kind: "message", T: Timestamp },
    { no: 2, name: "value", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AffiliationReportTimepoint {
    return new AffiliationReportTimepoint().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AffiliationReportTimepoint {
    return new AffiliationReportTimepoint().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AffiliationReportTimepoint {
    return new AffiliationReportTimepoint().fromJsonString(jsonString, options);
  }

  static equals(a: AffiliationReportTimepoint | PlainMessage<AffiliationReportTimepoint> | undefined, b: AffiliationReportTimepoint | PlainMessage<AffiliationReportTimepoint> | undefined): boolean {
    return proto3.util.equals(AffiliationReportTimepoint, a, b);
  }
}

/**
 * @generated from message librarian.proto.AffiliationReportProductsRequest
 */
export class AffiliationReportProductsRequest extends Message<AffiliationReportProductsRequest> {
  /**
   * @generated from field: librarian.proto.AffiliationReportFilter filter = 1;
   */
  filter?: AffiliationReportFilter;

  /**
   * @generated from field: common.proto.BasicPagination pagination = 2;
   */
  pagination?: BasicPagination;

  /**
   * @generated from field: librarian.proto.AffiliationReportProductsSort sort = 3;
   */
  sort = AffiliationReportProductsSort.PRODUCTS_REPORT_UNKNOWN;

  /**
   * @generated from field: common.proto.SortDirection sort_direction = 4;
   */
  sortDirection = SortDirection.DESC;

  constructor(data?: PartialMessage<AffiliationReportProductsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.AffiliationReportProductsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "filter", kind: "message", T: AffiliationReportFilter },
    { no: 2, name: "pagination", kind: "message", T: BasicPagination },
    { no: 3, name: "sort", kind: "enum", T: proto3.getEnumType(AffiliationReportProductsSort) },
    { no: 4, name: "sort_direction", kind: "enum", T: proto3.getEnumType(SortDirection) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AffiliationReportProductsRequest {
    return new AffiliationReportProductsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AffiliationReportProductsRequest {
    return new AffiliationReportProductsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AffiliationReportProductsRequest {
    return new AffiliationReportProductsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: AffiliationReportProductsRequest | PlainMessage<AffiliationReportProductsRequest> | undefined, b: AffiliationReportProductsRequest | PlainMessage<AffiliationReportProductsRequest> | undefined): boolean {
    return proto3.util.equals(AffiliationReportProductsRequest, a, b);
  }
}

/**
 * @generated from message librarian.proto.AffiliationReportProductsResponse
 */
export class AffiliationReportProductsResponse extends Message<AffiliationReportProductsResponse> {
  /**
   * @generated from field: repeated librarian.proto.AffiliationReportProductsResponse.AffiliationReportSale sales = 1;
   */
  sales: AffiliationReportProductsResponse_AffiliationReportSale[] = [];

  /**
   * @generated from field: int32 total = 2;
   */
  total = 0;

  constructor(data?: PartialMessage<AffiliationReportProductsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.AffiliationReportProductsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "sales", kind: "message", T: AffiliationReportProductsResponse_AffiliationReportSale, repeated: true },
    { no: 2, name: "total", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AffiliationReportProductsResponse {
    return new AffiliationReportProductsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AffiliationReportProductsResponse {
    return new AffiliationReportProductsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AffiliationReportProductsResponse {
    return new AffiliationReportProductsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: AffiliationReportProductsResponse | PlainMessage<AffiliationReportProductsResponse> | undefined, b: AffiliationReportProductsResponse | PlainMessage<AffiliationReportProductsResponse> | undefined): boolean {
    return proto3.util.equals(AffiliationReportProductsResponse, a, b);
  }
}

/**
 * @generated from message librarian.proto.AffiliationReportProductsResponse.AffiliationReportSale
 */
export class AffiliationReportProductsResponse_AffiliationReportSale extends Message<AffiliationReportProductsResponse_AffiliationReportSale> {
  /**
   * @generated from field: librarian.proto.OrderProduct product = 1;
   */
  product?: OrderProduct;

  /**
   * @generated from field: int32 quantity = 2;
   */
  quantity = 0;

  /**
   * @generated from field: float total_amount = 3;
   */
  totalAmount = 0;

  constructor(data?: PartialMessage<AffiliationReportProductsResponse_AffiliationReportSale>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.AffiliationReportProductsResponse.AffiliationReportSale";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "product", kind: "message", T: OrderProduct },
    { no: 2, name: "quantity", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "total_amount", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AffiliationReportProductsResponse_AffiliationReportSale {
    return new AffiliationReportProductsResponse_AffiliationReportSale().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AffiliationReportProductsResponse_AffiliationReportSale {
    return new AffiliationReportProductsResponse_AffiliationReportSale().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AffiliationReportProductsResponse_AffiliationReportSale {
    return new AffiliationReportProductsResponse_AffiliationReportSale().fromJsonString(jsonString, options);
  }

  static equals(a: AffiliationReportProductsResponse_AffiliationReportSale | PlainMessage<AffiliationReportProductsResponse_AffiliationReportSale> | undefined, b: AffiliationReportProductsResponse_AffiliationReportSale | PlainMessage<AffiliationReportProductsResponse_AffiliationReportSale> | undefined): boolean {
    return proto3.util.equals(AffiliationReportProductsResponse_AffiliationReportSale, a, b);
  }
}

/**
 * @generated from message librarian.proto.CheckAffiliatesNotInCampaignRequest
 */
export class CheckAffiliatesNotInCampaignRequest extends Message<CheckAffiliatesNotInCampaignRequest> {
  /**
   * @generated from field: int64 campaign_id = 1;
   */
  campaignId = protoInt64.zero;

  /**
   * @generated from field: librarian.proto.FlexibleCreatorSelector selector = 4;
   */
  selector?: FlexibleCreatorSelector;

  constructor(data?: PartialMessage<CheckAffiliatesNotInCampaignRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.CheckAffiliatesNotInCampaignRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "campaign_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 4, name: "selector", kind: "message", T: FlexibleCreatorSelector },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CheckAffiliatesNotInCampaignRequest {
    return new CheckAffiliatesNotInCampaignRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CheckAffiliatesNotInCampaignRequest {
    return new CheckAffiliatesNotInCampaignRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CheckAffiliatesNotInCampaignRequest {
    return new CheckAffiliatesNotInCampaignRequest().fromJsonString(jsonString, options);
  }

  static equals(a: CheckAffiliatesNotInCampaignRequest | PlainMessage<CheckAffiliatesNotInCampaignRequest> | undefined, b: CheckAffiliatesNotInCampaignRequest | PlainMessage<CheckAffiliatesNotInCampaignRequest> | undefined): boolean {
    return proto3.util.equals(CheckAffiliatesNotInCampaignRequest, a, b);
  }
}

/**
 * @generated from message librarian.proto.CheckAffiliatesNotInCampaignResponse
 */
export class CheckAffiliatesNotInCampaignResponse extends Message<CheckAffiliatesNotInCampaignResponse> {
  /**
   * @generated from field: repeated librarian.proto.CreatorCardSnippet base_snippets = 1;
   */
  baseSnippets: CreatorCardSnippet[] = [];

  constructor(data?: PartialMessage<CheckAffiliatesNotInCampaignResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.CheckAffiliatesNotInCampaignResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "base_snippets", kind: "message", T: CreatorCardSnippet, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CheckAffiliatesNotInCampaignResponse {
    return new CheckAffiliatesNotInCampaignResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CheckAffiliatesNotInCampaignResponse {
    return new CheckAffiliatesNotInCampaignResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CheckAffiliatesNotInCampaignResponse {
    return new CheckAffiliatesNotInCampaignResponse().fromJsonString(jsonString, options);
  }

  static equals(a: CheckAffiliatesNotInCampaignResponse | PlainMessage<CheckAffiliatesNotInCampaignResponse> | undefined, b: CheckAffiliatesNotInCampaignResponse | PlainMessage<CheckAffiliatesNotInCampaignResponse> | undefined): boolean {
    return proto3.util.equals(CheckAffiliatesNotInCampaignResponse, a, b);
  }
}

/**
 * @generated from message librarian.proto.AffiliationExportReq
 */
export class AffiliationExportReq extends Message<AffiliationExportReq> {
  /**
   * Filled by backend for RPC calls between librarian and archivist
   *
   * @generated from field: int64 user_id = 1;
   */
  userId = protoInt64.zero;

  constructor(data?: PartialMessage<AffiliationExportReq>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.AffiliationExportReq";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "user_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AffiliationExportReq {
    return new AffiliationExportReq().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AffiliationExportReq {
    return new AffiliationExportReq().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AffiliationExportReq {
    return new AffiliationExportReq().fromJsonString(jsonString, options);
  }

  static equals(a: AffiliationExportReq | PlainMessage<AffiliationExportReq> | undefined, b: AffiliationExportReq | PlainMessage<AffiliationExportReq> | undefined): boolean {
    return proto3.util.equals(AffiliationExportReq, a, b);
  }
}

/**
 * @generated from message librarian.proto.PixelPerformance
 */
export class PixelPerformance extends Message<PixelPerformance> {
  /**
   * @generated from field: int64 pixel_id = 1;
   */
  pixelId = protoInt64.zero;

  /**
   * @generated from field: int32 orders_count = 2;
   */
  ordersCount = 0;

  /**
   * @generated from field: float total_sales = 3;
   */
  totalSales = 0;

  /**
   * @generated from field: int32 links = 4;
   */
  links = 0;

  /**
   * @generated from field: int32 clicks = 5;
   */
  clicks = 0;

  constructor(data?: PartialMessage<PixelPerformance>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.PixelPerformance";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "pixel_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "orders_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "total_sales", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 4, name: "links", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: "clicks", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): PixelPerformance {
    return new PixelPerformance().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): PixelPerformance {
    return new PixelPerformance().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): PixelPerformance {
    return new PixelPerformance().fromJsonString(jsonString, options);
  }

  static equals(a: PixelPerformance | PlainMessage<PixelPerformance> | undefined, b: PixelPerformance | PlainMessage<PixelPerformance> | undefined): boolean {
    return proto3.util.equals(PixelPerformance, a, b);
  }
}

