import { Component, computed, output, signal } from '@angular/core';
import { takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop';
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { Duration } from '@bufbuild/protobuf';
import { injectLibrarianAffiliationClient } from '@frontend2/api';
import {
  getEnumValues,
  isGhostAffiliationProgram,
  readableAffiliationProgramCommissionType,
} from '@frontend2/core';
import {
  AffiliationProgramCommissionType,
  Program,
} from '@frontend2/proto/librarian/proto/affiliation_pb';
import { showToastException } from '../../error-handler';
import { injectLeftyEventsBus } from '../../events/events.service';
import { LeftyValidators } from '../../form-validators.helpers';
import { LeftyButtonDirective } from '../../lefty-button-directive/lefty-button.directive';
import { LeftyCurrencyValueInputComponent } from '../../lefty-currency-value-input/lefty-currency-value-input.component';
import { CurrencyValue } from '../../lefty-currency-value-input/lefty-currency-value.models';
import { LeftyDialogComponent } from '../../lefty-dialog/lefty-dialog.component';
import { DialogBase } from '../../lefty-dialog/lefty-dialog.helpers';
import { LeftyDurationInputComponent } from '../../lefty-duration-input/lefty-duration-input.component';
import { LeftyFormInputComponent } from '../../lefty-form-input/lefty-form-input.component';
import { LeftyFormNumberInputComponent } from '../../lefty-form-input/lefty-form-number-input.component';
import { LeftyFormComponent } from '../../lefty-form/lefty-form.component';
import { LeftyRadioGroupComponent } from '../../lefty-radio/lefty-radio-group.component';
import { LeftyRadioComponent } from '../../lefty-radio/lefty-radio.component';
import { injectToastManager } from '../../toast/toast.service';

@Component({
  selector: 'alfred-add-or-edit-program-dialog',
  templateUrl: 'add-or-edit-program-dialog.component.html',
  styleUrls: ['add-or-edit-program-dialog.component.scss'],
  standalone: true,

  imports: [
    LeftyDialogComponent,
    LeftyFormInputComponent,
    LeftyDurationInputComponent,
    LeftyRadioGroupComponent,
    LeftyRadioComponent,
    LeftyCurrencyValueInputComponent,
    LeftyFormNumberInputComponent,
    LeftyFormComponent,
    LeftyButtonDirective,
    ReactiveFormsModule,
  ],
})
export class AddOrEditProgramDialogComponent extends DialogBase {
  constructor() {
    super();
    toObservable(this.currentProgram)
      .pipe(takeUntilDestroyed())
      .subscribe((program) => {
        this._prefillForm(program);
      });

    toObservable(this.commissionType)
      .pipe(takeUntilDestroyed())
      .subscribe(() => {
        // to updated required on fields
        this.formModel.get('percentageValue')?.updateValueAndValidity();
        this.formModel.get('flatFeeValue')?.updateValueAndValidity();
      });
  }

  readonly DEFAULT_GHOST_PROGRAM = new Program();

  private readonly librarianAffiliation = injectLibrarianAffiliationClient();
  private readonly toastManager = injectToastManager();
  readonly eventBus = injectLeftyEventsBus();

  readonly closedWithoutSave$ = output<void>();

  readonly successfullySaved$ = output<Program>();

  readonly currentProgram = signal(this.DEFAULT_GHOST_PROGRAM);

  readonly loading = signal(false);

  readonly cancel$ = output<void>();

  openWith(program: Program): void {
    this.currentProgram.set(program);
    this.open();
  }

  override close(): void {
    this.currentProgram.set(this.DEFAULT_GHOST_PROGRAM);
    this.commissionType.set(AffiliationProgramCommissionType.COMMISSION_NONE);
    this.formModel.reset();
    super.close();
  }

  cancel(): void {
    this.closedWithoutSave$.emit();
    this.close();
  }

  readonly isEdit = computed(
    () => !isGhostAffiliationProgram(this.currentProgram()),
  );

  readonly commissionTypes = getEnumValues<AffiliationProgramCommissionType>(
    AffiliationProgramCommissionType,
  );

  readonly commissionType = signal(
    AffiliationProgramCommissionType.COMMISSION_NONE,
  );

  readonly isPercentage = computed(
    () =>
      this.commissionType() ===
      AffiliationProgramCommissionType.COMMISSION_PERCENTAGE,
  );

  readonly isFlatFee = computed(
    () =>
      this.commissionType() ===
      AffiliationProgramCommissionType.COMMISSION_FLAT_FEE,
  );

  readonly readableAffiliationProgramCommissionType =
    readableAffiliationProgramCommissionType;

  readonly formModel: FormGroup = new FormGroup({
    name: new FormControl<string>('', Validators.required),
    cookieDuration: new FormControl<Duration | null>(null, Validators.required),
    percentageValue: new FormControl<number | null>(
      null,
      LeftyValidators.conditionalRequired('', () => this.isPercentage()),
    ),
    flatFeeValue: new FormControl<CurrencyValue | null>(
      null,
      LeftyValidators.conditionalRequired('', () => this.isFlatFee()),
    ),
  });

  private _prefillForm(program: Program): void {
    if (isGhostAffiliationProgram(program)) {
      this.formModel.reset();
    } else {
      this.formModel.reset({
        name: program.name,
        cookieDuration: new Duration({
          seconds: BigInt(program.cookieDurationMinutes * 60),
        }),
      });
      this.commissionType.set(program.commissionType);
      switch (program.commissionType) {
        case AffiliationProgramCommissionType.COMMISSION_PERCENTAGE:
          this.formModel.patchValue({
            percentageValue: program.commissionValue,
          });
          break;
        case AffiliationProgramCommissionType.COMMISSION_FLAT_FEE: {
          this.formModel.patchValue({
            flatFeeValue: {
              value: program.commissionValue,
              currency: program.currency,
            },
          });
          break;
        }
      }
    }
  }

  private _buildNewProgram(): Program {
    const formValues = this.formModel.value;

    const newProgram = new Program({
      name: formValues.name,
      cookieDurationMinutes: Number(formValues.cookieDuration.seconds) / 60,
      commissionType: this.commissionType(),
    });

    if (this.isPercentage()) {
      newProgram.commissionValue = formValues.percentageValue;
    } else if (this.isFlatFee()) {
      newProgram.commissionValue = formValues.flatFeeValue.value;
      newProgram.currency = formValues.flatFeeValue.currency;
    }
    if (this.isEdit()) {
      newProgram.programId = this.currentProgram().programId;
    }
    return newProgram;
  }

  async submit(): Promise<void> {
    this.loading.set(true);
    const newProgram = this._buildNewProgram();

    try {
      if (this.isEdit()) {
        await this.librarianAffiliation.editProgramAPI(newProgram);
        this.eventBus.emit('edited_program', newProgram);
      } else {
        const newId = await this.librarianAffiliation.addProgramAPI(newProgram);
        newProgram.programId = newId.value;
        this.eventBus.emit('created_program', newProgram);
      }
      this.close();
      this.successfullySaved$.emit(newProgram);
    } catch (e) {
      showToastException(this.toastManager, e);
    } finally {
      this.loading.set(false);
    }
  }
}
