import { inject, Injectable } from '@angular/core';
import {
  BroncoTask,
  injectLibrarianAffiliationClient,
  LimitationsService,
} from '@frontend2/api';
import {
  createManageInfluencerEvent,
  isNotEmptyString,
  LeftyParentAppBridge,
} from '@frontend2/core';
import { AddAffiliatesRequest } from '@frontend2/proto/librarian/proto/affiliation_pb';
import { injectLeftyEventsBus } from '../events/events.service';
import { BroncoService } from '../services/bronco.service';
import { injectToastManager } from '../toast/toast.service';
import { AddAffiliateModel } from './affiliates.models';

@Injectable({
  providedIn: 'root',
})
export class AddAffiliatesService {
  readonly librarianAffiliation = injectLibrarianAffiliationClient();
  readonly eventBus = injectLeftyEventsBus();
  readonly bronco = inject(BroncoService);
  readonly limitation = inject(LimitationsService);
  readonly toastManager = injectToastManager();
  readonly parentAppBridge = inject(LeftyParentAppBridge);

  async addInfluencers(
    selection: AddAffiliateModel,
  ): Promise<AddAffiliateModel> {
    const request = new AddAffiliatesRequest({
      selector: selection.influencersSelector.flexibleSelector,
      programId: selection.program.programId,
    });

    const handle = await this.librarianAffiliation.addAffiliatesAPI(request);

    await this.subscribeBroncoOrSucceed(handle.value, selection);
    return selection;
  }

  private notifySuccess(event: AddAffiliateModel): void {
    this.eventBus.emit('affiliates_added', event);

    this.parentAppBridge.emitInfluencersAddedTo(
      'Affiliation',
      createManageInfluencerEvent({
        entityId: event.program.programId?.toString(),
        entityName: event.program.name,
        influencers: event.influencersSelector.influencers.map(
          (e) => e.baseSnippet,
        ),
      }),
    );
  }

  private async subscribeBronco(handle: string): Promise<BroncoTask> {
    return await this.bronco
      .subscribe(handle, {
        successMessage: () =>
          $localize`Influencers added to affiliates with success`,
        errorMessage: () => $localize`Adding affiliates...`,
        progressMessage: () => $localize`Failed to add affiliates`,
      })
      .wait();
  }

  private async subscribeBroncoOrSucceed(
    handle: string,
    action: AddAffiliateModel,
  ): Promise<void> {
    if (isNotEmptyString(handle)) {
      await this.subscribeBronco(handle);
    } else {
      this.notifySuccess(action);
      this.toastManager.showSuccess(
        $localize`Influencers added to affiliates with success`,
      );
    }
  }
}
