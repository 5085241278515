import { Component } from '@angular/core';

@Component({
  selector: 'olympe-not-found',
  templateUrl: 'not-found.route.html',
  styleUrls: ['not-found.route.scss'],
})
export class OlympeNotFoundRouteComponent {}

@Component({
  selector: 'olympe-empty-route',
  template: `
    <div class="spinner-container">
      <lefty-spinner></lefty-spinner>
    </div>
  `,
  styleUrls: ['not-found.route.scss'],
})
export class OlympeEmptyRouteComponent {}
