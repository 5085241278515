import { Component, computed, input } from '@angular/core';
import { Networks } from '@frontend2/core';
import { Network } from '@frontend2/proto/common/proto/common_pb';
import { LeftyTooltipDirective } from '../lefty-tooltip/lefty-tooltip.directive';
import { NetworkIconSize } from './network.component';
import { NetworkIconsComponent } from './networks.component';

const _DEFAULT_LIMIT_COUNT = 2;

@Component({
  selector: 'networks-sliced-icons',
  template: `
    <div
      class="container "
      [class.minimal-gap]="minimalGap()"
      [leftyTooltip]="networksTooltip()"
    >
      <networks-icons
        [networks]="networksSlice()"
        [grey]="disabled()"
        [minimalGap]="minimalGap()"
        [size]="size()"
        [grey]="grey()"
      ></networks-icons>
      @if (hasMoreNetworks()) {
        <span class="more-networks">+{{ moreNerworksCount() }}</span>
      }
    </div>
  `,
  styleUrl: './networks-sliced-icons.component.scss',
  standalone: true,
  imports: [LeftyTooltipDirective, NetworkIconsComponent],
})
export class NetworksSlicedIconsComponent {
  readonly disabled = input(false);
  readonly networks = input<Network[]>([]);
  readonly grey = input(false);
  readonly limit = input(_DEFAULT_LIMIT_COUNT);

  readonly sortedNetworks = computed(() => Networks.sort(this.networks()));

  readonly networksSlice = computed(() => {
    return this.sortedNetworks().slice(0, this.limit());
  });

  readonly moreNerworksCount = computed(() => {
    const networksCount = this.networks().length;
    return networksCount - this.networksSlice().length;
  });

  readonly hasMoreNetworks = computed(() => this.moreNerworksCount() > 0);

  readonly networksTooltip = computed(() => {
    return this.sortedNetworks()
      .map((n) => Networks.readable(n))
      .join(', ');
  });

  readonly size = input<NetworkIconSize>('medium');
  readonly minimalGap = input(false);
}
