// @generated by protoc-gen-es v1.10.0 with parameter "target=ts"
// @generated from file librarian/proto/entity_fields.proto (package librarian.proto, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { BoolValue, DoubleValue, Message, proto3, protoInt64, StringValue, Timestamp } from "@bufbuild/protobuf";
import { BaseEntityType, CampaignSelector, EntityField, FlexibleCreatorSelector, Notes, TimeRestriction } from "./common_pb";
import { FloatRange, Int32Range, StringList } from "../../common/proto/common_pb";
import { GoogleLocation, GoogleLocationList } from "./local_pb";
import { MetaIdPb } from "../../common/proto/creator_pb";

/**
 * @generated from enum librarian.proto.AddressFieldColumn
 */
export enum AddressFieldColumn {
  /**
   * @generated from enum value: ADDRESS_FIELD_COLUMN_UNKNOWN = 0;
   */
  ADDRESS_FIELD_COLUMN_UNKNOWN = 0,

  /**
   * @generated from enum value: ADDRESS_FIELD_FULL_NAME = 1;
   */
  ADDRESS_FIELD_FULL_NAME = 1,

  /**
   * @generated from enum value: ADDRESS_FIELD_COMPANY = 2;
   */
  ADDRESS_FIELD_COMPANY = 2,

  /**
   * @generated from enum value: ADDRESS_FIELD_ADDRESS = 3;
   */
  ADDRESS_FIELD_ADDRESS = 3,

  /**
   * @generated from enum value: ADDRESS_FIELD_ADDRESS2 = 4;
   */
  ADDRESS_FIELD_ADDRESS2 = 4,

  /**
   * @generated from enum value: ADDRESS_FIELD_CITY = 5;
   */
  ADDRESS_FIELD_CITY = 5,

  /**
   * @generated from enum value: ADDRESS_FIELD_PROVINCE = 6;
   */
  ADDRESS_FIELD_PROVINCE = 6,

  /**
   * @generated from enum value: ADDRESS_FIELD_POSTAL_CODE = 7;
   */
  ADDRESS_FIELD_POSTAL_CODE = 7,

  /**
   * @generated from enum value: ADDRESS_FIELD_COUNTRY = 8;
   */
  ADDRESS_FIELD_COUNTRY = 8,

  /**
   * @generated from enum value: ADDRESS_FIELD_PHONE = 9;
   */
  ADDRESS_FIELD_PHONE = 9,
}
// Retrieve enum metadata with: proto3.getEnumType(AddressFieldColumn)
proto3.util.setEnumType(AddressFieldColumn, "librarian.proto.AddressFieldColumn", [
  { no: 0, name: "ADDRESS_FIELD_COLUMN_UNKNOWN" },
  { no: 1, name: "ADDRESS_FIELD_FULL_NAME" },
  { no: 2, name: "ADDRESS_FIELD_COMPANY" },
  { no: 3, name: "ADDRESS_FIELD_ADDRESS" },
  { no: 4, name: "ADDRESS_FIELD_ADDRESS2" },
  { no: 5, name: "ADDRESS_FIELD_CITY" },
  { no: 6, name: "ADDRESS_FIELD_PROVINCE" },
  { no: 7, name: "ADDRESS_FIELD_POSTAL_CODE" },
  { no: 8, name: "ADDRESS_FIELD_COUNTRY" },
  { no: 9, name: "ADDRESS_FIELD_PHONE" },
]);

/**
 * @generated from enum librarian.proto.EntityFieldValueSource
 */
export enum EntityFieldValueSource {
  /**
   * @generated from enum value: ENTITY_FIELD_VALUE_SOURCE_UNKNOWN = 0;
   */
  UNKNOWN = 0,

  /**
   * @generated from enum value: ENTITY_FIELD_VALUE_SOURCE_MANUAL = 1;
   */
  MANUAL = 1,

  /**
   * @generated from enum value: ENTITY_FIELD_VALUE_SOURCE_UPLOAD = 2;
   */
  UPLOAD = 2,

  /**
   * @generated from enum value: ENTITY_FIELD_VALUE_SOURCE_PROGRAMMATIC = 3;
   */
  PROGRAMMATIC = 3,

  /**
   * @generated from enum value: ENTITY_FIELD_VALUE_SOURCE_PRODUCT_SEEDING = 4;
   */
  PRODUCT_SEEDING = 4,
}
// Retrieve enum metadata with: proto3.getEnumType(EntityFieldValueSource)
proto3.util.setEnumType(EntityFieldValueSource, "librarian.proto.EntityFieldValueSource", [
  { no: 0, name: "ENTITY_FIELD_VALUE_SOURCE_UNKNOWN" },
  { no: 1, name: "ENTITY_FIELD_VALUE_SOURCE_MANUAL" },
  { no: 2, name: "ENTITY_FIELD_VALUE_SOURCE_UPLOAD" },
  { no: 3, name: "ENTITY_FIELD_VALUE_SOURCE_PROGRAMMATIC" },
  { no: 4, name: "ENTITY_FIELD_VALUE_SOURCE_PRODUCT_SEEDING" },
]);

/**
 * @generated from message librarian.proto.AddressFieldProto
 */
export class AddressFieldProto extends Message<AddressFieldProto> {
  /**
   * @generated from field: string full_name = 1;
   */
  fullName = "";

  /**
   * @generated from field: string company = 2;
   */
  company = "";

  /**
   * @generated from field: string address = 3;
   */
  address = "";

  /**
   * Details like apartment, suite...
   *
   * @generated from field: string address2 = 4;
   */
  address2 = "";

  /**
   * @generated from field: string city = 5;
   */
  city = "";

  /**
   * @generated from field: string province = 6;
   */
  province = "";

  /**
   * @generated from field: string postal_code = 7;
   */
  postalCode = "";

  /**
   * @generated from field: string country = 8;
   */
  country = "";

  /**
   * @generated from field: string phone = 9;
   */
  phone = "";

  /**
   * @generated from field: string email = 10;
   */
  email = "";

  constructor(data?: PartialMessage<AddressFieldProto>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.AddressFieldProto";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "full_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "company", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "address", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "address2", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "city", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "province", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "postal_code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "country", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "phone", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 10, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AddressFieldProto {
    return new AddressFieldProto().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AddressFieldProto {
    return new AddressFieldProto().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AddressFieldProto {
    return new AddressFieldProto().fromJsonString(jsonString, options);
  }

  static equals(a: AddressFieldProto | PlainMessage<AddressFieldProto> | undefined, b: AddressFieldProto | PlainMessage<AddressFieldProto> | undefined): boolean {
    return proto3.util.equals(AddressFieldProto, a, b);
  }
}

/**
 * @generated from message librarian.proto.EntityFieldsList
 */
export class EntityFieldsList extends Message<EntityFieldsList> {
  /**
   * @generated from field: repeated librarian.proto.EntityField entity_fields = 1;
   */
  entityFields: EntityField[] = [];

  constructor(data?: PartialMessage<EntityFieldsList>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.EntityFieldsList";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "entity_fields", kind: "message", T: EntityField, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): EntityFieldsList {
    return new EntityFieldsList().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): EntityFieldsList {
    return new EntityFieldsList().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): EntityFieldsList {
    return new EntityFieldsList().fromJsonString(jsonString, options);
  }

  static equals(a: EntityFieldsList | PlainMessage<EntityFieldsList> | undefined, b: EntityFieldsList | PlainMessage<EntityFieldsList> | undefined): boolean {
    return proto3.util.equals(EntityFieldsList, a, b);
  }
}

/**
 * @generated from message librarian.proto.ShowEntityFieldInFiltersReq
 */
export class ShowEntityFieldInFiltersReq extends Message<ShowEntityFieldInFiltersReq> {
  /**
   * @generated from field: int64 entity_field_id = 1;
   */
  entityFieldId = protoInt64.zero;

  /**
   * @generated from field: bool show_in_filters = 2;
   */
  showInFilters = false;

  constructor(data?: PartialMessage<ShowEntityFieldInFiltersReq>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.ShowEntityFieldInFiltersReq";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "entity_field_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "show_in_filters", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ShowEntityFieldInFiltersReq {
    return new ShowEntityFieldInFiltersReq().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ShowEntityFieldInFiltersReq {
    return new ShowEntityFieldInFiltersReq().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ShowEntityFieldInFiltersReq {
    return new ShowEntityFieldInFiltersReq().fromJsonString(jsonString, options);
  }

  static equals(a: ShowEntityFieldInFiltersReq | PlainMessage<ShowEntityFieldInFiltersReq> | undefined, b: ShowEntityFieldInFiltersReq | PlainMessage<ShowEntityFieldInFiltersReq> | undefined): boolean {
    return proto3.util.equals(ShowEntityFieldInFiltersReq, a, b);
  }
}

/**
 * @generated from message librarian.proto.EntityFieldFilter
 */
export class EntityFieldFilter extends Message<EntityFieldFilter> {
  /**
   * @generated from field: librarian.proto.EntityField entity_field = 1;
   */
  entityField?: EntityField;

  /**
   * @generated from oneof librarian.proto.EntityFieldFilter.filter
   */
  filter: {
    /**
     * @generated from field: librarian.proto.EntityFieldFilter.ExactSearchList exact_search_list = 13;
     */
    value: EntityFieldFilter_ExactSearchList;
    case: "exactSearchList";
  } | {
    /**
     * @generated from field: string approx_search = 11;
     */
    value: string;
    case: "approxSearch";
  } | {
    /**
     * @generated from field: common.proto.Int32Range integer_range = 4;
     */
    value: Int32Range;
    case: "integerRange";
  } | {
    /**
     * @generated from field: common.proto.FloatRange float_range = 5;
     */
    value: FloatRange;
    case: "floatRange";
  } | {
    /**
     * @generated from field: librarian.proto.TimeRestriction time_restriction = 6;
     */
    value: TimeRestriction;
    case: "timeRestriction";
  } | {
    /**
     * @generated from field: bool bool_value = 7;
     */
    value: boolean;
    case: "boolValue";
  } | {
    /**
     * @generated from field: librarian.proto.GoogleLocationList locations_values = 9;
     */
    value: GoogleLocationList;
    case: "locationsValues";
  } | { case: undefined; value?: undefined } = { case: undefined };

  constructor(data?: PartialMessage<EntityFieldFilter>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.EntityFieldFilter";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "entity_field", kind: "message", T: EntityField },
    { no: 13, name: "exact_search_list", kind: "message", T: EntityFieldFilter_ExactSearchList, oneof: "filter" },
    { no: 11, name: "approx_search", kind: "scalar", T: 9 /* ScalarType.STRING */, oneof: "filter" },
    { no: 4, name: "integer_range", kind: "message", T: Int32Range, oneof: "filter" },
    { no: 5, name: "float_range", kind: "message", T: FloatRange, oneof: "filter" },
    { no: 6, name: "time_restriction", kind: "message", T: TimeRestriction, oneof: "filter" },
    { no: 7, name: "bool_value", kind: "scalar", T: 8 /* ScalarType.BOOL */, oneof: "filter" },
    { no: 9, name: "locations_values", kind: "message", T: GoogleLocationList, oneof: "filter" },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): EntityFieldFilter {
    return new EntityFieldFilter().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): EntityFieldFilter {
    return new EntityFieldFilter().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): EntityFieldFilter {
    return new EntityFieldFilter().fromJsonString(jsonString, options);
  }

  static equals(a: EntityFieldFilter | PlainMessage<EntityFieldFilter> | undefined, b: EntityFieldFilter | PlainMessage<EntityFieldFilter> | undefined): boolean {
    return proto3.util.equals(EntityFieldFilter, a, b);
  }
}

/**
 * it is allowed to have excluded and included words in the same filter
 *
 * @generated from message librarian.proto.EntityFieldFilter.ExactSearchList
 */
export class EntityFieldFilter_ExactSearchList extends Message<EntityFieldFilter_ExactSearchList> {
  /**
   * @generated from field: repeated string included_words = 1;
   */
  includedWords: string[] = [];

  /**
   * @generated from field: repeated string excluded_words = 2;
   */
  excludedWords: string[] = [];

  constructor(data?: PartialMessage<EntityFieldFilter_ExactSearchList>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.EntityFieldFilter.ExactSearchList";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "included_words", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 2, name: "excluded_words", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): EntityFieldFilter_ExactSearchList {
    return new EntityFieldFilter_ExactSearchList().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): EntityFieldFilter_ExactSearchList {
    return new EntityFieldFilter_ExactSearchList().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): EntityFieldFilter_ExactSearchList {
    return new EntityFieldFilter_ExactSearchList().fromJsonString(jsonString, options);
  }

  static equals(a: EntityFieldFilter_ExactSearchList | PlainMessage<EntityFieldFilter_ExactSearchList> | undefined, b: EntityFieldFilter_ExactSearchList | PlainMessage<EntityFieldFilter_ExactSearchList> | undefined): boolean {
    return proto3.util.equals(EntityFieldFilter_ExactSearchList, a, b);
  }
}

/**
 * @generated from message librarian.proto.EntityFieldValue
 */
export class EntityFieldValue extends Message<EntityFieldValue> {
  /**
   * @generated from field: int64 entity_field_id = 1;
   */
  entityFieldId = protoInt64.zero;

  /**
   * @generated from field: librarian.proto.BaseEntityType entity_type = 14;
   */
  entityType = BaseEntityType.UNKNOWN;

  /**
   * @generated from oneof librarian.proto.EntityFieldValue.entity_id
   */
  entityId: {
    /**
     * @generated from field: string influencer_lefty_id = 2;
     */
    value: string;
    case: "influencerLeftyId";
  } | {
    /**
     * @generated from field: int64 campaign_id = 15;
     */
    value: bigint;
    case: "campaignId";
  } | { case: undefined; value?: undefined } = { case: undefined };

  /**
   * @generated from field: librarian.proto.EntityFieldValueSource source = 11;
   */
  source = EntityFieldValueSource.UNKNOWN;

  /**
   * @generated from oneof librarian.proto.EntityFieldValue.value
   */
  value: {
    /**
     * @generated from field: string text_value = 3;
     */
    value: string;
    case: "textValue";
  } | {
    /**
     * @generated from field: int64 number_value = 4;
     */
    value: bigint;
    case: "numberValue";
  } | {
    /**
     * @generated from field: float decimal_value = 5;
     */
    value: number;
    case: "decimalValue";
  } | {
    /**
     * @generated from field: google.protobuf.Timestamp date_value = 6;
     */
    value: Timestamp;
    case: "dateValue";
  } | {
    /**
     * @generated from field: bool bool_value = 7;
     */
    value: boolean;
    case: "boolValue";
  } | {
    /**
     * @generated from field: common.proto.StringList enum_value = 8;
     */
    value: StringList;
    case: "enumValue";
  } | {
    /**
     * @generated from field: librarian.proto.GoogleLocation location_value = 9;
     */
    value: GoogleLocation;
    case: "locationValue";
  } | {
    /**
     * @generated from field: librarian.proto.AddressFieldProto address_value = 12;
     */
    value: AddressFieldProto;
    case: "addressValue";
  } | {
    /**
     * @generated from field: librarian.proto.Notes string_values = 10;
     */
    value: Notes;
    case: "stringValues";
  } | {
    /**
     * This is just a marker to set the oneof case, the value is actually ignored (meaning: we will unset even if value if false)
     *
     * @generated from field: bool unset_value = 100;
     */
    value: boolean;
    case: "unsetValue";
  } | { case: undefined; value?: undefined } = { case: undefined };

  /**
   * @generated from field: string entity_field_name = 13;
   */
  entityFieldName = "";

  /**
   * @generated from field: bool is_default = 16;
   */
  isDefault = false;

  /**
   * @generated from field: bool ai_generated = 17;
   */
  aiGenerated = false;

  constructor(data?: PartialMessage<EntityFieldValue>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.EntityFieldValue";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "entity_field_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 14, name: "entity_type", kind: "enum", T: proto3.getEnumType(BaseEntityType) },
    { no: 2, name: "influencer_lefty_id", kind: "scalar", T: 9 /* ScalarType.STRING */, oneof: "entity_id" },
    { no: 15, name: "campaign_id", kind: "scalar", T: 3 /* ScalarType.INT64 */, oneof: "entity_id" },
    { no: 11, name: "source", kind: "enum", T: proto3.getEnumType(EntityFieldValueSource) },
    { no: 3, name: "text_value", kind: "scalar", T: 9 /* ScalarType.STRING */, oneof: "value" },
    { no: 4, name: "number_value", kind: "scalar", T: 3 /* ScalarType.INT64 */, oneof: "value" },
    { no: 5, name: "decimal_value", kind: "scalar", T: 2 /* ScalarType.FLOAT */, oneof: "value" },
    { no: 6, name: "date_value", kind: "message", T: Timestamp, oneof: "value" },
    { no: 7, name: "bool_value", kind: "scalar", T: 8 /* ScalarType.BOOL */, oneof: "value" },
    { no: 8, name: "enum_value", kind: "message", T: StringList, oneof: "value" },
    { no: 9, name: "location_value", kind: "message", T: GoogleLocation, oneof: "value" },
    { no: 12, name: "address_value", kind: "message", T: AddressFieldProto, oneof: "value" },
    { no: 10, name: "string_values", kind: "message", T: Notes, oneof: "value" },
    { no: 100, name: "unset_value", kind: "scalar", T: 8 /* ScalarType.BOOL */, oneof: "value" },
    { no: 13, name: "entity_field_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 16, name: "is_default", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 17, name: "ai_generated", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): EntityFieldValue {
    return new EntityFieldValue().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): EntityFieldValue {
    return new EntityFieldValue().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): EntityFieldValue {
    return new EntityFieldValue().fromJsonString(jsonString, options);
  }

  static equals(a: EntityFieldValue | PlainMessage<EntityFieldValue> | undefined, b: EntityFieldValue | PlainMessage<EntityFieldValue> | undefined): boolean {
    return proto3.util.equals(EntityFieldValue, a, b);
  }
}

/**
 * @generated from message librarian.proto.EntityFieldRawValue
 */
export class EntityFieldRawValue extends Message<EntityFieldRawValue> {
  /**
   * @generated from field: int64 entity_field_id = 1;
   */
  entityFieldId = protoInt64.zero;

  /**
   * @generated from field: librarian.proto.EntityFieldRawValue.PossibleValues possible_values = 2;
   */
  possibleValues?: EntityFieldRawValue_PossibleValues;

  constructor(data?: PartialMessage<EntityFieldRawValue>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.EntityFieldRawValue";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "entity_field_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "possible_values", kind: "message", T: EntityFieldRawValue_PossibleValues },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): EntityFieldRawValue {
    return new EntityFieldRawValue().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): EntityFieldRawValue {
    return new EntityFieldRawValue().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): EntityFieldRawValue {
    return new EntityFieldRawValue().fromJsonString(jsonString, options);
  }

  static equals(a: EntityFieldRawValue | PlainMessage<EntityFieldRawValue> | undefined, b: EntityFieldRawValue | PlainMessage<EntityFieldRawValue> | undefined): boolean {
    return proto3.util.equals(EntityFieldRawValue, a, b);
  }
}

/**
 * @generated from message librarian.proto.EntityFieldRawValue.PossibleValues
 */
export class EntityFieldRawValue_PossibleValues extends Message<EntityFieldRawValue_PossibleValues> {
  /**
   * @generated from field: google.protobuf.StringValue text_value = 1;
   */
  textValue?: string;

  /**
   * @generated from field: google.protobuf.Timestamp time_value = 2;
   */
  timeValue?: Timestamp;

  /**
   * @generated from field: google.protobuf.BoolValue bool_value = 3;
   */
  boolValue?: boolean;

  /**
   * @generated from field: google.protobuf.DoubleValue double_value = 4;
   */
  doubleValue?: number;

  /**
   * @generated from field: common.proto.StringList text_list = 5;
   */
  textList?: StringList;

  /**
   * @generated from field: librarian.proto.AddressFieldProto address_value = 6;
   */
  addressValue?: AddressFieldProto;

  constructor(data?: PartialMessage<EntityFieldRawValue_PossibleValues>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.EntityFieldRawValue.PossibleValues";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "text_value", kind: "message", T: StringValue },
    { no: 2, name: "time_value", kind: "message", T: Timestamp },
    { no: 3, name: "bool_value", kind: "message", T: BoolValue },
    { no: 4, name: "double_value", kind: "message", T: DoubleValue },
    { no: 5, name: "text_list", kind: "message", T: StringList },
    { no: 6, name: "address_value", kind: "message", T: AddressFieldProto },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): EntityFieldRawValue_PossibleValues {
    return new EntityFieldRawValue_PossibleValues().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): EntityFieldRawValue_PossibleValues {
    return new EntityFieldRawValue_PossibleValues().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): EntityFieldRawValue_PossibleValues {
    return new EntityFieldRawValue_PossibleValues().fromJsonString(jsonString, options);
  }

  static equals(a: EntityFieldRawValue_PossibleValues | PlainMessage<EntityFieldRawValue_PossibleValues> | undefined, b: EntityFieldRawValue_PossibleValues | PlainMessage<EntityFieldRawValue_PossibleValues> | undefined): boolean {
    return proto3.util.equals(EntityFieldRawValue_PossibleValues, a, b);
  }
}

/**
 * @generated from message librarian.proto.EntityFieldRawValuesRequest
 */
export class EntityFieldRawValuesRequest extends Message<EntityFieldRawValuesRequest> {
  /**
   * @generated from field: int64 user_id = 1;
   */
  userId = protoInt64.zero;

  /**
   * @generated from field: librarian.proto.BaseEntityType entity_type = 2;
   */
  entityType = BaseEntityType.UNKNOWN;

  /**
   * @generated from oneof librarian.proto.EntityFieldRawValuesRequest.id
   */
  id: {
    /**
     * @generated from field: common.proto.MetaIdPb meta_id = 3;
     */
    value: MetaIdPb;
    case: "metaId";
  } | {
    /**
     * @generated from field: int64 campaign_id = 5;
     */
    value: bigint;
    case: "campaignId";
  } | { case: undefined; value?: undefined } = { case: undefined };

  /**
   * @generated from field: repeated librarian.proto.EntityFieldRawValue raw_value = 4;
   */
  rawValue: EntityFieldRawValue[] = [];

  constructor(data?: PartialMessage<EntityFieldRawValuesRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.EntityFieldRawValuesRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "user_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "entity_type", kind: "enum", T: proto3.getEnumType(BaseEntityType) },
    { no: 3, name: "meta_id", kind: "message", T: MetaIdPb, oneof: "id" },
    { no: 5, name: "campaign_id", kind: "scalar", T: 3 /* ScalarType.INT64 */, oneof: "id" },
    { no: 4, name: "raw_value", kind: "message", T: EntityFieldRawValue, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): EntityFieldRawValuesRequest {
    return new EntityFieldRawValuesRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): EntityFieldRawValuesRequest {
    return new EntityFieldRawValuesRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): EntityFieldRawValuesRequest {
    return new EntityFieldRawValuesRequest().fromJsonString(jsonString, options);
  }

  static equals(a: EntityFieldRawValuesRequest | PlainMessage<EntityFieldRawValuesRequest> | undefined, b: EntityFieldRawValuesRequest | PlainMessage<EntityFieldRawValuesRequest> | undefined): boolean {
    return proto3.util.equals(EntityFieldRawValuesRequest, a, b);
  }
}

/**
 * Set multiple entity fields values
 *
 * @generated from message librarian.proto.SetEntityFieldsValuesReq
 */
export class SetEntityFieldsValuesReq extends Message<SetEntityFieldsValuesReq> {
  /**
   * @generated from field: librarian.proto.BaseEntityType entity_type = 1;
   */
  entityType = BaseEntityType.UNKNOWN;

  /**
   * @generated from oneof librarian.proto.SetEntityFieldsValuesReq.selector
   */
  selector: {
    /**
     * @generated from field: librarian.proto.FlexibleCreatorSelector creator_selector = 2;
     */
    value: FlexibleCreatorSelector;
    case: "creatorSelector";
  } | {
    /**
     * @generated from field: librarian.proto.CampaignSelector campaign_selector = 6;
     */
    value: CampaignSelector;
    case: "campaignSelector";
  } | { case: undefined; value?: undefined } = { case: undefined };

  /**
   * @generated from field: repeated librarian.proto.EntityFieldValue entity_field_values = 3;
   */
  entityFieldValues: EntityFieldValue[] = [];

  /**
   * @generated from field: bool override_existing = 4;
   */
  overrideExisting = false;

  /**
   * Will be filled by backend
   *
   * @generated from field: int64 user_id = 5;
   */
  userId = protoInt64.zero;

  constructor(data?: PartialMessage<SetEntityFieldsValuesReq>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.SetEntityFieldsValuesReq";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "entity_type", kind: "enum", T: proto3.getEnumType(BaseEntityType) },
    { no: 2, name: "creator_selector", kind: "message", T: FlexibleCreatorSelector, oneof: "selector" },
    { no: 6, name: "campaign_selector", kind: "message", T: CampaignSelector, oneof: "selector" },
    { no: 3, name: "entity_field_values", kind: "message", T: EntityFieldValue, repeated: true },
    { no: 4, name: "override_existing", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 5, name: "user_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SetEntityFieldsValuesReq {
    return new SetEntityFieldsValuesReq().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SetEntityFieldsValuesReq {
    return new SetEntityFieldsValuesReq().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SetEntityFieldsValuesReq {
    return new SetEntityFieldsValuesReq().fromJsonString(jsonString, options);
  }

  static equals(a: SetEntityFieldsValuesReq | PlainMessage<SetEntityFieldsValuesReq> | undefined, b: SetEntityFieldsValuesReq | PlainMessage<SetEntityFieldsValuesReq> | undefined): boolean {
    return proto3.util.equals(SetEntityFieldsValuesReq, a, b);
  }
}

/**
 * @generated from message librarian.proto.EntityFieldUpdateReq
 */
export class EntityFieldUpdateReq extends Message<EntityFieldUpdateReq> {
  /**
   * @generated from field: librarian.proto.BaseEntityType entity_type = 1;
   */
  entityType = BaseEntityType.UNKNOWN;

  /**
   * @generated from field: int64 entity_field_id = 2;
   */
  entityFieldId = protoInt64.zero;

  constructor(data?: PartialMessage<EntityFieldUpdateReq>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.EntityFieldUpdateReq";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "entity_type", kind: "enum", T: proto3.getEnumType(BaseEntityType) },
    { no: 2, name: "entity_field_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): EntityFieldUpdateReq {
    return new EntityFieldUpdateReq().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): EntityFieldUpdateReq {
    return new EntityFieldUpdateReq().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): EntityFieldUpdateReq {
    return new EntityFieldUpdateReq().fromJsonString(jsonString, options);
  }

  static equals(a: EntityFieldUpdateReq | PlainMessage<EntityFieldUpdateReq> | undefined, b: EntityFieldUpdateReq | PlainMessage<EntityFieldUpdateReq> | undefined): boolean {
    return proto3.util.equals(EntityFieldUpdateReq, a, b);
  }
}

