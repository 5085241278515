import { Inject, Injectable, computed } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { Empty } from '@bufbuild/protobuf';
import {
  BlocState,
  Networks,
  analyticsBootstrap,
  createInfluencerTierMinMax,
  isNil,
  isNotNil,
  setupTracingUserContext,
  updateElementInArray,
} from '@frontend2/core';
import {
  isSuperAdmin,
  userHasAffiliationAccess,
  userHasCWAccess,
  userHasCampaignAccess,
  userHasCastingAccess,
  userHasChatAccess,
  userHasDirectoryAccess,
  userHasDiscoverAccess,
  userHasLandingPageAccess,
  userHasPaymentsAccess,
  userHasProductSeedingAccess,
} from '@frontend2/proto-helpers/librarian/frontend_misc_pb.helpers';
import { CurrencyEnum } from '@frontend2/proto/common/proto/common_pb';
import { UserSettingKind } from '@frontend2/proto/librarian/proto/common_pb';
import { SummaryType } from '@frontend2/proto/librarian/proto/competitive_watch_pb';
import {
  CustomerSpace,
  LoggedBootstrapping,
  LoginForm,
} from '@frontend2/proto/librarian/proto/frontend_misc_pb';
import { LeftyIframeDataSyncable } from 'packages/core/src/lib/iframe/lefty_data_syncable.service';
import posthog from 'posthog-js';
import { Observable } from 'rxjs';
import {
  FrontendMiscClient,
  FrontendMiscClientProvider,
} from '../frontend-misc.service';
import { WorkspaceAuth } from './workspace.interceptor';

const INITIAL_VALUE = new LoggedBootstrapping();

@Injectable({
  providedIn: 'root',
})
export class LeftyAuthBloc
  extends BlocState<LoggedBootstrapping>
  implements LeftyIframeDataSyncable<LoggedBootstrapping>
{
  constructor(
    @Inject(FrontendMiscClientProvider)
    private librarianAPI: FrontendMiscClient,
    private workspaceAuth: WorkspaceAuth,
  ) {
    super(INITIAL_VALUE);
  }

  readonly syncName = 'is_logged';

  get dataToSync$(): Observable<LoggedBootstrapping> {
    return this.state$;
  }

  get advancedCreationFlow(): boolean {
    return this.currentState.userSettings.some(
      (e) => e === UserSettingKind.CAMPAIGN_CREATION_WORKFLOW_SETTING,
    );
  }

  syncData(data: LoggedBootstrapping): void {
    this.updateState(data);
  }

  convertToJson(obj: LoggedBootstrapping): string {
    return obj.toJsonString();
  }

  convertFromJson(jsonString: string): LoggedBootstrapping {
    return LoggedBootstrapping.fromJsonString(jsonString);
  }

  get isSuperAdmin(): boolean {
    return isSuperAdmin(this.currentState);
  }

  readonly $user = toSignal(this.state$, {
    initialValue: INITIAL_VALUE,
  });

  get user(): LoggedBootstrapping {
    return this.$user();
  }

  readonly $currency = computed(() => this.$user().userCurrency);

  readonly allowedNetworks = computed(() => Networks.allowed(this.$user()));

  readonly allowedMergeNetworks = computed(() =>
    Networks.allowedMerge(this.$user()),
  );

  readonly allowedTrackingNetworks = computed(() =>
    Networks.allowedTracking(this.$user()),
  );

  readonly allowedAutocompleteNetworks = computed(() =>
    Networks.allowedAutocomplete(this.$user()),
  );

  readonly allowedCPMEditeNetworks = computed(() =>
    Networks.allowedCPMEdit(this.$user()),
  );

  readonly allowedCWNetworks = computed(() => Networks.allowedCW(this.$user()));

  get isLogged(): boolean {
    return this.user.isLogged;
  }

  get isAdmin(): boolean {
    return this.user.isAdmin;
  }

  get isImpersonating(): boolean {
    return this.user.isImpersonating;
  }

  get canDebug(): boolean {
    return this.isAdmin || this.isImpersonating;
  }

  get currency(): CurrencyEnum {
    return this.$currency();
  }

  get workspaceName(): string {
    return (
      this.user.customerSpaces.find((s) => s.idxHint === this.user.spaceIdxHint)
        ?.name ?? ''
    );
  }

  get hasCampaignAccess(): boolean {
    return userHasCampaignAccess(this.user);
  }

  get hasAffiliationAccess(): boolean {
    return userHasAffiliationAccess(this.user);
  }

  get hasDirectoryAccess(): boolean {
    return userHasDirectoryAccess(this.user);
  }

  get hasCWAccess(): boolean {
    return userHasCWAccess(this.user);
  }

  get hasChatAccess(): boolean {
    return userHasChatAccess(this.user);
  }

  get hasPaymentsAccess(): boolean {
    return userHasPaymentsAccess(this.user);
  }

  get hasDiscoverAccess(): boolean {
    return userHasDiscoverAccess(this.user);
  }

  get hasLandingPageAccess(): boolean {
    return userHasLandingPageAccess(this.user);
  }

  get hasCastingAccess(): boolean {
    return userHasCastingAccess(this.user);
  }

  get hasProductSeedingAccess(): boolean {
    return userHasProductSeedingAccess(this.user);
  }

  protected async callLogoutAPI(): Promise<Empty> {
    return this.librarianAPI.logoutAPI(new Empty());
  }

  protected async callLoginAPI(request: LoginForm): Promise<Empty> {
    return this.librarianAPI.logAPI(request);
  }

  protected async callIsLoggedAPI(): Promise<LoggedBootstrapping> {
    return this.librarianAPI.isLoggedAPI(new Empty());
  }

  async checkIsLogged(): Promise<LoggedBootstrapping> {
    const boot = await this.callIsLoggedAPI();

    setupTracingUserContext(boot);

    if (boot.isLogged) {
      analyticsBootstrap(boot);
      this.workspaceAuth.hint = boot.spaceIdxHint;
    }

    // TODO(hadrien): handle firebase login

    return this.updateState(boot);
  }

  async logout(): Promise<void> {
    await this.callLogoutAPI();

    posthog.reset();

    // refresh after logout to make sure we don't keep old state in memory
    // If not in iframe, it will refresh the app, else just the iframe
    window.location.href = window.location.origin;
  }

  async login(request: LoginForm): Promise<LoggedBootstrapping> {
    await this.callLoginAPI(request);
    return this.checkIsLogged();
  }

  updateCurrencyState(newCurrency: CurrencyEnum): void {
    this.updateState(
      new LoggedBootstrapping({
        ...this.currentState,
        userCurrency: newCurrency,
        dashboardCurrency: newCurrency,
        customerSpaces: updateElementInArray(
          this.user.customerSpaces,

          {
            predicate: (el) => el.idxHint === this.user.spaceIdxHint,
            modifier: (el) =>
              new CustomerSpace({ ...el, currency: newCurrency }),
          },
        ),
      }),
    );
  }

  updateCampaignUsed(number: number, campaignUsed: number): void {
    if (isNil(this.user.limitations)) {
      return;
    }
    const updatedUser = new LoggedBootstrapping({
      ...this.user,
      limitations: {
        campaignsUsed: campaignUsed + number,
      },
    });
    this.updateState(updatedUser);
  }

  readonly influencersTiers = computed(() => {
    const tiers = [
      ...this.$user().influencersTiers.filter(
        // Nano unused but backend is still sending it
        (t) => t.summaryType !== SummaryType.NANO,
      ),
    ];

    tiers.sort((a, b) => b.min - a.min);

    return tiers
      .map((tier, index) => {
        if (index - 1 >= 0) {
          return createInfluencerTierMinMax({
            type: tier.summaryType,
            min: tier.min,
            max: tiers[index - 1].min - 1,
          });
        }
        return createInfluencerTierMinMax({
          type: tier.summaryType,
          min: tier.min,
        });
      })
      .filter(isNotNil);
  });

  readonly summaryTypes = computed(() =>
    this.influencersTiers().map((tier) => tier.type),
  );
}
