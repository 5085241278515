import {
  ChangeDetectionStrategy,
  Component,
  input,
  output,
  signal,
  viewChild,
} from '@angular/core';

import {
  createInfluencerFromCreatorPoolResponse,
  Influencer,
  patchObject,
} from '@frontend2/core';
import { CreatorPoolResponse } from '@frontend2/proto/librarian/proto/pool_pb';
import {
  AddInfluencersMethod,
  AddInfluencersSelectionState,
  createAddInfluencersSelectionState,
  PlainTextInfluencerUploadData,
} from './influencers-management.models';
import { MethodSelectionDialogComponent } from './method-selection-dialog/method-selection-dialog.component';
import { PlainTextMethodDialogComponent } from './plain-text-method-dialog/plain-text-method-dialog.component';
import { SearchCreatorMethodDialogComponent } from './search-creator-method-dialog/search-creator-method-dialog.component';
import { SelectInfluencersFromDirectoryDialogComponent } from './select-from-directory-dialog/select-from-directory-dialog.component';

@Component({
  selector: 'select-influencers-dialog',
  templateUrl: 'select-influencers-dialog.component.html',
  styleUrls: ['./select-influencers-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    MethodSelectionDialogComponent,
    SearchCreatorMethodDialogComponent,
    PlainTextMethodDialogComponent,
    SelectInfluencersFromDirectoryDialogComponent,
  ],
})
export class LeftySelectInfluencersDialogComponent {
  readonly methodSelectionDialog = viewChild.required(
    MethodSelectionDialogComponent,
  );

  readonly searchCreatorMethodDialog = viewChild.required(
    SearchCreatorMethodDialogComponent,
  );

  readonly plainTextMethodDialog = viewChild.required(
    PlainTextMethodDialogComponent,
  );

  readonly directoryMethodDialog = viewChild.required(
    SelectInfluencersFromDirectoryDialogComponent,
  );

  readonly selectionState = signal(createAddInfluencersSelectionState());

  readonly selectedInfuencers = output<AddInfluencersSelectionState>();

  readonly addInfluencerMethods = signal<AddInfluencersMethod[]>([]);

  readonly submitButtonText = input($localize`Save`);

  readonly closedWithoutSave$ = output<void>();

  openWith(addInfluencerMethods: AddInfluencersMethod[]): void {
    this.addInfluencerMethods.set(addInfluencerMethods);
    this.openMethodSelectionDialog();
  }

  updateSelectionState(partial: Partial<AddInfluencersSelectionState>): void {
    const newState = patchObject(this.selectionState(), partial);
    this.selectionState.set(newState);
  }

  openDialogForSelectedMethod(method: AddInfluencersMethod): void {
    this.updateSelectionState({
      selectedAddMethod: method,
    });
    switch (method) {
      case 'search-creator':
        this.searchCreatorMethodDialog().open();
        break;

      case 'plain-text':
        this.plainTextMethodDialog().open();
        break;

      case 'directory':
        this.directoryMethodDialog().open();
        break;
      //TODO : other dialogs
      case 'file-upload':
    }
  }

  openMethodSelectionDialog(): void {
    this.selectionState.set(createAddInfluencersSelectionState());
    this.methodSelectionDialog().openWith(this.addInfluencerMethods());
  }

  influencersSaved(influencers: Influencer[]): void {
    this.updateSelectionState({ influencers: influencers });
    this.selectedInfuencers.emit(this.selectionState());
  }

  usernamesSaved(val: PlainTextInfluencerUploadData): void {
    this.updateSelectionState({
      plainTextUploadData: val,
    });
    this.selectedInfuencers.emit(this.selectionState());
  }

  directoryInfluencerSaved(influencers: CreatorPoolResponse[]): void {
    const convertedInfluencers = influencers.map((i) =>
      createInfluencerFromCreatorPoolResponse(i),
    );
    this.updateSelectionState({ influencers: convertedInfluencers });
    this.selectedInfuencers.emit(this.selectionState());
  }
}
