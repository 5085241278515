<lefty-dialog
  [hideFooter]="true"
  [(visible)]="visible"
  class="small"
  [autoDismissable]="true"
  [hideCloseButton]="true"
  [hideHeader]="true"
>
  <div class="content">
    <img
      [src]="WARNING_ICON_SVG"
      class="ico-warning"
    />

    <h3
      class="title"
      i18n
    >
      Limit reached
    </h3>
    <p
      i18n
      class="desc"
    >
      You have reached the maximum number of affiliates for your current plan.
      Please contact your account manager to upgrade.
    </p>

    <button
      leftyButton="primary"
      (click)="close()"
    >
      OK
    </button>
  </div>
</lefty-dialog>
