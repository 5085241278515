// @generated by protoc-gen-es v1.10.0 with parameter "target=ts"
// @generated from file librarian/proto/salesforce.proto (package librarian.proto, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, protoInt64, StringValue, Timestamp, UInt32Value } from "@bufbuild/protobuf";
import { CurrencyEnum } from "../../common/proto/common_pb";

/**
 * @generated from message librarian.proto.SalesforceCatalogPb
 */
export class SalesforceCatalogPb extends Message<SalesforceCatalogPb> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: string name = 2;
   */
  name = "";

  /**
   * @generated from field: int64 products_count = 3;
   */
  productsCount = protoInt64.zero;

  /**
   * @generated from field: int64 categories_count = 4;
   */
  categoriesCount = protoInt64.zero;

  constructor(data?: PartialMessage<SalesforceCatalogPb>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.SalesforceCatalogPb";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "products_count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 4, name: "categories_count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SalesforceCatalogPb {
    return new SalesforceCatalogPb().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SalesforceCatalogPb {
    return new SalesforceCatalogPb().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SalesforceCatalogPb {
    return new SalesforceCatalogPb().fromJsonString(jsonString, options);
  }

  static equals(a: SalesforceCatalogPb | PlainMessage<SalesforceCatalogPb> | undefined, b: SalesforceCatalogPb | PlainMessage<SalesforceCatalogPb> | undefined): boolean {
    return proto3.util.equals(SalesforceCatalogPb, a, b);
  }
}

/**
 * @generated from message librarian.proto.SalesforceCategoryPb
 */
export class SalesforceCategoryPb extends Message<SalesforceCategoryPb> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: string name = 2;
   */
  name = "";

  /**
   * @generated from field: repeated librarian.proto.SalesforceCategoryPb subcategories = 3;
   */
  subcategories: SalesforceCategoryPb[] = [];

  constructor(data?: PartialMessage<SalesforceCategoryPb>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.SalesforceCategoryPb";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "subcategories", kind: "message", T: SalesforceCategoryPb, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SalesforceCategoryPb {
    return new SalesforceCategoryPb().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SalesforceCategoryPb {
    return new SalesforceCategoryPb().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SalesforceCategoryPb {
    return new SalesforceCategoryPb().fromJsonString(jsonString, options);
  }

  static equals(a: SalesforceCategoryPb | PlainMessage<SalesforceCategoryPb> | undefined, b: SalesforceCategoryPb | PlainMessage<SalesforceCategoryPb> | undefined): boolean {
    return proto3.util.equals(SalesforceCategoryPb, a, b);
  }
}

/**
 * @generated from message librarian.proto.SalesforceProductPb
 */
export class SalesforceProductPb extends Message<SalesforceProductPb> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: string name = 2;
   */
  name = "";

  /**
   * @generated from field: string description = 3;
   */
  description = "";

  /**
   * @generated from field: repeated librarian.proto.SalesforceProductVariantPb variants = 5;
   */
  variants: SalesforceProductVariantPb[] = [];

  /**
   * @generated from field: google.protobuf.StringValue image_url = 6;
   */
  imageUrl?: string;

  /**
   * @generated from field: repeated string assigned_categories = 7;
   */
  assignedCategories: string[] = [];

  constructor(data?: PartialMessage<SalesforceProductPb>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.SalesforceProductPb";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "variants", kind: "message", T: SalesforceProductVariantPb, repeated: true },
    { no: 6, name: "image_url", kind: "message", T: StringValue },
    { no: 7, name: "assigned_categories", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SalesforceProductPb {
    return new SalesforceProductPb().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SalesforceProductPb {
    return new SalesforceProductPb().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SalesforceProductPb {
    return new SalesforceProductPb().fromJsonString(jsonString, options);
  }

  static equals(a: SalesforceProductPb | PlainMessage<SalesforceProductPb> | undefined, b: SalesforceProductPb | PlainMessage<SalesforceProductPb> | undefined): boolean {
    return proto3.util.equals(SalesforceProductPb, a, b);
  }
}

/**
 * @generated from message librarian.proto.SalesforceProductVariantPb
 */
export class SalesforceProductVariantPb extends Message<SalesforceProductVariantPb> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: string sku = 2;
   */
  sku = "";

  /**
   * @generated from field: int32 quantity = 3;
   */
  quantity = 0;

  /**
   * @generated from field: double price = 4;
   */
  price = 0;

  /**
   * @generated from field: common.proto.CurrencyEnum currency = 5;
   */
  currency = CurrencyEnum.NA;

  /**
   * @generated from field: repeated librarian.proto.SalesforceProductVariantAttributePb attributes = 6;
   */
  attributes: SalesforceProductVariantAttributePb[] = [];

  /**
   * @generated from field: bool already_imported = 7;
   */
  alreadyImported = false;

  /**
   * @generated from field: google.protobuf.StringValue image_url = 8;
   */
  imageUrl?: string;

  constructor(data?: PartialMessage<SalesforceProductVariantPb>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.SalesforceProductVariantPb";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "sku", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "quantity", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "price", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 5, name: "currency", kind: "enum", T: proto3.getEnumType(CurrencyEnum) },
    { no: 6, name: "attributes", kind: "message", T: SalesforceProductVariantAttributePb, repeated: true },
    { no: 7, name: "already_imported", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 8, name: "image_url", kind: "message", T: StringValue },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SalesforceProductVariantPb {
    return new SalesforceProductVariantPb().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SalesforceProductVariantPb {
    return new SalesforceProductVariantPb().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SalesforceProductVariantPb {
    return new SalesforceProductVariantPb().fromJsonString(jsonString, options);
  }

  static equals(a: SalesforceProductVariantPb | PlainMessage<SalesforceProductVariantPb> | undefined, b: SalesforceProductVariantPb | PlainMessage<SalesforceProductVariantPb> | undefined): boolean {
    return proto3.util.equals(SalesforceProductVariantPb, a, b);
  }
}

/**
 * @generated from message librarian.proto.SalesforceProductVariantAttributePb
 */
export class SalesforceProductVariantAttributePb extends Message<SalesforceProductVariantAttributePb> {
  /**
   * @generated from field: string name = 1;
   */
  name = "";

  /**
   * @generated from field: string value = 2;
   */
  value = "";

  constructor(data?: PartialMessage<SalesforceProductVariantAttributePb>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.SalesforceProductVariantAttributePb";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "value", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SalesforceProductVariantAttributePb {
    return new SalesforceProductVariantAttributePb().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SalesforceProductVariantAttributePb {
    return new SalesforceProductVariantAttributePb().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SalesforceProductVariantAttributePb {
    return new SalesforceProductVariantAttributePb().fromJsonString(jsonString, options);
  }

  static equals(a: SalesforceProductVariantAttributePb | PlainMessage<SalesforceProductVariantAttributePb> | undefined, b: SalesforceProductVariantAttributePb | PlainMessage<SalesforceProductVariantAttributePb> | undefined): boolean {
    return proto3.util.equals(SalesforceProductVariantAttributePb, a, b);
  }
}

/**
 * @generated from message librarian.proto.GetSalesforceCatalogsRequest
 */
export class GetSalesforceCatalogsRequest extends Message<GetSalesforceCatalogsRequest> {
  /**
   * @generated from field: int64 salesforce_store_id = 1;
   */
  salesforceStoreId = protoInt64.zero;

  constructor(data?: PartialMessage<GetSalesforceCatalogsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.GetSalesforceCatalogsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "salesforce_store_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetSalesforceCatalogsRequest {
    return new GetSalesforceCatalogsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetSalesforceCatalogsRequest {
    return new GetSalesforceCatalogsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetSalesforceCatalogsRequest {
    return new GetSalesforceCatalogsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetSalesforceCatalogsRequest | PlainMessage<GetSalesforceCatalogsRequest> | undefined, b: GetSalesforceCatalogsRequest | PlainMessage<GetSalesforceCatalogsRequest> | undefined): boolean {
    return proto3.util.equals(GetSalesforceCatalogsRequest, a, b);
  }
}

/**
 * @generated from message librarian.proto.GetSalesforceCatalogsResponse
 */
export class GetSalesforceCatalogsResponse extends Message<GetSalesforceCatalogsResponse> {
  /**
   * @generated from field: string handle = 1;
   */
  handle = "";

  constructor(data?: PartialMessage<GetSalesforceCatalogsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.GetSalesforceCatalogsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "handle", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetSalesforceCatalogsResponse {
    return new GetSalesforceCatalogsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetSalesforceCatalogsResponse {
    return new GetSalesforceCatalogsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetSalesforceCatalogsResponse {
    return new GetSalesforceCatalogsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetSalesforceCatalogsResponse | PlainMessage<GetSalesforceCatalogsResponse> | undefined, b: GetSalesforceCatalogsResponse | PlainMessage<GetSalesforceCatalogsResponse> | undefined): boolean {
    return proto3.util.equals(GetSalesforceCatalogsResponse, a, b);
  }
}

/**
 * @generated from message librarian.proto.GetSalesforceCatalogsTask
 */
export class GetSalesforceCatalogsTask extends Message<GetSalesforceCatalogsTask> {
  /**
   * @generated from field: librarian.proto.GetSalesforceCatalogsRequest request = 1;
   */
  request?: GetSalesforceCatalogsRequest;

  /**
   * @generated from field: int64 user_id = 2;
   */
  userId = protoInt64.zero;

  constructor(data?: PartialMessage<GetSalesforceCatalogsTask>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.GetSalesforceCatalogsTask";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "request", kind: "message", T: GetSalesforceCatalogsRequest },
    { no: 2, name: "user_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetSalesforceCatalogsTask {
    return new GetSalesforceCatalogsTask().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetSalesforceCatalogsTask {
    return new GetSalesforceCatalogsTask().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetSalesforceCatalogsTask {
    return new GetSalesforceCatalogsTask().fromJsonString(jsonString, options);
  }

  static equals(a: GetSalesforceCatalogsTask | PlainMessage<GetSalesforceCatalogsTask> | undefined, b: GetSalesforceCatalogsTask | PlainMessage<GetSalesforceCatalogsTask> | undefined): boolean {
    return proto3.util.equals(GetSalesforceCatalogsTask, a, b);
  }
}

/**
 * @generated from message librarian.proto.GetSalesforceCatalogsTaskOutput
 */
export class GetSalesforceCatalogsTaskOutput extends Message<GetSalesforceCatalogsTaskOutput> {
  /**
   * @generated from field: repeated librarian.proto.SalesforceCatalogPb catalogs = 1;
   */
  catalogs: SalesforceCatalogPb[] = [];

  constructor(data?: PartialMessage<GetSalesforceCatalogsTaskOutput>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.GetSalesforceCatalogsTaskOutput";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "catalogs", kind: "message", T: SalesforceCatalogPb, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetSalesforceCatalogsTaskOutput {
    return new GetSalesforceCatalogsTaskOutput().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetSalesforceCatalogsTaskOutput {
    return new GetSalesforceCatalogsTaskOutput().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetSalesforceCatalogsTaskOutput {
    return new GetSalesforceCatalogsTaskOutput().fromJsonString(jsonString, options);
  }

  static equals(a: GetSalesforceCatalogsTaskOutput | PlainMessage<GetSalesforceCatalogsTaskOutput> | undefined, b: GetSalesforceCatalogsTaskOutput | PlainMessage<GetSalesforceCatalogsTaskOutput> | undefined): boolean {
    return proto3.util.equals(GetSalesforceCatalogsTaskOutput, a, b);
  }
}

/**
 * @generated from message librarian.proto.GetSalesforceCategoriesRequest
 */
export class GetSalesforceCategoriesRequest extends Message<GetSalesforceCategoriesRequest> {
  /**
   * @generated from field: int64 salesforce_store_id = 1;
   */
  salesforceStoreId = protoInt64.zero;

  /**
   * Choose how many levels of categories you want to get (Default value is 3)
   *
   * @generated from field: google.protobuf.UInt32Value levels = 2;
   */
  levels?: number;

  constructor(data?: PartialMessage<GetSalesforceCategoriesRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.GetSalesforceCategoriesRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "salesforce_store_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "levels", kind: "message", T: UInt32Value },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetSalesforceCategoriesRequest {
    return new GetSalesforceCategoriesRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetSalesforceCategoriesRequest {
    return new GetSalesforceCategoriesRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetSalesforceCategoriesRequest {
    return new GetSalesforceCategoriesRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetSalesforceCategoriesRequest | PlainMessage<GetSalesforceCategoriesRequest> | undefined, b: GetSalesforceCategoriesRequest | PlainMessage<GetSalesforceCategoriesRequest> | undefined): boolean {
    return proto3.util.equals(GetSalesforceCategoriesRequest, a, b);
  }
}

/**
 * @generated from message librarian.proto.GetSalesforceCategoriesResponse
 */
export class GetSalesforceCategoriesResponse extends Message<GetSalesforceCategoriesResponse> {
  /**
   * @generated from field: string handle = 1;
   */
  handle = "";

  constructor(data?: PartialMessage<GetSalesforceCategoriesResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.GetSalesforceCategoriesResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "handle", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetSalesforceCategoriesResponse {
    return new GetSalesforceCategoriesResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetSalesforceCategoriesResponse {
    return new GetSalesforceCategoriesResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetSalesforceCategoriesResponse {
    return new GetSalesforceCategoriesResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetSalesforceCategoriesResponse | PlainMessage<GetSalesforceCategoriesResponse> | undefined, b: GetSalesforceCategoriesResponse | PlainMessage<GetSalesforceCategoriesResponse> | undefined): boolean {
    return proto3.util.equals(GetSalesforceCategoriesResponse, a, b);
  }
}

/**
 * @generated from message librarian.proto.GetSalesforceCategoriesTask
 */
export class GetSalesforceCategoriesTask extends Message<GetSalesforceCategoriesTask> {
  /**
   * @generated from field: librarian.proto.GetSalesforceCategoriesRequest request = 1;
   */
  request?: GetSalesforceCategoriesRequest;

  /**
   * @generated from field: int64 user_id = 2;
   */
  userId = protoInt64.zero;

  constructor(data?: PartialMessage<GetSalesforceCategoriesTask>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.GetSalesforceCategoriesTask";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "request", kind: "message", T: GetSalesforceCategoriesRequest },
    { no: 2, name: "user_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetSalesforceCategoriesTask {
    return new GetSalesforceCategoriesTask().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetSalesforceCategoriesTask {
    return new GetSalesforceCategoriesTask().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetSalesforceCategoriesTask {
    return new GetSalesforceCategoriesTask().fromJsonString(jsonString, options);
  }

  static equals(a: GetSalesforceCategoriesTask | PlainMessage<GetSalesforceCategoriesTask> | undefined, b: GetSalesforceCategoriesTask | PlainMessage<GetSalesforceCategoriesTask> | undefined): boolean {
    return proto3.util.equals(GetSalesforceCategoriesTask, a, b);
  }
}

/**
 * @generated from message librarian.proto.GetSalesforceCategoriesTaskOutput
 */
export class GetSalesforceCategoriesTaskOutput extends Message<GetSalesforceCategoriesTaskOutput> {
  /**
   * @generated from field: repeated librarian.proto.SalesforceCategoryPb categories = 1;
   */
  categories: SalesforceCategoryPb[] = [];

  constructor(data?: PartialMessage<GetSalesforceCategoriesTaskOutput>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.GetSalesforceCategoriesTaskOutput";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "categories", kind: "message", T: SalesforceCategoryPb, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetSalesforceCategoriesTaskOutput {
    return new GetSalesforceCategoriesTaskOutput().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetSalesforceCategoriesTaskOutput {
    return new GetSalesforceCategoriesTaskOutput().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetSalesforceCategoriesTaskOutput {
    return new GetSalesforceCategoriesTaskOutput().fromJsonString(jsonString, options);
  }

  static equals(a: GetSalesforceCategoriesTaskOutput | PlainMessage<GetSalesforceCategoriesTaskOutput> | undefined, b: GetSalesforceCategoriesTaskOutput | PlainMessage<GetSalesforceCategoriesTaskOutput> | undefined): boolean {
    return proto3.util.equals(GetSalesforceCategoriesTaskOutput, a, b);
  }
}

/**
 * @generated from message librarian.proto.GetSalesforceProductsRequest
 */
export class GetSalesforceProductsRequest extends Message<GetSalesforceProductsRequest> {
  /**
   * @generated from field: int64 salesforce_store_id = 1;
   */
  salesforceStoreId = protoInt64.zero;

  /**
   * @generated from field: bool force_refresh = 2;
   */
  forceRefresh = false;

  constructor(data?: PartialMessage<GetSalesforceProductsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.GetSalesforceProductsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "salesforce_store_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "force_refresh", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetSalesforceProductsRequest {
    return new GetSalesforceProductsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetSalesforceProductsRequest {
    return new GetSalesforceProductsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetSalesforceProductsRequest {
    return new GetSalesforceProductsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetSalesforceProductsRequest | PlainMessage<GetSalesforceProductsRequest> | undefined, b: GetSalesforceProductsRequest | PlainMessage<GetSalesforceProductsRequest> | undefined): boolean {
    return proto3.util.equals(GetSalesforceProductsRequest, a, b);
  }
}

/**
 * @generated from message librarian.proto.GetSalesforceProductsFilter
 */
export class GetSalesforceProductsFilter extends Message<GetSalesforceProductsFilter> {
  /**
   * @generated from field: repeated string category_ids = 2;
   */
  categoryIds: string[] = [];

  constructor(data?: PartialMessage<GetSalesforceProductsFilter>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.GetSalesforceProductsFilter";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 2, name: "category_ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetSalesforceProductsFilter {
    return new GetSalesforceProductsFilter().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetSalesforceProductsFilter {
    return new GetSalesforceProductsFilter().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetSalesforceProductsFilter {
    return new GetSalesforceProductsFilter().fromJsonString(jsonString, options);
  }

  static equals(a: GetSalesforceProductsFilter | PlainMessage<GetSalesforceProductsFilter> | undefined, b: GetSalesforceProductsFilter | PlainMessage<GetSalesforceProductsFilter> | undefined): boolean {
    return proto3.util.equals(GetSalesforceProductsFilter, a, b);
  }
}

/**
 * @generated from message librarian.proto.GetSalesforceProductsResponse
 */
export class GetSalesforceProductsResponse extends Message<GetSalesforceProductsResponse> {
  /**
   * @generated from field: string handle = 1;
   */
  handle = "";

  constructor(data?: PartialMessage<GetSalesforceProductsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.GetSalesforceProductsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "handle", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetSalesforceProductsResponse {
    return new GetSalesforceProductsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetSalesforceProductsResponse {
    return new GetSalesforceProductsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetSalesforceProductsResponse {
    return new GetSalesforceProductsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetSalesforceProductsResponse | PlainMessage<GetSalesforceProductsResponse> | undefined, b: GetSalesforceProductsResponse | PlainMessage<GetSalesforceProductsResponse> | undefined): boolean {
    return proto3.util.equals(GetSalesforceProductsResponse, a, b);
  }
}

/**
 * @generated from message librarian.proto.GetSalesforceProductsTask
 */
export class GetSalesforceProductsTask extends Message<GetSalesforceProductsTask> {
  /**
   * @generated from field: librarian.proto.GetSalesforceProductsRequest request = 1;
   */
  request?: GetSalesforceProductsRequest;

  /**
   * @generated from field: int64 user_id = 2;
   */
  userId = protoInt64.zero;

  constructor(data?: PartialMessage<GetSalesforceProductsTask>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.GetSalesforceProductsTask";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "request", kind: "message", T: GetSalesforceProductsRequest },
    { no: 2, name: "user_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetSalesforceProductsTask {
    return new GetSalesforceProductsTask().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetSalesforceProductsTask {
    return new GetSalesforceProductsTask().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetSalesforceProductsTask {
    return new GetSalesforceProductsTask().fromJsonString(jsonString, options);
  }

  static equals(a: GetSalesforceProductsTask | PlainMessage<GetSalesforceProductsTask> | undefined, b: GetSalesforceProductsTask | PlainMessage<GetSalesforceProductsTask> | undefined): boolean {
    return proto3.util.equals(GetSalesforceProductsTask, a, b);
  }
}

/**
 * @generated from message librarian.proto.GetSalesforceProductsTaskOutput
 */
export class GetSalesforceProductsTaskOutput extends Message<GetSalesforceProductsTaskOutput> {
  /**
   * @generated from field: repeated librarian.proto.SalesforceProductPb products = 1;
   */
  products: SalesforceProductPb[] = [];

  /**
   * import date in UTC
   *
   * @generated from field: google.protobuf.Timestamp import_date = 2;
   */
  importDate?: Timestamp;

  constructor(data?: PartialMessage<GetSalesforceProductsTaskOutput>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.GetSalesforceProductsTaskOutput";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "products", kind: "message", T: SalesforceProductPb, repeated: true },
    { no: 2, name: "import_date", kind: "message", T: Timestamp },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetSalesforceProductsTaskOutput {
    return new GetSalesforceProductsTaskOutput().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetSalesforceProductsTaskOutput {
    return new GetSalesforceProductsTaskOutput().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetSalesforceProductsTaskOutput {
    return new GetSalesforceProductsTaskOutput().fromJsonString(jsonString, options);
  }

  static equals(a: GetSalesforceProductsTaskOutput | PlainMessage<GetSalesforceProductsTaskOutput> | undefined, b: GetSalesforceProductsTaskOutput | PlainMessage<GetSalesforceProductsTaskOutput> | undefined): boolean {
    return proto3.util.equals(GetSalesforceProductsTaskOutput, a, b);
  }
}

/**
 * @generated from message librarian.proto.GetAvailableSalesforceProductsRequest
 */
export class GetAvailableSalesforceProductsRequest extends Message<GetAvailableSalesforceProductsRequest> {
  /**
   * @generated from field: int64 salesforce_store_id = 1;
   */
  salesforceStoreId = protoInt64.zero;

  /**
   * @generated from field: librarian.proto.GetSalesforceProductsFilter filter = 2;
   */
  filter?: GetSalesforceProductsFilter;

  constructor(data?: PartialMessage<GetAvailableSalesforceProductsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.GetAvailableSalesforceProductsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "salesforce_store_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "filter", kind: "message", T: GetSalesforceProductsFilter },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetAvailableSalesforceProductsRequest {
    return new GetAvailableSalesforceProductsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetAvailableSalesforceProductsRequest {
    return new GetAvailableSalesforceProductsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetAvailableSalesforceProductsRequest {
    return new GetAvailableSalesforceProductsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetAvailableSalesforceProductsRequest | PlainMessage<GetAvailableSalesforceProductsRequest> | undefined, b: GetAvailableSalesforceProductsRequest | PlainMessage<GetAvailableSalesforceProductsRequest> | undefined): boolean {
    return proto3.util.equals(GetAvailableSalesforceProductsRequest, a, b);
  }
}

/**
 * @generated from message librarian.proto.GetAvailableSalesforceProductsResponse
 */
export class GetAvailableSalesforceProductsResponse extends Message<GetAvailableSalesforceProductsResponse> {
  /**
   * @generated from field: repeated librarian.proto.SalesforceProductPb products = 1;
   */
  products: SalesforceProductPb[] = [];

  /**
   * import date in UTC
   *
   * @generated from field: google.protobuf.Timestamp import_date = 2;
   */
  importDate?: Timestamp;

  constructor(data?: PartialMessage<GetAvailableSalesforceProductsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.GetAvailableSalesforceProductsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "products", kind: "message", T: SalesforceProductPb, repeated: true },
    { no: 2, name: "import_date", kind: "message", T: Timestamp },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetAvailableSalesforceProductsResponse {
    return new GetAvailableSalesforceProductsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetAvailableSalesforceProductsResponse {
    return new GetAvailableSalesforceProductsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetAvailableSalesforceProductsResponse {
    return new GetAvailableSalesforceProductsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetAvailableSalesforceProductsResponse | PlainMessage<GetAvailableSalesforceProductsResponse> | undefined, b: GetAvailableSalesforceProductsResponse | PlainMessage<GetAvailableSalesforceProductsResponse> | undefined): boolean {
    return proto3.util.equals(GetAvailableSalesforceProductsResponse, a, b);
  }
}

/**
 * @generated from message librarian.proto.ImportSalesforceProductsRequest
 */
export class ImportSalesforceProductsRequest extends Message<ImportSalesforceProductsRequest> {
  /**
   * @generated from field: repeated librarian.proto.SalesforceProductPb products = 1;
   */
  products: SalesforceProductPb[] = [];

  /**
   * @generated from field: int64 salesforce_store_id = 2;
   */
  salesforceStoreId = protoInt64.zero;

  constructor(data?: PartialMessage<ImportSalesforceProductsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.ImportSalesforceProductsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "products", kind: "message", T: SalesforceProductPb, repeated: true },
    { no: 2, name: "salesforce_store_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ImportSalesforceProductsRequest {
    return new ImportSalesforceProductsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ImportSalesforceProductsRequest {
    return new ImportSalesforceProductsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ImportSalesforceProductsRequest {
    return new ImportSalesforceProductsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ImportSalesforceProductsRequest | PlainMessage<ImportSalesforceProductsRequest> | undefined, b: ImportSalesforceProductsRequest | PlainMessage<ImportSalesforceProductsRequest> | undefined): boolean {
    return proto3.util.equals(ImportSalesforceProductsRequest, a, b);
  }
}

/**
 * @generated from message librarian.proto.ImportSalesforceProductsTask
 */
export class ImportSalesforceProductsTask extends Message<ImportSalesforceProductsTask> {
  /**
   * @generated from field: int64 user_id = 1;
   */
  userId = protoInt64.zero;

  /**
   * @generated from field: librarian.proto.ImportSalesforceProductsRequest request = 2;
   */
  request?: ImportSalesforceProductsRequest;

  constructor(data?: PartialMessage<ImportSalesforceProductsTask>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.ImportSalesforceProductsTask";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "user_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "request", kind: "message", T: ImportSalesforceProductsRequest },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ImportSalesforceProductsTask {
    return new ImportSalesforceProductsTask().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ImportSalesforceProductsTask {
    return new ImportSalesforceProductsTask().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ImportSalesforceProductsTask {
    return new ImportSalesforceProductsTask().fromJsonString(jsonString, options);
  }

  static equals(a: ImportSalesforceProductsTask | PlainMessage<ImportSalesforceProductsTask> | undefined, b: ImportSalesforceProductsTask | PlainMessage<ImportSalesforceProductsTask> | undefined): boolean {
    return proto3.util.equals(ImportSalesforceProductsTask, a, b);
  }
}

/**
 * @generated from message librarian.proto.ImportSalesforceProductsTaskOutput
 */
export class ImportSalesforceProductsTaskOutput extends Message<ImportSalesforceProductsTaskOutput> {
  /**
   * @generated from field: int32 products_imported_count = 1;
   */
  productsImportedCount = 0;

  /**
   * @generated from field: repeated librarian.proto.ImportSalesforceProductsTaskOutput.ImportSalesforceProductsTaskError errors = 2;
   */
  errors: ImportSalesforceProductsTaskOutput_ImportSalesforceProductsTaskError[] = [];

  constructor(data?: PartialMessage<ImportSalesforceProductsTaskOutput>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.ImportSalesforceProductsTaskOutput";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "products_imported_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "errors", kind: "message", T: ImportSalesforceProductsTaskOutput_ImportSalesforceProductsTaskError, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ImportSalesforceProductsTaskOutput {
    return new ImportSalesforceProductsTaskOutput().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ImportSalesforceProductsTaskOutput {
    return new ImportSalesforceProductsTaskOutput().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ImportSalesforceProductsTaskOutput {
    return new ImportSalesforceProductsTaskOutput().fromJsonString(jsonString, options);
  }

  static equals(a: ImportSalesforceProductsTaskOutput | PlainMessage<ImportSalesforceProductsTaskOutput> | undefined, b: ImportSalesforceProductsTaskOutput | PlainMessage<ImportSalesforceProductsTaskOutput> | undefined): boolean {
    return proto3.util.equals(ImportSalesforceProductsTaskOutput, a, b);
  }
}

/**
 * @generated from message librarian.proto.ImportSalesforceProductsTaskOutput.ImportSalesforceProductsTaskError
 */
export class ImportSalesforceProductsTaskOutput_ImportSalesforceProductsTaskError extends Message<ImportSalesforceProductsTaskOutput_ImportSalesforceProductsTaskError> {
  /**
   * @generated from field: string external_product_id = 1;
   */
  externalProductId = "";

  /**
   * @generated from field: string error = 2;
   */
  error = "";

  constructor(data?: PartialMessage<ImportSalesforceProductsTaskOutput_ImportSalesforceProductsTaskError>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.ImportSalesforceProductsTaskOutput.ImportSalesforceProductsTaskError";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "external_product_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "error", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ImportSalesforceProductsTaskOutput_ImportSalesforceProductsTaskError {
    return new ImportSalesforceProductsTaskOutput_ImportSalesforceProductsTaskError().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ImportSalesforceProductsTaskOutput_ImportSalesforceProductsTaskError {
    return new ImportSalesforceProductsTaskOutput_ImportSalesforceProductsTaskError().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ImportSalesforceProductsTaskOutput_ImportSalesforceProductsTaskError {
    return new ImportSalesforceProductsTaskOutput_ImportSalesforceProductsTaskError().fromJsonString(jsonString, options);
  }

  static equals(a: ImportSalesforceProductsTaskOutput_ImportSalesforceProductsTaskError | PlainMessage<ImportSalesforceProductsTaskOutput_ImportSalesforceProductsTaskError> | undefined, b: ImportSalesforceProductsTaskOutput_ImportSalesforceProductsTaskError | PlainMessage<ImportSalesforceProductsTaskOutput_ImportSalesforceProductsTaskError> | undefined): boolean {
    return proto3.util.equals(ImportSalesforceProductsTaskOutput_ImportSalesforceProductsTaskError, a, b);
  }
}

/**
 * @generated from message librarian.proto.ImportSalesforceProductsResponse
 */
export class ImportSalesforceProductsResponse extends Message<ImportSalesforceProductsResponse> {
  /**
   * @generated from field: string task_handle = 1;
   */
  taskHandle = "";

  constructor(data?: PartialMessage<ImportSalesforceProductsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.ImportSalesforceProductsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "task_handle", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ImportSalesforceProductsResponse {
    return new ImportSalesforceProductsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ImportSalesforceProductsResponse {
    return new ImportSalesforceProductsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ImportSalesforceProductsResponse {
    return new ImportSalesforceProductsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ImportSalesforceProductsResponse | PlainMessage<ImportSalesforceProductsResponse> | undefined, b: ImportSalesforceProductsResponse | PlainMessage<ImportSalesforceProductsResponse> | undefined): boolean {
    return proto3.util.equals(ImportSalesforceProductsResponse, a, b);
  }
}

