// @generated by protoc-gen-es v1.10.0 with parameter "target=ts"
// @generated from file librarian/proto/epson.proto (package epson, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3 } from "@bufbuild/protobuf";
import { CollaboratorCastingInfluencersFilter, DynamicFilter, TimeRestriction } from "./common_pb";
import { ReportTimeStep } from "./reports_pb";

/**
 * @generated from enum epson.PrintFormat
 */
export enum PrintFormat {
  /**
   * @generated from enum value: PRINT_FORMAT_UNDEFINED = 0;
   */
  UNDEFINED = 0,

  /**
   * @generated from enum value: PRINT_FORMAT_PDF = 1;
   */
  PDF = 1,

  /**
   * @generated from enum value: PRINT_FORMAT_PPTX = 2;
   */
  PPTX = 2,
}
// Retrieve enum metadata with: proto3.getEnumType(PrintFormat)
proto3.util.setEnumType(PrintFormat, "epson.PrintFormat", [
  { no: 0, name: "PRINT_FORMAT_UNDEFINED" },
  { no: 1, name: "PRINT_FORMAT_PDF" },
  { no: 2, name: "PRINT_FORMAT_PPTX" },
]);

/**
 * @generated from message epson.PrintCredentials
 */
export class PrintCredentials extends Message<PrintCredentials> {
  /**
   * use email to store PDF in correct bucket
   *
   * @generated from field: string email = 1;
   */
  email = "";

  /**
   * cookie we will use to make request on the Epson APP
   *
   * @generated from field: string cookie = 2;
   */
  cookie = "";

  /**
   * @generated from field: int32 space_hint = 3;
   */
  spaceHint = 0;

  constructor(data?: PartialMessage<PrintCredentials>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "epson.PrintCredentials";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "cookie", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "space_hint", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): PrintCredentials {
    return new PrintCredentials().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): PrintCredentials {
    return new PrintCredentials().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): PrintCredentials {
    return new PrintCredentials().fromJsonString(jsonString, options);
  }

  static equals(a: PrintCredentials | PlainMessage<PrintCredentials> | undefined, b: PrintCredentials | PlainMessage<PrintCredentials> | undefined): boolean {
    return proto3.util.equals(PrintCredentials, a, b);
  }
}

/**
 * @generated from message epson.PrintOptions
 */
export class PrintOptions extends Message<PrintOptions> {
  /**
   * by default the Epson Service 
   * will target the Epson App hosted on https://print.lefty.io
   *
   * @generated from field: string target_host = 1;
   */
  targetHost = "";

  /**
   * @generated from field: epson.PrintFormat print_format = 2;
   */
  printFormat = PrintFormat.UNDEFINED;

  constructor(data?: PartialMessage<PrintOptions>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "epson.PrintOptions";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "target_host", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "print_format", kind: "enum", T: proto3.getEnumType(PrintFormat) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): PrintOptions {
    return new PrintOptions().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): PrintOptions {
    return new PrintOptions().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): PrintOptions {
    return new PrintOptions().fromJsonString(jsonString, options);
  }

  static equals(a: PrintOptions | PlainMessage<PrintOptions> | undefined, b: PrintOptions | PlainMessage<PrintOptions> | undefined): boolean {
    return proto3.util.equals(PrintOptions, a, b);
  }
}

/**
 * @generated from message epson.PrintInfluencerReq
 */
export class PrintInfluencerReq extends Message<PrintInfluencerReq> {
  /**
   * @generated from field: epson.PrintCredentials credentials = 1;
   */
  credentials?: PrintCredentials;

  /**
   * @generated from field: epson.PrintOptions options = 2;
   */
  options?: PrintOptions;

  /**
   * @generated from field: string influencer_id = 3;
   */
  influencerId = "";

  constructor(data?: PartialMessage<PrintInfluencerReq>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "epson.PrintInfluencerReq";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "credentials", kind: "message", T: PrintCredentials },
    { no: 2, name: "options", kind: "message", T: PrintOptions },
    { no: 3, name: "influencer_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): PrintInfluencerReq {
    return new PrintInfluencerReq().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): PrintInfluencerReq {
    return new PrintInfluencerReq().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): PrintInfluencerReq {
    return new PrintInfluencerReq().fromJsonString(jsonString, options);
  }

  static equals(a: PrintInfluencerReq | PlainMessage<PrintInfluencerReq> | undefined, b: PrintInfluencerReq | PlainMessage<PrintInfluencerReq> | undefined): boolean {
    return proto3.util.equals(PrintInfluencerReq, a, b);
  }
}

/**
 * @generated from message epson.PrintDirectoryReportFilter
 */
export class PrintDirectoryReportFilter extends Message<PrintDirectoryReportFilter> {
  /**
   * @generated from field: librarian.proto.TimeRestriction time_restriction = 1;
   */
  timeRestriction?: TimeRestriction;

  /**
   * @generated from field: librarian.proto.DynamicFilter dynamic_filter = 2;
   */
  dynamicFilter?: DynamicFilter;

  /**
   * @generated from field: librarian.proto.ReportTimeStep time_step = 3;
   */
  timeStep = ReportTimeStep.REPORT_STEP_AUTO;

  constructor(data?: PartialMessage<PrintDirectoryReportFilter>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "epson.PrintDirectoryReportFilter";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "time_restriction", kind: "message", T: TimeRestriction },
    { no: 2, name: "dynamic_filter", kind: "message", T: DynamicFilter },
    { no: 3, name: "time_step", kind: "enum", T: proto3.getEnumType(ReportTimeStep) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): PrintDirectoryReportFilter {
    return new PrintDirectoryReportFilter().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): PrintDirectoryReportFilter {
    return new PrintDirectoryReportFilter().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): PrintDirectoryReportFilter {
    return new PrintDirectoryReportFilter().fromJsonString(jsonString, options);
  }

  static equals(a: PrintDirectoryReportFilter | PlainMessage<PrintDirectoryReportFilter> | undefined, b: PrintDirectoryReportFilter | PlainMessage<PrintDirectoryReportFilter> | undefined): boolean {
    return proto3.util.equals(PrintDirectoryReportFilter, a, b);
  }
}

/**
 * @generated from message epson.PrintDirectoryReportReq
 */
export class PrintDirectoryReportReq extends Message<PrintDirectoryReportReq> {
  /**
   * @generated from field: epson.PrintCredentials credentials = 1;
   */
  credentials?: PrintCredentials;

  /**
   * @generated from field: epson.PrintOptions options = 2;
   */
  options?: PrintOptions;

  /**
   * @generated from field: epson.PrintDirectoryReportFilter filter = 3;
   */
  filter?: PrintDirectoryReportFilter;

  constructor(data?: PartialMessage<PrintDirectoryReportReq>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "epson.PrintDirectoryReportReq";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "credentials", kind: "message", T: PrintCredentials },
    { no: 2, name: "options", kind: "message", T: PrintOptions },
    { no: 3, name: "filter", kind: "message", T: PrintDirectoryReportFilter },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): PrintDirectoryReportReq {
    return new PrintDirectoryReportReq().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): PrintDirectoryReportReq {
    return new PrintDirectoryReportReq().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): PrintDirectoryReportReq {
    return new PrintDirectoryReportReq().fromJsonString(jsonString, options);
  }

  static equals(a: PrintDirectoryReportReq | PlainMessage<PrintDirectoryReportReq> | undefined, b: PrintDirectoryReportReq | PlainMessage<PrintDirectoryReportReq> | undefined): boolean {
    return proto3.util.equals(PrintDirectoryReportReq, a, b);
  }
}

/**
 * @generated from message epson.PrintCastingReq
 */
export class PrintCastingReq extends Message<PrintCastingReq> {
  /**
   * @generated from field: string casting_token = 1;
   */
  castingToken = "";

  /**
   * @generated from field: epson.PrintOptions options = 2;
   */
  options?: PrintOptions;

  /**
   * @generated from field: librarian.proto.CollaboratorCastingInfluencersFilter filters = 4;
   */
  filters?: CollaboratorCastingInfluencersFilter;

  constructor(data?: PartialMessage<PrintCastingReq>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "epson.PrintCastingReq";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "casting_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "options", kind: "message", T: PrintOptions },
    { no: 4, name: "filters", kind: "message", T: CollaboratorCastingInfluencersFilter },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): PrintCastingReq {
    return new PrintCastingReq().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): PrintCastingReq {
    return new PrintCastingReq().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): PrintCastingReq {
    return new PrintCastingReq().fromJsonString(jsonString, options);
  }

  static equals(a: PrintCastingReq | PlainMessage<PrintCastingReq> | undefined, b: PrintCastingReq | PlainMessage<PrintCastingReq> | undefined): boolean {
    return proto3.util.equals(PrintCastingReq, a, b);
  }
}

/**
 * This message is published to tube EPSON and then polled by java, switch on the oneof and java calls
 * the corresponding service above.
 *
 * @generated from message epson.PDFGenTask
 */
export class PDFGenTask extends Message<PDFGenTask> {
  /**
   * @generated from oneof epson.PDFGenTask.the_task
   */
  theTask: {
    /**
     * @generated from field: epson.PrintDirectoryReportReq directory_report = 1;
     */
    value: PrintDirectoryReportReq;
    case: "directoryReport";
  } | {
    /**
     * @generated from field: epson.PrintCastingReq casting = 2;
     */
    value: PrintCastingReq;
    case: "casting";
  } | {
    /**
     * @generated from field: epson.PrintCastingReq forecast = 3;
     */
    value: PrintCastingReq;
    case: "forecast";
  } | { case: undefined; value?: undefined } = { case: undefined };

  constructor(data?: PartialMessage<PDFGenTask>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "epson.PDFGenTask";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "directory_report", kind: "message", T: PrintDirectoryReportReq, oneof: "the_task" },
    { no: 2, name: "casting", kind: "message", T: PrintCastingReq, oneof: "the_task" },
    { no: 3, name: "forecast", kind: "message", T: PrintCastingReq, oneof: "the_task" },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): PDFGenTask {
    return new PDFGenTask().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): PDFGenTask {
    return new PDFGenTask().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): PDFGenTask {
    return new PDFGenTask().fromJsonString(jsonString, options);
  }

  static equals(a: PDFGenTask | PlainMessage<PDFGenTask> | undefined, b: PDFGenTask | PlainMessage<PDFGenTask> | undefined): boolean {
    return proto3.util.equals(PDFGenTask, a, b);
  }
}

