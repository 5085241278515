import {
  AffiliationProgramCommissionType,
  Program,
} from '@frontend2/proto/librarian/proto/affiliation_pb';
import { LeftyFormat } from '../../format';
import { formatDuration } from '../../times';
import { isNil } from '../../utils/common.helpers';

export function isGhostAffiliationProgram(program: Program): boolean {
  return isNil(program.programId) || program.programId === BigInt(0);
}

export function readableCookieDuration(cookieDurationMinutes: number): string {
  return formatDuration(cookieDurationMinutes * 60, true);
}

export function readableProgramCommission(program: Program): string {
  switch (program.commissionType) {
    case AffiliationProgramCommissionType.COMMISSION_NONE:
      return $localize`None`;
    case AffiliationProgramCommissionType.COMMISSION_PERCENTAGE:
      return LeftyFormat.percent((program.commissionValue ?? 0) / 100, {
        maximumFractionDigits: 1,
      });
    case AffiliationProgramCommissionType.COMMISSION_FLAT_FEE:
      return LeftyFormat.currency(program.commissionValue ?? 0, {
        currency: program.currency,
      });
  }
}
export function readableAffiliationProgramCommissionType(
  type: AffiliationProgramCommissionType,
): string {
  switch (type) {
    case AffiliationProgramCommissionType.COMMISSION_NONE:
      return $localize`None`;
    case AffiliationProgramCommissionType.COMMISSION_PERCENTAGE:
      return $localize`Percentage`;
    case AffiliationProgramCommissionType.COMMISSION_FLAT_FEE:
      return $localize`Flat Fee`;
  }
}
