// @generated by protoc-gen-es v1.10.0 with parameter "target=ts"
// @generated from file librarian/proto/ecommerce_stores.proto (package librarian.proto, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { BoolValue, Duration, Message, proto3, protoInt64, StringValue, Timestamp } from "@bufbuild/protobuf";
import { CurrencyEnum } from "../../common/proto/common_pb";

/**
 * @generated from enum librarian.proto.EcommerceStoreType
 */
export enum EcommerceStoreType {
  /**
   * @generated from enum value: ECOMMERCE_STORE_TYPE_UNKNOWN = 0;
   */
  UNKNOWN = 0,

  /**
   * @generated from enum value: ECOMMERCE_STORE_TYPE_MANUAL = 1;
   */
  MANUAL = 1,

  /**
   * @generated from enum value: ECOMMERCE_STORE_TYPE_SHOPIFY = 2;
   */
  SHOPIFY = 2,

  /**
   * @generated from enum value: ECOMMERCE_STORE_TYPE_SALESFORCE = 3;
   */
  SALESFORCE = 3,

  /**
   * @generated from enum value: ECOMMERCE_STORE_TYPE_MAGENTO = 4;
   */
  MAGENTO = 4,
}
// Retrieve enum metadata with: proto3.getEnumType(EcommerceStoreType)
proto3.util.setEnumType(EcommerceStoreType, "librarian.proto.EcommerceStoreType", [
  { no: 0, name: "ECOMMERCE_STORE_TYPE_UNKNOWN" },
  { no: 1, name: "ECOMMERCE_STORE_TYPE_MANUAL" },
  { no: 2, name: "ECOMMERCE_STORE_TYPE_SHOPIFY" },
  { no: 3, name: "ECOMMERCE_STORE_TYPE_SALESFORCE" },
  { no: 4, name: "ECOMMERCE_STORE_TYPE_MAGENTO" },
]);

/**
 * @generated from message librarian.proto.ManualStorePb
 */
export class ManualStorePb extends Message<ManualStorePb> {
  /**
   * @generated from field: int64 id = 1;
   */
  id = protoInt64.zero;

  /**
   * @generated from field: string name = 2;
   */
  name = "";

  /**
   * @generated from field: common.proto.CurrencyEnum currency = 3;
   */
  currency = CurrencyEnum.NA;

  /**
   * @generated from field: google.protobuf.StringValue domain = 4;
   */
  domain?: string;

  /**
   * @generated from field: bool archived = 5;
   */
  archived = false;

  /**
   * @generated from field: google.protobuf.Timestamp created_at = 6;
   */
  createdAt?: Timestamp;

  constructor(data?: PartialMessage<ManualStorePb>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.ManualStorePb";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "currency", kind: "enum", T: proto3.getEnumType(CurrencyEnum) },
    { no: 4, name: "domain", kind: "message", T: StringValue },
    { no: 5, name: "archived", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 6, name: "created_at", kind: "message", T: Timestamp },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ManualStorePb {
    return new ManualStorePb().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ManualStorePb {
    return new ManualStorePb().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ManualStorePb {
    return new ManualStorePb().fromJsonString(jsonString, options);
  }

  static equals(a: ManualStorePb | PlainMessage<ManualStorePb> | undefined, b: ManualStorePb | PlainMessage<ManualStorePb> | undefined): boolean {
    return proto3.util.equals(ManualStorePb, a, b);
  }
}

/**
 * @generated from message librarian.proto.ShopifyStorePbV2
 */
export class ShopifyStorePbV2 extends Message<ShopifyStorePbV2> {
  /**
   * @generated from field: int64 id = 1;
   */
  id = protoInt64.zero;

  /**
   * @generated from field: string domain = 2;
   */
  domain = "";

  /**
   * @generated from field: bool has_access = 3;
   */
  hasAccess = false;

  /**
   * @generated from field: google.protobuf.StringValue name = 4;
   */
  name?: string;

  /**
   * @generated from field: common.proto.CurrencyEnum currency = 5;
   */
  currency = CurrencyEnum.NA;

  /**
   * @generated from field: bool archived = 6;
   */
  archived = false;

  /**
   * @generated from field: bool connected = 7;
   */
  connected = false;

  /**
   * @generated from field: google.protobuf.Timestamp created_at = 8;
   */
  createdAt?: Timestamp;

  /**
   * @generated from field: librarian.proto.EcommerceStoreUserSettingsPb settings = 9;
   */
  settings?: EcommerceStoreUserSettingsPb;

  /**
   * @generated from field: google.protobuf.Duration timezone_offset = 10;
   */
  timezoneOffset?: Duration;

  constructor(data?: PartialMessage<ShopifyStorePbV2>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.ShopifyStorePbV2";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "domain", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "has_access", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 4, name: "name", kind: "message", T: StringValue },
    { no: 5, name: "currency", kind: "enum", T: proto3.getEnumType(CurrencyEnum) },
    { no: 6, name: "archived", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 7, name: "connected", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 8, name: "created_at", kind: "message", T: Timestamp },
    { no: 9, name: "settings", kind: "message", T: EcommerceStoreUserSettingsPb },
    { no: 10, name: "timezone_offset", kind: "message", T: Duration },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ShopifyStorePbV2 {
    return new ShopifyStorePbV2().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ShopifyStorePbV2 {
    return new ShopifyStorePbV2().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ShopifyStorePbV2 {
    return new ShopifyStorePbV2().fromJsonString(jsonString, options);
  }

  static equals(a: ShopifyStorePbV2 | PlainMessage<ShopifyStorePbV2> | undefined, b: ShopifyStorePbV2 | PlainMessage<ShopifyStorePbV2> | undefined): boolean {
    return proto3.util.equals(ShopifyStorePbV2, a, b);
  }
}

/**
 * @generated from message librarian.proto.SalesforceStorePb
 */
export class SalesforceStorePb extends Message<SalesforceStorePb> {
  /**
   * @generated from field: int64 id = 1;
   */
  id = protoInt64.zero;

  /**
   * @generated from field: string domain = 2;
   */
  domain = "";

  /**
   * @generated from field: string site_id = 3;
   */
  siteId = "";

  /**
   * @generated from field: google.protobuf.StringValue name = 4;
   */
  name?: string;

  /**
   * @generated from field: common.proto.CurrencyEnum currency = 5;
   */
  currency = CurrencyEnum.NA;

  /**
   * @generated from field: google.protobuf.Timestamp created_at = 6;
   */
  createdAt?: Timestamp;

  /**
   * @generated from field: bool archived = 7;
   */
  archived = false;

  /**
   * @generated from field: librarian.proto.EcommerceStoreUserSettingsPb settings = 8;
   */
  settings?: EcommerceStoreUserSettingsPb;

  constructor(data?: PartialMessage<SalesforceStorePb>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.SalesforceStorePb";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "domain", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "site_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "name", kind: "message", T: StringValue },
    { no: 5, name: "currency", kind: "enum", T: proto3.getEnumType(CurrencyEnum) },
    { no: 6, name: "created_at", kind: "message", T: Timestamp },
    { no: 7, name: "archived", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 8, name: "settings", kind: "message", T: EcommerceStoreUserSettingsPb },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SalesforceStorePb {
    return new SalesforceStorePb().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SalesforceStorePb {
    return new SalesforceStorePb().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SalesforceStorePb {
    return new SalesforceStorePb().fromJsonString(jsonString, options);
  }

  static equals(a: SalesforceStorePb | PlainMessage<SalesforceStorePb> | undefined, b: SalesforceStorePb | PlainMessage<SalesforceStorePb> | undefined): boolean {
    return proto3.util.equals(SalesforceStorePb, a, b);
  }
}

/**
 * @generated from message librarian.proto.MagentoStorePb
 */
export class MagentoStorePb extends Message<MagentoStorePb> {
  /**
   * @generated from field: int64 id = 1;
   */
  id = protoInt64.zero;

  /**
   * @generated from field: string domain = 2;
   */
  domain = "";

  /**
   * @generated from field: string name = 3;
   */
  name = "";

  /**
   * @generated from field: common.proto.CurrencyEnum currency = 4;
   */
  currency = CurrencyEnum.NA;

  /**
   * @generated from field: google.protobuf.Timestamp created_at = 5;
   */
  createdAt?: Timestamp;

  /**
   * @generated from field: bool archived = 6;
   */
  archived = false;

  constructor(data?: PartialMessage<MagentoStorePb>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.MagentoStorePb";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "domain", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "currency", kind: "enum", T: proto3.getEnumType(CurrencyEnum) },
    { no: 5, name: "created_at", kind: "message", T: Timestamp },
    { no: 6, name: "archived", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): MagentoStorePb {
    return new MagentoStorePb().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): MagentoStorePb {
    return new MagentoStorePb().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): MagentoStorePb {
    return new MagentoStorePb().fromJsonString(jsonString, options);
  }

  static equals(a: MagentoStorePb | PlainMessage<MagentoStorePb> | undefined, b: MagentoStorePb | PlainMessage<MagentoStorePb> | undefined): boolean {
    return proto3.util.equals(MagentoStorePb, a, b);
  }
}

/**
 * @generated from message librarian.proto.EcommerceStorePb
 */
export class EcommerceStorePb extends Message<EcommerceStorePb> {
  /**
   * @generated from oneof librarian.proto.EcommerceStorePb.store
   */
  store: {
    /**
     * @generated from field: librarian.proto.ShopifyStorePbV2 shopify_store = 1;
     */
    value: ShopifyStorePbV2;
    case: "shopifyStore";
  } | {
    /**
     * @generated from field: librarian.proto.ManualStorePb manual_store = 2;
     */
    value: ManualStorePb;
    case: "manualStore";
  } | {
    /**
     * @generated from field: librarian.proto.SalesforceStorePb salesforce_store = 3;
     */
    value: SalesforceStorePb;
    case: "salesforceStore";
  } | {
    /**
     * @generated from field: librarian.proto.MagentoStorePb magento_store = 4;
     */
    value: MagentoStorePb;
    case: "magentoStore";
  } | { case: undefined; value?: undefined } = { case: undefined };

  constructor(data?: PartialMessage<EcommerceStorePb>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.EcommerceStorePb";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "shopify_store", kind: "message", T: ShopifyStorePbV2, oneof: "store" },
    { no: 2, name: "manual_store", kind: "message", T: ManualStorePb, oneof: "store" },
    { no: 3, name: "salesforce_store", kind: "message", T: SalesforceStorePb, oneof: "store" },
    { no: 4, name: "magento_store", kind: "message", T: MagentoStorePb, oneof: "store" },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): EcommerceStorePb {
    return new EcommerceStorePb().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): EcommerceStorePb {
    return new EcommerceStorePb().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): EcommerceStorePb {
    return new EcommerceStorePb().fromJsonString(jsonString, options);
  }

  static equals(a: EcommerceStorePb | PlainMessage<EcommerceStorePb> | undefined, b: EcommerceStorePb | PlainMessage<EcommerceStorePb> | undefined): boolean {
    return proto3.util.equals(EcommerceStorePb, a, b);
  }
}

/**
 * @generated from message librarian.proto.EcommerceStoreUserSettingsPb
 */
export class EcommerceStoreUserSettingsPb extends Message<EcommerceStoreUserSettingsPb> {
  /**
   * @generated from field: repeated string shipping_methods = 2;
   */
  shippingMethods: string[] = [];

  /**
   * @generated from field: google.protobuf.StringValue discount_code_reason = 3;
   */
  discountCodeReason?: string;

  constructor(data?: PartialMessage<EcommerceStoreUserSettingsPb>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.EcommerceStoreUserSettingsPb";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 2, name: "shipping_methods", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 3, name: "discount_code_reason", kind: "message", T: StringValue },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): EcommerceStoreUserSettingsPb {
    return new EcommerceStoreUserSettingsPb().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): EcommerceStoreUserSettingsPb {
    return new EcommerceStoreUserSettingsPb().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): EcommerceStoreUserSettingsPb {
    return new EcommerceStoreUserSettingsPb().fromJsonString(jsonString, options);
  }

  static equals(a: EcommerceStoreUserSettingsPb | PlainMessage<EcommerceStoreUserSettingsPb> | undefined, b: EcommerceStoreUserSettingsPb | PlainMessage<EcommerceStoreUserSettingsPb> | undefined): boolean {
    return proto3.util.equals(EcommerceStoreUserSettingsPb, a, b);
  }
}

/**
 * @generated from message librarian.proto.EcommerceStoreWithInfoPb
 */
export class EcommerceStoreWithInfoPb extends Message<EcommerceStoreWithInfoPb> {
  /**
   * @generated from field: librarian.proto.EcommerceStorePb store = 1;
   */
  store?: EcommerceStorePb;

  /**
   * @generated from field: int32 products_count = 2;
   */
  productsCount = 0;

  constructor(data?: PartialMessage<EcommerceStoreWithInfoPb>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.EcommerceStoreWithInfoPb";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "store", kind: "message", T: EcommerceStorePb },
    { no: 2, name: "products_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): EcommerceStoreWithInfoPb {
    return new EcommerceStoreWithInfoPb().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): EcommerceStoreWithInfoPb {
    return new EcommerceStoreWithInfoPb().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): EcommerceStoreWithInfoPb {
    return new EcommerceStoreWithInfoPb().fromJsonString(jsonString, options);
  }

  static equals(a: EcommerceStoreWithInfoPb | PlainMessage<EcommerceStoreWithInfoPb> | undefined, b: EcommerceStoreWithInfoPb | PlainMessage<EcommerceStoreWithInfoPb> | undefined): boolean {
    return proto3.util.equals(EcommerceStoreWithInfoPb, a, b);
  }
}

/**
 * @generated from message librarian.proto.GetEcommerceStoresRequest
 */
export class GetEcommerceStoresRequest extends Message<GetEcommerceStoresRequest> {
  /**
   * @generated from field: librarian.proto.GetEcommerceStoresFilter filter = 1;
   */
  filter?: GetEcommerceStoresFilter;

  constructor(data?: PartialMessage<GetEcommerceStoresRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.GetEcommerceStoresRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "filter", kind: "message", T: GetEcommerceStoresFilter },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetEcommerceStoresRequest {
    return new GetEcommerceStoresRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetEcommerceStoresRequest {
    return new GetEcommerceStoresRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetEcommerceStoresRequest {
    return new GetEcommerceStoresRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetEcommerceStoresRequest | PlainMessage<GetEcommerceStoresRequest> | undefined, b: GetEcommerceStoresRequest | PlainMessage<GetEcommerceStoresRequest> | undefined): boolean {
    return proto3.util.equals(GetEcommerceStoresRequest, a, b);
  }
}

/**
 * @generated from message librarian.proto.GetEcommerceStoresFilter
 */
export class GetEcommerceStoresFilter extends Message<GetEcommerceStoresFilter> {
  /**
   * @generated from field: google.protobuf.BoolValue archived = 1;
   */
  archived?: boolean;

  /**
   * @generated from field: google.protobuf.StringValue name = 2;
   */
  name?: string;

  /**
   * @generated from field: repeated librarian.proto.EcommerceStoreType types = 3;
   */
  types: EcommerceStoreType[] = [];

  constructor(data?: PartialMessage<GetEcommerceStoresFilter>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.GetEcommerceStoresFilter";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "archived", kind: "message", T: BoolValue },
    { no: 2, name: "name", kind: "message", T: StringValue },
    { no: 3, name: "types", kind: "enum", T: proto3.getEnumType(EcommerceStoreType), repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetEcommerceStoresFilter {
    return new GetEcommerceStoresFilter().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetEcommerceStoresFilter {
    return new GetEcommerceStoresFilter().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetEcommerceStoresFilter {
    return new GetEcommerceStoresFilter().fromJsonString(jsonString, options);
  }

  static equals(a: GetEcommerceStoresFilter | PlainMessage<GetEcommerceStoresFilter> | undefined, b: GetEcommerceStoresFilter | PlainMessage<GetEcommerceStoresFilter> | undefined): boolean {
    return proto3.util.equals(GetEcommerceStoresFilter, a, b);
  }
}

/**
 * @generated from message librarian.proto.GetEcommerceStoresResponse
 */
export class GetEcommerceStoresResponse extends Message<GetEcommerceStoresResponse> {
  /**
   * @generated from field: repeated librarian.proto.EcommerceStoreWithInfoPb stores = 1;
   */
  stores: EcommerceStoreWithInfoPb[] = [];

  constructor(data?: PartialMessage<GetEcommerceStoresResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.GetEcommerceStoresResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "stores", kind: "message", T: EcommerceStoreWithInfoPb, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetEcommerceStoresResponse {
    return new GetEcommerceStoresResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetEcommerceStoresResponse {
    return new GetEcommerceStoresResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetEcommerceStoresResponse {
    return new GetEcommerceStoresResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetEcommerceStoresResponse | PlainMessage<GetEcommerceStoresResponse> | undefined, b: GetEcommerceStoresResponse | PlainMessage<GetEcommerceStoresResponse> | undefined): boolean {
    return proto3.util.equals(GetEcommerceStoresResponse, a, b);
  }
}

/**
 * @generated from message librarian.proto.CreateManualStoreRequest
 */
export class CreateManualStoreRequest extends Message<CreateManualStoreRequest> {
  /**
   * @generated from field: string name = 1;
   */
  name = "";

  /**
   * @generated from field: google.protobuf.StringValue domain = 2;
   */
  domain?: string;

  /**
   * @generated from field: common.proto.CurrencyEnum currency = 3;
   */
  currency = CurrencyEnum.NA;

  constructor(data?: PartialMessage<CreateManualStoreRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.CreateManualStoreRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "domain", kind: "message", T: StringValue },
    { no: 3, name: "currency", kind: "enum", T: proto3.getEnumType(CurrencyEnum) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateManualStoreRequest {
    return new CreateManualStoreRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateManualStoreRequest {
    return new CreateManualStoreRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateManualStoreRequest {
    return new CreateManualStoreRequest().fromJsonString(jsonString, options);
  }

  static equals(a: CreateManualStoreRequest | PlainMessage<CreateManualStoreRequest> | undefined, b: CreateManualStoreRequest | PlainMessage<CreateManualStoreRequest> | undefined): boolean {
    return proto3.util.equals(CreateManualStoreRequest, a, b);
  }
}

/**
 * @generated from message librarian.proto.CreateManualStoreResponse
 */
export class CreateManualStoreResponse extends Message<CreateManualStoreResponse> {
  /**
   * @generated from field: librarian.proto.ManualStorePb manual_store = 1;
   */
  manualStore?: ManualStorePb;

  constructor(data?: PartialMessage<CreateManualStoreResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.CreateManualStoreResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "manual_store", kind: "message", T: ManualStorePb },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateManualStoreResponse {
    return new CreateManualStoreResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateManualStoreResponse {
    return new CreateManualStoreResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateManualStoreResponse {
    return new CreateManualStoreResponse().fromJsonString(jsonString, options);
  }

  static equals(a: CreateManualStoreResponse | PlainMessage<CreateManualStoreResponse> | undefined, b: CreateManualStoreResponse | PlainMessage<CreateManualStoreResponse> | undefined): boolean {
    return proto3.util.equals(CreateManualStoreResponse, a, b);
  }
}

/**
 * @generated from message librarian.proto.EditManualStoreRequest
 */
export class EditManualStoreRequest extends Message<EditManualStoreRequest> {
  /**
   * @generated from field: int64 id = 1;
   */
  id = protoInt64.zero;

  /**
   * @generated from field: google.protobuf.StringValue name = 2;
   */
  name?: string;

  /**
   * @generated from field: google.protobuf.StringValue domain = 3;
   */
  domain?: string;

  constructor(data?: PartialMessage<EditManualStoreRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.EditManualStoreRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "name", kind: "message", T: StringValue },
    { no: 3, name: "domain", kind: "message", T: StringValue },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): EditManualStoreRequest {
    return new EditManualStoreRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): EditManualStoreRequest {
    return new EditManualStoreRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): EditManualStoreRequest {
    return new EditManualStoreRequest().fromJsonString(jsonString, options);
  }

  static equals(a: EditManualStoreRequest | PlainMessage<EditManualStoreRequest> | undefined, b: EditManualStoreRequest | PlainMessage<EditManualStoreRequest> | undefined): boolean {
    return proto3.util.equals(EditManualStoreRequest, a, b);
  }
}

/**
 * @generated from message librarian.proto.EditManualStoreResponse
 */
export class EditManualStoreResponse extends Message<EditManualStoreResponse> {
  /**
   * @generated from field: librarian.proto.ManualStorePb manual_store = 1;
   */
  manualStore?: ManualStorePb;

  constructor(data?: PartialMessage<EditManualStoreResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.EditManualStoreResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "manual_store", kind: "message", T: ManualStorePb },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): EditManualStoreResponse {
    return new EditManualStoreResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): EditManualStoreResponse {
    return new EditManualStoreResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): EditManualStoreResponse {
    return new EditManualStoreResponse().fromJsonString(jsonString, options);
  }

  static equals(a: EditManualStoreResponse | PlainMessage<EditManualStoreResponse> | undefined, b: EditManualStoreResponse | PlainMessage<EditManualStoreResponse> | undefined): boolean {
    return proto3.util.equals(EditManualStoreResponse, a, b);
  }
}

/**
 * @generated from message librarian.proto.CreateSalesforceStoresRequest
 */
export class CreateSalesforceStoresRequest extends Message<CreateSalesforceStoresRequest> {
  /**
   * @generated from field: repeated string site_ids = 1;
   */
  siteIds: string[] = [];

  /**
   * @generated from field: string client_id = 2;
   */
  clientId = "";

  /**
   * @generated from field: string client_secret = 3;
   */
  clientSecret = "";

  /**
   * @generated from field: string short_code = 4;
   */
  shortCode = "";

  /**
   * @generated from field: string organization_id = 5;
   */
  organizationId = "";

  /**
   * @generated from field: string slas_admin_token = 6;
   */
  slasAdminToken = "";

  constructor(data?: PartialMessage<CreateSalesforceStoresRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.CreateSalesforceStoresRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "site_ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 2, name: "client_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "client_secret", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "short_code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "organization_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "slas_admin_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateSalesforceStoresRequest {
    return new CreateSalesforceStoresRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateSalesforceStoresRequest {
    return new CreateSalesforceStoresRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateSalesforceStoresRequest {
    return new CreateSalesforceStoresRequest().fromJsonString(jsonString, options);
  }

  static equals(a: CreateSalesforceStoresRequest | PlainMessage<CreateSalesforceStoresRequest> | undefined, b: CreateSalesforceStoresRequest | PlainMessage<CreateSalesforceStoresRequest> | undefined): boolean {
    return proto3.util.equals(CreateSalesforceStoresRequest, a, b);
  }
}

/**
 * @generated from message librarian.proto.CreateSalesforceStoresResponse
 */
export class CreateSalesforceStoresResponse extends Message<CreateSalesforceStoresResponse> {
  /**
   * @generated from field: string task_handle = 1;
   */
  taskHandle = "";

  constructor(data?: PartialMessage<CreateSalesforceStoresResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.CreateSalesforceStoresResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "task_handle", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateSalesforceStoresResponse {
    return new CreateSalesforceStoresResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateSalesforceStoresResponse {
    return new CreateSalesforceStoresResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateSalesforceStoresResponse {
    return new CreateSalesforceStoresResponse().fromJsonString(jsonString, options);
  }

  static equals(a: CreateSalesforceStoresResponse | PlainMessage<CreateSalesforceStoresResponse> | undefined, b: CreateSalesforceStoresResponse | PlainMessage<CreateSalesforceStoresResponse> | undefined): boolean {
    return proto3.util.equals(CreateSalesforceStoresResponse, a, b);
  }
}

/**
 * @generated from message librarian.proto.ValidateCreateSalesforceStoresAPIClientRequest
 */
export class ValidateCreateSalesforceStoresAPIClientRequest extends Message<ValidateCreateSalesforceStoresAPIClientRequest> {
  /**
   * @generated from field: string client_id = 1;
   */
  clientId = "";

  /**
   * @generated from field: string client_secret = 2;
   */
  clientSecret = "";

  /**
   * @generated from field: string short_code = 3;
   */
  shortCode = "";

  /**
   * @generated from field: string organization_id = 4;
   */
  organizationId = "";

  constructor(data?: PartialMessage<ValidateCreateSalesforceStoresAPIClientRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.ValidateCreateSalesforceStoresAPIClientRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "client_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "client_secret", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "short_code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "organization_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ValidateCreateSalesforceStoresAPIClientRequest {
    return new ValidateCreateSalesforceStoresAPIClientRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ValidateCreateSalesforceStoresAPIClientRequest {
    return new ValidateCreateSalesforceStoresAPIClientRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ValidateCreateSalesforceStoresAPIClientRequest {
    return new ValidateCreateSalesforceStoresAPIClientRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ValidateCreateSalesforceStoresAPIClientRequest | PlainMessage<ValidateCreateSalesforceStoresAPIClientRequest> | undefined, b: ValidateCreateSalesforceStoresAPIClientRequest | PlainMessage<ValidateCreateSalesforceStoresAPIClientRequest> | undefined): boolean {
    return proto3.util.equals(ValidateCreateSalesforceStoresAPIClientRequest, a, b);
  }
}

/**
 * @generated from message librarian.proto.ValidateCreateSalesforceStoresAPIClientResponse
 */
export class ValidateCreateSalesforceStoresAPIClientResponse extends Message<ValidateCreateSalesforceStoresAPIClientResponse> {
  /**
   * @generated from oneof librarian.proto.ValidateCreateSalesforceStoresAPIClientResponse.error
   */
  error: {
    /**
     * @generated from field: string api_client_credentials_error = 1;
     */
    value: string;
    case: "apiClientCredentialsError";
  } | {
    /**
     * @generated from field: string organization_id_error = 2;
     */
    value: string;
    case: "organizationIdError";
  } | {
    /**
     * @generated from field: string short_code_error = 3;
     */
    value: string;
    case: "shortCodeError";
  } | { case: undefined; value?: undefined } = { case: undefined };

  constructor(data?: PartialMessage<ValidateCreateSalesforceStoresAPIClientResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.ValidateCreateSalesforceStoresAPIClientResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "api_client_credentials_error", kind: "scalar", T: 9 /* ScalarType.STRING */, oneof: "error" },
    { no: 2, name: "organization_id_error", kind: "scalar", T: 9 /* ScalarType.STRING */, oneof: "error" },
    { no: 3, name: "short_code_error", kind: "scalar", T: 9 /* ScalarType.STRING */, oneof: "error" },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ValidateCreateSalesforceStoresAPIClientResponse {
    return new ValidateCreateSalesforceStoresAPIClientResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ValidateCreateSalesforceStoresAPIClientResponse {
    return new ValidateCreateSalesforceStoresAPIClientResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ValidateCreateSalesforceStoresAPIClientResponse {
    return new ValidateCreateSalesforceStoresAPIClientResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ValidateCreateSalesforceStoresAPIClientResponse | PlainMessage<ValidateCreateSalesforceStoresAPIClientResponse> | undefined, b: ValidateCreateSalesforceStoresAPIClientResponse | PlainMessage<ValidateCreateSalesforceStoresAPIClientResponse> | undefined): boolean {
    return proto3.util.equals(ValidateCreateSalesforceStoresAPIClientResponse, a, b);
  }
}

/**
 * @generated from message librarian.proto.ValidateCreateSalesforceStoresSlasAdminTokenRequest
 */
export class ValidateCreateSalesforceStoresSlasAdminTokenRequest extends Message<ValidateCreateSalesforceStoresSlasAdminTokenRequest> {
  /**
   * @generated from field: string slas_admin_token = 1;
   */
  slasAdminToken = "";

  /**
   * @generated from field: string short_code = 2;
   */
  shortCode = "";

  /**
   * @generated from field: string organization_id = 3;
   */
  organizationId = "";

  constructor(data?: PartialMessage<ValidateCreateSalesforceStoresSlasAdminTokenRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.ValidateCreateSalesforceStoresSlasAdminTokenRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "slas_admin_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "short_code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "organization_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ValidateCreateSalesforceStoresSlasAdminTokenRequest {
    return new ValidateCreateSalesforceStoresSlasAdminTokenRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ValidateCreateSalesforceStoresSlasAdminTokenRequest {
    return new ValidateCreateSalesforceStoresSlasAdminTokenRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ValidateCreateSalesforceStoresSlasAdminTokenRequest {
    return new ValidateCreateSalesforceStoresSlasAdminTokenRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ValidateCreateSalesforceStoresSlasAdminTokenRequest | PlainMessage<ValidateCreateSalesforceStoresSlasAdminTokenRequest> | undefined, b: ValidateCreateSalesforceStoresSlasAdminTokenRequest | PlainMessage<ValidateCreateSalesforceStoresSlasAdminTokenRequest> | undefined): boolean {
    return proto3.util.equals(ValidateCreateSalesforceStoresSlasAdminTokenRequest, a, b);
  }
}

/**
 * @generated from message librarian.proto.ValidateCreateSalesforceStoresSlasAdminTokenResponse
 */
export class ValidateCreateSalesforceStoresSlasAdminTokenResponse extends Message<ValidateCreateSalesforceStoresSlasAdminTokenResponse> {
  /**
   * @generated from field: google.protobuf.StringValue error = 1;
   */
  error?: string;

  constructor(data?: PartialMessage<ValidateCreateSalesforceStoresSlasAdminTokenResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.ValidateCreateSalesforceStoresSlasAdminTokenResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "error", kind: "message", T: StringValue },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ValidateCreateSalesforceStoresSlasAdminTokenResponse {
    return new ValidateCreateSalesforceStoresSlasAdminTokenResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ValidateCreateSalesforceStoresSlasAdminTokenResponse {
    return new ValidateCreateSalesforceStoresSlasAdminTokenResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ValidateCreateSalesforceStoresSlasAdminTokenResponse {
    return new ValidateCreateSalesforceStoresSlasAdminTokenResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ValidateCreateSalesforceStoresSlasAdminTokenResponse | PlainMessage<ValidateCreateSalesforceStoresSlasAdminTokenResponse> | undefined, b: ValidateCreateSalesforceStoresSlasAdminTokenResponse | PlainMessage<ValidateCreateSalesforceStoresSlasAdminTokenResponse> | undefined): boolean {
    return proto3.util.equals(ValidateCreateSalesforceStoresSlasAdminTokenResponse, a, b);
  }
}

/**
 * @generated from message librarian.proto.GetSalesforceSlasAdminUILinkRequest
 */
export class GetSalesforceSlasAdminUILinkRequest extends Message<GetSalesforceSlasAdminUILinkRequest> {
  /**
   * @generated from field: string short_code = 1;
   */
  shortCode = "";

  constructor(data?: PartialMessage<GetSalesforceSlasAdminUILinkRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.GetSalesforceSlasAdminUILinkRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "short_code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetSalesforceSlasAdminUILinkRequest {
    return new GetSalesforceSlasAdminUILinkRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetSalesforceSlasAdminUILinkRequest {
    return new GetSalesforceSlasAdminUILinkRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetSalesforceSlasAdminUILinkRequest {
    return new GetSalesforceSlasAdminUILinkRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetSalesforceSlasAdminUILinkRequest | PlainMessage<GetSalesforceSlasAdminUILinkRequest> | undefined, b: GetSalesforceSlasAdminUILinkRequest | PlainMessage<GetSalesforceSlasAdminUILinkRequest> | undefined): boolean {
    return proto3.util.equals(GetSalesforceSlasAdminUILinkRequest, a, b);
  }
}

/**
 * @generated from message librarian.proto.GetSalesforceSlasAdminUILinkResponse
 */
export class GetSalesforceSlasAdminUILinkResponse extends Message<GetSalesforceSlasAdminUILinkResponse> {
  /**
   * @generated from field: string slas_admin_ui_link = 1;
   */
  slasAdminUiLink = "";

  constructor(data?: PartialMessage<GetSalesforceSlasAdminUILinkResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.GetSalesforceSlasAdminUILinkResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "slas_admin_ui_link", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetSalesforceSlasAdminUILinkResponse {
    return new GetSalesforceSlasAdminUILinkResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetSalesforceSlasAdminUILinkResponse {
    return new GetSalesforceSlasAdminUILinkResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetSalesforceSlasAdminUILinkResponse {
    return new GetSalesforceSlasAdminUILinkResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetSalesforceSlasAdminUILinkResponse | PlainMessage<GetSalesforceSlasAdminUILinkResponse> | undefined, b: GetSalesforceSlasAdminUILinkResponse | PlainMessage<GetSalesforceSlasAdminUILinkResponse> | undefined): boolean {
    return proto3.util.equals(GetSalesforceSlasAdminUILinkResponse, a, b);
  }
}

/**
 * @generated from message librarian.proto.CreateSalesforceStoresTask
 */
export class CreateSalesforceStoresTask extends Message<CreateSalesforceStoresTask> {
  /**
   * @generated from field: int64 user_id = 1;
   */
  userId = protoInt64.zero;

  /**
   * @generated from field: librarian.proto.CreateSalesforceStoresRequest request = 2;
   */
  request?: CreateSalesforceStoresRequest;

  constructor(data?: PartialMessage<CreateSalesforceStoresTask>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.CreateSalesforceStoresTask";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "user_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "request", kind: "message", T: CreateSalesforceStoresRequest },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateSalesforceStoresTask {
    return new CreateSalesforceStoresTask().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateSalesforceStoresTask {
    return new CreateSalesforceStoresTask().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateSalesforceStoresTask {
    return new CreateSalesforceStoresTask().fromJsonString(jsonString, options);
  }

  static equals(a: CreateSalesforceStoresTask | PlainMessage<CreateSalesforceStoresTask> | undefined, b: CreateSalesforceStoresTask | PlainMessage<CreateSalesforceStoresTask> | undefined): boolean {
    return proto3.util.equals(CreateSalesforceStoresTask, a, b);
  }
}

/**
 * @generated from message librarian.proto.CreateSalesforceStoresTaskOutput
 */
export class CreateSalesforceStoresTaskOutput extends Message<CreateSalesforceStoresTaskOutput> {
  /**
   * @generated from field: repeated librarian.proto.SalesforceStorePb stores = 1;
   */
  stores: SalesforceStorePb[] = [];

  constructor(data?: PartialMessage<CreateSalesforceStoresTaskOutput>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.CreateSalesforceStoresTaskOutput";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "stores", kind: "message", T: SalesforceStorePb, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateSalesforceStoresTaskOutput {
    return new CreateSalesforceStoresTaskOutput().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateSalesforceStoresTaskOutput {
    return new CreateSalesforceStoresTaskOutput().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateSalesforceStoresTaskOutput {
    return new CreateSalesforceStoresTaskOutput().fromJsonString(jsonString, options);
  }

  static equals(a: CreateSalesforceStoresTaskOutput | PlainMessage<CreateSalesforceStoresTaskOutput> | undefined, b: CreateSalesforceStoresTaskOutput | PlainMessage<CreateSalesforceStoresTaskOutput> | undefined): boolean {
    return proto3.util.equals(CreateSalesforceStoresTaskOutput, a, b);
  }
}

/**
 * @generated from message librarian.proto.EditEcommerceStoreSettingsRequest
 */
export class EditEcommerceStoreSettingsRequest extends Message<EditEcommerceStoreSettingsRequest> {
  /**
   * @generated from field: int64 ecommerce_store_id = 1;
   */
  ecommerceStoreId = protoInt64.zero;

  /**
   * @generated from field: google.protobuf.StringValue discount_code_reason = 2;
   */
  discountCodeReason?: string;

  /**
   * @generated from field: librarian.proto.EditEcommerceStoreSettingsRequest.EditEcommerceStoreSettingsShippingMethods shipping_methods = 3;
   */
  shippingMethods?: EditEcommerceStoreSettingsRequest_EditEcommerceStoreSettingsShippingMethods;

  constructor(data?: PartialMessage<EditEcommerceStoreSettingsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.EditEcommerceStoreSettingsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "ecommerce_store_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "discount_code_reason", kind: "message", T: StringValue },
    { no: 3, name: "shipping_methods", kind: "message", T: EditEcommerceStoreSettingsRequest_EditEcommerceStoreSettingsShippingMethods },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): EditEcommerceStoreSettingsRequest {
    return new EditEcommerceStoreSettingsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): EditEcommerceStoreSettingsRequest {
    return new EditEcommerceStoreSettingsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): EditEcommerceStoreSettingsRequest {
    return new EditEcommerceStoreSettingsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: EditEcommerceStoreSettingsRequest | PlainMessage<EditEcommerceStoreSettingsRequest> | undefined, b: EditEcommerceStoreSettingsRequest | PlainMessage<EditEcommerceStoreSettingsRequest> | undefined): boolean {
    return proto3.util.equals(EditEcommerceStoreSettingsRequest, a, b);
  }
}

/**
 * @generated from message librarian.proto.EditEcommerceStoreSettingsRequest.EditEcommerceStoreSettingsShippingMethods
 */
export class EditEcommerceStoreSettingsRequest_EditEcommerceStoreSettingsShippingMethods extends Message<EditEcommerceStoreSettingsRequest_EditEcommerceStoreSettingsShippingMethods> {
  /**
   * @generated from field: repeated string shipping_methods = 1;
   */
  shippingMethods: string[] = [];

  constructor(data?: PartialMessage<EditEcommerceStoreSettingsRequest_EditEcommerceStoreSettingsShippingMethods>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.EditEcommerceStoreSettingsRequest.EditEcommerceStoreSettingsShippingMethods";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "shipping_methods", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): EditEcommerceStoreSettingsRequest_EditEcommerceStoreSettingsShippingMethods {
    return new EditEcommerceStoreSettingsRequest_EditEcommerceStoreSettingsShippingMethods().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): EditEcommerceStoreSettingsRequest_EditEcommerceStoreSettingsShippingMethods {
    return new EditEcommerceStoreSettingsRequest_EditEcommerceStoreSettingsShippingMethods().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): EditEcommerceStoreSettingsRequest_EditEcommerceStoreSettingsShippingMethods {
    return new EditEcommerceStoreSettingsRequest_EditEcommerceStoreSettingsShippingMethods().fromJsonString(jsonString, options);
  }

  static equals(a: EditEcommerceStoreSettingsRequest_EditEcommerceStoreSettingsShippingMethods | PlainMessage<EditEcommerceStoreSettingsRequest_EditEcommerceStoreSettingsShippingMethods> | undefined, b: EditEcommerceStoreSettingsRequest_EditEcommerceStoreSettingsShippingMethods | PlainMessage<EditEcommerceStoreSettingsRequest_EditEcommerceStoreSettingsShippingMethods> | undefined): boolean {
    return proto3.util.equals(EditEcommerceStoreSettingsRequest_EditEcommerceStoreSettingsShippingMethods, a, b);
  }
}

/**
 * @generated from message librarian.proto.EditEcommerceStoreSettingsResponse
 */
export class EditEcommerceStoreSettingsResponse extends Message<EditEcommerceStoreSettingsResponse> {
  /**
   * @generated from field: librarian.proto.EcommerceStoreUserSettingsPb settings = 1;
   */
  settings?: EcommerceStoreUserSettingsPb;

  constructor(data?: PartialMessage<EditEcommerceStoreSettingsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.EditEcommerceStoreSettingsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "settings", kind: "message", T: EcommerceStoreUserSettingsPb },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): EditEcommerceStoreSettingsResponse {
    return new EditEcommerceStoreSettingsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): EditEcommerceStoreSettingsResponse {
    return new EditEcommerceStoreSettingsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): EditEcommerceStoreSettingsResponse {
    return new EditEcommerceStoreSettingsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: EditEcommerceStoreSettingsResponse | PlainMessage<EditEcommerceStoreSettingsResponse> | undefined, b: EditEcommerceStoreSettingsResponse | PlainMessage<EditEcommerceStoreSettingsResponse> | undefined): boolean {
    return proto3.util.equals(EditEcommerceStoreSettingsResponse, a, b);
  }
}

