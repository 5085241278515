<select-influencers-dialog
  title="Add influencers to affiliation"
  i18n-title
  (selectedInfuencers)="influencersSelected($event)"
  submitButtonText="Next"
  i18n-submitButtonText
  #selectInfluencerDialog
  (closedWithoutSave$)="closeWithoutSave()"
></select-influencers-dialog>

<lefty-select-program-dialog
  cancelButtonText="Back"
  i18n-cancelButtonText
  (cancel$)="cancelProgram()"
  (selected$)="submit($event)"
  [withAddProgramButton]="true"
  (addProgramClicked$)="addProgramDialog.open()"
>
  <div
    title
    class="custom-title"
  >
    @if (hasBaseSnippetInSelection()) {
      <h3 i18n>Select program for</h3>
      <lefty-influencers-profile-pictures-stack
        [influencers]="baseSnippetInSelection()"
        [itemsToShow]="4"
      ></lefty-influencers-profile-pictures-stack>
      <h3 i18n>{{ baseSnippetInSelection().length }} affiliates</h3>
    } @else {
      <h3 i18n>Select program</h3>
    }
  </div>
</lefty-select-program-dialog>

<upload-influencer-loader-dialog
  (close$)="uploadResultClosed()"
></upload-influencer-loader-dialog>

<affiliates-limitation-dialog
  (close$)="closeWithoutSave()"
></affiliates-limitation-dialog>

<alfred-add-or-edit-program-dialog
  #addProgramDialog
  (closedWithoutSave$)="selectProgramDialog().openWith(selectedProgram())"
  (successfullySaved$)="selectProgramDialog().openWith($event)"
  #addOrEditProgramDialog
></alfred-add-or-edit-program-dialog>
