import {
  ChangeDetectionStrategy,
  Component,
  computed,
  inject,
  input,
} from '@angular/core';

import { LeftyAuthBloc } from '@frontend2/api';
import { isEmptyArray, isNotNil } from '@frontend2/core';
import { GetForecastingStatsAndPerformancesResp_ForecastingNetworkEstimatedPerformances } from '@frontend2/proto/librarian/proto/casting_pb';
import { ComponentFactory } from '../dynamic-component.component';
import { LeftyCardComponent } from '../lefty-card/lefty-card.component';
import { LeftyDataTableComponent } from '../lefty-data-table/lefty-data-table.component';
import {
  createCell,
  createColumnNoSort,
  createRow,
} from '../lefty-data-table/lefty-data-table.helpers';
import { LeftySpinnerComponent } from '../loading.component';
import { LeftyComponent } from '../utils';
import { CpeCellComponent } from './cpe-cell/cpe-cell.component';
import { CpmCellComponent } from './cpm-cell/cpm-cell.component';
import { NetworkCellComponent } from './network-cell/network-cell.component';
import {
  buildReportPerformancesRow,
  createCommentCell,
  createEmvCell,
  createEngagementRateCell,
  createEstimatedCostsCell,
  createEstimatedCpeCell,
  createEstimatedCpmCell,
  createEstimatedRoiCell,
  createImpressionsCell,
  createLikesCell,
  createReportPerformances,
  createTotalRow,
} from './report-performance.helpers';

@Component({
  selector: 'lefty-report-performance',
  templateUrl: 'report-performance.route.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['report-performance.route.component.scss'],
  standalone: true,
  imports: [LeftyCardComponent, LeftyDataTableComponent, LeftySpinnerComponent],
})
export class LeftyReportPerformanceComponent extends LeftyComponent {
  private readonly _auth = inject(LeftyAuthBloc);

  readonly isLoading = input(false);
  readonly networksEstimatedPerformances = input([
    new GetForecastingStatsAndPerformancesResp_ForecastingNetworkEstimatedPerformances(),
  ]);
  readonly columns = computed(() => [
    createColumnNoSort('networks', { label: $localize`Networks` }),
    createColumnNoSort('likes', { label: $localize`Likes` }),
    createColumnNoSort('comments', { label: $localize`Comments` }),
    createColumnNoSort('impressions', { label: $localize`Impressions` }),
    createColumnNoSort('emv', { label: $localize`EMV` }),
    createColumnNoSort('eng_rate', { label: $localize`Eng. Rate` }),
    createColumnNoSort('costs', {
      label: $localize`Est. Costs`,
      alignRight: true,
    }),
    createColumnNoSort('roi', {
      label: $localize`Est. ROI`,
      alignRight: true,
    }),
    createColumnNoSort('cpm', {
      label: $localize`Est. CPM`,
      alignRight: true,
    }),
    createColumnNoSort('cpe', {
      label: $localize`Est. CPE`,
      alignRight: true,
    }),
  ]);

  readonly rows = computed(() => {
    const reportPerformances = createReportPerformances({
      tableRows: this.networksEstimatedPerformances()
        .map((item) => buildReportPerformancesRow(item, this._auth.currency))
        .filter(isNotNil),
    });

    if (reportPerformances.tableRows.length > 1) {
      const totalReportPerformance = createTotalRow(
        this.networksEstimatedPerformances(),
      );
      reportPerformances.tableRows.push(
        createRow('total', {
          cells: [
            createCell({
              formattedValue: $localize`Total`,
            }),
            createLikesCell(totalReportPerformance),
            createCommentCell(totalReportPerformance),
            createImpressionsCell(totalReportPerformance),
            createEmvCell(totalReportPerformance, this._auth.currency),
            createEngagementRateCell(totalReportPerformance),
            createEstimatedCostsCell(
              totalReportPerformance,
              this._auth.currency,
            ),
            createEstimatedRoiCell(totalReportPerformance),
            createEstimatedCpmCell(totalReportPerformance),
            createEstimatedCpeCell(totalReportPerformance),
          ],
        }),
      );
    }
    return reportPerformances;
  });

  readonly componentFactories: {
    [key: string]: ComponentFactory<unknown>;
  } = {
    ['networks']: () => NetworkCellComponent,
    ['cpe']: () => CpeCellComponent,
    ['cpm']: () => CpmCellComponent,
  };

  readonly isEmptyState = computed(
    () => !this.isLoading() && isEmptyArray(this.rows().tableRows),
  );
}
