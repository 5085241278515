import { NgFor } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  Inject,
  input,
  Input,
} from '@angular/core';
import { API_HOST } from '@frontend2/api';
import {
  createGhostInfluencer,
  getInfluencerNetworksLinks,
  Influencer,
  isNil,
  isNotNil,
  Networks,
} from '@frontend2/core';
import { getCampaignNetworks } from '@frontend2/proto-helpers/librarian/campaign_pb.helpers';
import { Network } from '@frontend2/proto/common/proto/common_pb';
import { Campaign } from '@frontend2/proto/librarian/proto/campaigns_pb';
import { LeftyButtonDirective } from '../lefty-button-directive/lefty-button.directive';
import { NetworkIconComponent, NetworkIconSize } from './network.component';

/**
 * Display grid of networks icons
 * {@link InfluencerNetworksIconsComponent} and {@link CampaignNetworksComponent}
 */
@Component({
  selector: 'networks-icons',
  styleUrls: ['networks.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <div
      class="networks"
      [class.minimal-gap]="minimalGap"
    >
      <a
        *ngFor="let network of networks()"
        [class.no-link]="hasLink(network) === false"
        [class.disabled]="isDisabled(network) === true"
        [attr.href]="getLink(network)"
        target="_blank"
        (click)="$event.stopPropagation()"
        class="network"
      >
        <network-icon
          [network]="network"
          [size]="size"
          [grey]="grey"
          [disabled]="isDisabled(network)"
          [hasLink]="hasLink(network)"
        ></network-icon>
      </a>
    </div>
  `,
  standalone: true,
  imports: [NgFor, NetworkIconComponent],
})
export class NetworkIconsComponent {
  readonly networks = input<Network[]>([]);

  readonly sortedNetworks = computed(() => Networks.sort(this.networks()));

  @Input()
  links = new Map<Network, string>();

  @Input()
  disabledNetworks: Network[] = [];

  @Input()
  size: NetworkIconSize = 'medium';

  @Input()
  grey = false;

  @Input()
  minimalGap = false;

  hasLink(network: Network): boolean {
    return isNil(this.links.get(network)) === false;
  }

  getLink(network: Network): string {
    return this.links.get(network) ?? '';
  }

  isDisabled(network: Network): boolean {
    return this.disabledNetworks.includes(network);
  }
}

@Component({
  selector: 'influencer-networks-icons',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <networks-icons
      [networks]="influencer.networks"
      [links]="networksLinks"
      [size]="size"
      [grey]="grey"
    >
    </networks-icons>
  `,
  standalone: true,
  imports: [NetworkIconsComponent],
})
export class InfluencerNetworksIconsComponent {
  constructor(@Inject(API_HOST) private apiHost: string) {}

  @Input()
  influencer: Influencer = createGhostInfluencer();

  @Input()
  size: NetworkIconSize = 'medium';

  @Input()
  grey = false;

  get networksLinks(): Map<Network, string> {
    return getInfluencerNetworksLinks(this.influencer, this.apiHost);
  }
}

/**
 * Display grid of networks icons
 * {@link InfluencerNetworksIconsComponent} and {@link CampaignNetworksComponent}
 */
@Component({
  selector: 'networks-buttons',
  styleUrls: ['networks.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <div class="networks">
      <a
        *ngFor="let network of networks"
        [class.no-link]="hasLink(network) === false"
        [attr.disabled]="isDisabled(network) === true"
        [attr.href]="getLink(network)"
        target="_blank"
        (click)="$event.stopPropagation()"
        leftyButton="outline"
        size="small"
        class="icon network"
      >
        <network-icon [network]="network"></network-icon>
      </a>
    </div>
  `,
  standalone: true,
  imports: [NgFor, LeftyButtonDirective, NetworkIconComponent],
})
export class NetworkButtonsComponent {
  @Input()
  networks: Network[] = [];

  @Input()
  links = new Map<Network, string>();

  @Input()
  disabledNetworks: Network[] = [];

  hasLink(network: Network): boolean {
    return isNil(this.links.get(network)) === false;
  }

  getLink(network: Network): string {
    return this.links.get(network) ?? '';
  }

  isDisabled(network: Network): boolean {
    return this.disabledNetworks.includes(network);
  }
}

@Component({
  selector: 'influencer-networks-buttons',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <networks-buttons
      [networks]="influencer.networks"
      [links]="networksLinks"
    >
    </networks-buttons>
  `,
  standalone: true,
  imports: [NetworkButtonsComponent],
})
export class InfluencerNetworksButtonsComponent {
  constructor(@Inject(API_HOST) private apiHost: string) {}

  @Input()
  influencer: Influencer = createGhostInfluencer();

  get networksLinks(): Map<Network, string> {
    return getInfluencerNetworksLinks(this.influencer, this.apiHost);
  }
}

@Component({
  selector: 'campaign-network-icons',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <networks-icons
      [networks]="networks"
      [size]="size"
      [grey]="grey"
    >
    </networks-icons>
  `,
  standalone: true,
  imports: [NetworkIconsComponent],
})
export class CampaignNetworkIconsComponent {
  @Input()
  campaign = new Campaign();

  @Input()
  size: NetworkIconSize = 'medium';

  @Input()
  grey = false;

  get networks(): Network[] {
    if (isNotNil(this.campaign)) {
      return getCampaignNetworks(this.campaign);
    }
    return [];
  }
}
