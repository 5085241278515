// @generated by protoc-gen-connect-es v1.6.1 with parameter "target=ts"
// @generated from file librarian/proto/discover.proto (package librarian.proto, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { AuxiliaryBrands, AuxiliaryCategories, DiscoverRequest, DiscoverResponse, LookalikeRequest } from "./discover_pb";
import { Empty, MethodKind } from "@bufbuild/protobuf";

/**
 * @generated from service librarian.proto.LibrarianDiscover
 */
export const LibrarianDiscover = {
  typeName: "librarian.proto.LibrarianDiscover",
  methods: {
    /**
     * Search influencers
     *
     * @generated from rpc librarian.proto.LibrarianDiscover.SearchInfluencersAPI
     */
    searchInfluencersAPI: {
      name: "SearchInfluencersAPI",
      I: DiscoverRequest,
      O: DiscoverResponse,
      kind: MethodKind.Unary,
    },
    /**
     * Search lookalike influencers
     *
     * @generated from rpc librarian.proto.LibrarianDiscover.SearchLookalikeInfluencersAPI
     */
    searchLookalikeInfluencersAPI: {
      name: "SearchLookalikeInfluencersAPI",
      I: LookalikeRequest,
      O: DiscoverResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianDiscover.GetAuxiliaryBrands
     */
    getAuxiliaryBrands: {
      name: "GetAuxiliaryBrands",
      I: Empty,
      O: AuxiliaryBrands,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianDiscover.GetAuxiliaryCategories
     */
    getAuxiliaryCategories: {
      name: "GetAuxiliaryCategories",
      I: Empty,
      O: AuxiliaryCategories,
      kind: MethodKind.Unary,
    },
  }
} as const;

