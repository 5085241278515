import { ErrorHandler, Injectable, NgModule } from '@angular/core';
import { Code, ConnectError } from '@connectrpc/connect';
import { datadogRum } from '@datadog/browser-rum';
import {
  BroncoTaskError,
  isInternalServerError,
  isNetworkError,
} from '@frontend2/api';
import { Messages, SentryModule } from '@frontend2/core';
import * as Sentry from '@sentry/browser';
import { injectToastManager, ToastManager } from './toast/toast.service';

const _networkErrorMsg = $localize`Error - Please check your internet connection and try again`;

export function showToastException(
  toastManager: ToastManager,
  e: unknown,
  message?: string,
): void {
  if (e instanceof ConnectError && isNetworkError(e)) {
    toastManager.showError(_networkErrorMsg);
    return;
  }

  if (e instanceof ConnectError && !isInternalServerError(e)) {
    // don't need to show unauthenticated message
    // since user will be redirect anyway
    if (e.code !== Code.Unauthenticated) {
      toastManager.showError(message ?? e.rawMessage);
      console.warn(e);
    }
  } else {
    console.error(e);
    toastManager.showError(message ?? Messages.errorHappenReportToAM);
  }
}

// Report and exception to Sentry
// Except RpcError that are not internal.
//
// This function must be use in a try/catch that also report the error to the user
// mainly using a Toast.
function reportToSentry(error: unknown): void {
  if (error instanceof ConnectError && !isInternalServerError(error)) {
    return;
  }

  try {
    Sentry.captureException(error);
  } catch (e) {
    console.warn(e);
  }
}

function reportToDatadog(error: unknown): void {
  if (error instanceof ConnectError && !isInternalServerError(error)) {
    return;
  }

  try {
    console.log('Report error to Datadog', error);
    datadogRum.addError(error);
  } catch (e) {
    console.warn(e);
  }
}

export function reportError(error: unknown): void {
  reportToSentry(error);
  reportToDatadog(error);
}

@Injectable({ providedIn: 'root' })
class LeftyErrorHandler extends ErrorHandler {
  readonly toastManager = injectToastManager();

  override handleError(error: unknown): void {
    // Don't report Bronco errors if not critical error
    if (error instanceof BroncoTaskError && !error.isInternalServerError) {
      return;
    }

    // Don't report Lefty API errors if not critical error
    if (error instanceof ConnectError && !isInternalServerError(error)) {
      return;
    }

    reportError(error);

    showToastException(this.toastManager, error);

    super.handleError(error);
  }
}

@NgModule({
  imports: [SentryModule],
  providers: [
    {
      provide: ErrorHandler,
      useClass: LeftyErrorHandler,
      deps: [ToastManager],
    },
  ],
})
export class LeftyErrorHandlerModule {}
