// This file defines common protocol buffers (typically, used in more than one project).

// @generated by protoc-gen-es v1.10.0 with parameter "target=ts"
// @generated from file common/proto/user.proto (package common.proto, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { BoolValue, DoubleValue, FloatValue, Int32Value, Int64Value, Message, proto3, protoInt64, StringValue, Timestamp } from "@bufbuild/protobuf";
import { ComputationOrigin, Gender, Mention, PrivateInfo, StatValue } from "./common_pb";
import { GeoPoint } from "./geo_pb";

/**
 * @generated from enum common.proto.GeographySource
 */
export enum GeographySource {
  /**
   * @generated from enum value: GEOSOURCE_UNKNOWN = 0;
   */
  GEOSOURCE_UNKNOWN = 0,

  /**
   * @generated from enum value: GEOLOC_POSTS = 1;
   */
  GEOLOC_POSTS = 1,

  /**
   * @generated from enum value: BIO = 2;
   */
  BIO = 2,

  /**
   * @generated from enum value: MENTIONS_GRAPH = 3;
   */
  MENTIONS_GRAPH = 3,

  /**
   * @generated from enum value: INSTAGRAM_SCRAPPED_INFO = 4;
   */
  INSTAGRAM_SCRAPPED_INFO = 4,
}
// Retrieve enum metadata with: proto3.getEnumType(GeographySource)
proto3.util.setEnumType(GeographySource, "common.proto.GeographySource", [
  { no: 0, name: "GEOSOURCE_UNKNOWN" },
  { no: 1, name: "GEOLOC_POSTS" },
  { no: 2, name: "BIO" },
  { no: 3, name: "MENTIONS_GRAPH" },
  { no: 4, name: "INSTAGRAM_SCRAPPED_INFO" },
]);

/**
 * Brands information as deduced from the scrapped category that we get from Instagram.
 *
 * @generated from enum common.proto.BrandBit
 */
export enum BrandBit {
  /**
   * @generated from enum value: BRAND_BIT_UNKNOWN = 0;
   */
  BRAND_BIT_UNKNOWN = 0,

  /**
   * a big and healthy brand, Guerlain, BMW, etc etc...
   *
   * @generated from enum value: BRAND = 1;
   */
  BRAND = 1,

  /**
   * any sort of crappy business, eg: store to sell bags online, deli down the street, etc... It will be considered
   * like a brand in that it can be excluded when want to search influencers only.
   * However if we ever want to do rankings, such as what were the best BRAND collaborations of an influencer, etc then we
   * could want to exclude those.
   *
   * @generated from enum value: BUSINESS = 2;
   */
  BUSINESS = 2,

  /**
   * web publication or fanzine that tends to do tons of regrams, no original content, etc. Not to be considered
   * a business, but probably best to exclude from a default search too.
   *
   * @generated from enum value: RESHARE = 3;
   */
  RESHARE = 3,

  /**
   * Influencer.
   *
   * @generated from enum value: INFLU = 4;
   */
  INFLU = 4,

  /**
   * category that seems to cater to influencer of very low quality, or accounts that we're not so sure
   * whether they're influencers or online businesses (nail salons, etc...). Those may be included in influencer searches,
   * except some day if we add some scoring/rankings of who to poll in priority, we may want to downgrade those.
   *
   * @generated from enum value: INFLU_BOF = 5;
   */
  INFLU_BOF = 5,
}
// Retrieve enum metadata with: proto3.getEnumType(BrandBit)
proto3.util.setEnumType(BrandBit, "common.proto.BrandBit", [
  { no: 0, name: "BRAND_BIT_UNKNOWN" },
  { no: 1, name: "BRAND" },
  { no: 2, name: "BUSINESS" },
  { no: 3, name: "RESHARE" },
  { no: 4, name: "INFLU" },
  { no: 5, name: "INFLU_BOF" },
]);

/**
 * More general categories that we were able to extract from Instagram.
 * Note that not all those necessarily have to be exposed in our frontend.
 *
 * DEPRECATED to be deleted.
 *
 * @generated from enum common.proto.CategoryV2
 */
export enum CategoryV2 {
  /**
   * @generated from enum value: CATEGORY_V2_UNKNOWN = 0;
   */
  CATEGORY_V2_UNKNOWN = 0,

  /**
   * @generated from enum value: BEAUTY = 1;
   */
  BEAUTY = 1,

  /**
   * @generated from enum value: FASHION = 2;
   */
  FASHION = 2,

  /**
   * @generated from enum value: TRAVEL = 3;
   */
  TRAVEL = 3,

  /**
   * @generated from enum value: PARENT = 4;
   */
  PARENT = 4,

  /**
   * @generated from enum value: FOOD = 5;
   */
  FOOD = 5,

  /**
   * @generated from enum value: SPORT = 6;
   */
  SPORT = 6,

  /**
   * @generated from enum value: JEWELRY_WATCHES = 7;
   */
  JEWELRY_WATCHES = 7,

  /**
   * @generated from enum value: OUTDOOR = 8;
   */
  OUTDOOR = 8,

  /**
   * @generated from enum value: MUSIC = 9;
   */
  MUSIC = 9,

  /**
   * @generated from enum value: PHOTO = 10;
   */
  PHOTO = 10,

  /**
   * @generated from enum value: HOME = 11;
   */
  HOME = 11,

  /**
   * @generated from enum value: CARS = 12;
   */
  CARS = 12,

  /**
   * @generated from enum value: GAMING = 13;
   */
  GAMING = 13,

  /**
   * @generated from enum value: SPIRITS = 14;
   */
  SPIRITS = 14,

  /**
   * @generated from enum value: ELECTRONICS = 15;
   */
  ELECTRONICS = 15,

  /**
   * @generated from enum value: PET = 16;
   */
  PET = 16,

  /**
   * ca envoie du lourd.
   *
   * @generated from enum value: DESIGNER = 17;
   */
  DESIGNER = 17,

  /**
   * Subcategories: it is always OK when indexing and searching to assume that BEAUTY_X also counts as BEAUTY,
   * FASHION_X as FASHION, etc...
   *
   * @generated from enum value: BEAUTY_NAILS = 101;
   */
  BEAUTY_NAILS = 101,

  /**
   * @generated from enum value: BEAUTY_MAKEUP = 102;
   */
  BEAUTY_MAKEUP = 102,

  /**
   * @generated from enum value: BEAUTY_HAIR = 103;
   */
  BEAUTY_HAIR = 103,

  /**
   * @generated from enum value: FASHION_MEN = 1001;
   */
  FASHION_MEN = 1001,
}
// Retrieve enum metadata with: proto3.getEnumType(CategoryV2)
proto3.util.setEnumType(CategoryV2, "common.proto.CategoryV2", [
  { no: 0, name: "CATEGORY_V2_UNKNOWN" },
  { no: 1, name: "BEAUTY" },
  { no: 2, name: "FASHION" },
  { no: 3, name: "TRAVEL" },
  { no: 4, name: "PARENT" },
  { no: 5, name: "FOOD" },
  { no: 6, name: "SPORT" },
  { no: 7, name: "JEWELRY_WATCHES" },
  { no: 8, name: "OUTDOOR" },
  { no: 9, name: "MUSIC" },
  { no: 10, name: "PHOTO" },
  { no: 11, name: "HOME" },
  { no: 12, name: "CARS" },
  { no: 13, name: "GAMING" },
  { no: 14, name: "SPIRITS" },
  { no: 15, name: "ELECTRONICS" },
  { no: 16, name: "PET" },
  { no: 17, name: "DESIGNER" },
  { no: 101, name: "BEAUTY_NAILS" },
  { no: 102, name: "BEAUTY_MAKEUP" },
  { no: 103, name: "BEAUTY_HAIR" },
  { no: 1001, name: "FASHION_MEN" },
]);

/**
 * Reason why a creator was deemed not of interest.
 * If the creator is of interest, UNINTERESTING_REASON_UNKNOWN shall be used.
 *
 * If the creator is not of interest, we persist the first reason (they may actually have
 * matched more).
 *
 * @generated from enum common.proto.UninterestingReason
 */
export enum UninterestingReason {
  /**
   * @generated from enum value: UNINTERESTING_REASON_UNKNOWN = 0;
   */
  UNINTERESTING_REASON_UNKNOWN = 0,

  /**
   * The creator's scrapped category is known to contain only crap.
   *
   * @generated from enum value: BAD_CATEGORY = 1;
   */
  BAD_CATEGORY = 1,

  /**
   * not enough followers.
   *
   * @generated from enum value: LOW_FOLLOWERS = 2;
   */
  LOW_FOLLOWERS = 2,

  /**
   * @generated from enum value: GDPR_OPT_OUT = 3;
   */
  GDPR_OPT_OUT = 3,

  /**
   * hasn't posted in a long time.
   *
   * @generated from enum value: INACTIVE = 4;
   */
  INACTIVE = 4,

  /**
   * The creator has suspiscious stats (follows/followers, engagement, etc)
   * See the computation for the actual rules.
   *
   * @generated from enum value: SUSPISCIOUS_RATIOS = 5;
   */
  SUSPISCIOUS_RATIOS = 5,

  /**
   * This is only applied to small influencers (< 50k). We are more forviging to huge influencers because it might be
   * harder for huge influencers to have super engagements rates.
   *
   * @generated from enum value: ENGAGEMENT_RIDICULOUS = 6;
   */
  ENGAGEMENT_RIDICULOUS = 6,
}
// Retrieve enum metadata with: proto3.getEnumType(UninterestingReason)
proto3.util.setEnumType(UninterestingReason, "common.proto.UninterestingReason", [
  { no: 0, name: "UNINTERESTING_REASON_UNKNOWN" },
  { no: 1, name: "BAD_CATEGORY" },
  { no: 2, name: "LOW_FOLLOWERS" },
  { no: 3, name: "GDPR_OPT_OUT" },
  { no: 4, name: "INACTIVE" },
  { no: 5, name: "SUSPISCIOUS_RATIOS" },
  { no: 6, name: "ENGAGEMENT_RIDICULOUS" },
]);

/**
 * @generated from message common.proto.SpamInfo
 */
export class SpamInfo extends Message<SpamInfo> {
  /**
   * @generated from field: google.protobuf.DoubleValue spam_probability = 1;
   */
  spamProbability?: number;

  /**
   * @generated from field: google.protobuf.BoolValue is_spam = 2;
   */
  isSpam?: boolean;

  /**
   * @generated from field: google.protobuf.Timestamp creation_time = 3;
   */
  creationTime?: Timestamp;

  /**
   * @generated from field: google.protobuf.Int32Value version = 4;
   */
  version?: number;

  constructor(data?: PartialMessage<SpamInfo>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.SpamInfo";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "spam_probability", kind: "message", T: DoubleValue },
    { no: 2, name: "is_spam", kind: "message", T: BoolValue },
    { no: 3, name: "creation_time", kind: "message", T: Timestamp },
    { no: 4, name: "version", kind: "message", T: Int32Value },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SpamInfo {
    return new SpamInfo().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SpamInfo {
    return new SpamInfo().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SpamInfo {
    return new SpamInfo().fromJsonString(jsonString, options);
  }

  static equals(a: SpamInfo | PlainMessage<SpamInfo> | undefined, b: SpamInfo | PlainMessage<SpamInfo> | undefined): boolean {
    return proto3.util.equals(SpamInfo, a, b);
  }
}

/**
 * @generated from message common.proto.GenderInfo
 */
export class GenderInfo extends Message<GenderInfo> {
  /**
   * @generated from field: common.proto.Gender gender = 1;
   */
  gender = Gender.GENDER_UNDEFINED;

  /**
   * @generated from field: float female_probability = 2;
   */
  femaleProbability = 0;

  /**
   * @generated from field: int32 version = 3;
   */
  version = 0;

  /**
   * @generated from field: common.proto.ComputationOrigin computation_origin = 4;
   */
  computationOrigin?: ComputationOrigin;

  /**
   * @generated from field: google.protobuf.Timestamp creation_time = 5;
   */
  creationTime?: Timestamp;

  /**
   * @generated from field: int64 processing_time_ms = 6;
   */
  processingTimeMs = protoInt64.zero;

  constructor(data?: PartialMessage<GenderInfo>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.GenderInfo";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "gender", kind: "enum", T: proto3.getEnumType(Gender) },
    { no: 2, name: "female_probability", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 3, name: "version", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "computation_origin", kind: "message", T: ComputationOrigin },
    { no: 5, name: "creation_time", kind: "message", T: Timestamp },
    { no: 6, name: "processing_time_ms", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GenderInfo {
    return new GenderInfo().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GenderInfo {
    return new GenderInfo().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GenderInfo {
    return new GenderInfo().fromJsonString(jsonString, options);
  }

  static equals(a: GenderInfo | PlainMessage<GenderInfo> | undefined, b: GenderInfo | PlainMessage<GenderInfo> | undefined): boolean {
    return proto3.util.equals(GenderInfo, a, b);
  }
}

/**
 * @generated from message common.proto.AgeInfo
 */
export class AgeInfo extends Message<AgeInfo> {
  /**
   * age. negatives values are errors.
   *
   * @generated from field: google.protobuf.DoubleValue age = 1;
   */
  age?: number;

  /**
   * @generated from field: google.protobuf.Timestamp creation_time = 2;
   */
  creationTime?: Timestamp;

  /**
   * version 0 is manually annotated. version > 0 are automatic ones.
   *
   * @generated from field: google.protobuf.Int32Value version = 3;
   */
  version?: number;

  /**
   * @generated from field: google.protobuf.BoolValue is_jap = 4;
   */
  isJap?: boolean;

  /**
   * age_bio is the age found in the bio. (e.g. XX years old or XX歳)
   *
   * @generated from field: google.protobuf.Int32Value age_bio = 5;
   */
  ageBio?: number;

  constructor(data?: PartialMessage<AgeInfo>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.AgeInfo";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "age", kind: "message", T: DoubleValue },
    { no: 2, name: "creation_time", kind: "message", T: Timestamp },
    { no: 3, name: "version", kind: "message", T: Int32Value },
    { no: 4, name: "is_jap", kind: "message", T: BoolValue },
    { no: 5, name: "age_bio", kind: "message", T: Int32Value },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AgeInfo {
    return new AgeInfo().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AgeInfo {
    return new AgeInfo().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AgeInfo {
    return new AgeInfo().fromJsonString(jsonString, options);
  }

  static equals(a: AgeInfo | PlainMessage<AgeInfo> | undefined, b: AgeInfo | PlainMessage<AgeInfo> | undefined): boolean {
    return proto3.util.equals(AgeInfo, a, b);
  }
}

/**
 * @generated from message common.proto.LanguagePercent
 */
export class LanguagePercent extends Message<LanguagePercent> {
  /**
   * ISO 639 code of the language (2 letters or 3 letters or more...)
   *
   * @generated from field: string code = 1;
   */
  code = "";

  /**
   * percentage of text in the caption of this language
   *
   * @generated from field: double percent = 2;
   */
  percent = 0;

  constructor(data?: PartialMessage<LanguagePercent>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.LanguagePercent";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "percent", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): LanguagePercent {
    return new LanguagePercent().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): LanguagePercent {
    return new LanguagePercent().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): LanguagePercent {
    return new LanguagePercent().fromJsonString(jsonString, options);
  }

  static equals(a: LanguagePercent | PlainMessage<LanguagePercent> | undefined, b: LanguagePercent | PlainMessage<LanguagePercent> | undefined): boolean {
    return proto3.util.equals(LanguagePercent, a, b);
  }
}

/**
 * This represents percentages of each language in the union of an user's captions.
 *
 * @generated from message common.proto.LanguageInfo
 */
export class LanguageInfo extends Message<LanguageInfo> {
  /**
   * @generated from field: common.proto.LanguageInfo.LanguagesEnveloppe languages_enveloppe = 1;
   */
  languagesEnveloppe?: LanguageInfo_LanguagesEnveloppe;

  /**
   * The time at which we computed the languages.
   *
   * @generated from field: google.protobuf.Timestamp compute_time = 2;
   */
  computeTime?: Timestamp;

  constructor(data?: PartialMessage<LanguageInfo>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.LanguageInfo";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "languages_enveloppe", kind: "message", T: LanguageInfo_LanguagesEnveloppe },
    { no: 2, name: "compute_time", kind: "message", T: Timestamp },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): LanguageInfo {
    return new LanguageInfo().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): LanguageInfo {
    return new LanguageInfo().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): LanguageInfo {
    return new LanguageInfo().fromJsonString(jsonString, options);
  }

  static equals(a: LanguageInfo | PlainMessage<LanguageInfo> | undefined, b: LanguageInfo | PlainMessage<LanguageInfo> | undefined): boolean {
    return proto3.util.equals(LanguageInfo, a, b);
  }
}

/**
 * @generated from message common.proto.LanguageInfo.LanguagesEnveloppe
 */
export class LanguageInfo_LanguagesEnveloppe extends Message<LanguageInfo_LanguagesEnveloppe> {
  /**
   * @generated from field: repeated common.proto.LanguagePercent language = 1;
   */
  language: LanguagePercent[] = [];

  constructor(data?: PartialMessage<LanguageInfo_LanguagesEnveloppe>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.LanguageInfo.LanguagesEnveloppe";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "language", kind: "message", T: LanguagePercent, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): LanguageInfo_LanguagesEnveloppe {
    return new LanguageInfo_LanguagesEnveloppe().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): LanguageInfo_LanguagesEnveloppe {
    return new LanguageInfo_LanguagesEnveloppe().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): LanguageInfo_LanguagesEnveloppe {
    return new LanguageInfo_LanguagesEnveloppe().fromJsonString(jsonString, options);
  }

  static equals(a: LanguageInfo_LanguagesEnveloppe | PlainMessage<LanguageInfo_LanguagesEnveloppe> | undefined, b: LanguageInfo_LanguagesEnveloppe | PlainMessage<LanguageInfo_LanguagesEnveloppe> | undefined): boolean {
    return proto3.util.equals(LanguageInfo_LanguagesEnveloppe, a, b);
  }
}

/**
 * Information of a user coming straight from instagram.
 *
 * @generated from message common.proto.InstagramUser
 */
export class InstagramUser extends Message<InstagramUser> {
  /**
   * @generated from field: string username = 1;
   */
  username = "";

  /**
   * @generated from field: string full_name = 2;
   */
  fullName = "";

  /**
   * @generated from field: string website = 3;
   */
  website = "";

  /**
   * @generated from field: string profile_picture = 4;
   */
  profilePicture = "";

  /**
   * @generated from field: string bio = 5;
   */
  bio = "";

  /**
   * @generated from field: string id = 6;
   */
  id = "";

  /**
   * @generated from field: common.proto.UserStats counts = 7;
   */
  counts?: UserStats;

  /**
   * If set to true or false, then the value is whether we are considered a creator/business account in Facebook's
   * graph API. This is a special status, for instance non creators accounts cannot login with instagram and give
   * advanced permissions such as retrieving their insights.
   *
   * Unset means we don't know for sure, for instance this InstagramUser was retrieved with a method that does not include
   * the field (anonymous scrapping is one of those).
   *
   * @generated from field: google.protobuf.BoolValue is_business = 18;
   */
  isBusiness?: boolean;

  /**
   * Unset means we don't know for sure, for instance this InstagramUser was retrieved with a method that does not include
   * the field
   *
   * @generated from field: google.protobuf.BoolValue hide_like_and_view_counts = 19;
   */
  hideLikeAndViewCounts?: boolean;

  /**
   * Same as above: this will not be set if the retrieval method is not facebook graph API and the field is not available.
   * In that case the string remains empty.
   *
   * @generated from field: string facebook_id = 16;
   */
  facebookId = "";

  /**
   * Note that this flag can be true and yet the rest is filled (because we went to get it with a specific account).
   *
   * @generated from field: bool is_restricted = 17;
   */
  isRestricted = false;

  constructor(data?: PartialMessage<InstagramUser>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.InstagramUser";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "username", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "full_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "website", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "profile_picture", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "bio", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "counts", kind: "message", T: UserStats },
    { no: 18, name: "is_business", kind: "message", T: BoolValue },
    { no: 19, name: "hide_like_and_view_counts", kind: "message", T: BoolValue },
    { no: 16, name: "facebook_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 17, name: "is_restricted", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): InstagramUser {
    return new InstagramUser().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): InstagramUser {
    return new InstagramUser().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): InstagramUser {
    return new InstagramUser().fromJsonString(jsonString, options);
  }

  static equals(a: InstagramUser | PlainMessage<InstagramUser> | undefined, b: InstagramUser | PlainMessage<InstagramUser> | undefined): boolean {
    return proto3.util.equals(InstagramUser, a, b);
  }
}

/**
 * This represents influencer categories percentages.
 * Interest are present only if their percentage is > 0 (so >= 1 because they are truncated).
 *
 * @generated from message common.proto.Interests
 */
export class Interests extends Message<Interests> {
  /**
   * @generated from field: common.proto.Interests.InterestsEnveloppe interests_enveloppe = 1;
   */
  interestsEnveloppe?: Interests_InterestsEnveloppe;

  /**
   * The time at which we computed the interests.
   *
   * @generated from field: google.protobuf.Timestamp compute_time = 2;
   */
  computeTime?: Timestamp;

  constructor(data?: PartialMessage<Interests>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.Interests";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "interests_enveloppe", kind: "message", T: Interests_InterestsEnveloppe },
    { no: 2, name: "compute_time", kind: "message", T: Timestamp },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Interests {
    return new Interests().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Interests {
    return new Interests().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Interests {
    return new Interests().fromJsonString(jsonString, options);
  }

  static equals(a: Interests | PlainMessage<Interests> | undefined, b: Interests | PlainMessage<Interests> | undefined): boolean {
    return proto3.util.equals(Interests, a, b);
  }
}

/**
 * This list can be empty if we didn't detect interest with percentage >= 1.
 *
 * @generated from message common.proto.Interests.InterestsEnveloppe
 */
export class Interests_InterestsEnveloppe extends Message<Interests_InterestsEnveloppe> {
  /**
   * @generated from field: repeated common.proto.Interests.InterestsEnveloppe.InterestPercent interest = 1;
   */
  interest: Interests_InterestsEnveloppe_InterestPercent[] = [];

  constructor(data?: PartialMessage<Interests_InterestsEnveloppe>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.Interests.InterestsEnveloppe";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "interest", kind: "message", T: Interests_InterestsEnveloppe_InterestPercent, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Interests_InterestsEnveloppe {
    return new Interests_InterestsEnveloppe().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Interests_InterestsEnveloppe {
    return new Interests_InterestsEnveloppe().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Interests_InterestsEnveloppe {
    return new Interests_InterestsEnveloppe().fromJsonString(jsonString, options);
  }

  static equals(a: Interests_InterestsEnveloppe | PlainMessage<Interests_InterestsEnveloppe> | undefined, b: Interests_InterestsEnveloppe | PlainMessage<Interests_InterestsEnveloppe> | undefined): boolean {
    return proto3.util.equals(Interests_InterestsEnveloppe, a, b);
  }
}

/**
 * @generated from message common.proto.Interests.InterestsEnveloppe.InterestPercent
 */
export class Interests_InterestsEnveloppe_InterestPercent extends Message<Interests_InterestsEnveloppe_InterestPercent> {
  /**
   * @generated from field: string name = 1;
   */
  name = "";

  /**
   * percentage of photos of an user portfolio. this is truncated.
   *
   * @generated from field: int32 percent = 2;
   */
  percent = 0;

  constructor(data?: PartialMessage<Interests_InterestsEnveloppe_InterestPercent>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.Interests.InterestsEnveloppe.InterestPercent";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "percent", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Interests_InterestsEnveloppe_InterestPercent {
    return new Interests_InterestsEnveloppe_InterestPercent().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Interests_InterestsEnveloppe_InterestPercent {
    return new Interests_InterestsEnveloppe_InterestPercent().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Interests_InterestsEnveloppe_InterestPercent {
    return new Interests_InterestsEnveloppe_InterestPercent().fromJsonString(jsonString, options);
  }

  static equals(a: Interests_InterestsEnveloppe_InterestPercent | PlainMessage<Interests_InterestsEnveloppe_InterestPercent> | undefined, b: Interests_InterestsEnveloppe_InterestPercent | PlainMessage<Interests_InterestsEnveloppe_InterestPercent> | undefined): boolean {
    return proto3.util.equals(Interests_InterestsEnveloppe_InterestPercent, a, b);
  }
}

/**
 * @generated from message common.proto.Geography
 */
export class Geography extends Message<Geography> {
  /**
   * @generated from field: common.proto.GeoPoint original_point = 1;
   */
  originalPoint?: GeoPoint;

  /**
   * @generated from field: google.protobuf.StringValue country = 2;
   */
  country?: string;

  /**
   * @generated from field: google.protobuf.StringValue city = 3;
   */
  city?: string;

  /**
   * @generated from field: google.protobuf.Timestamp compute_time = 4;
   */
  computeTime?: Timestamp;

  /**
   * @generated from field: common.proto.GeographySource source = 5;
   */
  source = GeographySource.GEOSOURCE_UNKNOWN;

  constructor(data?: PartialMessage<Geography>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.Geography";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "original_point", kind: "message", T: GeoPoint },
    { no: 2, name: "country", kind: "message", T: StringValue },
    { no: 3, name: "city", kind: "message", T: StringValue },
    { no: 4, name: "compute_time", kind: "message", T: Timestamp },
    { no: 5, name: "source", kind: "enum", T: proto3.getEnumType(GeographySource) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Geography {
    return new Geography().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Geography {
    return new Geography().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Geography {
    return new Geography().fromJsonString(jsonString, options);
  }

  static equals(a: Geography | PlainMessage<Geography> | undefined, b: Geography | PlainMessage<Geography> | undefined): boolean {
    return proto3.util.equals(Geography, a, b);
  }
}

/**
 * Geography encoded by our AI algorithms.
 * We save it separately than the original 'Geography' such that we can still compute
 * it when 'Geography' was filled, and we can compute the results.
 *
 * @generated from message common.proto.AiGeography
 */
export class AiGeography extends Message<AiGeography> {
  /**
   * @generated from field: google.protobuf.StringValue country_code = 1;
   */
  countryCode?: string;

  /**
   * @generated from field: google.protobuf.Timestamp compute_time = 2;
   */
  computeTime?: Timestamp;

  /**
   * version of the algorithm that computed it.
   *
   * @generated from field: google.protobuf.Int32Value version = 3;
   */
  version?: number;

  constructor(data?: PartialMessage<AiGeography>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.AiGeography";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "country_code", kind: "message", T: StringValue },
    { no: 2, name: "compute_time", kind: "message", T: Timestamp },
    { no: 3, name: "version", kind: "message", T: Int32Value },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AiGeography {
    return new AiGeography().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AiGeography {
    return new AiGeography().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AiGeography {
    return new AiGeography().fromJsonString(jsonString, options);
  }

  static equals(a: AiGeography | PlainMessage<AiGeography> | undefined, b: AiGeography | PlainMessage<AiGeography> | undefined): boolean {
    return proto3.util.equals(AiGeography, a, b);
  }
}

/**
 * @generated from message common.proto.GeographyReport
 */
export class GeographyReport extends Message<GeographyReport> {
  /**
   * @generated from field: string name = 1;
   */
  name = "";

  /**
   * @generated from field: float percentage = 2;
   */
  percentage = 0;

  constructor(data?: PartialMessage<GeographyReport>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.GeographyReport";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "percentage", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GeographyReport {
    return new GeographyReport().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GeographyReport {
    return new GeographyReport().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GeographyReport {
    return new GeographyReport().fromJsonString(jsonString, options);
  }

  static equals(a: GeographyReport | PlainMessage<GeographyReport> | undefined, b: GeographyReport | PlainMessage<GeographyReport> | undefined): boolean {
    return proto3.util.equals(GeographyReport, a, b);
  }
}

/**
 * @generated from message common.proto.GeographyReports
 */
export class GeographyReports extends Message<GeographyReports> {
  /**
   * @generated from field: repeated common.proto.GeographyReport report = 1;
   */
  report: GeographyReport[] = [];

  constructor(data?: PartialMessage<GeographyReports>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.GeographyReports";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "report", kind: "message", T: GeographyReport, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GeographyReports {
    return new GeographyReports().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GeographyReports {
    return new GeographyReports().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GeographyReports {
    return new GeographyReports().fromJsonString(jsonString, options);
  }

  static equals(a: GeographyReports | PlainMessage<GeographyReports> | undefined, b: GeographyReports | PlainMessage<GeographyReports> | undefined): boolean {
    return proto3.util.equals(GeographyReports, a, b);
  }
}

/**
 * @generated from message common.proto.AgesReport
 */
export class AgesReport extends Message<AgesReport> {
  /**
   * @generated from field: repeated common.proto.AgesReport.BucketValue age_bucket = 1;
   */
  ageBucket: AgesReport_BucketValue[] = [];

  constructor(data?: PartialMessage<AgesReport>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.AgesReport";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "age_bucket", kind: "message", T: AgesReport_BucketValue, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AgesReport {
    return new AgesReport().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AgesReport {
    return new AgesReport().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AgesReport {
    return new AgesReport().fromJsonString(jsonString, options);
  }

  static equals(a: AgesReport | PlainMessage<AgesReport> | undefined, b: AgesReport | PlainMessage<AgesReport> | undefined): boolean {
    return proto3.util.equals(AgesReport, a, b);
  }
}

/**
 * This represent a value for a bucket, low and high are inclusive bounds.
 * It means there is value % of samples between low and high, inclusive.
 *
 * @generated from message common.proto.AgesReport.BucketValue
 */
export class AgesReport_BucketValue extends Message<AgesReport_BucketValue> {
  /**
   * @generated from field: int32 low = 1;
   */
  low = 0;

  /**
   * @generated from field: int32 high = 2;
   */
  high = 0;

  /**
   * @generated from field: double value = 3;
   */
  value = 0;

  constructor(data?: PartialMessage<AgesReport_BucketValue>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.AgesReport.BucketValue";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "low", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "high", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "value", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AgesReport_BucketValue {
    return new AgesReport_BucketValue().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AgesReport_BucketValue {
    return new AgesReport_BucketValue().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AgesReport_BucketValue {
    return new AgesReport_BucketValue().fromJsonString(jsonString, options);
  }

  static equals(a: AgesReport_BucketValue | PlainMessage<AgesReport_BucketValue> | undefined, b: AgesReport_BucketValue | PlainMessage<AgesReport_BucketValue> | undefined): boolean {
    return proto3.util.equals(AgesReport_BucketValue, a, b);
  }
}

/**
 * @generated from message common.proto.AudienceQuality
 */
export class AudienceQuality extends Message<AudienceQuality> {
  /**
   * percentage of real followers.
   *
   * @generated from field: float value = 1;
   */
  value = 0;

  /**
   * average between similar influencers.
   *
   * @generated from field: float average = 2;
   */
  average = 0;

  constructor(data?: PartialMessage<AudienceQuality>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.AudienceQuality";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "value", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 2, name: "average", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AudienceQuality {
    return new AudienceQuality().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AudienceQuality {
    return new AudienceQuality().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AudienceQuality {
    return new AudienceQuality().fromJsonString(jsonString, options);
  }

  static equals(a: AudienceQuality | PlainMessage<AudienceQuality> | undefined, b: AudienceQuality | PlainMessage<AudienceQuality> | undefined): boolean {
    return proto3.util.equals(AudienceQuality, a, b);
  }
}

/**
 * Audience of a user.
 *
 * @generated from message common.proto.AudienceReport
 */
export class AudienceReport extends Message<AudienceReport> {
  /**
   * percentage of females in the audience.
   *
   * @generated from field: double female_percentage = 1;
   */
  femalePercentage = 0;

  /**
   * Interests of the audience. The percentage is averaged over the audience.
   *
   * @generated from field: common.proto.Interests interests = 7;
   */
  interests?: Interests;

  /**
   * Top countries in the audience
   *
   * @generated from field: common.proto.GeographyReports geography_reports = 8;
   */
  geographyReports?: GeographyReports;

  /**
   * Top cities in the audience
   *
   * @generated from field: common.proto.GeographyReports top_cities = 9;
   */
  topCities?: GeographyReports;

  /**
   * Distribution of ages in the audience.
   *
   * @generated from field: common.proto.AgesReport ages_report = 11;
   */
  agesReport?: AgesReport;

  /**
   * Distribution of ages in the female audience.
   *
   * @generated from field: common.proto.AgesReport ages_report_female = 13;
   */
  agesReportFemale?: AgesReport;

  /**
   * Distribution of ages in the male audience.
   *
   * @generated from field: common.proto.AgesReport ages_report_male = 14;
   */
  agesReportMale?: AgesReport;

  /**
   * Distribution of audience languages
   *
   * @generated from field: common.proto.LanguageInfo language_report = 16;
   */
  languageReport?: LanguageInfo;

  /**
   * @generated from field: common.proto.AudienceQuality audience_quality = 17;
   */
  audienceQuality?: AudienceQuality;

  /**
   * True if the report couldn't be computed. This is typically because the account hasn't posted
   * for a too long period. If true, all fields above have no meaning.
   *
   * @generated from field: bool couldnt_compute = 18;
   */
  couldntCompute = false;

  constructor(data?: PartialMessage<AudienceReport>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.AudienceReport";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "female_percentage", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 7, name: "interests", kind: "message", T: Interests },
    { no: 8, name: "geography_reports", kind: "message", T: GeographyReports },
    { no: 9, name: "top_cities", kind: "message", T: GeographyReports },
    { no: 11, name: "ages_report", kind: "message", T: AgesReport },
    { no: 13, name: "ages_report_female", kind: "message", T: AgesReport },
    { no: 14, name: "ages_report_male", kind: "message", T: AgesReport },
    { no: 16, name: "language_report", kind: "message", T: LanguageInfo },
    { no: 17, name: "audience_quality", kind: "message", T: AudienceQuality },
    { no: 18, name: "couldnt_compute", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AudienceReport {
    return new AudienceReport().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AudienceReport {
    return new AudienceReport().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AudienceReport {
    return new AudienceReport().fromJsonString(jsonString, options);
  }

  static equals(a: AudienceReport | PlainMessage<AudienceReport> | undefined, b: AudienceReport | PlainMessage<AudienceReport> | undefined): boolean {
    return proto3.util.equals(AudienceReport, a, b);
  }
}

/**
 * @generated from message common.proto.SocialMedia
 */
export class SocialMedia extends Message<SocialMedia> {
  /**
   * @generated from field: google.protobuf.StringValue facebook_username = 1;
   */
  facebookUsername?: string;

  /**
   * @generated from field: google.protobuf.StringValue twitter_username = 2;
   */
  twitterUsername?: string;

  /**
   * @generated from field: google.protobuf.StringValue snapchat_username = 3;
   */
  snapchatUsername?: string;

  /**
   * This could be more accurately named youtube_link but we don't rename to maintain cassandra compatibility.
   * (This is a link of the form http://youtube.com/user/AnUser or http://youtube.com/channel/ALONGCOMPLICATEDCHANNELID)
   *
   * @generated from field: google.protobuf.StringValue youtube_username = 4;
   */
  youtubeUsername?: string;

  /**
   * @generated from field: google.protobuf.Timestamp creation_time = 5;
   */
  creationTime?: Timestamp;

  constructor(data?: PartialMessage<SocialMedia>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.SocialMedia";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "facebook_username", kind: "message", T: StringValue },
    { no: 2, name: "twitter_username", kind: "message", T: StringValue },
    { no: 3, name: "snapchat_username", kind: "message", T: StringValue },
    { no: 4, name: "youtube_username", kind: "message", T: StringValue },
    { no: 5, name: "creation_time", kind: "message", T: Timestamp },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SocialMedia {
    return new SocialMedia().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SocialMedia {
    return new SocialMedia().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SocialMedia {
    return new SocialMedia().fromJsonString(jsonString, options);
  }

  static equals(a: SocialMedia | PlainMessage<SocialMedia> | undefined, b: SocialMedia | PlainMessage<SocialMedia> | undefined): boolean {
    return proto3.util.equals(SocialMedia, a, b);
  }
}

/**
 * This kinda mirrors rogue.proto.User except:
 *   - we added the "last_scrapped_time" fields.
 *   - we removed stuff that would be duplicated with common.proto.User (bio, name, etc...).
 *   - we removed stuff that is obviously useless, for instance info related to the logged in instagrammer (is_favorite, etc).
 *
 * @generated from message common.proto.UserScrappedInfo
 */
export class UserScrappedInfo extends Message<UserScrappedInfo> {
  /**
   * When we last scrapped the user. This superseeds the last_visit_times, eg if we scrapped
   * it counts as a visit, but the opposite is not true (scrapping returns more stuff).
   *
   * @generated from field: google.protobuf.Timestamp stats_last_scrap_time = 1;
   */
  statsLastScrapTime?: Timestamp;

  /**
   * @generated from field: google.protobuf.Timestamp images_last_scrap_time = 2;
   */
  imagesLastScrapTime?: Timestamp;

  /**
   * @generated from field: google.protobuf.BoolValue has_anonymous_profile_picture = 3;
   */
  hasAnonymousProfilePicture?: boolean;

  /**
   * @generated from field: google.protobuf.Int64Value city_id = 4;
   */
  cityId?: bigint;

  /**
   * @generated from field: google.protobuf.StringValue public_phone_number = 5;
   */
  publicPhoneNumber?: string;

  /**
   * @generated from field: google.protobuf.StringValue external_lynx_url = 6;
   */
  externalLynxUrl?: string;

  /**
   * @generated from field: google.protobuf.StringValue address_street = 10;
   */
  addressStreet?: string;

  /**
   * @generated from field: google.protobuf.StringValue category = 11;
   */
  category?: string;

  /**
   * @generated from field: google.protobuf.StringValue zip = 14;
   */
  zip?: string;

  /**
   * @generated from field: google.protobuf.StringValue profile_context = 15;
   */
  profileContext?: string;

  /**
   * @generated from field: google.protobuf.StringValue public_email = 16;
   */
  publicEmail?: string;

  /**
   * @generated from field: google.protobuf.StringValue business_contact_method = 17;
   */
  businessContactMethod?: string;

  /**
   * @generated from field: google.protobuf.Int32Value geo_media_count = 18;
   */
  geoMediaCount?: number;

  /**
   * @generated from field: google.protobuf.StringValue city_name = 21;
   */
  cityName?: string;

  /**
   * @generated from field: google.protobuf.StringValue direct_messaging = 25;
   */
  directMessaging?: string;

  /**
   * @generated from field: google.protobuf.FloatValue latitude = 26;
   */
  latitude?: number;

  /**
   * @generated from field: google.protobuf.FloatValue longitude = 28;
   */
  longitude?: number;

  /**
   * @generated from field: google.protobuf.Int32Value following_tag_count = 29;
   */
  followingTagCount?: number;

  /**
   * @generated from field: google.protobuf.StringValue public_phone_country_code = 30;
   */
  publicPhoneCountryCode?: string;

  /**
   * @generated from field: google.protobuf.BoolValue is_verified = 33;
   */
  isVerified?: boolean;

  /**
   * @generated from field: google.protobuf.BoolValue is_call_to_action_enabled = 34;
   */
  isCallToActionEnabled?: boolean;

  /**
   * @generated from field: google.protobuf.Int32Value usertags_count = 35;
   */
  usertagsCount?: number;

  /**
   * @generated from field: google.protobuf.StringValue contact_phone_number = 36;
   */
  contactPhoneNumber?: string;

  constructor(data?: PartialMessage<UserScrappedInfo>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.UserScrappedInfo";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "stats_last_scrap_time", kind: "message", T: Timestamp },
    { no: 2, name: "images_last_scrap_time", kind: "message", T: Timestamp },
    { no: 3, name: "has_anonymous_profile_picture", kind: "message", T: BoolValue },
    { no: 4, name: "city_id", kind: "message", T: Int64Value },
    { no: 5, name: "public_phone_number", kind: "message", T: StringValue },
    { no: 6, name: "external_lynx_url", kind: "message", T: StringValue },
    { no: 10, name: "address_street", kind: "message", T: StringValue },
    { no: 11, name: "category", kind: "message", T: StringValue },
    { no: 14, name: "zip", kind: "message", T: StringValue },
    { no: 15, name: "profile_context", kind: "message", T: StringValue },
    { no: 16, name: "public_email", kind: "message", T: StringValue },
    { no: 17, name: "business_contact_method", kind: "message", T: StringValue },
    { no: 18, name: "geo_media_count", kind: "message", T: Int32Value },
    { no: 21, name: "city_name", kind: "message", T: StringValue },
    { no: 25, name: "direct_messaging", kind: "message", T: StringValue },
    { no: 26, name: "latitude", kind: "message", T: FloatValue },
    { no: 28, name: "longitude", kind: "message", T: FloatValue },
    { no: 29, name: "following_tag_count", kind: "message", T: Int32Value },
    { no: 30, name: "public_phone_country_code", kind: "message", T: StringValue },
    { no: 33, name: "is_verified", kind: "message", T: BoolValue },
    { no: 34, name: "is_call_to_action_enabled", kind: "message", T: BoolValue },
    { no: 35, name: "usertags_count", kind: "message", T: Int32Value },
    { no: 36, name: "contact_phone_number", kind: "message", T: StringValue },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UserScrappedInfo {
    return new UserScrappedInfo().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UserScrappedInfo {
    return new UserScrappedInfo().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UserScrappedInfo {
    return new UserScrappedInfo().fromJsonString(jsonString, options);
  }

  static equals(a: UserScrappedInfo | PlainMessage<UserScrappedInfo> | undefined, b: UserScrappedInfo | PlainMessage<UserScrappedInfo> | undefined): boolean {
    return proto3.util.equals(UserScrappedInfo, a, b);
  }
}

/**
 * @generated from message common.proto.InterestingInfo
 */
export class InterestingInfo extends Message<InterestingInfo> {
  /**
   * @generated from field: google.protobuf.BoolValue is_of_interest = 1;
   */
  isOfInterest?: boolean;

  /**
   * reason will be 0 if the
   *
   * @generated from field: common.proto.UninterestingReason reason = 2;
   */
  reason = UninterestingReason.UNINTERESTING_REASON_UNKNOWN;

  /**
   * Version of the logic that computed this bit.
   *
   * @generated from field: google.protobuf.Int32Value version = 3;
   */
  version?: number;

  constructor(data?: PartialMessage<InterestingInfo>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.InterestingInfo";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "is_of_interest", kind: "message", T: BoolValue },
    { no: 2, name: "reason", kind: "enum", T: proto3.getEnumType(UninterestingReason) },
    { no: 3, name: "version", kind: "message", T: Int32Value },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): InterestingInfo {
    return new InterestingInfo().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): InterestingInfo {
    return new InterestingInfo().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): InterestingInfo {
    return new InterestingInfo().fromJsonString(jsonString, options);
  }

  static equals(a: InterestingInfo | PlainMessage<InterestingInfo> | undefined, b: InterestingInfo | PlainMessage<InterestingInfo> | undefined): boolean {
    return proto3.util.equals(InterestingInfo, a, b);
  }
}

/**
 * we have a wrapper enveloppe for the repeated list because the companion still requires that.
 *
 * @generated from message common.proto.TopicoCategories
 */
export class TopicoCategories extends Message<TopicoCategories> {
  /**
   * @generated from field: repeated common.proto.CategoryV2 categories = 1;
   */
  categories: CategoryV2[] = [];

  constructor(data?: PartialMessage<TopicoCategories>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.TopicoCategories";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "categories", kind: "enum", T: proto3.getEnumType(CategoryV2), repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TopicoCategories {
    return new TopicoCategories().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TopicoCategories {
    return new TopicoCategories().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TopicoCategories {
    return new TopicoCategories().fromJsonString(jsonString, options);
  }

  static equals(a: TopicoCategories | PlainMessage<TopicoCategories> | undefined, b: TopicoCategories | PlainMessage<TopicoCategories> | undefined): boolean {
    return proto3.util.equals(TopicoCategories, a, b);
  }
}

/**
 * Persist the output of topico.
 *
 * @generated from message common.proto.Topico
 */
export class Topico extends Message<Topico> {
  /**
   * @generated from field: common.proto.TopicoCategories categories = 1;
   */
  categories?: TopicoCategories;

  /**
   * @generated from field: google.protobuf.Int32Value version = 2;
   */
  version?: number;

  /**
   * @generated from field: google.protobuf.Timestamp computed_at = 3;
   */
  computedAt?: Timestamp;

  constructor(data?: PartialMessage<Topico>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.Topico";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "categories", kind: "message", T: TopicoCategories },
    { no: 2, name: "version", kind: "message", T: Int32Value },
    { no: 3, name: "computed_at", kind: "message", T: Timestamp },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Topico {
    return new Topico().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Topico {
    return new Topico().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Topico {
    return new Topico().fromJsonString(jsonString, options);
  }

  static equals(a: Topico | PlainMessage<Topico> | undefined, b: Topico | PlainMessage<Topico> | undefined): boolean {
    return proto3.util.equals(Topico, a, b);
  }
}

/**
 * Author of an instagram asset.
 *
 * @generated from message common.proto.User
 */
export class User extends Message<User> {
  /**
   * @generated from field: string username = 1;
   */
  username = "";

  /**
   * @generated from field: string full_name = 2;
   */
  fullName = "";

  /**
   * @generated from field: string website = 3;
   */
  website = "";

  /**
   * @generated from field: string profile_picture = 4;
   */
  profilePicture = "";

  /**
   * @generated from field: string bio = 5;
   */
  bio = "";

  /**
   * @generated from field: string id = 6;
   */
  id = "";

  /**
   * @generated from field: common.proto.UserStats counts = 7;
   */
  counts?: UserStats;

  /**
   * alcohol, ass, etc...
   *
   * @generated from field: bool is_restricted = 46;
   */
  isRestricted = false;

  /**
   * @generated from field: google.protobuf.Timestamp created = 10;
   */
  created?: Timestamp;

  /**
   * @generated from field: google.protobuf.Timestamp updated = 11;
   */
  updated?: Timestamp;

  /**
   * This is the last time that the user were processed and indexed
   * (as influencer or photographer).
   *
   * @generated from field: google.protobuf.Timestamp user_last_processing_time = 15;
   */
  userLastProcessingTime?: Timestamp;

  /**
   * This is the last time that gender info was computed.
   *
   * @generated from field: google.protobuf.Timestamp gender_info_last_compute_time = 16;
   */
  genderInfoLastComputeTime?: Timestamp;

  /**
   * this field does not come in instagram but will be parsed from users bio
   *
   * @generated from field: common.proto.UserEmails emails = 12;
   */
  emails?: UserEmails;

  /**
   * Gender information
   *
   * @generated from field: common.proto.GenderInfo gender_info = 14;
   */
  genderInfo?: GenderInfo;

  /**
   * @generated from field: common.proto.PrivateInfo private_info = 18;
   */
  privateInfo?: PrivateInfo;

  /**
   * @generated from field: common.proto.AgeInfo age_info = 21;
   */
  ageInfo?: AgeInfo;

  /**
   * @generated from field: common.proto.Geography geography = 22;
   */
  geography?: Geography;

  /**
   * @generated from field: common.proto.LanguageInfo language_info = 23;
   */
  languageInfo?: LanguageInfo;

  /**
   * @generated from field: common.proto.SocialMedia social_media = 24;
   */
  socialMedia?: SocialMedia;

  /**
   * @generated from field: common.proto.ComputedInfluencerStats computed_stats = 28;
   */
  computedStats?: ComputedInfluencerStats;

  /**
   * @generated from field: google.protobuf.DoubleValue pagerank = 30;
   */
  pagerank?: number;

  /**
   * @generated from field: common.proto.UserScrappedInfo scrapped_info = 32;
   */
  scrappedInfo?: UserScrappedInfo;

  /**
   * @generated from field: string facebook_id = 33;
   */
  facebookId = "";

  /**
   * This field is the md5 hash of an instance of the message FactsFingerprint.
   * It is meant to be able to quickly determine whether a user shall have its facts recomputed or not.
   *
   * @generated from field: string facts_fingerprint = 34;
   */
  factsFingerprint = "";

  /**
   * @generated from field: bool gdpr_opt_out = 35;
   */
  gdprOptOut = false;

  /**
   * @generated from field: google.protobuf.Timestamp gdpr_opt_out_time = 36;
   */
  gdprOptOutTime?: Timestamp;

  /**
   * @generated from field: common.proto.BrandBit brand_bit = 39;
   */
  brandBit = BrandBit.BRAND_BIT_UNKNOWN;

  /**
   * scrapped category from instagram if any.
   *
   * @generated from field: common.proto.CategoryV2 category_v2 = 40;
   */
  categoryV2 = CategoryV2.CATEGORY_V2_UNKNOWN;

  /**
   * Categories retrieved from Topico
   * Legacy!!! Now in MetaCreator>metaFacts>categories. TODO(mateo) delete
   *
   * @generated from field: common.proto.Topico topico = 43;
   */
  topico?: Topico;

  /**
   * @generated from field: common.proto.AiGeography ai_geo = 41;
   */
  aiGeo?: AiGeography;

  /**
   * @generated from field: common.proto.InterestingInfo is_of_interest_info = 42;
   */
  isOfInterestInfo?: InterestingInfo;

  /**
   * @generated from field: google.protobuf.Timestamp last_reel_polling_time = 45;
   */
  lastReelPollingTime?: Timestamp;

  /**
   * true or false means this profile is or is not a business account (with regards to the graph API).
   * null means we don't know yet.
   *
   * @generated from field: google.protobuf.BoolValue is_creator_or_business = 47;
   */
  isCreatorOrBusiness?: boolean;

  /**
   * null means we don't know yet.
   *
   * @generated from field: google.protobuf.BoolValue hide_like_and_view_counts = 48;
   */
  hideLikeAndViewCounts?: boolean;

  constructor(data?: PartialMessage<User>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.User";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "username", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "full_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "website", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "profile_picture", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "bio", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "counts", kind: "message", T: UserStats },
    { no: 46, name: "is_restricted", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 10, name: "created", kind: "message", T: Timestamp },
    { no: 11, name: "updated", kind: "message", T: Timestamp },
    { no: 15, name: "user_last_processing_time", kind: "message", T: Timestamp },
    { no: 16, name: "gender_info_last_compute_time", kind: "message", T: Timestamp },
    { no: 12, name: "emails", kind: "message", T: UserEmails },
    { no: 14, name: "gender_info", kind: "message", T: GenderInfo },
    { no: 18, name: "private_info", kind: "message", T: PrivateInfo },
    { no: 21, name: "age_info", kind: "message", T: AgeInfo },
    { no: 22, name: "geography", kind: "message", T: Geography },
    { no: 23, name: "language_info", kind: "message", T: LanguageInfo },
    { no: 24, name: "social_media", kind: "message", T: SocialMedia },
    { no: 28, name: "computed_stats", kind: "message", T: ComputedInfluencerStats },
    { no: 30, name: "pagerank", kind: "message", T: DoubleValue },
    { no: 32, name: "scrapped_info", kind: "message", T: UserScrappedInfo },
    { no: 33, name: "facebook_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 34, name: "facts_fingerprint", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 35, name: "gdpr_opt_out", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 36, name: "gdpr_opt_out_time", kind: "message", T: Timestamp },
    { no: 39, name: "brand_bit", kind: "enum", T: proto3.getEnumType(BrandBit) },
    { no: 40, name: "category_v2", kind: "enum", T: proto3.getEnumType(CategoryV2) },
    { no: 43, name: "topico", kind: "message", T: Topico },
    { no: 41, name: "ai_geo", kind: "message", T: AiGeography },
    { no: 42, name: "is_of_interest_info", kind: "message", T: InterestingInfo },
    { no: 45, name: "last_reel_polling_time", kind: "message", T: Timestamp },
    { no: 47, name: "is_creator_or_business", kind: "message", T: BoolValue },
    { no: 48, name: "hide_like_and_view_counts", kind: "message", T: BoolValue },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): User {
    return new User().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): User {
    return new User().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): User {
    return new User().fromJsonString(jsonString, options);
  }

  static equals(a: User | PlainMessage<User> | undefined, b: User | PlainMessage<User> | undefined): boolean {
    return proto3.util.equals(User, a, b);
  }
}

/**
 * @generated from message common.proto.UserEmails
 */
export class UserEmails extends Message<UserEmails> {
  /**
   * @generated from field: repeated string emails = 1;
   */
  emails: string[] = [];

  constructor(data?: PartialMessage<UserEmails>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.UserEmails";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "emails", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UserEmails {
    return new UserEmails().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UserEmails {
    return new UserEmails().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UserEmails {
    return new UserEmails().fromJsonString(jsonString, options);
  }

  static equals(a: UserEmails | PlainMessage<UserEmails> | undefined, b: UserEmails | PlainMessage<UserEmails> | undefined): boolean {
    return proto3.util.equals(UserEmails, a, b);
  }
}

/**
 * @generated from message common.proto.UserStats
 */
export class UserStats extends Message<UserStats> {
  /**
   * @generated from field: int32 posts = 1;
   */
  posts = 0;

  /**
   * @generated from field: int32 follows = 2;
   */
  follows = 0;

  /**
   * @generated from field: int32 followers = 3;
   */
  followers = 0;

  constructor(data?: PartialMessage<UserStats>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.UserStats";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "posts", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "follows", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "followers", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UserStats {
    return new UserStats().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UserStats {
    return new UserStats().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UserStats {
    return new UserStats().fromJsonString(jsonString, options);
  }

  static equals(a: UserStats | PlainMessage<UserStats> | undefined, b: UserStats | PlainMessage<UserStats> | undefined): boolean {
    return proto3.util.equals(UserStats, a, b);
  }
}

/**
 * stuff that we compute when scanning all images and persist back to cassandra.
 *
 * @generated from message common.proto.ComputedInfluencerStats
 */
export class ComputedInfluencerStats extends Message<ComputedInfluencerStats> {
  /**
   * @generated from field: google.protobuf.FloatValue engagement_score = 1;
   */
  engagementScore?: number;

  /**
   * TODO(mateo) engagement rate estimated or not
   *
   * @generated from field: bool engagement_score_estimated = 15;
   */
  engagementScoreEstimated = false;

  /**
   * @generated from field: common.proto.StatValue likes = 2;
   */
  likes?: StatValue;

  /**
   * @generated from field: common.proto.StatValue comments_count = 3;
   */
  commentsCount?: StatValue;

  /**
   * In this proto, the values are the number of seconds between 2 posts.
   *
   * @generated from field: common.proto.StatValue posting_frequency = 4;
   */
  postingFrequency?: StatValue;

  /**
   * Top tags used by the user
   *
   * @generated from field: common.proto.ComputedInfluencerStats.TopTags top_tags = 5;
   */
  topTags?: ComputedInfluencerStats_TopTags;

  /**
   * Number of times a top was used
   *
   * @generated from field: common.proto.ComputedInfluencerStats.TopTagsCounts top_tags_counts = 6;
   */
  topTagsCounts?: ComputedInfluencerStats_TopTagsCounts;

  /**
   * @generated from field: google.protobuf.Int32Value count_sponsored_posts = 7;
   */
  countSponsoredPosts?: number;

  /**
   * @generated from field: common.proto.StatValue sponsored_likes = 8;
   */
  sponsoredLikes?: StatValue;

  /**
   * @generated from field: common.proto.StatValue sponsored_comments_count = 9;
   */
  sponsoredCommentsCount?: StatValue;

  /**
   * @generated from field: common.proto.StatValue sponsored_posting_frequency = 10;
   */
  sponsoredPostingFrequency?: StatValue;

  /**
   * @generated from field: google.protobuf.FloatValue saturation_rate = 14;
   */
  saturationRate?: number;

  /**
   * Last post date ( this is the last post in db, may not be realtime )
   *
   * @generated from field: google.protobuf.Timestamp last_post = 11;
   */
  lastPost?: Timestamp;

  /**
   * Last sponsored post date ( this is the last post in db, may not be realtime )
   *
   * @generated from field: google.protobuf.Timestamp last_sponsored_post = 12;
   */
  lastSponsoredPost?: Timestamp;

  /**
   * @generated from field: common.proto.ComputedInfluencerStats.TopMentions top_mentions = 13;
   */
  topMentions?: ComputedInfluencerStats_TopMentions;

  constructor(data?: PartialMessage<ComputedInfluencerStats>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.ComputedInfluencerStats";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "engagement_score", kind: "message", T: FloatValue },
    { no: 15, name: "engagement_score_estimated", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 2, name: "likes", kind: "message", T: StatValue },
    { no: 3, name: "comments_count", kind: "message", T: StatValue },
    { no: 4, name: "posting_frequency", kind: "message", T: StatValue },
    { no: 5, name: "top_tags", kind: "message", T: ComputedInfluencerStats_TopTags },
    { no: 6, name: "top_tags_counts", kind: "message", T: ComputedInfluencerStats_TopTagsCounts },
    { no: 7, name: "count_sponsored_posts", kind: "message", T: Int32Value },
    { no: 8, name: "sponsored_likes", kind: "message", T: StatValue },
    { no: 9, name: "sponsored_comments_count", kind: "message", T: StatValue },
    { no: 10, name: "sponsored_posting_frequency", kind: "message", T: StatValue },
    { no: 14, name: "saturation_rate", kind: "message", T: FloatValue },
    { no: 11, name: "last_post", kind: "message", T: Timestamp },
    { no: 12, name: "last_sponsored_post", kind: "message", T: Timestamp },
    { no: 13, name: "top_mentions", kind: "message", T: ComputedInfluencerStats_TopMentions },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ComputedInfluencerStats {
    return new ComputedInfluencerStats().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ComputedInfluencerStats {
    return new ComputedInfluencerStats().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ComputedInfluencerStats {
    return new ComputedInfluencerStats().fromJsonString(jsonString, options);
  }

  static equals(a: ComputedInfluencerStats | PlainMessage<ComputedInfluencerStats> | undefined, b: ComputedInfluencerStats | PlainMessage<ComputedInfluencerStats> | undefined): boolean {
    return proto3.util.equals(ComputedInfluencerStats, a, b);
  }
}

/**
 * enveloppe.
 *
 * @generated from message common.proto.ComputedInfluencerStats.TopTags
 */
export class ComputedInfluencerStats_TopTags extends Message<ComputedInfluencerStats_TopTags> {
  /**
   * @generated from field: repeated string top_tags = 1;
   */
  topTags: string[] = [];

  constructor(data?: PartialMessage<ComputedInfluencerStats_TopTags>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.ComputedInfluencerStats.TopTags";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "top_tags", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ComputedInfluencerStats_TopTags {
    return new ComputedInfluencerStats_TopTags().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ComputedInfluencerStats_TopTags {
    return new ComputedInfluencerStats_TopTags().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ComputedInfluencerStats_TopTags {
    return new ComputedInfluencerStats_TopTags().fromJsonString(jsonString, options);
  }

  static equals(a: ComputedInfluencerStats_TopTags | PlainMessage<ComputedInfluencerStats_TopTags> | undefined, b: ComputedInfluencerStats_TopTags | PlainMessage<ComputedInfluencerStats_TopTags> | undefined): boolean {
    return proto3.util.equals(ComputedInfluencerStats_TopTags, a, b);
  }
}

/**
 * @generated from message common.proto.ComputedInfluencerStats.TopTagsCounts
 */
export class ComputedInfluencerStats_TopTagsCounts extends Message<ComputedInfluencerStats_TopTagsCounts> {
  /**
   * @generated from field: repeated int32 top_tags_count = 2;
   */
  topTagsCount: number[] = [];

  constructor(data?: PartialMessage<ComputedInfluencerStats_TopTagsCounts>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.ComputedInfluencerStats.TopTagsCounts";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 2, name: "top_tags_count", kind: "scalar", T: 5 /* ScalarType.INT32 */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ComputedInfluencerStats_TopTagsCounts {
    return new ComputedInfluencerStats_TopTagsCounts().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ComputedInfluencerStats_TopTagsCounts {
    return new ComputedInfluencerStats_TopTagsCounts().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ComputedInfluencerStats_TopTagsCounts {
    return new ComputedInfluencerStats_TopTagsCounts().fromJsonString(jsonString, options);
  }

  static equals(a: ComputedInfluencerStats_TopTagsCounts | PlainMessage<ComputedInfluencerStats_TopTagsCounts> | undefined, b: ComputedInfluencerStats_TopTagsCounts | PlainMessage<ComputedInfluencerStats_TopTagsCounts> | undefined): boolean {
    return proto3.util.equals(ComputedInfluencerStats_TopTagsCounts, a, b);
  }
}

/**
 * @generated from message common.proto.ComputedInfluencerStats.TopMentions
 */
export class ComputedInfluencerStats_TopMentions extends Message<ComputedInfluencerStats_TopMentions> {
  /**
   * @generated from field: repeated common.proto.ComputedInfluencerStats.TopMentions.MentionCount mentions = 1;
   */
  mentions: ComputedInfluencerStats_TopMentions_MentionCount[] = [];

  constructor(data?: PartialMessage<ComputedInfluencerStats_TopMentions>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.ComputedInfluencerStats.TopMentions";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "mentions", kind: "message", T: ComputedInfluencerStats_TopMentions_MentionCount, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ComputedInfluencerStats_TopMentions {
    return new ComputedInfluencerStats_TopMentions().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ComputedInfluencerStats_TopMentions {
    return new ComputedInfluencerStats_TopMentions().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ComputedInfluencerStats_TopMentions {
    return new ComputedInfluencerStats_TopMentions().fromJsonString(jsonString, options);
  }

  static equals(a: ComputedInfluencerStats_TopMentions | PlainMessage<ComputedInfluencerStats_TopMentions> | undefined, b: ComputedInfluencerStats_TopMentions | PlainMessage<ComputedInfluencerStats_TopMentions> | undefined): boolean {
    return proto3.util.equals(ComputedInfluencerStats_TopMentions, a, b);
  }
}

/**
 * @generated from message common.proto.ComputedInfluencerStats.TopMentions.MentionCount
 */
export class ComputedInfluencerStats_TopMentions_MentionCount extends Message<ComputedInfluencerStats_TopMentions_MentionCount> {
  /**
   * @generated from field: common.proto.Mention mention = 1;
   */
  mention?: Mention;

  /**
   * @generated from field: int32 count = 2;
   */
  count = 0;

  constructor(data?: PartialMessage<ComputedInfluencerStats_TopMentions_MentionCount>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.ComputedInfluencerStats.TopMentions.MentionCount";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "mention", kind: "message", T: Mention },
    { no: 2, name: "count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ComputedInfluencerStats_TopMentions_MentionCount {
    return new ComputedInfluencerStats_TopMentions_MentionCount().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ComputedInfluencerStats_TopMentions_MentionCount {
    return new ComputedInfluencerStats_TopMentions_MentionCount().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ComputedInfluencerStats_TopMentions_MentionCount {
    return new ComputedInfluencerStats_TopMentions_MentionCount().fromJsonString(jsonString, options);
  }

  static equals(a: ComputedInfluencerStats_TopMentions_MentionCount | PlainMessage<ComputedInfluencerStats_TopMentions_MentionCount> | undefined, b: ComputedInfluencerStats_TopMentions_MentionCount | PlainMessage<ComputedInfluencerStats_TopMentions_MentionCount> | undefined): boolean {
    return proto3.util.equals(ComputedInfluencerStats_TopMentions_MentionCount, a, b);
  }
}

/**
 * Stats computed by lefty for influencers (engagement, average likes, etc...)
 *
 * @generated from message common.proto.InfluencerStats
 */
export class InfluencerStats extends Message<InfluencerStats> {
  /**
   * @generated from field: float engagement_score = 1;
   */
  engagementScore = 0;

  /**
   * @generated from field: float avg_likes = 2;
   */
  avgLikes = 0;

  /**
   * If you're an influencer, we should always be able to report your audience
   *
   * @generated from field: bool can_report = 3;
   */
  canReport = false;

  /**
   * Languages spoken by the user with their percentage
   *
   * @generated from field: repeated common.proto.LanguagePercent languages = 4;
   */
  languages: LanguagePercent[] = [];

  /**
   * Top tags used by the user
   *
   * @generated from field: repeated string top_tags = 5;
   */
  topTags: string[] = [];

  /**
   * Average likes over the last 2 months
   *
   * @generated from field: float avg_likes_two_months = 6;
   */
  avgLikesTwoMonths = 0;

  /**
   * Number of tags for each top tags
   *
   * @generated from field: repeated int32 number_of_tags = 7;
   */
  numberOfTags: number[] = [];

  /**
   * Other social media accounts detected
   *
   * @generated from field: common.proto.SocialMedia social_media = 8;
   */
  socialMedia?: SocialMedia;

  constructor(data?: PartialMessage<InfluencerStats>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.InfluencerStats";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "engagement_score", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 2, name: "avg_likes", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 3, name: "can_report", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 4, name: "languages", kind: "message", T: LanguagePercent, repeated: true },
    { no: 5, name: "top_tags", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 6, name: "avg_likes_two_months", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 7, name: "number_of_tags", kind: "scalar", T: 5 /* ScalarType.INT32 */, repeated: true },
    { no: 8, name: "social_media", kind: "message", T: SocialMedia },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): InfluencerStats {
    return new InfluencerStats().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): InfluencerStats {
    return new InfluencerStats().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): InfluencerStats {
    return new InfluencerStats().fromJsonString(jsonString, options);
  }

  static equals(a: InfluencerStats | PlainMessage<InfluencerStats> | undefined, b: InfluencerStats | PlainMessage<InfluencerStats> | undefined): boolean {
    return proto3.util.equals(InfluencerStats, a, b);
  }
}

/**
 * @generated from message common.proto.Medu
 */
export class Medu extends Message<Medu> {
  /**
   * @generated from field: google.protobuf.StringValue user_id = 1;
   */
  userId?: string;

  /**
   * @generated from field: google.protobuf.StringValue mentioned_id = 2;
   */
  mentionedId?: string;

  /**
   * @generated from field: google.protobuf.StringValue user_name = 3;
   */
  userName?: string;

  /**
   * @generated from field: google.protobuf.StringValue mentioned_name = 4;
   */
  mentionedName?: string;

  /**
   * @generated from field: google.protobuf.Int32Value count = 5;
   */
  count?: number;

  constructor(data?: PartialMessage<Medu>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.Medu";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "user_id", kind: "message", T: StringValue },
    { no: 2, name: "mentioned_id", kind: "message", T: StringValue },
    { no: 3, name: "user_name", kind: "message", T: StringValue },
    { no: 4, name: "mentioned_name", kind: "message", T: StringValue },
    { no: 5, name: "count", kind: "message", T: Int32Value },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Medu {
    return new Medu().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Medu {
    return new Medu().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Medu {
    return new Medu().fromJsonString(jsonString, options);
  }

  static equals(a: Medu | PlainMessage<Medu> | undefined, b: Medu | PlainMessage<Medu> | undefined): boolean {
    return proto3.util.equals(Medu, a, b);
  }
}

/**
 * @generated from message common.proto.Mingu
 */
export class Mingu extends Message<Mingu> {
  /**
   * @generated from field: google.protobuf.StringValue user_id = 1;
   */
  userId?: string;

  /**
   * @generated from field: google.protobuf.StringValue mentioned_id = 2;
   */
  mentionedId?: string;

  /**
   * @generated from field: google.protobuf.StringValue user_name = 3;
   */
  userName?: string;

  /**
   * @generated from field: google.protobuf.StringValue mentioned_name = 4;
   */
  mentionedName?: string;

  /**
   * @generated from field: google.protobuf.Int32Value count = 5;
   */
  count?: number;

  constructor(data?: PartialMessage<Mingu>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.Mingu";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "user_id", kind: "message", T: StringValue },
    { no: 2, name: "mentioned_id", kind: "message", T: StringValue },
    { no: 3, name: "user_name", kind: "message", T: StringValue },
    { no: 4, name: "mentioned_name", kind: "message", T: StringValue },
    { no: 5, name: "count", kind: "message", T: Int32Value },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Mingu {
    return new Mingu().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Mingu {
    return new Mingu().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Mingu {
    return new Mingu().fromJsonString(jsonString, options);
  }

  static equals(a: Mingu | PlainMessage<Mingu> | undefined, b: Mingu | PlainMessage<Mingu> | undefined): boolean {
    return proto3.util.equals(Mingu, a, b);
  }
}

