import { inject, Injectable } from '@angular/core';
import { StringValue } from '@bufbuild/protobuf';
import { LeftyParentAppBridge } from '@frontend2/core';
import { injectLeftyEventsBus } from '../../events/events.service';
import { PlainTextInfluencerUploadData } from '../influencers-management.models';
import { InfluencersUploadToTargetState } from './influencer-upload.models';

@Injectable()
export abstract class InfluencerUploadService {
  readonly leftyEvents = injectLeftyEventsBus();
  readonly parentAppBridge = inject(LeftyParentAppBridge);

  abstract buildErrorMessage(e: unknown): string;

  /// should trigger API call and return bronco token
  abstract doUploadRequest(
    uploadData: PlainTextInfluencerUploadData,
    targetId: bigint,
  ): Promise<StringValue>;

  abstract onUploadDone(state: InfluencersUploadToTargetState): void;
}
