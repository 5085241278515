import { enableProdMode, Type } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import {
  initDatadog,
  initPosthog,
  initSentry,
  IS_IN_IFRAME,
  isNotEmptyString,
  isNotNil,
} from '@frontend2/core';
import { PostHogConfig } from 'posthog-js';

interface Environment {
  readonly name: string;
  readonly version: string;

  readonly isProduction?: boolean;
  readonly enableAngularProdMode?: boolean;

  readonly sentryDsn?: string;
  readonly posthogToken?: string;
  readonly posthogHost?: string;

  readonly datadog?: {
    readonly applicationId: string;
    readonly clientToken: string;
  };
}

function _isApp2Type(): boolean {
  return window.location.host.includes('2.lefty.io');
}

function _redirectToHostApp(): void {
  // remove `2.` from host (ex: `app2.lefty.io` => `app.lefty.io`)
  const host = window.location.host.replace('2.', '.');

  // then apply hash as path
  // (ex: `app2.lefty.io/#/home` => `app.lefty.io/home`)
  window.location.href = `https://${host}${window.location.hash.slice(1)}`;
}

export function initLeftyApp<Module>(
  appName: string,
  moduleType: Type<Module>,
  environment: Environment,
  options?: {
    readonly posthogConfig?: Partial<PostHogConfig>;
  },
): void {
  console.log('Init Lefty', environment.name, environment.version);
  const posthogToken = environment.posthogToken;
  const enablePosthog = isNotEmptyString(posthogToken);

  if (environment.isProduction && IS_IN_IFRAME === false && _isApp2Type()) {
    // if someone try to access the app without IFRAME
    // force redirection to the parent app url keeping the hash as path
    _redirectToHostApp();
    return;
  }

  if (environment.isProduction || environment.enableAngularProdMode) {
    enableProdMode();
  }

  if (enablePosthog) {
    setTimeout(() => {
      console.log('Init Posthog');
      initPosthog(posthogToken, environment.version, {
        ...options?.posthogConfig,
        api_host: environment.posthogHost,
      });
    }, 0);
  }

  // Note (hadrien):
  // We don't want to have both Sentry and Datadog running on the browser.
  // Currently, Datadog is active on staging but not production,
  // while Sentry is disabled on staging but enabled in production.
  if (environment.isProduction) {
    const sentryDsn = environment.sentryDsn;
    const enableSentry = isNotEmptyString(sentryDsn);
    if (enableSentry) {
      console.log('Init Sentry');
      initSentry(environment.name, sentryDsn, {
        enablePosthog,
        isProduction: environment.isProduction,
        release: environment.version,
      });
    }
  } else {
    const datadog = environment.datadog;
    if (isNotNil(datadog) && environment.isProduction === false) {
      console.log('Init Datadog');
      initDatadog(
        appName,
        datadog.applicationId,
        datadog.clientToken,
        environment.name,
        {
          isProduction: environment.isProduction,
          release: environment.version,
        },
      );
    }
  }

  platformBrowserDynamic()
    .bootstrapModule(moduleType)
    .catch((err) => console.error(err));
}
