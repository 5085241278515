import {
  ChangeDetectionStrategy,
  Component,
  effect,
  signal,
} from '@angular/core';
import { takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop';
import { Duration } from '@bufbuild/protobuf';
import {
  DurationUnit,
  isNil,
  SECONDS_IN_A_DAY,
  SECONDS_IN_AN_HOUR,
} from '@frontend2/core';
import { LeftyControlValueAccessor } from '../form';
import { LeftyFormNumberInputComponent } from '../lefty-form-input/lefty-form-number-input.component';
import { LeftyFormSelectComponent } from '../lefty-form-select/lefty-form-select.component';
import { LeftyFormComponent } from '../lefty-form/lefty-form.component';

@Component({
  selector: 'lefty-duration-input',
  templateUrl: 'lefty-duration-input.component.html',
  styleUrls: ['lefty-duration-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    LeftyFormComponent,
    LeftyFormNumberInputComponent,
    LeftyFormSelectComponent,
  ],
})
export class LeftyDurationInputComponent extends LeftyControlValueAccessor<
  Duration | undefined
> {
  constructor() {
    super();
    effect(
      () => {
        const durationValue = this.durationValue();
        const durationUnit = this.durationUnit();

        if (isNil(durationValue)) {
          this.setValueAndNotify(undefined);
          return;
        }

        const duration = this._buildDuration(durationValue, durationUnit);

        this.setValueAndNotify(duration);
      },
      {
        allowSignalWrites: true,
      },
    );

    toObservable(this.value)
      .pipe(takeUntilDestroyed())
      .subscribe((v) => {
        const bestDurationUnit = this._findBestDurationUnit(v);
        if (this.durationUnit() !== bestDurationUnit) {
          this.durationUnit.set(bestDurationUnit);
        }
        this.durationValue.set(this.recalculateDurationValue(bestDurationUnit));
      });
  }

  readonly DEFAULT_DURATION_UNIT: DurationUnit = 'hours';

  readonly durationUnit = signal<DurationUnit>(this.DEFAULT_DURATION_UNIT);
  readonly durationValue = signal<number | undefined>(undefined);

  readonly supportedDurationUnits: DurationUnit[] = [
    'days',
    'hours',
    'minutes',
  ];

  durationUnitRenderer(unit: DurationUnit): string {
    return $localize`${unit}`;
  }

  recalculateDurationValue(unit: DurationUnit): number | undefined {
    const duration = this.value();
    if (isNil(duration)) {
      return;
    }

    const seconds = Number(duration.seconds);

    switch (unit) {
      case 'seconds':
        return seconds;
      case 'minutes':
        return seconds / 60;
      case 'hours':
        return seconds / SECONDS_IN_AN_HOUR;
      case 'days':
        return seconds / SECONDS_IN_A_DAY;
      default:
        return;
    }
  }

  private _findBestDurationUnit(duration: Duration | undefined): DurationUnit {
    if (isNil(duration)) {
      return this.durationUnit();
    }

    const inMinute = Number(duration.seconds) / 60;
    const inHour = inMinute / 60;
    if (inMinute % 60 === 0) {
      if (inHour % 24 === 0) {
        return 'days';
      }
      return 'hours';
    }
    return 'minutes';
  }

  private _buildDuration(
    durationValue: number,
    durationUnit: DurationUnit,
  ): Duration {
    const newDuration = new Duration();

    switch (durationUnit) {
      case 'days':
        newDuration.seconds = BigInt(durationValue * SECONDS_IN_A_DAY);
        break;
      case 'hours':
        newDuration.seconds = BigInt(durationValue * SECONDS_IN_AN_HOUR);
        break;
      case 'minutes':
        newDuration.seconds = BigInt(durationValue * 60);
        break;
    }
    return newDuration;
  }

  override isValidType(obj: unknown): obj is Duration | undefined {
    return isNil(obj) || obj instanceof Duration;
  }

  override writeValue(obj: unknown): void {
    if (isNil(obj)) {
      this.durationUnit.set(this.DEFAULT_DURATION_UNIT);
    }
    super.writeValue(obj);
  }
}
