<ng-template #formTemplate>
  @for (type of settings().types; track $index) {
    <ng-container branch>
      @if (isLocationKeyword(type)) {
        <lefty-chips-autocomplete
          [value]="getLocations(type)"
          (valueChange)="setLocationKeywords(type, $event)"
          [withLogicOperator]="withLogicOperators()"
          branch
          [class.ng-invalid]="ngControl?.invalid"
          [class.ng-dirty]="ngControl?.dirty"
          [class.ng-touched]="ngControl?.dirty"
          [label]="getSettingLable(type)"
          [placeholder]="getSettingPlaceholder(type)"
          [noFiltering]="true"
          [options]="locationOptions()"
          (inputTextChange)="locationQuery.set($event)"
          [itemRenderer]="locationRenderer"
          [emptyPlaceholder]="locationEmptyPlaceholder()"
          [loading]="loading()"
          [logicOperatorValue]="getLogicOperatorForKeyword(type)"
          (logicOperatorValueChange)="setLogicOperatorForKeyword(type, $event)"
        >
        </lefty-chips-autocomplete>
      } @else {
        <lefty-chips-editor
          [withLogicOperator]="withLogicOperators()"
          branch
          [class.ng-invalid]="ngControl?.invalid"
          [class.ng-dirty]="ngControl?.dirty"
          [class.ng-touched]="ngControl?.touched"
          [label]="getSettingLable(type)"
          [placeholder]="getSettingPlaceholder(type)"
          [value]="getKeywords(type)"
          (valueChange)="setKeywords(type, $event)"
          [canRemoveChip]="true"
          [prefix]="chipsPrefix(type)"
          [delimiters]="getSettingDelimeters(type)"
          [logicOperatorValue]="getLogicOperatorForKeyword(type)"
          (logicOperatorValueChange)="setLogicOperatorForKeyword(type, $event)"
        >
        </lefty-chips-editor>
      }
    </ng-container>
  }
</ng-template>

<div class="container">
  <div class="header-container">
    @if (!hideNetworks()) {
      <div class="network">
        @if (cantEditSelectedNetwork() || availableNetworks().length === 0) {
          <span class="network-name body-1">
            <network-icon [network]="network()"></network-icon>
            {{ readableNetwork(network()) }}
          </span>
        } @else {
          <network-form-select
            label=""
            (valueChange)="setNetwork($event)"
            [value]="network()"
            [networks]="availableNetworks()"
            buttonClass="outline"
          >
          </network-form-select>
        }
      </div>
    }

    @if (canRemove()) {
      <div class="remove">
        <button
          leftyButton="plain"
          class="remove-link"
          (click)="clickRemove$.emit($event)"
          i18n
        >
          Remove
        </button>
      </div>
    }
  </div>
  @if (ngControl?.invalid) {
    <lefty-feedback type="error">
      {{ settings().errorMessage }}
    </lefty-feedback>
  }

  @if (mustHaveLogicOperatorTree()) {
    <lefty-logic-operator-tree
      [value]="logicOperator()"
      (valueChange)="setLogicOperator($event)"
      [topOffset]="logicTreeOffset"
    >
      <ng-container
        *ngTemplateOutlet="formTemplate"
        branch
      ></ng-container>
    </lefty-logic-operator-tree>
  } @else {
    <div class="form-container">
      <ng-container *ngTemplateOutlet="formTemplate"></ng-container>
    </div>
  }
</div>
