import {
  ChangeDetectionStrategy,
  Component,
  computed,
  inject,
  output,
  signal,
  viewChild,
} from '@angular/core';
import { ConnectError } from '@connectrpc/connect';
import { LimitationsService } from '@frontend2/api';
import {
  createInfluencer,
  getSuccessResultFromInfluencerUpload,
  isNil,
  isNotEmptyArray,
  isNotEmptyString,
  isNotNil,
} from '@frontend2/core';
import { Program } from '@frontend2/proto/librarian/proto/affiliation_pb';
import { UploadInfluencersResult } from '@frontend2/proto/librarian/proto/upload_influencer_pb';
import { AddAffiliatesService } from '../affiliation/add-affiliates-service';
import { AddOrEditProgramDialogComponent } from '../affiliation/add-or-edit-program-dialog/add-or-edit-program-dialog.component';
import { LeftyAffiliatesLimitationDialogComponent } from '../affiliation/affiliates-limitation-dialog/affiliates-limitation-dialog.component';
import { AffiliateAdditionResult } from '../affiliation/affiliates.models';
import { LeftySelectProgramDialogComponent } from '../affiliation/select-program-dialog/select-program-dialog.component';
import { showToastException } from '../error-handler';
import { AffiliationUploadService } from '../influencers-management/influencer-upload.services/affiliation-upload.service';
import {
  AddInfluencersMethod,
  AddInfluencersSelectionState,
  createAddInfluencersSelectionState,
  createInfluencersSelection,
  getAddInfluencersSelectionStateCount,
} from '../influencers-management/influencers-management.models';
import { LeftySelectInfluencersDialogComponent } from '../influencers-management/select-influencers-dialog.component';
import { LeftyUploadInfluencerLoaderDialogComponent } from '../influencers-management/upload-influencer-loader-dialog/upload-influencer-loader-dialog.component';
import { InfluencerProfilePicturesComponent } from '../lefty-influencers-profile-pictures-stack/lefty-influencers-profile-pictures-stack.component';
import { ToastManager } from '../toast/toast.service';

@Component({
  selector: 'add-affiliates-dialog',
  templateUrl: './add-affiliates-dialog.component.html',
  styleUrls: ['./add-affiliates-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    LeftySelectInfluencersDialogComponent,
    LeftyUploadInfluencerLoaderDialogComponent,
    LeftySelectProgramDialogComponent,
    InfluencerProfilePicturesComponent,
    LeftyAffiliatesLimitationDialogComponent,
    AddOrEditProgramDialogComponent,
  ],
})
export class AddAffiliatesDialogComponent {
  readonly uploadService = inject(AffiliationUploadService);
  readonly addService = inject(AddAffiliatesService);
  readonly toastManager = inject(ToastManager);
  readonly limitation = inject(LimitationsService);

  readonly ADD_AFFILIATE_SUPPORTED_METHODS: AddInfluencersMethod[] = [
    'search-creator',
    'directory',
    'plain-text',
  ];

  readonly selectInfluencersDialog = viewChild.required(
    LeftySelectInfluencersDialogComponent,
  );

  readonly selectProgramDialog = viewChild.required(
    LeftySelectProgramDialogComponent,
  );

  readonly influencerUploadProgressDialog = viewChild.required(
    LeftyUploadInfluencerLoaderDialogComponent,
  );

  readonly limitationDialog = viewChild.required(
    LeftyAffiliatesLimitationDialogComponent,
  );

  readonly success$ = output<AffiliateAdditionResult>();

  open(): void {
    this.selectInfluencersDialog().openWith(
      this.ADD_AFFILIATE_SUPPORTED_METHODS,
    );
  }

  readonly influencerSelection = signal(createAddInfluencersSelectionState());
  readonly selectedProgram = signal(new Program());

  readonly baseSnippetInSelection = computed(() =>
    this.influencerSelection().influencers.map((i) => i.baseSnippet),
  );

  readonly hasBaseSnippetInSelection = computed(() =>
    isNotEmptyArray(this.baseSnippetInSelection()),
  );

  async influencersSelected(
    selection: AddInfluencersSelectionState,
  ): Promise<void> {
    if (this.canAddAffiliates(selection)) {
      this.influencerSelection.set(selection);
      this.selectProgramDialog().open();
    } else {
      this.limitationDialog().open();
    }
  }

  canAddAffiliates(selection: AddInfluencersSelectionState): boolean {
    const count = getAddInfluencersSelectionStateCount(selection);
    return this.limitation.canAddAffiliates(count);
  }

  cancelProgram(): void {
    const method = this.influencerSelection().selectedAddMethod;
    if (isNil(method)) {
      this.selectInfluencersDialog().openWith(
        this.ADD_AFFILIATE_SUPPORTED_METHODS,
      );
    } else {
      this.selectInfluencersDialog().openDialogForSelectedMethod(method);
    }
  }

  async submit(program: Program): Promise<void> {
    this.selectedProgram.set(program);
    const method = this.influencerSelection().selectedAddMethod;

    if (
      method === 'plain-text' &&
      isNotEmptyArray(this.influencerSelection().plainTextUploadData.usernames)
    ) {
      await this._uploadInfluencers();
    } else {
      await this._addInfluencers(program);
    }
    this.resetState();
  }

  async _uploadInfluencers(): Promise<void> {
    try {
      //we open the progress as loader, then open it again with the right token
      this.influencerUploadProgressDialog().open();
      const resp = await this.uploadService.doUploadRequest(
        this.influencerSelection().plainTextUploadData,
        this.selectedProgram().programId ?? BigInt(0),
      );

      this.influencerUploadProgressDialog().openWith(
        resp.value,
        this.influencerSelection().plainTextUploadData,
      );
    } catch (e) {
      if (e instanceof ConnectError) {
        const message = this.uploadService.buildErrorMessage(e.code);
        if (isNotEmptyString(message)) {
          this.toastManager.showError(message);
        } else {
          showToastException(this.toastManager, e);
        }
      }
      this.influencerUploadProgressDialog().close();
    }
  }

  async _addInfluencers(program: Program): Promise<void> {
    const influencers = this.influencerSelection().influencers;
    const completeSelection = createInfluencersSelection(influencers);
    await this.addService.addInfluencers({
      program: program,
      influencersSelector: completeSelection,
    });
    this.success$.emit({
      influencers: influencers,
      program: program,
      isClosedWithoutSave: false,
    });
  }

  uploadResultReady(res: UploadInfluencersResult): void {
    const successful = getSuccessResultFromInfluencerUpload(res);
    const cards = successful.map((s) => s.creatorCard).filter(isNotNil);
    const influencers = cards.map((c) => createInfluencer(c));
  }

  uploadResultClosed(): void {
    //since we dont get the influencers in the UploadInfluencersResult response yet, we cant send them in the success,
    //we can add when the backend supports
    //in that case we should use uploadResultReady
    this.success$.emit({
      influencers: [],
      program: this.selectedProgram(),
      isClosedWithoutSave: false,
    });
  }

  resetState(): void {
    this.influencerSelection.set(createAddInfluencersSelectionState());
    this.selectedProgram.set(new Program());
  }

  closeWithoutSave(): void {
    this.success$.emit({
      influencers: [],
      program: new Program(),
      isClosedWithoutSave: true,
    });
  }
}
