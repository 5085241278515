import { Network } from '@frontend2/proto/common/proto/common_pb';
import { plural } from '../utils/common.helpers';

export abstract class Messages {
  static welcome: string = $localize`Welcome`;
  static influencer: string = $localize`influencer`;
  static errorHappen: string = $localize`An unknown error happened.`;
  static errorHappenReportToAM: string = $localize`An unknown error happened. Please report it to your account manager.`;
  static sortBy = $localize`Sort by`;
  static hide = $localize`Hide`;
  static close = $localize`Close`;
  static engagementRate = $localize`Engagement rate`;
  static save = $localize`Save`;
  static create = $localize`Create`;
  static cancel = $localize`Cancel`;
  static confirm = $localize`Confirm`;
  static women = $localize`Women`;
  static men = $localize`Men`;
  static accessDenied = $localize`Access denied`;
  static reach = $localize`Reach`;
  static averageReach = $localize`Average reach`;
  static averageCoverage = $localize`Average coverage`;
  static averageReshares = $localize`Average reshares`;
  static saturationRate = $localize`Saturation rate`;
  static success = $localize`Success`;
  static loading = $localize`Loading`;
  static filters = $localize`Filters`;
  static clearAllFilters = $localize`Clear all filters`;
  static clearAll = $localize`Clear all`;
  static networks = $localize`Networks`;
  static allNetworks = $localize`All Networks`;
  static unknown = $localize`Unknown`;
  static selectAll = $localize`Select all`;
  static notFound = $localize`Not found`;
  static download = $localize`Download`;
  static debug = $localize`Debug`;
  static select = $localize`Select`;
  static marked = $localize`Marked`;
  static signIn = $localize`Sign in`;
  static signUp = $localize`Sign Up`;
  static logout = $localize`Logout`;
  static clear = $localize`Clear`;
  static collaborations = $localize`Collaborations`;
  static conversations = $localize`Conversations`;
  static settings = $localize`Settings`;
  static requiredError: string = $localize`This field is required`;
  static avgViews = $localize`Avg. views`;
  static engRate = $localize`Eng. rate`;
  static delete = $localize`Delete`;
  static remove = $localize`Remove`;
  static edit = $localize`Edit`;
  static archive = $localize`Archive`;
  static unarchive = $localize`Unarchive`;
  static yes = $localize`Yes`;
  static no = $localize`No`;
  static search = $localize`Search`;
  static contactUs = $localize`Contact us`;
  static send = $localize`Send`;
  static add = $localize`Add`;

  static creatorsLabel(count: number | bigint): string {
    return plural(count, {
      one: $localize`influencer`,
      other: $localize`influencers`,
    });
  }

  static profilesLabel(count: number | bigint): string {
    return plural(count, {
      one: $localize`profile`,
      other: $localize`profiles`,
    });
  }

  static followersLabel(count: number | bigint): string {
    return plural(count, {
      one: $localize`follower`,
      other: $localize`followers`,
    });
  }

  static impressions(network: Network): string {
    if (network === Network.YOUTUBE) {
      return $localize`Views`;
    }
    return $localize`Impressions`;
  }

  static averageImpressions(network: Network): string {
    if (network === Network.YOUTUBE) {
      return $localize`Average views`;
    }
    return $localize`Average impressions`;
  }

  static minLengthError(value: number): string {
    return $localize`The entered value should be longer than ${value}`;
  }

  static maxLengthError(value: number): string {
    return $localize`The entered value should be shorter than ${value}`;
  }
}

export abstract class AppTitles {
  static notFound: string = $localize`Not found`;
  static campaignNotFound: string = $localize`Campaign not found`;
  static login: string = $localize`Login`;
  static ssoLogin: string = $localize`SSO Login`;
  static reconnect: string = $localize`Reconnect`;
  static resetPassword: string = $localize`Reset password`;
  static campaigns: string = $localize`Campaigns`;
  static castings: string = $localize`Castings`;
  static analytics: string = $localize`Analytics`;
  static discover: string = $localize`Discover`;
  static audience: string = $localize`Audience search`;
  static account: string = $localize`Account`;
  static plan: string = $localize`Plan`;
  static invoices: string = $localize`Invoices`;
  static notifications: string = $localize`Notifications`;
  static emvSettings: string = $localize`EMV`;
  static currencySettings: string = $localize`Currency`;
  static customFields: string = $localize`Custom fields`;
  static campaignCustomFields: string = $localize`Campaign fields`;
  static influencerCustomFields: string = $localize`Influencer fields`;
  static manageLabels: string = $localize`Labels`;
  static campaignLabels: string = $localize`Campaign Labels`;
  static postLabels: string = $localize`Post Labels`;
  static influencerLabels: string = $localize`Influencer Labels`;
  static directory: string = $localize`Directory`;
  static directorySettings: string = $localize`Directory settings`;
  static affiliation: string = $localize`Affiliation`;
  static monitoringDashboard: string = $localize`Monitoring Dashboard`;
  static manualClassifier: string = $localize`Manual Classifier`;
  static paymentMethods: string = $localize`Payment methods`;
  static messages: string = $localize`Messages`;
  static campaignInfluencers: string = $localize`Influencers`;
  static campaignPosts: string = $localize`Posts`;
  static campaignReport: string = $localize`Report`;
  static campaignSettings: string = $localize`Settings`;
  static directoryPosts: string = $localize`Posts`;
  static directoryReport: string = $localize`Report`;
  static payments: string = $localize`Payments`;
  static landingPage: string = $localize`Landing page`;
  static helpPage: string = $localize`Help center`;
  static brands: string = $localize`Brands`;
  static userPermissions: string = $localize`User Permissions`;
  static integrations: string = $localize`Integrations`;
  static productSeeding: string = $localize`Product Seeding`;
  static affiliates: string = $localize`Affiliates`;
  static affiliationLinks: string = $localize`Links`;
  static affiliationPromoCodes: string = $localize`Promo Codes`;
  static affiliationConversions: string = $localize`Conversions`;
  static affiliationReport: string = $localize`Report`;
  static affiliationSettings: string = $localize`Settings`;
  static affiliationDestinationUrl: string = $localize`Destination URLs`;
  static affiliationPrograms: string = $localize`Programs`;
  static affiliationPixels: string = $localize`Pixels`;
}

export class MessagesKPI {
  static emvFormula = $localize`
EMV is calculated based on the number of estimated impressions and your CPM.

<strong>EMV = Impressions x CPM / 1000</strong>
      `;
}
