import {
  ChangeDetectionStrategy,
  Component,
  computed,
  input,
  output,
  signal,
} from '@angular/core';
import { takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop';
import { injectLibrarianPoolClient } from '@frontend2/api';
import {
  addOrUpdateInArray,
  isEmptyArray,
  isNotEmptyArray,
  isNotEmptyString,
  isNotNil,
  LeftyFormat,
  plural,
  removeFromArray,
} from '@frontend2/core';
import {
  BasicPagination,
  LogicOperator,
} from '@frontend2/proto/common/proto/common_pb';
import { Campaign } from '@frontend2/proto/librarian/proto/campaigns_pb';
import {
  CreatorPoolRequestV2,
  DynamicFilter,
} from '@frontend2/proto/librarian/proto/common_pb';
import { EntityLabel } from '@frontend2/proto/librarian/proto/entity_labels_pb';
import { CreatorPoolResponse } from '@frontend2/proto/librarian/proto/pool_pb';
import { debounceTime, merge } from 'rxjs';
import { LeftyCheckboxComponent } from '../../checkbox/checkbox.component';
import { showToastException } from '../../error-handler';
import { IntersectionObserverDirective } from '../../intersection-observer.directive';
import { LeftyButtonDirective } from '../../lefty-button-directive/lefty-button.directive';
import { LeftyDialogComponent } from '../../lefty-dialog/lefty-dialog.component';
import { DialogBase } from '../../lefty-dialog/lefty-dialog.helpers';
import { LeftyDirectoryFiltersButtonComponent } from '../../lefty-directory-filters-button/directory-filters.component';
import {
  addCampaignsFilter,
  addInfluencerLabelsFilter,
  addSearchTextFilter,
} from '../../lefty-dynamic-filters/dynamic-filters.helpers';
import { LeftyFormInputComponent } from '../../lefty-form-input/lefty-form-input.component';
import { LeftyTabsComponent } from '../../lefty-tabs/lefty-tabs.component';
import { injectToastManager } from '../../toast/toast.service';
import { SelectFromDirectoryInfluencerRowItemComponent } from './influencer-row-item/influencer-row-item.component';

@Component({
  selector: 'select-influencers-from-directory-dialog',
  templateUrl: 'select-from-directory-dialog.component.html',
  styleUrls: ['./select-from-directory-dialog.component.scss'],
  providers: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    LeftyDialogComponent,
    LeftyTabsComponent,
    LeftyFormInputComponent,
    LeftyDirectoryFiltersButtonComponent,
    LeftyCheckboxComponent,
    SelectFromDirectoryInfluencerRowItemComponent,
    IntersectionObserverDirective,
    LeftyButtonDirective,
  ],
})
export class SelectInfluencersFromDirectoryDialogComponent extends DialogBase {
  constructor() {
    super();
    merge(
      toObservable(this.searchValue),
      toObservable(this.filterSelectedCampaigns),
      toObservable(this.filterSelectedInfluencerLabels),
      toObservable(this.isVisible),
    )
      .pipe(takeUntilDestroyed(), debounceTime(200))
      .subscribe(() => {
        if (this.isVisible()) {
          this.applyFilters();
        }
      });
  }

  applyFilters(): void {
    this.influencers.set([]);
    this.paginationFrom.set(0);
    this._getAndAppendInfluencers();
  }

  private readonly _librarianPool = injectLibrarianPoolClient();
  private readonly toastManager = injectToastManager();

  readonly title = input($localize`Select influencers from directory`);

  readonly searchValue = signal('');

  readonly tabIndex = signal(0);

  readonly isInfluencersTab = computed(() => this.tabIndex() === 0);

  readonly dialogTabs = computed(() => {
    return [
      { label: $localize`Influencers`, id: '1' },
      {
        label: $localize`Selection (${this.selectedInfluencers().length})`,
        id: '2',
      },
    ];
  });

  readonly paginationFrom = signal(0);
  readonly paginationSize = 15;

  readonly totalHits = signal(BigInt(0));

  readonly loading = signal(false);

  readonly ghostArray = Array(10);

  readonly selectedInfluencers = signal<CreatorPoolResponse[]>([]);
  readonly selectedInfluencersIds = computed(() =>
    this.selectedInfluencers()
      .map((i) => i.baseSnippet?.userId)
      .filter(isNotNil),
  );

  readonly influencers = signal<CreatorPoolResponse[]>([]);

  readonly filterSelectedInfluencerLabels = signal(
    new Map<bigint, EntityLabel>(),
  );
  readonly filterSelectedCampaigns = signal(new Map<bigint, Campaign>());

  readonly saved$ = output<CreatorPoolResponse[]>();

  readonly back$ = output<void>();

  readonly dialogDismissed$ = output<void>();

  readonly submitButtonText = input($localize`Save`);

  readonly totalHitsText = computed(() => {
    return $localize`${LeftyFormat.format(this.totalHits(), { showZero: true })} ${plural(this.totalHits(), { one: $localize`Result`, other: $localize`Results` })}`;
  });

  readonly selectAllValue = computed(() => {
    if (isEmptyArray(this.influencers())) {
      return false;
    }

    const influencerIds = this.influencers()
      .map((i) => i.baseSnippet?.userId)
      .filter(isNotNil);
    return influencerIds.every((i) =>
      this.selectedInfluencersIds().includes(i),
    );
  });

  nextPage(): void {
    if (this.totalHits() > this.paginationFrom()) {
      this._getAndAppendInfluencers();
    }
  }

  selectAllChanged(val: boolean): void {
    this.influencers().forEach((influencer) => {
      this.addOrRemoveFromSelection(val, influencer);
    });
  }

  private async _getAndAppendInfluencers(): Promise<void> {
    this.loading.set(true);
    try {
      const resp = await this._librarianPool.searchV2(
        this._buildSearchRequest(
          this.filterSelectedInfluencerLabels(),
          this.filterSelectedCampaigns(),
        ),
      );
      this.paginationFrom.set(this.paginationFrom() + this.paginationSize);
      this.influencers.set([...this.influencers(), ...resp.responses]);
      this.totalHits.set(resp.totalHits);
    } catch (e) {
      showToastException(this.toastManager, e);
    } finally {
      this.loading.set(false);
    }
  }

  private _buildSearchRequest(
    selectedLabelsFilter: Map<bigint, EntityLabel>,
    selectedCampaignsFilter: Map<bigint, Campaign>,
  ): CreatorPoolRequestV2 {
    const labelsFilter = Array.from(selectedLabelsFilter.keys()).map((l) =>
      l.toString(),
    );
    const campaignsFilter = Array.from(selectedCampaignsFilter.keys()).map(
      (l) => l.toString(),
    );

    let dynamicFilter = new DynamicFilter({
      operator: LogicOperator.AND,
      filterGroup: [],
    });

    if (isNotEmptyString(this.searchValue())) {
      dynamicFilter = addSearchTextFilter(
        dynamicFilter,
        'contains',
        this.searchValue(),
      );
    }

    if (isNotEmptyArray(labelsFilter)) {
      dynamicFilter = addInfluencerLabelsFilter(
        dynamicFilter,
        'anyOf',
        labelsFilter,
      );
    }

    if (isNotEmptyArray(campaignsFilter)) {
      dynamicFilter = addCampaignsFilter(
        dynamicFilter,
        'anyOf',
        campaignsFilter,
      );
    }

    return new CreatorPoolRequestV2({
      pagination: new BasicPagination({
        from: this.paginationFrom(),
        size: this.paginationSize,
      }),
      dynamicFilter: dynamicFilter,
    });
  }

  resetState(): void {
    this.influencers.set([]);
    this.selectedInfluencers.set([]);
    this.paginationFrom.set(0);
    this.totalHits.set(BigInt(0));
    this.searchValue.set('');
    this.tabIndex.set(0);
    this.filterSelectedCampaigns.set(new Map()),
      this.filterSelectedInfluencerLabels.set(new Map());
  }

  override close(): void {
    super.close();
    this.resetState();
  }

  save(): void {
    this.saved$.emit(this.selectedInfluencers());
    this.close();
  }

  back(): void {
    this.close();
    this.back$.emit();
  }

  addOrRemoveFromSelection(
    isAdd: boolean,
    influencer: CreatorPoolResponse,
  ): void {
    let newSelection: CreatorPoolResponse[];
    if (isAdd) {
      newSelection = addOrUpdateInArray(
        this.selectedInfluencers(),
        influencer,
        {
          predicate: (i) =>
            i.baseSnippet?.userId === influencer.baseSnippet?.userId,
        },
      );
    } else {
      newSelection = removeFromArray(this.selectedInfluencers(), {
        predicate: (i) =>
          i.baseSnippet?.userId === influencer.baseSnippet?.userId,
      });
    }
    this.selectedInfluencers.set(newSelection);
  }

  influencerIsSelected(influencer: CreatorPoolResponse): boolean {
    return (
      isNotNil(influencer.baseSnippet?.userId) &&
      this.selectedInfluencersIds().includes(influencer.baseSnippet?.userId)
    );
  }

  dismissed(): void {
    this.resetState();
    this.dialogDismissed$.emit();
  }
}
