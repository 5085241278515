<lefty-dialog
  [autoDismissable]="true"
  size="medium"
  [(visible)]="visible"
  [noContentPadding]="true"
  (close$)="close()"
  class="directory-method-dialog"
  #dialog
  (closedWithCloseButtonOrDismiss$)="dismissed()"
>
  <h3
    title
    i18n
  >
    {{ title() }}
  </h3>
  <div
    title
    class="tabs-container"
  >
    <lefty-tabs
      [tabs]="dialogTabs()"
      [(activeTabIndex)]="tabIndex"
    ></lefty-tabs>
  </div>
  @if (isInfluencersTab()) {
    <div
      description
      class="filters"
    >
      <lefty-form-input
        [(value)]="searchValue"
        placeholder="Search influencers"
        i18n-placeholder
        class="fill-space"
      ></lefty-form-input>
      <lefty-directory-filters-button
        buttonSize="large"
        [(selectedInfluencerLabels)]="filterSelectedInfluencerLabels"
        [(selectedCampaigns)]="filterSelectedCampaigns"
      ></lefty-directory-filters-button>
    </div>
  }
  @if (isInfluencersTab()) {
    <div
      subHeader
      class="flex header-row"
      [class.with-scroll-bar]="dialog.$hasScrollBar()"
    >
      <div class="fill-space flex">
        <lefty-checkbox
          [checked]="selectAllValue()"
          (click)="selectAllChanged(selectAll.checked)"
          #selectAll
        />
        <p i18n>Name</p>
      </div>

      <p
        i18n
        class="networks"
      >
        Networks
      </p>
      <p
        i18n
        class="followers"
      >
        Followers
      </p>
    </div>
  }

  <div class="influencer-container">
    @if (isInfluencersTab()) {
      @for (influencer of influencers(); track influencer.baseSnippet?.userId) {
        <select-from-directory-influencer-row-item
          (checkedChange)="addOrRemoveFromSelection($event, influencer)"
          [influencer]="influencer"
          [checked]="influencerIsSelected(influencer)"
        ></select-from-directory-influencer-row-item>
      } @empty {
        @if (!loading()) {
          <div class="empty-state">
            <p i18n>No influencer available.</p>
          </div>
        }
      }

      @if (loading()) {
        @for (ghost of ghostArray; track $index) {
          <select-from-directory-influencer-row-item></select-from-directory-influencer-row-item>
        }
      }
      <intersection-observer (trigger$)="nextPage()"></intersection-observer>
    } @else {
      @for (
        influencer of selectedInfluencers();
        track influencer.baseSnippet?.userId
      ) {
        <select-from-directory-influencer-row-item
          (checkedChange)="addOrRemoveFromSelection($event, influencer)"
          [influencer]="influencer"
          [checked]="influencerIsSelected(influencer)"
        ></select-from-directory-influencer-row-item>
      } @empty {
        <div class="empty-state">
          <h4 i18n>No selection available</h4>
          <p i18n>
            Select the influencers you would like to include in your selection
            from the influencers tab.
          </p>
        </div>
      }
    }
  </div>

  <div
    footer
    class="footer"
  >
    <p class="fill-space total-hits">
      @if (loading()) {
        <div class="loading"></div>
      } @else {
        {{ totalHitsText() }}
      }
    </p>
    <button
      leftyButton="secondary"
      i18n
      (click)="back()"
    >
      Back
    </button>
    <button
      leftyButton="primary"
      (click)="save()"
    >
      {{ submitButtonText() }}
    </button>
  </div>
</lefty-dialog>
