// @generated by protoc-gen-es v1.10.0 with parameter "target=ts"
// @generated from file librarian/proto/users.proto (package librarian.proto, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { BoolValue, FloatValue, Int32Value, Message, proto3, protoInt64, StringValue, Timestamp } from "@bufbuild/protobuf";
import { InstagramUserResponse } from "./librarian_pb";
import { UniversalRespHeader } from "../../universal/common/proto/universal_pb";
import { AffiliationInfo, CreatorCardSnippet, CreatorProfileCard, CreatorStatsCard, CreatorTiniestCard, GenerikInfoCard } from "./creators_pb";
import { AuthorPermission, ContentMimeType, FloatRange, MediaPerformance, Network, PostType, SensitiveTopic, TimestampedInt32 } from "../../common/proto/common_pb";
import { CreatorImpressions } from "../../common/proto/author_audience_insights_pb";
import { Notes } from "./common_pb";
import { EntityFieldValue } from "./entity_fields_pb";
import { TwitterThumbnail } from "../../common/proto/twitter_media_pb";
import { MediaLocation } from "../../common/proto/instagram_media_pb";
import { CampaignKeywordType, InfluencerPrice, PostInteractions, VisualEditedPerf } from "../../common/proto/campaign_pb";
import { MediaResolution } from "../../common/proto/media_pb";
import { EntityLabel } from "./entity_labels_pb";

/**
 * Most messages that request a creator by id should just use StringValue.
 * This is for backwards compatibility with the frontend when we can't rename 'id' -> 'value'
 * (since the json encoding is field name based).
 *
 * @generated from message librarian.proto.CreatorId
 */
export class CreatorId extends Message<CreatorId> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  constructor(data?: PartialMessage<CreatorId>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.CreatorId";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreatorId {
    return new CreatorId().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreatorId {
    return new CreatorId().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreatorId {
    return new CreatorId().fromJsonString(jsonString, options);
  }

  static equals(a: CreatorId | PlainMessage<CreatorId> | undefined, b: CreatorId | PlainMessage<CreatorId> | undefined): boolean {
    return proto3.util.equals(CreatorId, a, b);
  }
}

/**
 * response of getting one influencer by id.
 *
 * @generated from message librarian.proto.GetInfluencerResponse
 */
export class GetInfluencerResponse extends Message<GetInfluencerResponse> {
  /**
   * @generated from field: librarian.proto.InstagramUserResponse response = 1;
   */
  response?: InstagramUserResponse;

  /**
   * @generated from field: universal.proto.UniversalRespHeader header = 2;
   */
  header?: UniversalRespHeader;

  constructor(data?: PartialMessage<GetInfluencerResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.GetInfluencerResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "response", kind: "message", T: InstagramUserResponse },
    { no: 2, name: "header", kind: "message", T: UniversalRespHeader },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetInfluencerResponse {
    return new GetInfluencerResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetInfluencerResponse {
    return new GetInfluencerResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetInfluencerResponse {
    return new GetInfluencerResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetInfluencerResponse | PlainMessage<GetInfluencerResponse> | undefined, b: GetInfluencerResponse | PlainMessage<GetInfluencerResponse> | undefined): boolean {
    return proto3.util.equals(GetInfluencerResponse, a, b);
  }
}

/**
 * response of getting one creator by id, for its base card (top level of a /posts tab).
 *
 * @generated from message librarian.proto.GetCreatorBasicResponse
 */
export class GetCreatorBasicResponse extends Message<GetCreatorBasicResponse> {
  /**
   * @generated from field: librarian.proto.CreatorProfileCard creator = 1;
   */
  creator?: CreatorProfileCard;

  /**
   * @generated from field: universal.proto.UniversalRespHeader header = 2;
   */
  header?: UniversalRespHeader;

  /**
   * @generated from field: librarian.proto.AffiliationInfo affiliation_info = 4;
   */
  affiliationInfo?: AffiliationInfo;

  /**
   * @generated from field: bool in_pool = 5;
   */
  inPool = false;

  /**
   * @generated from field: bool tracked = 6;
   */
  tracked = false;

  /**
   * INSTA specifics
   *
   * @generated from field: common.proto.AuthorPermission instagram_insights = 7;
   */
  instagramInsights = AuthorPermission.PERMISSION_UNDEFINED;

  /**
   * @generated from field: google.protobuf.BoolValue instagram_hide_like_and_view_counts = 8;
   */
  instagramHideLikeAndViewCounts?: boolean;

  /**
   * Snapchat specific
   *
   * @generated from field: google.protobuf.BoolValue snapchat_share_authorized_data_with_api_partners = 9;
   */
  snapchatShareAuthorizedDataWithApiPartners?: boolean;

  constructor(data?: PartialMessage<GetCreatorBasicResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.GetCreatorBasicResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "creator", kind: "message", T: CreatorProfileCard },
    { no: 2, name: "header", kind: "message", T: UniversalRespHeader },
    { no: 4, name: "affiliation_info", kind: "message", T: AffiliationInfo },
    { no: 5, name: "in_pool", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 6, name: "tracked", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 7, name: "instagram_insights", kind: "enum", T: proto3.getEnumType(AuthorPermission) },
    { no: 8, name: "instagram_hide_like_and_view_counts", kind: "message", T: BoolValue },
    { no: 9, name: "snapchat_share_authorized_data_with_api_partners", kind: "message", T: BoolValue },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetCreatorBasicResponse {
    return new GetCreatorBasicResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetCreatorBasicResponse {
    return new GetCreatorBasicResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetCreatorBasicResponse {
    return new GetCreatorBasicResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetCreatorBasicResponse | PlainMessage<GetCreatorBasicResponse> | undefined, b: GetCreatorBasicResponse | PlainMessage<GetCreatorBasicResponse> | undefined): boolean {
    return proto3.util.equals(GetCreatorBasicResponse, a, b);
  }
}

/**
 * @generated from message librarian.proto.GetCreatorsBasicRequest
 */
export class GetCreatorsBasicRequest extends Message<GetCreatorsBasicRequest> {
  /**
   * @generated from field: repeated string influencer_ids = 1;
   */
  influencerIds: string[] = [];

  constructor(data?: PartialMessage<GetCreatorsBasicRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.GetCreatorsBasicRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "influencer_ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetCreatorsBasicRequest {
    return new GetCreatorsBasicRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetCreatorsBasicRequest {
    return new GetCreatorsBasicRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetCreatorsBasicRequest {
    return new GetCreatorsBasicRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetCreatorsBasicRequest | PlainMessage<GetCreatorsBasicRequest> | undefined, b: GetCreatorsBasicRequest | PlainMessage<GetCreatorsBasicRequest> | undefined): boolean {
    return proto3.util.equals(GetCreatorsBasicRequest, a, b);
  }
}

/**
 * @generated from message librarian.proto.GetCreatorsBasicResponse
 */
export class GetCreatorsBasicResponse extends Message<GetCreatorsBasicResponse> {
  /**
   * @generated from field: repeated librarian.proto.CreatorCardSnippet creator_cards = 1;
   */
  creatorCards: CreatorCardSnippet[] = [];

  constructor(data?: PartialMessage<GetCreatorsBasicResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.GetCreatorsBasicResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "creator_cards", kind: "message", T: CreatorCardSnippet, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetCreatorsBasicResponse {
    return new GetCreatorsBasicResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetCreatorsBasicResponse {
    return new GetCreatorsBasicResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetCreatorsBasicResponse {
    return new GetCreatorsBasicResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetCreatorsBasicResponse | PlainMessage<GetCreatorsBasicResponse> | undefined, b: GetCreatorsBasicResponse | PlainMessage<GetCreatorsBasicResponse> | undefined): boolean {
    return proto3.util.equals(GetCreatorsBasicResponse, a, b);
  }
}

/**
 * @generated from message librarian.proto.GetCreatorStatsResponse
 */
export class GetCreatorStatsResponse extends Message<GetCreatorStatsResponse> {
  /**
   * @generated from field: librarian.proto.CreatorStatsCard creator = 1;
   */
  creator?: CreatorStatsCard;

  /**
   * @generated from field: universal.proto.UniversalRespHeader header = 2;
   */
  header?: UniversalRespHeader;

  /**
   * set if and only if the facebook impressions are shared.
   *
   * @generated from field: common.proto.CreatorImpressions impressions = 3;
   */
  impressions?: CreatorImpressions;

  constructor(data?: PartialMessage<GetCreatorStatsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.GetCreatorStatsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "creator", kind: "message", T: CreatorStatsCard },
    { no: 2, name: "header", kind: "message", T: UniversalRespHeader },
    { no: 3, name: "impressions", kind: "message", T: CreatorImpressions },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetCreatorStatsResponse {
    return new GetCreatorStatsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetCreatorStatsResponse {
    return new GetCreatorStatsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetCreatorStatsResponse {
    return new GetCreatorStatsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetCreatorStatsResponse | PlainMessage<GetCreatorStatsResponse> | undefined, b: GetCreatorStatsResponse | PlainMessage<GetCreatorStatsResponse> | undefined): boolean {
    return proto3.util.equals(GetCreatorStatsResponse, a, b);
  }
}

/**
 * @generated from message librarian.proto.IndexUserRequest
 */
export class IndexUserRequest extends Message<IndexUserRequest> {
  /**
   * @generated from field: string user_id = 1;
   */
  userId = "";

  /**
   * @generated from field: bool force_polling_images = 2;
   */
  forcePollingImages = false;

  constructor(data?: PartialMessage<IndexUserRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.IndexUserRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "user_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "force_polling_images", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): IndexUserRequest {
    return new IndexUserRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): IndexUserRequest {
    return new IndexUserRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): IndexUserRequest {
    return new IndexUserRequest().fromJsonString(jsonString, options);
  }

  static equals(a: IndexUserRequest | PlainMessage<IndexUserRequest> | undefined, b: IndexUserRequest | PlainMessage<IndexUserRequest> | undefined): boolean {
    return proto3.util.equals(IndexUserRequest, a, b);
  }
}

/**
 * @generated from message librarian.proto.InfosResponse
 */
export class InfosResponse extends Message<InfosResponse> {
  /**
   * @generated from field: librarian.proto.Infos infos = 1;
   */
  infos?: Infos;

  /**
   * @generated from field: librarian.proto.Notes notes = 2;
   */
  notes?: Notes;

  /**
   * @generated from field: repeated librarian.proto.EntityFieldValue entity_fields_values = 5;
   */
  entityFieldsValues: EntityFieldValue[] = [];

  /**
   * Email scrapped by Lefty OR share by influencer via creators.lefty.io
   * Frontend may display it when there is no email to show in CreatorFieldValue
   *
   * @generated from field: google.protobuf.StringValue email = 4;
   */
  email?: string;

  constructor(data?: PartialMessage<InfosResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.InfosResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "infos", kind: "message", T: Infos },
    { no: 2, name: "notes", kind: "message", T: Notes },
    { no: 5, name: "entity_fields_values", kind: "message", T: EntityFieldValue, repeated: true },
    { no: 4, name: "email", kind: "message", T: StringValue },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): InfosResponse {
    return new InfosResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): InfosResponse {
    return new InfosResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): InfosResponse {
    return new InfosResponse().fromJsonString(jsonString, options);
  }

  static equals(a: InfosResponse | PlainMessage<InfosResponse> | undefined, b: InfosResponse | PlainMessage<InfosResponse> | undefined): boolean {
    return proto3.util.equals(InfosResponse, a, b);
  }
}

/**
 * @generated from message librarian.proto.Infos
 */
export class Infos extends Message<Infos> {
  /**
   * @generated from field: librarian.proto.Infos.Contact contact = 1;
   */
  contact?: Infos_Contact;

  /**
   * @generated from field: librarian.proto.Infos.Postal postal = 2;
   */
  postal?: Infos_Postal;

  /**
   * @generated from field: librarian.proto.Infos.Contact agent_contact = 3;
   */
  agentContact?: Infos_Contact;

  /**
   * @generated from field: librarian.proto.Infos.Social social = 4;
   */
  social?: Infos_Social;

  constructor(data?: PartialMessage<Infos>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.Infos";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "contact", kind: "message", T: Infos_Contact },
    { no: 2, name: "postal", kind: "message", T: Infos_Postal },
    { no: 3, name: "agent_contact", kind: "message", T: Infos_Contact },
    { no: 4, name: "social", kind: "message", T: Infos_Social },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Infos {
    return new Infos().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Infos {
    return new Infos().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Infos {
    return new Infos().fromJsonString(jsonString, options);
  }

  static equals(a: Infos | PlainMessage<Infos> | undefined, b: Infos | PlainMessage<Infos> | undefined): boolean {
    return proto3.util.equals(Infos, a, b);
  }
}

/**
 * @generated from message librarian.proto.Infos.Postal
 */
export class Infos_Postal extends Message<Infos_Postal> {
  /**
   * @generated from field: string address = 1;
   */
  address = "";

  /**
   * @generated from field: string zip_code = 2;
   */
  zipCode = "";

  /**
   * @generated from field: string city = 3;
   */
  city = "";

  /**
   * @generated from field: string country = 4;
   */
  country = "";

  constructor(data?: PartialMessage<Infos_Postal>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.Infos.Postal";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "address", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "zip_code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "city", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "country", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Infos_Postal {
    return new Infos_Postal().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Infos_Postal {
    return new Infos_Postal().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Infos_Postal {
    return new Infos_Postal().fromJsonString(jsonString, options);
  }

  static equals(a: Infos_Postal | PlainMessage<Infos_Postal> | undefined, b: Infos_Postal | PlainMessage<Infos_Postal> | undefined): boolean {
    return proto3.util.equals(Infos_Postal, a, b);
  }
}

/**
 * @generated from message librarian.proto.Infos.Contact
 */
export class Infos_Contact extends Message<Infos_Contact> {
  /**
   * @generated from field: string first_name = 1;
   */
  firstName = "";

  /**
   * @generated from field: string last_name = 2;
   */
  lastName = "";

  /**
   * @generated from field: string email = 3;
   */
  email = "";

  /**
   * @generated from field: string phone = 4;
   */
  phone = "";

  constructor(data?: PartialMessage<Infos_Contact>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.Infos.Contact";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "first_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "last_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "phone", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Infos_Contact {
    return new Infos_Contact().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Infos_Contact {
    return new Infos_Contact().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Infos_Contact {
    return new Infos_Contact().fromJsonString(jsonString, options);
  }

  static equals(a: Infos_Contact | PlainMessage<Infos_Contact> | undefined, b: Infos_Contact | PlainMessage<Infos_Contact> | undefined): boolean {
    return proto3.util.equals(Infos_Contact, a, b);
  }
}

/**
 * @generated from message librarian.proto.Infos.Social
 */
export class Infos_Social extends Message<Infos_Social> {
  /**
   * @generated from field: string instagram = 1;
   */
  instagram = "";

  /**
   * @generated from field: string youtube = 2;
   */
  youtube = "";

  /**
   * @generated from field: string twitter = 3;
   */
  twitter = "";

  /**
   * @generated from field: string facebook = 4;
   */
  facebook = "";

  /**
   * @generated from field: string snapchat = 5;
   */
  snapchat = "";

  /**
   * @generated from field: string blog = 6;
   */
  blog = "";

  constructor(data?: PartialMessage<Infos_Social>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.Infos.Social";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "instagram", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "youtube", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "twitter", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "facebook", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "snapchat", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "blog", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Infos_Social {
    return new Infos_Social().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Infos_Social {
    return new Infos_Social().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Infos_Social {
    return new Infos_Social().fromJsonString(jsonString, options);
  }

  static equals(a: Infos_Social | PlainMessage<Infos_Social> | undefined, b: Infos_Social | PlainMessage<Infos_Social> | undefined): boolean {
    return proto3.util.equals(Infos_Social, a, b);
  }
}

/**
 * @generated from message librarian.proto.EditInfosRequest
 */
export class EditInfosRequest extends Message<EditInfosRequest> {
  /**
   * @generated from field: string user_id = 1;
   */
  userId = "";

  /**
   * @generated from field: librarian.proto.Infos infos = 2;
   */
  infos?: Infos;

  constructor(data?: PartialMessage<EditInfosRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.EditInfosRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "user_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "infos", kind: "message", T: Infos },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): EditInfosRequest {
    return new EditInfosRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): EditInfosRequest {
    return new EditInfosRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): EditInfosRequest {
    return new EditInfosRequest().fromJsonString(jsonString, options);
  }

  static equals(a: EditInfosRequest | PlainMessage<EditInfosRequest> | undefined, b: EditInfosRequest | PlainMessage<EditInfosRequest> | undefined): boolean {
    return proto3.util.equals(EditInfosRequest, a, b);
  }
}

/**
 * @generated from message librarian.proto.CreatorAutocompleteRequest
 */
export class CreatorAutocompleteRequest extends Message<CreatorAutocompleteRequest> {
  /**
   * Prefix of the user name to autocomplete on.
   *
   * @generated from field: string name_prefix = 1;
   */
  namePrefix = "";

  /**
   * If set, number of profiles to return, else the backend chooses a sane default.
   *
   * @generated from field: google.protobuf.Int32Value count = 2;
   */
  count?: number;

  /**
   * Network to consider.
   *
   * @generated from field: common.proto.Network network = 3;
   */
  network = Network.NETWORK_UNKNOWN;

  /**
   * If set to true, return network_info
   *
   * @generated from field: bool with_network_info = 4;
   */
  withNetworkInfo = false;

  constructor(data?: PartialMessage<CreatorAutocompleteRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.CreatorAutocompleteRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "name_prefix", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "count", kind: "message", T: Int32Value },
    { no: 3, name: "network", kind: "enum", T: proto3.getEnumType(Network) },
    { no: 4, name: "with_network_info", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreatorAutocompleteRequest {
    return new CreatorAutocompleteRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreatorAutocompleteRequest {
    return new CreatorAutocompleteRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreatorAutocompleteRequest {
    return new CreatorAutocompleteRequest().fromJsonString(jsonString, options);
  }

  static equals(a: CreatorAutocompleteRequest | PlainMessage<CreatorAutocompleteRequest> | undefined, b: CreatorAutocompleteRequest | PlainMessage<CreatorAutocompleteRequest> | undefined): boolean {
    return proto3.util.equals(CreatorAutocompleteRequest, a, b);
  }
}

/**
 * @generated from message librarian.proto.CreatorAutocompleteResponse
 */
export class CreatorAutocompleteResponse extends Message<CreatorAutocompleteResponse> {
  /**
   * @generated from field: repeated librarian.proto.CreatorTiniestCard creator_matches = 1 [deprecated = true];
   * @deprecated
   */
  creatorMatches: CreatorTiniestCard[] = [];

  /**
   * @generated from field: repeated librarian.proto.CreatorAutocompleteResponse.CreatorAutocompleteMatch creators = 2;
   */
  creators: CreatorAutocompleteResponse_CreatorAutocompleteMatch[] = [];

  constructor(data?: PartialMessage<CreatorAutocompleteResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.CreatorAutocompleteResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "creator_matches", kind: "message", T: CreatorTiniestCard, repeated: true },
    { no: 2, name: "creators", kind: "message", T: CreatorAutocompleteResponse_CreatorAutocompleteMatch, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreatorAutocompleteResponse {
    return new CreatorAutocompleteResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreatorAutocompleteResponse {
    return new CreatorAutocompleteResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreatorAutocompleteResponse {
    return new CreatorAutocompleteResponse().fromJsonString(jsonString, options);
  }

  static equals(a: CreatorAutocompleteResponse | PlainMessage<CreatorAutocompleteResponse> | undefined, b: CreatorAutocompleteResponse | PlainMessage<CreatorAutocompleteResponse> | undefined): boolean {
    return proto3.util.equals(CreatorAutocompleteResponse, a, b);
  }
}

/**
 * @generated from message librarian.proto.CreatorAutocompleteResponse.CreatorAutocompleteMatch
 */
export class CreatorAutocompleteResponse_CreatorAutocompleteMatch extends Message<CreatorAutocompleteResponse_CreatorAutocompleteMatch> {
  /**
   * @generated from field: librarian.proto.CreatorTiniestCard creator = 1;
   */
  creator?: CreatorTiniestCard;

  /**
   * only returned when with_network_info is true
   *
   * @generated from field: repeated librarian.proto.GenerikInfoCard network_info = 2;
   */
  networkInfo: GenerikInfoCard[] = [];

  constructor(data?: PartialMessage<CreatorAutocompleteResponse_CreatorAutocompleteMatch>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.CreatorAutocompleteResponse.CreatorAutocompleteMatch";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "creator", kind: "message", T: CreatorTiniestCard },
    { no: 2, name: "network_info", kind: "message", T: GenerikInfoCard, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreatorAutocompleteResponse_CreatorAutocompleteMatch {
    return new CreatorAutocompleteResponse_CreatorAutocompleteMatch().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreatorAutocompleteResponse_CreatorAutocompleteMatch {
    return new CreatorAutocompleteResponse_CreatorAutocompleteMatch().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreatorAutocompleteResponse_CreatorAutocompleteMatch {
    return new CreatorAutocompleteResponse_CreatorAutocompleteMatch().fromJsonString(jsonString, options);
  }

  static equals(a: CreatorAutocompleteResponse_CreatorAutocompleteMatch | PlainMessage<CreatorAutocompleteResponse_CreatorAutocompleteMatch> | undefined, b: CreatorAutocompleteResponse_CreatorAutocompleteMatch | PlainMessage<CreatorAutocompleteResponse_CreatorAutocompleteMatch> | undefined): boolean {
    return proto3.util.equals(CreatorAutocompleteResponse_CreatorAutocompleteMatch, a, b);
  }
}

/**
 * @generated from message librarian.proto.RetweetInfo
 */
export class RetweetInfo extends Message<RetweetInfo> {
  /**
   * @generated from field: string screen_name = 1;
   */
  screenName = "";

  /**
   * @generated from field: string tweet_link = 2;
   */
  tweetLink = "";

  /**
   * @generated from field: string text = 3;
   */
  text = "";

  /**
   * @generated from field: repeated string mentions = 4;
   */
  mentions: string[] = [];

  /**
   * @generated from field: repeated string tags = 5;
   */
  tags: string[] = [];

  /**
   * @generated from field: repeated common.proto.TwitterThumbnail thumbnails = 6;
   */
  thumbnails: TwitterThumbnail[] = [];

  /**
   * @generated from field: google.protobuf.Timestamp published_at = 7;
   */
  publishedAt?: Timestamp;

  /**
   * @generated from field: string profile_pic = 8;
   */
  profilePic = "";

  constructor(data?: PartialMessage<RetweetInfo>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.RetweetInfo";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "screen_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "tweet_link", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "text", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "mentions", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 5, name: "tags", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 6, name: "thumbnails", kind: "message", T: TwitterThumbnail, repeated: true },
    { no: 7, name: "published_at", kind: "message", T: Timestamp },
    { no: 8, name: "profile_pic", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RetweetInfo {
    return new RetweetInfo().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RetweetInfo {
    return new RetweetInfo().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): RetweetInfo {
    return new RetweetInfo().fromJsonString(jsonString, options);
  }

  static equals(a: RetweetInfo | PlainMessage<RetweetInfo> | undefined, b: RetweetInfo | PlainMessage<RetweetInfo> | undefined): boolean {
    return proto3.util.equals(RetweetInfo, a, b);
  }
}

/**
 * @generated from message librarian.proto.TwitterSnippet
 */
export class TwitterSnippet extends Message<TwitterSnippet> {
  /**
   * Frontend Id
   *
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: google.protobuf.Timestamp published_at = 2;
   */
  publishedAt?: Timestamp;

  /**
   * @generated from field: repeated common.proto.TwitterThumbnail thumbnails = 3;
   */
  thumbnails: TwitterThumbnail[] = [];

  /**
   * @generated from field: string text = 4;
   */
  text = "";

  /**
   * @generated from field: int64 like_count = 5;
   */
  likeCount = protoInt64.zero;

  /**
   * @generated from field: int64 retweet_count = 6;
   */
  retweetCount = protoInt64.zero;

  /**
   * @generated from field: int32 comment_count = 14;
   */
  commentCount = 0;

  /**
   * @generated from field: librarian.proto.CreatorTiniestCard creator = 8;
   */
  creator?: CreatorTiniestCard;

  /**
   * @generated from field: repeated string tags = 10;
   */
  tags: string[] = [];

  /**
   * @generated from field: repeated string mentions = 11;
   */
  mentions: string[] = [];

  /**
   * Ids of the campaigns this visual belong to
   *
   * @generated from field: repeated int64 campaigns_ids = 9;
   */
  campaignsIds: bigint[] = [];

  /**
   * @generated from field: librarian.proto.RetweetInfo retweet_info = 12;
   */
  retweetInfo?: RetweetInfo;

  /**
   * @generated from field: common.proto.MediaPerformance perf = 13;
   */
  perf?: MediaPerformance;

  constructor(data?: PartialMessage<TwitterSnippet>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.TwitterSnippet";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "published_at", kind: "message", T: Timestamp },
    { no: 3, name: "thumbnails", kind: "message", T: TwitterThumbnail, repeated: true },
    { no: 4, name: "text", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "like_count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 6, name: "retweet_count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 14, name: "comment_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 8, name: "creator", kind: "message", T: CreatorTiniestCard },
    { no: 10, name: "tags", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 11, name: "mentions", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 9, name: "campaigns_ids", kind: "scalar", T: 3 /* ScalarType.INT64 */, repeated: true },
    { no: 12, name: "retweet_info", kind: "message", T: RetweetInfo },
    { no: 13, name: "perf", kind: "message", T: MediaPerformance },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TwitterSnippet {
    return new TwitterSnippet().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TwitterSnippet {
    return new TwitterSnippet().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TwitterSnippet {
    return new TwitterSnippet().fromJsonString(jsonString, options);
  }

  static equals(a: TwitterSnippet | PlainMessage<TwitterSnippet> | undefined, b: TwitterSnippet | PlainMessage<TwitterSnippet> | undefined): boolean {
    return proto3.util.equals(TwitterSnippet, a, b);
  }
}

/**
 * @generated from message librarian.proto.GenerikSnippet
 */
export class GenerikSnippet extends Message<GenerikSnippet> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: librarian.proto.CreatorTiniestCard creator = 2;
   */
  creator?: CreatorTiniestCard;

  /**
   * INSTA only for now
   *
   * @generated from field: repeated librarian.proto.CreatorTiniestCard co_authors = 17;
   */
  coAuthors: CreatorTiniestCard[] = [];

  /**
   * @generated from field: google.protobuf.Timestamp published_at = 3;
   */
  publishedAt?: Timestamp;

  /**
   * @generated from field: string caption = 4;
   */
  caption = "";

  /**
   * @generated from field: string title = 5;
   */
  title = "";

  /**
   * @generated from field: string description = 12;
   */
  description = "";

  /**
   * @generated from field: repeated string tags = 6;
   */
  tags: string[] = [];

  /**
   * @generated from field: repeated string mentions = 7;
   */
  mentions: string[] = [];

  /**
   * @generated from field: common.proto.MediaLocation location = 15;
   */
  location?: MediaLocation;

  /**
   * @generated from field: common.proto.PostInteractions counts = 8;
   */
  counts?: PostInteractions;

  /**
   * @generated from field: repeated librarian.proto.GenerikSnippet.GenerikUrl urls = 9;
   */
  urls: GenerikSnippet_GenerikUrl[] = [];

  /**
   * @generated from field: common.proto.MediaPerformance perf = 10;
   */
  perf?: MediaPerformance;

  /**
   * @generated from field: common.proto.PostType post_type = 11;
   */
  postType = PostType.POST_TYPE_UNDEFINED;

  /**
   * Whether the counts in PostInteractions are estimated or not. This is network dependent, and may have specific meaning
   * depending on the network.
   *
   * For instance, as of this writing, the only use case is "hidden likes" on Instagram. Thus if this is true it means that
   * the author has hidden the likes for this post. Other interactions such as comments or views are still available.
   *
   * The frontend can use this bit to display a little tooltip.
   *
   * @generated from field: bool counts_are_estimated = 13;
   */
  countsAreEstimated = false;

  /**
   * @generated from field: common.proto.VisualEditedPerf edited_perf = 14;
   */
  editedPerf?: VisualEditedPerf;

  /**
   * @generated from field: bool sponsored_post = 16;
   */
  sponsoredPost = false;

  constructor(data?: PartialMessage<GenerikSnippet>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.GenerikSnippet";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "creator", kind: "message", T: CreatorTiniestCard },
    { no: 17, name: "co_authors", kind: "message", T: CreatorTiniestCard, repeated: true },
    { no: 3, name: "published_at", kind: "message", T: Timestamp },
    { no: 4, name: "caption", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 12, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "tags", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 7, name: "mentions", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 15, name: "location", kind: "message", T: MediaLocation },
    { no: 8, name: "counts", kind: "message", T: PostInteractions },
    { no: 9, name: "urls", kind: "message", T: GenerikSnippet_GenerikUrl, repeated: true },
    { no: 10, name: "perf", kind: "message", T: MediaPerformance },
    { no: 11, name: "post_type", kind: "enum", T: proto3.getEnumType(PostType) },
    { no: 13, name: "counts_are_estimated", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 14, name: "edited_perf", kind: "message", T: VisualEditedPerf },
    { no: 16, name: "sponsored_post", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GenerikSnippet {
    return new GenerikSnippet().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GenerikSnippet {
    return new GenerikSnippet().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GenerikSnippet {
    return new GenerikSnippet().fromJsonString(jsonString, options);
  }

  static equals(a: GenerikSnippet | PlainMessage<GenerikSnippet> | undefined, b: GenerikSnippet | PlainMessage<GenerikSnippet> | undefined): boolean {
    return proto3.util.equals(GenerikSnippet, a, b);
  }
}

/**
 * @generated from message librarian.proto.GenerikSnippet.GenerikUrl
 */
export class GenerikSnippet_GenerikUrl extends Message<GenerikSnippet_GenerikUrl> {
  /**
   * @generated from field: google.protobuf.StringValue url = 1;
   */
  url?: string;

  /**
   * We use Instagram resolution for generik media
   *
   * @generated from field: common.proto.MediaResolution resolution = 2;
   */
  resolution = MediaResolution.LOW;

  /**
   * @generated from field: common.proto.ContentMimeType mime_type = 3;
   */
  mimeType = ContentMimeType.UNDEFINED;

  /**
   * If this is a carousel it is set to the index of the carousel this is for, else it is not set.
   *
   * @generated from field: google.protobuf.Int32Value carousel_index = 4;
   */
  carouselIndex?: number;

  constructor(data?: PartialMessage<GenerikSnippet_GenerikUrl>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.GenerikSnippet.GenerikUrl";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "url", kind: "message", T: StringValue },
    { no: 2, name: "resolution", kind: "enum", T: proto3.getEnumType(MediaResolution) },
    { no: 3, name: "mime_type", kind: "enum", T: proto3.getEnumType(ContentMimeType) },
    { no: 4, name: "carousel_index", kind: "message", T: Int32Value },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GenerikSnippet_GenerikUrl {
    return new GenerikSnippet_GenerikUrl().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GenerikSnippet_GenerikUrl {
    return new GenerikSnippet_GenerikUrl().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GenerikSnippet_GenerikUrl {
    return new GenerikSnippet_GenerikUrl().fromJsonString(jsonString, options);
  }

  static equals(a: GenerikSnippet_GenerikUrl | PlainMessage<GenerikSnippet_GenerikUrl> | undefined, b: GenerikSnippet_GenerikUrl | PlainMessage<GenerikSnippet_GenerikUrl> | undefined): boolean {
    return proto3.util.equals(GenerikSnippet_GenerikUrl, a, b);
  }
}

/**
 * @generated from message librarian.proto.UpdatePriceReq
 */
export class UpdatePriceReq extends Message<UpdatePriceReq> {
  /**
   * @generated from field: string influencer_id = 1;
   */
  influencerId = "";

  /**
   * @generated from field: int64 campaign_id = 2;
   */
  campaignId = protoInt64.zero;

  /**
   * @generated from field: common.proto.InfluencerPrice price = 3;
   */
  price?: InfluencerPrice;

  /**
   * Whether we request to recompute perfs computed from price (ROI, CPE..)
   *
   * @generated from field: bool compute_perfs = 4;
   */
  computePerfs = false;

  constructor(data?: PartialMessage<UpdatePriceReq>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.UpdatePriceReq";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "influencer_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "campaign_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "price", kind: "message", T: InfluencerPrice },
    { no: 4, name: "compute_perfs", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdatePriceReq {
    return new UpdatePriceReq().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdatePriceReq {
    return new UpdatePriceReq().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdatePriceReq {
    return new UpdatePriceReq().fromJsonString(jsonString, options);
  }

  static equals(a: UpdatePriceReq | PlainMessage<UpdatePriceReq> | undefined, b: UpdatePriceReq | PlainMessage<UpdatePriceReq> | undefined): boolean {
    return proto3.util.equals(UpdatePriceReq, a, b);
  }
}

/**
 * @generated from message librarian.proto.UpdatePriceResp
 */
export class UpdatePriceResp extends Message<UpdatePriceResp> {
  /**
   * @generated from field: google.protobuf.FloatValue roi = 1;
   */
  roi?: number;

  /**
   * @generated from field: google.protobuf.FloatValue cpe = 2;
   */
  cpe?: number;

  constructor(data?: PartialMessage<UpdatePriceResp>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.UpdatePriceResp";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "roi", kind: "message", T: FloatValue },
    { no: 2, name: "cpe", kind: "message", T: FloatValue },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdatePriceResp {
    return new UpdatePriceResp().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdatePriceResp {
    return new UpdatePriceResp().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdatePriceResp {
    return new UpdatePriceResp().fromJsonString(jsonString, options);
  }

  static equals(a: UpdatePriceResp | PlainMessage<UpdatePriceResp> | undefined, b: UpdatePriceResp | PlainMessage<UpdatePriceResp> | undefined): boolean {
    return proto3.util.equals(UpdatePriceResp, a, b);
  }
}

/**
 * @generated from message librarian.proto.ResolveUserNameResp
 */
export class ResolveUserNameResp extends Message<ResolveUserNameResp> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: string task_token = 2;
   */
  taskToken = "";

  constructor(data?: PartialMessage<ResolveUserNameResp>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.ResolveUserNameResp";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "task_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ResolveUserNameResp {
    return new ResolveUserNameResp().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ResolveUserNameResp {
    return new ResolveUserNameResp().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ResolveUserNameResp {
    return new ResolveUserNameResp().fromJsonString(jsonString, options);
  }

  static equals(a: ResolveUserNameResp | PlainMessage<ResolveUserNameResp> | undefined, b: ResolveUserNameResp | PlainMessage<ResolveUserNameResp> | undefined): boolean {
    return proto3.util.equals(ResolveUserNameResp, a, b);
  }
}

/**
 * @generated from message librarian.proto.ResolveUserNameRespV2
 */
export class ResolveUserNameRespV2 extends Message<ResolveUserNameRespV2> {
  /**
   * @generated from oneof librarian.proto.ResolveUserNameRespV2.response
   */
  response: {
    /**
     * If name <=> id association was known and up to date
     *
     * @generated from field: string id = 1;
     */
    value: string;
    case: "id";
  } | {
    /**
     * If we had to resolve name
     *
     * @generated from field: string task_token = 2;
     */
    value: string;
    case: "taskToken";
  } | { case: undefined; value?: undefined } = { case: undefined };

  constructor(data?: PartialMessage<ResolveUserNameRespV2>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.ResolveUserNameRespV2";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */, oneof: "response" },
    { no: 2, name: "task_token", kind: "scalar", T: 9 /* ScalarType.STRING */, oneof: "response" },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ResolveUserNameRespV2 {
    return new ResolveUserNameRespV2().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ResolveUserNameRespV2 {
    return new ResolveUserNameRespV2().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ResolveUserNameRespV2 {
    return new ResolveUserNameRespV2().fromJsonString(jsonString, options);
  }

  static equals(a: ResolveUserNameRespV2 | PlainMessage<ResolveUserNameRespV2> | undefined, b: ResolveUserNameRespV2 | PlainMessage<ResolveUserNameRespV2> | undefined): boolean {
    return proto3.util.equals(ResolveUserNameRespV2, a, b);
  }
}

/**
 * @generated from message librarian.proto.ResolveInfluencerMetaData
 */
export class ResolveInfluencerMetaData extends Message<ResolveInfluencerMetaData> {
  /**
   * Influencer id
   *
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * Task token of intelligence if issued
   *
   * @generated from field: google.protobuf.StringValue task_token = 2;
   */
  taskToken?: string;

  constructor(data?: PartialMessage<ResolveInfluencerMetaData>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.ResolveInfluencerMetaData";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "task_token", kind: "message", T: StringValue },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ResolveInfluencerMetaData {
    return new ResolveInfluencerMetaData().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ResolveInfluencerMetaData {
    return new ResolveInfluencerMetaData().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ResolveInfluencerMetaData {
    return new ResolveInfluencerMetaData().fromJsonString(jsonString, options);
  }

  static equals(a: ResolveInfluencerMetaData | PlainMessage<ResolveInfluencerMetaData> | undefined, b: ResolveInfluencerMetaData | PlainMessage<ResolveInfluencerMetaData> | undefined): boolean {
    return proto3.util.equals(ResolveInfluencerMetaData, a, b);
  }
}

/**
 * @generated from message librarian.proto.ResolveInfluencerReq
 */
export class ResolveInfluencerReq extends Message<ResolveInfluencerReq> {
  /**
   * @generated from field: common.proto.Network network = 1;
   */
  network = Network.NETWORK_UNKNOWN;

  /**
   * input to resolve to an influencer. Typically it could be a username or an URL.
   * The endpoint will apply all the rules that we have to parse it as best as we can.
   *
   * For instance, all those shall work: "bettyautier", "@bettyautier", "https://instagram.com/bettyautier",
   * "https://instagram.com/bettyautier?hl=en"
   * etc etc...
   *
   * When the input is not an URL, we shall consider that it is a username. When it is an URL, how we handle it will depend
   * on the network. Sometimes it is straightforward to deduce the username (https://instagram.com/bettyautier -> bettyautier).
   *
   * At the extreme if the input parses as a valid URL but nothing can be extracted, we will visit the URL to check what's
   * there (it could even be a bit.ly, a youtube.me vanity URL, etc...).
   *
   * @generated from field: string input = 2;
   */
  input = "";

  /**
   * Network dependant: some networks allow to retrieve one or more rounds of images for free when resolving the user
   * (some don't). If this is the case AND this flag is true, we will ingest those side media and wait for completion.
   *
   * This can slow down the resolving process but improve the user experience later as you make it to a non empty profile.
   * It shall be set in some specific occasions (for instance when adding a single influencer from the interface, we know the user
   * would then be redirected to their page).
   *
   * @generated from field: bool with_fast_ingest = 3;
   */
  withFastIngest = false;

  /**
   * This shall only be set if fast ingest is set. In that case we will go one step further and launch intelligence once
   * fast ingest has completed. This can improve a user experience as they will make it to the profile page of the influencer
   * populated with stats (engagement, avg views, etc...).
   *
   * @generated from field: bool with_intelligence = 4;
   */
  withIntelligence = false;

  constructor(data?: PartialMessage<ResolveInfluencerReq>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.ResolveInfluencerReq";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "network", kind: "enum", T: proto3.getEnumType(Network) },
    { no: 2, name: "input", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "with_fast_ingest", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 4, name: "with_intelligence", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ResolveInfluencerReq {
    return new ResolveInfluencerReq().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ResolveInfluencerReq {
    return new ResolveInfluencerReq().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ResolveInfluencerReq {
    return new ResolveInfluencerReq().fromJsonString(jsonString, options);
  }

  static equals(a: ResolveInfluencerReq | PlainMessage<ResolveInfluencerReq> | undefined, b: ResolveInfluencerReq | PlainMessage<ResolveInfluencerReq> | undefined): boolean {
    return proto3.util.equals(ResolveInfluencerReq, a, b);
  }
}

/**
 * @generated from message librarian.proto.GenerikMediaSnippet
 */
export class GenerikMediaSnippet extends Message<GenerikMediaSnippet> {
  /**
   * MediaId.toIdString() for the corresponding media.
   *
   * @generated from field: string media_id = 1;
   */
  mediaId = "";

  /**
   * @generated from field: librarian.proto.GenerikSnippet generik = 5;
   */
  generik?: GenerikSnippet;

  /**
   * @generated from field: repeated librarian.proto.EntityLabel labels = 22;
   */
  labels: EntityLabel[] = [];

  /**
   * Ids of the campaigns this media belong to
   *
   * @generated from field: repeated int64 campaigns_ids = 20;
   */
  campaignsIds: bigint[] = [];

  /**
   * @generated from field: repeated librarian.proto.CampaignTrackedReason campaign_tracked_reasons = 21;
   */
  campaignTrackedReasons: CampaignTrackedReason[] = [];

  constructor(data?: PartialMessage<GenerikMediaSnippet>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.GenerikMediaSnippet";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "media_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "generik", kind: "message", T: GenerikSnippet },
    { no: 22, name: "labels", kind: "message", T: EntityLabel, repeated: true },
    { no: 20, name: "campaigns_ids", kind: "scalar", T: 3 /* ScalarType.INT64 */, repeated: true },
    { no: 21, name: "campaign_tracked_reasons", kind: "message", T: CampaignTrackedReason, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GenerikMediaSnippet {
    return new GenerikMediaSnippet().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GenerikMediaSnippet {
    return new GenerikMediaSnippet().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GenerikMediaSnippet {
    return new GenerikMediaSnippet().fromJsonString(jsonString, options);
  }

  static equals(a: GenerikMediaSnippet | PlainMessage<GenerikMediaSnippet> | undefined, b: GenerikMediaSnippet | PlainMessage<GenerikMediaSnippet> | undefined): boolean {
    return proto3.util.equals(GenerikMediaSnippet, a, b);
  }
}

/**
 * @generated from message librarian.proto.CampaignTrackedReason
 */
export class CampaignTrackedReason extends Message<CampaignTrackedReason> {
  /**
   * @generated from field: int64 campaign_id = 1;
   */
  campaignId = protoInt64.zero;

  /**
   * @generated from field: librarian.proto.CampaignTrackedReason.TrackedReason tracked_reason = 2;
   */
  trackedReason?: CampaignTrackedReason_TrackedReason;

  constructor(data?: PartialMessage<CampaignTrackedReason>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.CampaignTrackedReason";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "campaign_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "tracked_reason", kind: "message", T: CampaignTrackedReason_TrackedReason },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CampaignTrackedReason {
    return new CampaignTrackedReason().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CampaignTrackedReason {
    return new CampaignTrackedReason().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CampaignTrackedReason {
    return new CampaignTrackedReason().fromJsonString(jsonString, options);
  }

  static equals(a: CampaignTrackedReason | PlainMessage<CampaignTrackedReason> | undefined, b: CampaignTrackedReason | PlainMessage<CampaignTrackedReason> | undefined): boolean {
    return proto3.util.equals(CampaignTrackedReason, a, b);
  }
}

/**
 * @generated from message librarian.proto.CampaignTrackedReason.TrackedReason
 */
export class CampaignTrackedReason_TrackedReason extends Message<CampaignTrackedReason_TrackedReason> {
  /**
   * @generated from oneof librarian.proto.CampaignTrackedReason.TrackedReason.reason
   */
  reason: {
    /**
     * @generated from field: librarian.proto.CampaignTrackedReason.TrackedReason.TrackedReasonKeywords tracked_reason_keywords = 1;
     */
    value: CampaignTrackedReason_TrackedReason_TrackedReasonKeywords;
    case: "trackedReasonKeywords";
  } | {
    /**
     * @generated from field: bool manual = 2;
     */
    value: boolean;
    case: "manual";
  } | { case: undefined; value?: undefined } = { case: undefined };

  constructor(data?: PartialMessage<CampaignTrackedReason_TrackedReason>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.CampaignTrackedReason.TrackedReason";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "tracked_reason_keywords", kind: "message", T: CampaignTrackedReason_TrackedReason_TrackedReasonKeywords, oneof: "reason" },
    { no: 2, name: "manual", kind: "scalar", T: 8 /* ScalarType.BOOL */, oneof: "reason" },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CampaignTrackedReason_TrackedReason {
    return new CampaignTrackedReason_TrackedReason().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CampaignTrackedReason_TrackedReason {
    return new CampaignTrackedReason_TrackedReason().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CampaignTrackedReason_TrackedReason {
    return new CampaignTrackedReason_TrackedReason().fromJsonString(jsonString, options);
  }

  static equals(a: CampaignTrackedReason_TrackedReason | PlainMessage<CampaignTrackedReason_TrackedReason> | undefined, b: CampaignTrackedReason_TrackedReason | PlainMessage<CampaignTrackedReason_TrackedReason> | undefined): boolean {
    return proto3.util.equals(CampaignTrackedReason_TrackedReason, a, b);
  }
}

/**
 * @generated from message librarian.proto.CampaignTrackedReason.TrackedReason.TrackedReasonKeywords
 */
export class CampaignTrackedReason_TrackedReason_TrackedReasonKeywords extends Message<CampaignTrackedReason_TrackedReason_TrackedReasonKeywords> {
  /**
   * @generated from field: repeated librarian.proto.CampaignTrackedReason.TrackedReason.TrackedReasonKeywords.TrackedReasonKeyword tracked_keywords = 1;
   */
  trackedKeywords: CampaignTrackedReason_TrackedReason_TrackedReasonKeywords_TrackedReasonKeyword[] = [];

  constructor(data?: PartialMessage<CampaignTrackedReason_TrackedReason_TrackedReasonKeywords>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.CampaignTrackedReason.TrackedReason.TrackedReasonKeywords";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "tracked_keywords", kind: "message", T: CampaignTrackedReason_TrackedReason_TrackedReasonKeywords_TrackedReasonKeyword, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CampaignTrackedReason_TrackedReason_TrackedReasonKeywords {
    return new CampaignTrackedReason_TrackedReason_TrackedReasonKeywords().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CampaignTrackedReason_TrackedReason_TrackedReasonKeywords {
    return new CampaignTrackedReason_TrackedReason_TrackedReasonKeywords().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CampaignTrackedReason_TrackedReason_TrackedReasonKeywords {
    return new CampaignTrackedReason_TrackedReason_TrackedReasonKeywords().fromJsonString(jsonString, options);
  }

  static equals(a: CampaignTrackedReason_TrackedReason_TrackedReasonKeywords | PlainMessage<CampaignTrackedReason_TrackedReason_TrackedReasonKeywords> | undefined, b: CampaignTrackedReason_TrackedReason_TrackedReasonKeywords | PlainMessage<CampaignTrackedReason_TrackedReason_TrackedReasonKeywords> | undefined): boolean {
    return proto3.util.equals(CampaignTrackedReason_TrackedReason_TrackedReasonKeywords, a, b);
  }
}

/**
 * @generated from message librarian.proto.CampaignTrackedReason.TrackedReason.TrackedReasonKeywords.TrackedReasonKeyword
 */
export class CampaignTrackedReason_TrackedReason_TrackedReasonKeywords_TrackedReasonKeyword extends Message<CampaignTrackedReason_TrackedReason_TrackedReasonKeywords_TrackedReasonKeyword> {
  /**
   * @generated from field: common.proto.CampaignKeywordType keyword_type = 1;
   */
  keywordType = CampaignKeywordType.CAMPAIGN_KEYWORD_TYPE_UNKNOWN;

  /**
   * @generated from field: repeated string keywords = 2;
   */
  keywords: string[] = [];

  constructor(data?: PartialMessage<CampaignTrackedReason_TrackedReason_TrackedReasonKeywords_TrackedReasonKeyword>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.CampaignTrackedReason.TrackedReason.TrackedReasonKeywords.TrackedReasonKeyword";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "keyword_type", kind: "enum", T: proto3.getEnumType(CampaignKeywordType) },
    { no: 2, name: "keywords", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CampaignTrackedReason_TrackedReason_TrackedReasonKeywords_TrackedReasonKeyword {
    return new CampaignTrackedReason_TrackedReason_TrackedReasonKeywords_TrackedReasonKeyword().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CampaignTrackedReason_TrackedReason_TrackedReasonKeywords_TrackedReasonKeyword {
    return new CampaignTrackedReason_TrackedReason_TrackedReasonKeywords_TrackedReasonKeyword().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CampaignTrackedReason_TrackedReason_TrackedReasonKeywords_TrackedReasonKeyword {
    return new CampaignTrackedReason_TrackedReason_TrackedReasonKeywords_TrackedReasonKeyword().fromJsonString(jsonString, options);
  }

  static equals(a: CampaignTrackedReason_TrackedReason_TrackedReasonKeywords_TrackedReasonKeyword | PlainMessage<CampaignTrackedReason_TrackedReason_TrackedReasonKeywords_TrackedReasonKeyword> | undefined, b: CampaignTrackedReason_TrackedReason_TrackedReasonKeywords_TrackedReasonKeyword | PlainMessage<CampaignTrackedReason_TrackedReason_TrackedReasonKeywords_TrackedReasonKeyword> | undefined): boolean {
    return proto3.util.equals(CampaignTrackedReason_TrackedReason_TrackedReasonKeywords_TrackedReasonKeyword, a, b);
  }
}

/**
 * @generated from message librarian.proto.PortfolioResp
 */
export class PortfolioResp extends Message<PortfolioResp> {
  /**
   * @generated from field: repeated librarian.proto.GenerikMediaSnippet media = 1;
   */
  media: GenerikMediaSnippet[] = [];

  /**
   * @generated from field: int64 total_hits = 2;
   */
  totalHits = protoInt64.zero;

  /**
   * If set and non empty, this is a bronco task token. The same search shall be re-issued once
   * the task has ended.
   *
   * @generated from field: string refresh_token = 3;
   */
  refreshToken = "";

  constructor(data?: PartialMessage<PortfolioResp>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.PortfolioResp";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "media", kind: "message", T: GenerikMediaSnippet, repeated: true },
    { no: 2, name: "total_hits", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "refresh_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): PortfolioResp {
    return new PortfolioResp().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): PortfolioResp {
    return new PortfolioResp().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): PortfolioResp {
    return new PortfolioResp().fromJsonString(jsonString, options);
  }

  static equals(a: PortfolioResp | PlainMessage<PortfolioResp> | undefined, b: PortfolioResp | PlainMessage<PortfolioResp> | undefined): boolean {
    return proto3.util.equals(PortfolioResp, a, b);
  }
}

/**
 * @generated from message librarian.proto.CreatorStatsReq
 */
export class CreatorStatsReq extends Message<CreatorStatsReq> {
  /**
   * @generated from field: string creator_id = 1;
   */
  creatorId = "";

  /**
   * @generated from field: common.proto.Network network = 2;
   */
  network = Network.NETWORK_UNKNOWN;

  constructor(data?: PartialMessage<CreatorStatsReq>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.CreatorStatsReq";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "creator_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "network", kind: "enum", T: proto3.getEnumType(Network) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreatorStatsReq {
    return new CreatorStatsReq().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreatorStatsReq {
    return new CreatorStatsReq().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreatorStatsReq {
    return new CreatorStatsReq().fromJsonString(jsonString, options);
  }

  static equals(a: CreatorStatsReq | PlainMessage<CreatorStatsReq> | undefined, b: CreatorStatsReq | PlainMessage<CreatorStatsReq> | undefined): boolean {
    return proto3.util.equals(CreatorStatsReq, a, b);
  }
}

/**
 * @generated from message librarian.proto.FollowersTimeSeries
 */
export class FollowersTimeSeries extends Message<FollowersTimeSeries> {
  /**
   * @generated from field: repeated common.proto.TimestampedInt32 values = 1;
   */
  values: TimestampedInt32[] = [];

  constructor(data?: PartialMessage<FollowersTimeSeries>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.FollowersTimeSeries";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "values", kind: "message", T: TimestampedInt32, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FollowersTimeSeries {
    return new FollowersTimeSeries().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FollowersTimeSeries {
    return new FollowersTimeSeries().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FollowersTimeSeries {
    return new FollowersTimeSeries().fromJsonString(jsonString, options);
  }

  static equals(a: FollowersTimeSeries | PlainMessage<FollowersTimeSeries> | undefined, b: FollowersTimeSeries | PlainMessage<FollowersTimeSeries> | undefined): boolean {
    return proto3.util.equals(FollowersTimeSeries, a, b);
  }
}

/**
 * @generated from message librarian.proto.SimilarCreatorsHistogram
 */
export class SimilarCreatorsHistogram extends Message<SimilarCreatorsHistogram> {
  /**
   * @generated from field: repeated librarian.proto.SimilarCreatorsHistogram.CreatorsEngagement values = 1;
   */
  values: SimilarCreatorsHistogram_CreatorsEngagement[] = [];

  /**
   * @generated from field: float creator_eng = 2;
   */
  creatorEng = 0;

  constructor(data?: PartialMessage<SimilarCreatorsHistogram>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.SimilarCreatorsHistogram";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "values", kind: "message", T: SimilarCreatorsHistogram_CreatorsEngagement, repeated: true },
    { no: 2, name: "creator_eng", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SimilarCreatorsHistogram {
    return new SimilarCreatorsHistogram().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SimilarCreatorsHistogram {
    return new SimilarCreatorsHistogram().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SimilarCreatorsHistogram {
    return new SimilarCreatorsHistogram().fromJsonString(jsonString, options);
  }

  static equals(a: SimilarCreatorsHistogram | PlainMessage<SimilarCreatorsHistogram> | undefined, b: SimilarCreatorsHistogram | PlainMessage<SimilarCreatorsHistogram> | undefined): boolean {
    return proto3.util.equals(SimilarCreatorsHistogram, a, b);
  }
}

/**
 * contains the percentage of the creators that belongs to an engagement range
 *
 * @generated from message librarian.proto.SimilarCreatorsHistogram.CreatorsEngagement
 */
export class SimilarCreatorsHistogram_CreatorsEngagement extends Message<SimilarCreatorsHistogram_CreatorsEngagement> {
  /**
   * @generated from field: common.proto.FloatRange engagement_range = 1;
   */
  engagementRange?: FloatRange;

  /**
   * @generated from field: float percentage = 2;
   */
  percentage = 0;

  /**
   * @generated from field: bool creator_included = 3;
   */
  creatorIncluded = false;

  constructor(data?: PartialMessage<SimilarCreatorsHistogram_CreatorsEngagement>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.SimilarCreatorsHistogram.CreatorsEngagement";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "engagement_range", kind: "message", T: FloatRange },
    { no: 2, name: "percentage", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 3, name: "creator_included", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SimilarCreatorsHistogram_CreatorsEngagement {
    return new SimilarCreatorsHistogram_CreatorsEngagement().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SimilarCreatorsHistogram_CreatorsEngagement {
    return new SimilarCreatorsHistogram_CreatorsEngagement().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SimilarCreatorsHistogram_CreatorsEngagement {
    return new SimilarCreatorsHistogram_CreatorsEngagement().fromJsonString(jsonString, options);
  }

  static equals(a: SimilarCreatorsHistogram_CreatorsEngagement | PlainMessage<SimilarCreatorsHistogram_CreatorsEngagement> | undefined, b: SimilarCreatorsHistogram_CreatorsEngagement | PlainMessage<SimilarCreatorsHistogram_CreatorsEngagement> | undefined): boolean {
    return proto3.util.equals(SimilarCreatorsHistogram_CreatorsEngagement, a, b);
  }
}

/**
 * @generated from message librarian.proto.RefreshIntelligenceRequest
 */
export class RefreshIntelligenceRequest extends Message<RefreshIntelligenceRequest> {
  /**
   * @generated from field: string user_id = 1;
   */
  userId = "";

  /**
   * @generated from field: common.proto.Network network = 2;
   */
  network = Network.NETWORK_UNKNOWN;

  constructor(data?: PartialMessage<RefreshIntelligenceRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.RefreshIntelligenceRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "user_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "network", kind: "enum", T: proto3.getEnumType(Network) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RefreshIntelligenceRequest {
    return new RefreshIntelligenceRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RefreshIntelligenceRequest {
    return new RefreshIntelligenceRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): RefreshIntelligenceRequest {
    return new RefreshIntelligenceRequest().fromJsonString(jsonString, options);
  }

  static equals(a: RefreshIntelligenceRequest | PlainMessage<RefreshIntelligenceRequest> | undefined, b: RefreshIntelligenceRequest | PlainMessage<RefreshIntelligenceRequest> | undefined): boolean {
    return proto3.util.equals(RefreshIntelligenceRequest, a, b);
  }
}

/**
 * @generated from message librarian.proto.InviteRequest
 */
export class InviteRequest extends Message<InviteRequest> {
  /**
   * Meta id of the invited influencer.
   *
   * @generated from field: string influencer_id = 1;
   */
  influencerId = "";

  /**
   * the landing page id that will be used to onboard the influencer
   *
   * @generated from field: int64 landing_page_id = 2;
   */
  landingPageId = protoInt64.zero;

  /**
   * @generated from field: string email = 3;
   */
  email = "";

  /**
   * @generated from field: string message = 4;
   */
  message = "";

  /**
   * This is an optional config that will allow us to send the corpus in a specific language.
   *
   * @generated from field: string language = 5;
   */
  language = "";

  /**
   * @generated from field: bool receive_copy = 6;
   */
  receiveCopy = false;

  constructor(data?: PartialMessage<InviteRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.InviteRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "influencer_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "landing_page_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "message", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "language", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "receive_copy", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): InviteRequest {
    return new InviteRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): InviteRequest {
    return new InviteRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): InviteRequest {
    return new InviteRequest().fromJsonString(jsonString, options);
  }

  static equals(a: InviteRequest | PlainMessage<InviteRequest> | undefined, b: InviteRequest | PlainMessage<InviteRequest> | undefined): boolean {
    return proto3.util.equals(InviteRequest, a, b);
  }
}

/**
 * @generated from message librarian.proto.ExportInfluencerBrandSafetyRequest
 */
export class ExportInfluencerBrandSafetyRequest extends Message<ExportInfluencerBrandSafetyRequest> {
  /**
   * Meta id of the influencer.
   *
   * @generated from field: string influencer_id = 1;
   */
  influencerId = "";

  /**
   * Sensitive topics we will consider
   *
   * @generated from field: repeated common.proto.SensitiveTopic sensitive_topics = 2;
   */
  sensitiveTopics: SensitiveTopic[] = [];

  /**
   * Filled by backend
   *
   * @generated from field: int64 user_id = 3;
   */
  userId = protoInt64.zero;

  constructor(data?: PartialMessage<ExportInfluencerBrandSafetyRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.ExportInfluencerBrandSafetyRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "influencer_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "sensitive_topics", kind: "enum", T: proto3.getEnumType(SensitiveTopic), repeated: true },
    { no: 3, name: "user_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ExportInfluencerBrandSafetyRequest {
    return new ExportInfluencerBrandSafetyRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ExportInfluencerBrandSafetyRequest {
    return new ExportInfluencerBrandSafetyRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ExportInfluencerBrandSafetyRequest {
    return new ExportInfluencerBrandSafetyRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ExportInfluencerBrandSafetyRequest | PlainMessage<ExportInfluencerBrandSafetyRequest> | undefined, b: ExportInfluencerBrandSafetyRequest | PlainMessage<ExportInfluencerBrandSafetyRequest> | undefined): boolean {
    return proto3.util.equals(ExportInfluencerBrandSafetyRequest, a, b);
  }
}

/**
 * @generated from message librarian.proto.UploadInfluencersListTaskRequest
 */
export class UploadInfluencersListTaskRequest extends Message<UploadInfluencersListTaskRequest> {
  /**
   * @generated from field: repeated string names = 1;
   */
  names: string[] = [];

  /**
   * @generated from field: common.proto.Network network = 2;
   */
  network = Network.NETWORK_UNKNOWN;

  /**
   * @generated from field: int64 user_id = 3;
   */
  userId = protoInt64.zero;

  constructor(data?: PartialMessage<UploadInfluencersListTaskRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.UploadInfluencersListTaskRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "names", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 2, name: "network", kind: "enum", T: proto3.getEnumType(Network) },
    { no: 3, name: "user_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UploadInfluencersListTaskRequest {
    return new UploadInfluencersListTaskRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UploadInfluencersListTaskRequest {
    return new UploadInfluencersListTaskRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UploadInfluencersListTaskRequest {
    return new UploadInfluencersListTaskRequest().fromJsonString(jsonString, options);
  }

  static equals(a: UploadInfluencersListTaskRequest | PlainMessage<UploadInfluencersListTaskRequest> | undefined, b: UploadInfluencersListTaskRequest | PlainMessage<UploadInfluencersListTaskRequest> | undefined): boolean {
    return proto3.util.equals(UploadInfluencersListTaskRequest, a, b);
  }
}

/**
 * @generated from message librarian.proto.UploadInfluencersListTaskResponse
 */
export class UploadInfluencersListTaskResponse extends Message<UploadInfluencersListTaskResponse> {
  /**
   * @generated from field: string task_handle = 1;
   */
  taskHandle = "";

  constructor(data?: PartialMessage<UploadInfluencersListTaskResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.UploadInfluencersListTaskResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "task_handle", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UploadInfluencersListTaskResponse {
    return new UploadInfluencersListTaskResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UploadInfluencersListTaskResponse {
    return new UploadInfluencersListTaskResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UploadInfluencersListTaskResponse {
    return new UploadInfluencersListTaskResponse().fromJsonString(jsonString, options);
  }

  static equals(a: UploadInfluencersListTaskResponse | PlainMessage<UploadInfluencersListTaskResponse> | undefined, b: UploadInfluencersListTaskResponse | PlainMessage<UploadInfluencersListTaskResponse> | undefined): boolean {
    return proto3.util.equals(UploadInfluencersListTaskResponse, a, b);
  }
}

/**
 * @generated from message librarian.proto.UploadInfluencersListTaskOutput
 */
export class UploadInfluencersListTaskOutput extends Message<UploadInfluencersListTaskOutput> {
  /**
   * @generated from field: repeated librarian.proto.CreatorCardSnippet influencers_cards = 1;
   */
  influencersCards: CreatorCardSnippet[] = [];

  constructor(data?: PartialMessage<UploadInfluencersListTaskOutput>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.UploadInfluencersListTaskOutput";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "influencers_cards", kind: "message", T: CreatorCardSnippet, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UploadInfluencersListTaskOutput {
    return new UploadInfluencersListTaskOutput().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UploadInfluencersListTaskOutput {
    return new UploadInfluencersListTaskOutput().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UploadInfluencersListTaskOutput {
    return new UploadInfluencersListTaskOutput().fromJsonString(jsonString, options);
  }

  static equals(a: UploadInfluencersListTaskOutput | PlainMessage<UploadInfluencersListTaskOutput> | undefined, b: UploadInfluencersListTaskOutput | PlainMessage<UploadInfluencersListTaskOutput> | undefined): boolean {
    return proto3.util.equals(UploadInfluencersListTaskOutput, a, b);
  }
}

