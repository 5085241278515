import {
  ChangeDetectionStrategy,
  Component,
  computed,
  inject,
  input,
  Input,
  output,
  signal,
} from '@angular/core';
import {
  escapeRegExp,
  isGhostAffiliationProgram,
  isNotEmptyString,
} from '@frontend2/core';
import { Program } from '@frontend2/proto/librarian/proto/affiliation_pb';

import { LeftyButtonDirective } from '../../lefty-button-directive/lefty-button.directive';
import { LeftyDialogComponent } from '../../lefty-dialog/lefty-dialog.component';
import { DialogBase } from '../../lefty-dialog/lefty-dialog.helpers';
import { LeftyFormInputComponent } from '../../lefty-form-input/lefty-form-input.component';
import { LeftySpinnerComponent } from '../../loading.component';
import { AffiliationProgramsCache } from '../affiliation-programs.cache';
import { AlfredProgramCardComponent } from '../program-card/program-card-component';

@Component({
  selector: 'lefty-select-program-dialog',
  templateUrl: 'select-program-dialog.component.html',
  styleUrls: ['select-program-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    LeftyDialogComponent,
    LeftyFormInputComponent,
    LeftySpinnerComponent,
    AlfredProgramCardComponent,
    LeftyButtonDirective,
  ],
})
export class LeftySelectProgramDialogComponent extends DialogBase {
  readonly searchValue = signal('');

  @Input()
  cancelButtonText = $localize`Cancel`;

  @Input()
  selectButtonText = $localize`Select`;

  @Input()
  withAddProgramButton = false;

  readonly description = input('');

  readonly hasDescription = computed(() =>
    isNotEmptyString(this.description()),
  );

  readonly addProgramClicked$ = output<void>();

  override open(): void {
    this.selectedProgram.set(new Program());
    this.programsCache.load();
    super.open();
  }

  openWith(program: Program): void {
    this.selectedProgram.set(program);
    this.programsCache.load();
    super.open();
  }

  addProgram(): void {
    this.close();
    this.addProgramClicked$.emit();
  }

  private readonly programsCache = inject(AffiliationProgramsCache);

  readonly loading = this.programsCache.isLoading;

  readonly selected$ = output<Program>();

  readonly cancel$ = output<void>();

  readonly closedWithCloseButtonOrDismiss$ = output<void>();

  readonly programs = computed(() => this.programsCache.notArchivedPrograms());

  readonly filteredPrograms = computed(() => {
    const query = escapeRegExp(this.searchValue().trim());
    const regex = new RegExp(query, 'i');

    return this.programs().filter((p) => {
      return isNotEmptyString(p.name) && regex.test(p.name);
    });
  });

  readonly selectedProgram = signal(new Program());

  readonly hasSelection = computed(
    () => !isGhostAffiliationProgram(this.selectedProgram()),
  );

  isSelected(program: Program): boolean {
    return (
      !isGhostAffiliationProgram(program) &&
      this.selectedProgram().programId === program.programId
    );
  }

  selectProgram(program: Program): void {
    this.selectedProgram.set(program);
  }

  next(): void {
    this.close();
    this.selected$.emit(this.selectedProgram());
  }

  cancel(): void {
    this.cancel$.emit();
    this.selectedProgram.set(new Program());
    this.close();
  }
}
