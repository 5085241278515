import { Injectable } from '@angular/core';
import { CampaignKeywordType } from '@frontend2/proto/common/proto/campaign_pb';
import { Network } from '@frontend2/proto/common/proto/common_pb';
import {
  createTrackingKeywordsConfig,
  snapchatKeywordsField,
  snapchatMentionsField,
  snapchatTagsField,
} from './config.helpers';
import * as TKMessages from './config.messages';
import { DefaultNetworkConfig, FormField } from './config.models';

@Injectable({ providedIn: 'root' })
export class SnapchatConfig extends DefaultNetworkConfig {
  override readonly network = Network.SNAPCHAT;
  override readonly supportViews = true;
  override readonly supportReshares = true;
  override readonly uploadPlaceholder: string = [
    'selenagomez',
    'www.snapchat.com/add/selenagomez',
    '...',
  ].join('\n');

  override readonly keywords = createTrackingKeywordsConfig({
    types: [
      CampaignKeywordType.SNAPCHAT_TAG,
      CampaignKeywordType.SNAPCHAT_MENTION,
      CampaignKeywordType.SNAPCHAT_KEYWORD,
    ],
    formHelpers: new Map<CampaignKeywordType, FormField>([
      [CampaignKeywordType.SNAPCHAT_TAG, snapchatTagsField],
      [CampaignKeywordType.SNAPCHAT_MENTION, snapchatMentionsField],
      [CampaignKeywordType.SNAPCHAT_KEYWORD, snapchatKeywordsField],
    ]),
    delimiters: new Map<CampaignKeywordType, string>([
      // SPACE not accepted as delimiter since mention/keywords can contain space
      [CampaignKeywordType.SNAPCHAT_MENTION, ',#@'],
      [CampaignKeywordType.SNAPCHAT_KEYWORD, ',#@'],
    ]),
    errorMessage: TKMessages.snapchatErrorMessage,
  });
}
