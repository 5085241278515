// This file defines common protobufs for creators.

// @generated by protoc-gen-es v1.10.0 with parameter "target=ts"
// @generated from file librarian/proto/creators.proto (package librarian.proto, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { FloatValue, Message, proto3, protoInt64, StringValue, Timestamp } from "@bufbuild/protobuf";
import { LanguagePercent } from "../../common/proto/user_pb";
import { CampaignSnippet, CreatorPerformance, FavoritesLabels, InfluencerCampaigns, InfluencerPrice, InfluencerStatus } from "../../common/proto/campaign_pb";
import { EntityFieldValue } from "./entity_fields_pb";
import { ComputedGenerikStats, Network, PostType, StringWeight } from "../../common/proto/common_pb";

/**
 * @generated from message librarian.proto.CreatorTiniestCard
 */
export class CreatorTiniestCard extends Message<CreatorTiniestCard> {
  /**
   * @generated from field: string user_id = 1;
   */
  userId = "";

  /**
   * @generated from field: string user_name = 2;
   */
  userName = "";

  /**
   * @generated from field: int64 followers = 4;
   */
  followers = protoInt64.zero;

  /**
   * same remark as in the CreatorCardSnippet message.
   *
   * @generated from field: string profile_pic_kept = 5;
   */
  profilePicKept = "";

  constructor(data?: PartialMessage<CreatorTiniestCard>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.CreatorTiniestCard";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "user_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "user_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "followers", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 5, name: "profile_pic_kept", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreatorTiniestCard {
    return new CreatorTiniestCard().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreatorTiniestCard {
    return new CreatorTiniestCard().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreatorTiniestCard {
    return new CreatorTiniestCard().fromJsonString(jsonString, options);
  }

  static equals(a: CreatorTiniestCard | PlainMessage<CreatorTiniestCard> | undefined, b: CreatorTiniestCard | PlainMessage<CreatorTiniestCard> | undefined): boolean {
    return proto3.util.equals(CreatorTiniestCard, a, b);
  }
}

/**
 * The very basic information that goes into a creator card.
 *
 * @generated from message librarian.proto.CreatorCardSnippet
 */
export class CreatorCardSnippet extends Message<CreatorCardSnippet> {
  /**
   * @generated from field: string user_id = 1;
   */
  userId = "";

  /**
   * @generated from field: string user_name = 2;
   */
  userName = "";

  /**
   * The profile picture URL as hosted by the network (so we get the latest one and use their CDNs).
   * This might be also equal to 'profile_pic_kept' field below for networks that never host a reliable profile pic URL
   * (eg: TikTok).
   *
   * @generated from field: string profile_pic = 3;
   */
  profilePic = "";

  /**
   * The profile picture URL in our antkeeper, that may be used as a fallback if the above doesn't answer.
   *
   * @generated from field: string profile_pic_kept = 4;
   */
  profilePicKept = "";

  /**
   * @generated from field: repeated common.proto.LanguagePercent languages = 5;
   */
  languages: LanguagePercent[] = [];

  constructor(data?: PartialMessage<CreatorCardSnippet>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.CreatorCardSnippet";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "user_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "user_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "profile_pic", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "profile_pic_kept", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "languages", kind: "message", T: LanguagePercent, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreatorCardSnippet {
    return new CreatorCardSnippet().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreatorCardSnippet {
    return new CreatorCardSnippet().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreatorCardSnippet {
    return new CreatorCardSnippet().fromJsonString(jsonString, options);
  }

  static equals(a: CreatorCardSnippet | PlainMessage<CreatorCardSnippet> | undefined, b: CreatorCardSnippet | PlainMessage<CreatorCardSnippet> | undefined): boolean {
    return proto3.util.equals(CreatorCardSnippet, a, b);
  }
}

/**
 * @generated from message librarian.proto.CreatorRegisteredDetails
 */
export class CreatorRegisteredDetails extends Message<CreatorRegisteredDetails> {
  /**
   * This is the status of the creator in the client workspace
   * from invited to accepted to be part of the community
   *
   * @generated from field: common.proto.InfluencerStatus status = 1;
   */
  status = InfluencerStatus.INFLUENCER_PENDING;

  /**
   * @generated from field: bool has_payment_info = 2;
   */
  hasPaymentInfo = false;

  constructor(data?: PartialMessage<CreatorRegisteredDetails>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.CreatorRegisteredDetails";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "status", kind: "enum", T: proto3.getEnumType(InfluencerStatus) },
    { no: 2, name: "has_payment_info", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreatorRegisteredDetails {
    return new CreatorRegisteredDetails().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreatorRegisteredDetails {
    return new CreatorRegisteredDetails().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreatorRegisteredDetails {
    return new CreatorRegisteredDetails().fromJsonString(jsonString, options);
  }

  static equals(a: CreatorRegisteredDetails | PlainMessage<CreatorRegisteredDetails> | undefined, b: CreatorRegisteredDetails | PlainMessage<CreatorRegisteredDetails> | undefined): boolean {
    return proto3.util.equals(CreatorRegisteredDetails, a, b);
  }
}

/**
 * @generated from message librarian.proto.CreatorCWCardSnippet
 */
export class CreatorCWCardSnippet extends Message<CreatorCWCardSnippet> {
  /**
   * @generated from field: string user_id = 1;
   */
  userId = "";

  /**
   * @generated from field: string user_name = 2;
   */
  userName = "";

  /**
   * @generated from field: string profile_pic = 3;
   */
  profilePic = "";

  /**
   * @generated from field: librarian.proto.CreatorCWCardSnippet.ReportStats stats = 4;
   */
  stats?: CreatorCWCardSnippet_ReportStats;

  constructor(data?: PartialMessage<CreatorCWCardSnippet>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.CreatorCWCardSnippet";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "user_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "user_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "profile_pic", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "stats", kind: "message", T: CreatorCWCardSnippet_ReportStats },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreatorCWCardSnippet {
    return new CreatorCWCardSnippet().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreatorCWCardSnippet {
    return new CreatorCWCardSnippet().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreatorCWCardSnippet {
    return new CreatorCWCardSnippet().fromJsonString(jsonString, options);
  }

  static equals(a: CreatorCWCardSnippet | PlainMessage<CreatorCWCardSnippet> | undefined, b: CreatorCWCardSnippet | PlainMessage<CreatorCWCardSnippet> | undefined): boolean {
    return proto3.util.equals(CreatorCWCardSnippet, a, b);
  }
}

/**
 * @generated from message librarian.proto.CreatorCWCardSnippet.ReportStats
 */
export class CreatorCWCardSnippet_ReportStats extends Message<CreatorCWCardSnippet_ReportStats> {
  /**
   * @generated from field: float engagement = 1;
   */
  engagement = 0;

  /**
   * @generated from field: float avk_likes_2_months = 2;
   */
  avkLikes2Months = 0;

  /**
   * @generated from field: int32 followers_count = 3;
   */
  followersCount = 0;

  /**
   * @generated from field: int32 posts_count = 4;
   */
  postsCount = 0;

  /**
   * @generated from field: int32 stories_count = 7;
   */
  storiesCount = 0;

  /**
   * @generated from field: int64 emv = 8;
   */
  emv = protoInt64.zero;

  /**
   * @generated from field: int64 impressions = 6;
   */
  impressions = protoInt64.zero;

  constructor(data?: PartialMessage<CreatorCWCardSnippet_ReportStats>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.CreatorCWCardSnippet.ReportStats";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "engagement", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 2, name: "avk_likes_2_months", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 3, name: "followers_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "posts_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 7, name: "stories_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 8, name: "emv", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 6, name: "impressions", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreatorCWCardSnippet_ReportStats {
    return new CreatorCWCardSnippet_ReportStats().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreatorCWCardSnippet_ReportStats {
    return new CreatorCWCardSnippet_ReportStats().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreatorCWCardSnippet_ReportStats {
    return new CreatorCWCardSnippet_ReportStats().fromJsonString(jsonString, options);
  }

  static equals(a: CreatorCWCardSnippet_ReportStats | PlainMessage<CreatorCWCardSnippet_ReportStats> | undefined, b: CreatorCWCardSnippet_ReportStats | PlainMessage<CreatorCWCardSnippet_ReportStats> | undefined): boolean {
    return proto3.util.equals(CreatorCWCardSnippet_ReportStats, a, b);
  }
}

/**
 * @generated from message librarian.proto.CampaignCardSnippet
 */
export class CampaignCardSnippet extends Message<CampaignCardSnippet> {
  /**
   * @generated from field: librarian.proto.CreatorCardSnippet base_snippet = 1;
   */
  baseSnippet?: CreatorCardSnippet;

  /**
   * @generated from field: common.proto.InfluencerCampaigns statuses = 2;
   */
  statuses?: InfluencerCampaigns;

  /**
   * @generated from field: common.proto.FavoritesLabels labels = 3;
   */
  labels?: FavoritesLabels;

  /**
   * @generated from field: librarian.proto.CreatorRegisteredDetails registered_details = 4;
   */
  registeredDetails?: CreatorRegisteredDetails;

  constructor(data?: PartialMessage<CampaignCardSnippet>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.CampaignCardSnippet";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "base_snippet", kind: "message", T: CreatorCardSnippet },
    { no: 2, name: "statuses", kind: "message", T: InfluencerCampaigns },
    { no: 3, name: "labels", kind: "message", T: FavoritesLabels },
    { no: 4, name: "registered_details", kind: "message", T: CreatorRegisteredDetails },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CampaignCardSnippet {
    return new CampaignCardSnippet().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CampaignCardSnippet {
    return new CampaignCardSnippet().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CampaignCardSnippet {
    return new CampaignCardSnippet().fromJsonString(jsonString, options);
  }

  static equals(a: CampaignCardSnippet | PlainMessage<CampaignCardSnippet> | undefined, b: CampaignCardSnippet | PlainMessage<CampaignCardSnippet> | undefined): boolean {
    return proto3.util.equals(CampaignCardSnippet, a, b);
  }
}

/**
 * @generated from message librarian.proto.AffiliationInfo
 */
export class AffiliationInfo extends Message<AffiliationInfo> {
  /**
   * @generated from field: int64 affiliate_id = 4;
   */
  affiliateId = protoInt64.zero;

  /**
   * @generated from field: int64 program_id = 5;
   */
  programId = protoInt64.zero;

  /**
   * @generated from field: int32 clicks = 1;
   */
  clicks = 0;

  /**
   * @generated from field: int32 orders = 2;
   */
  orders = 0;

  /**
   * @generated from field: float sales = 3;
   */
  sales = 0;

  /**
   * @generated from field: bool archived = 6;
   */
  archived = false;

  constructor(data?: PartialMessage<AffiliationInfo>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.AffiliationInfo";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 4, name: "affiliate_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 5, name: "program_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 1, name: "clicks", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "orders", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "sales", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 6, name: "archived", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AffiliationInfo {
    return new AffiliationInfo().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AffiliationInfo {
    return new AffiliationInfo().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AffiliationInfo {
    return new AffiliationInfo().fromJsonString(jsonString, options);
  }

  static equals(a: AffiliationInfo | PlainMessage<AffiliationInfo> | undefined, b: AffiliationInfo | PlainMessage<AffiliationInfo> | undefined): boolean {
    return proto3.util.equals(AffiliationInfo, a, b);
  }
}

/**
 * @generated from message librarian.proto.CreatorCampaignCard
 */
export class CreatorCampaignCard extends Message<CreatorCampaignCard> {
  /**
   * @generated from field: librarian.proto.CreatorCardSnippet base_snippet = 1;
   */
  baseSnippet?: CreatorCardSnippet;

  /**
   * @generated from field: librarian.proto.CreatorRegisteredDetails registered_details = 100;
   */
  registeredDetails?: CreatorRegisteredDetails;

  /**
   * @generated from field: repeated librarian.proto.GenerikInfoCard network_info = 13;
   */
  networkInfo: GenerikInfoCard[] = [];

  /**
   * @generated from field: common.proto.InfluencerCampaigns statuses = 3;
   */
  statuses?: InfluencerCampaigns;

  /**
   * @generated from field: common.proto.FavoritesLabels labels = 4;
   */
  labels?: FavoritesLabels;

  /**
   * @generated from field: common.proto.CreatorPerformance perf = 10;
   */
  perf?: CreatorPerformance;

  /**
   * @generated from field: common.proto.InfluencerPrice price = 6;
   */
  price?: InfluencerPrice;

  /**
   * @generated from field: google.protobuf.FloatValue roi = 19;
   */
  roi?: number;

  /**
   * @generated from field: google.protobuf.FloatValue cpe = 20;
   */
  cpe?: number;

  /**
   * @generated from field: repeated librarian.proto.EntityFieldValue entity_fields_values = 17;
   */
  entityFieldsValues: EntityFieldValue[] = [];

  /**
   * @generated from field: librarian.proto.AffiliationInfo affiliation_info = 14;
   */
  affiliationInfo?: AffiliationInfo;

  /**
   * This is the CPM inferred from the paid price by the client
   *
   * @generated from field: float equivalent_cpm = 7 [deprecated = true];
   * @deprecated
   */
  equivalentCpm = 0;

  /**
   * @generated from field: google.protobuf.Timestamp added_time = 11;
   */
  addedTime?: Timestamp;

  /**
   * @generated from field: int32 notes_count = 12;
   */
  notesCount = 0;

  /**
   * This may be used when listing the campaigns of an influencer
   *
   * @generated from field: common.proto.CampaignSnippet campaign_snippet = 8;
   */
  campaignSnippet?: CampaignSnippet;

  /**
   * @generated from field: bool in_pool = 16;
   */
  inPool = false;

  /**
   * Prism specific for now
   *
   * @generated from field: bool untracked_in_campaign = 18;
   */
  untrackedInCampaign = false;

  constructor(data?: PartialMessage<CreatorCampaignCard>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.CreatorCampaignCard";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "base_snippet", kind: "message", T: CreatorCardSnippet },
    { no: 100, name: "registered_details", kind: "message", T: CreatorRegisteredDetails },
    { no: 13, name: "network_info", kind: "message", T: GenerikInfoCard, repeated: true },
    { no: 3, name: "statuses", kind: "message", T: InfluencerCampaigns },
    { no: 4, name: "labels", kind: "message", T: FavoritesLabels },
    { no: 10, name: "perf", kind: "message", T: CreatorPerformance },
    { no: 6, name: "price", kind: "message", T: InfluencerPrice },
    { no: 19, name: "roi", kind: "message", T: FloatValue },
    { no: 20, name: "cpe", kind: "message", T: FloatValue },
    { no: 17, name: "entity_fields_values", kind: "message", T: EntityFieldValue, repeated: true },
    { no: 14, name: "affiliation_info", kind: "message", T: AffiliationInfo },
    { no: 7, name: "equivalent_cpm", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 11, name: "added_time", kind: "message", T: Timestamp },
    { no: 12, name: "notes_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 8, name: "campaign_snippet", kind: "message", T: CampaignSnippet },
    { no: 16, name: "in_pool", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 18, name: "untracked_in_campaign", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreatorCampaignCard {
    return new CreatorCampaignCard().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreatorCampaignCard {
    return new CreatorCampaignCard().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreatorCampaignCard {
    return new CreatorCampaignCard().fromJsonString(jsonString, options);
  }

  static equals(a: CreatorCampaignCard | PlainMessage<CreatorCampaignCard> | undefined, b: CreatorCampaignCard | PlainMessage<CreatorCampaignCard> | undefined): boolean {
    return proto3.util.equals(CreatorCampaignCard, a, b);
  }
}

/**
 * @generated from message librarian.proto.CreatorCampaignCards
 */
export class CreatorCampaignCards extends Message<CreatorCampaignCards> {
  /**
   * @generated from field: repeated librarian.proto.CreatorCampaignCard creators = 1;
   */
  creators: CreatorCampaignCard[] = [];

  /**
   * used to know which networks are used for stats filters
   * backend is already computing it, so no need to compute it in the frontend
   *
   * @generated from field: repeated common.proto.Network filtered_networks = 4;
   */
  filteredNetworks: Network[] = [];

  /**
   * @generated from field: int32 total_hits = 2;
   */
  totalHits = 0;

  constructor(data?: PartialMessage<CreatorCampaignCards>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.CreatorCampaignCards";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "creators", kind: "message", T: CreatorCampaignCard, repeated: true },
    { no: 4, name: "filtered_networks", kind: "enum", T: proto3.getEnumType(Network), repeated: true },
    { no: 2, name: "total_hits", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreatorCampaignCards {
    return new CreatorCampaignCards().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreatorCampaignCards {
    return new CreatorCampaignCards().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreatorCampaignCards {
    return new CreatorCampaignCards().fromJsonString(jsonString, options);
  }

  static equals(a: CreatorCampaignCards | PlainMessage<CreatorCampaignCards> | undefined, b: CreatorCampaignCards | PlainMessage<CreatorCampaignCards> | undefined): boolean {
    return proto3.util.equals(CreatorCampaignCards, a, b);
  }
}

/**
 * @generated from message librarian.proto.GenerikInfoCard
 */
export class GenerikInfoCard extends Message<GenerikInfoCard> {
  /**
   * @generated from field: common.proto.Network network = 1;
   */
  network = Network.NETWORK_UNKNOWN;

  /**
   * username for the given network
   *
   * @generated from field: string username = 8;
   */
  username = "";

  /**
   * profile pic for the given network
   *
   * @generated from field: string profile_pic = 9;
   */
  profilePic = "";

  /**
   * this field is used for complete link, for example Youtube need the channel Id
   * for Twitter it's screen name etc...
   *
   * @generated from field: string network_link = 2;
   */
  networkLink = "";

  /**
   * Field for give the bio or the description of creator's profil on the network.
   *
   * @generated from field: string caption = 3;
   */
  caption = "";

  /**
   * @generated from field: librarian.proto.GenerikInfoCard.GenerikCount count = 4;
   */
  count?: GenerikInfoCard_GenerikCount;

  /**
   * @generated from field: librarian.proto.GenerikInfoCard.Stats stats = 5;
   */
  stats?: GenerikInfoCard_Stats;

  /**
   * @generated from field: bool is_private = 6;
   */
  isPrivate = false;

  /**
   * @generated from field: bool is_deleted = 7;
   */
  isDeleted = false;

  constructor(data?: PartialMessage<GenerikInfoCard>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.GenerikInfoCard";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "network", kind: "enum", T: proto3.getEnumType(Network) },
    { no: 8, name: "username", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "profile_pic", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "network_link", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "caption", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "count", kind: "message", T: GenerikInfoCard_GenerikCount },
    { no: 5, name: "stats", kind: "message", T: GenerikInfoCard_Stats },
    { no: 6, name: "is_private", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 7, name: "is_deleted", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GenerikInfoCard {
    return new GenerikInfoCard().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GenerikInfoCard {
    return new GenerikInfoCard().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GenerikInfoCard {
    return new GenerikInfoCard().fromJsonString(jsonString, options);
  }

  static equals(a: GenerikInfoCard | PlainMessage<GenerikInfoCard> | undefined, b: GenerikInfoCard | PlainMessage<GenerikInfoCard> | undefined): boolean {
    return proto3.util.equals(GenerikInfoCard, a, b);
  }
}

/**
 * Count are stats given by the creator's network, it isn't our computed values.
 *
 * @generated from message librarian.proto.GenerikInfoCard.GenerikCount
 */
export class GenerikInfoCard_GenerikCount extends Message<GenerikInfoCard_GenerikCount> {
  /**
   * @generated from field: int64 followers_count = 1;
   */
  followersCount = protoInt64.zero;

  /**
   * @generated from field: int32 post_count = 2;
   */
  postCount = 0;

  /**
   * @generated from field: int64 likes_count = 3;
   */
  likesCount = protoInt64.zero;

  /**
   * @generated from field: int64 views_count = 4;
   */
  viewsCount = protoInt64.zero;

  /**
   * @generated from field: int64 reshares_count = 5;
   */
  resharesCount = protoInt64.zero;

  constructor(data?: PartialMessage<GenerikInfoCard_GenerikCount>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.GenerikInfoCard.GenerikCount";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "followers_count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "post_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "likes_count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 4, name: "views_count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 5, name: "reshares_count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GenerikInfoCard_GenerikCount {
    return new GenerikInfoCard_GenerikCount().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GenerikInfoCard_GenerikCount {
    return new GenerikInfoCard_GenerikCount().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GenerikInfoCard_GenerikCount {
    return new GenerikInfoCard_GenerikCount().fromJsonString(jsonString, options);
  }

  static equals(a: GenerikInfoCard_GenerikCount | PlainMessage<GenerikInfoCard_GenerikCount> | undefined, b: GenerikInfoCard_GenerikCount | PlainMessage<GenerikInfoCard_GenerikCount> | undefined): boolean {
    return proto3.util.equals(GenerikInfoCard_GenerikCount, a, b);
  }
}

/**
 * Stats are calculated on the last 2 months.
 *
 * @generated from message librarian.proto.GenerikInfoCard.Stats
 */
export class GenerikInfoCard_Stats extends Message<GenerikInfoCard_Stats> {
  /**
   * @generated from field: float avg_likes = 1;
   */
  avgLikes = 0;

  /**
   * @generated from field: float avg_views = 2;
   */
  avgViews = 0;

  /**
   * @generated from field: float avg_reshares = 3;
   */
  avgReshares = 0;

  /**
   * @generated from field: float avg_clicks = 6;
   */
  avgClicks = 0;

  /**
   * @generated from field: float avg_comments = 7;
   */
  avgComments = 0;

  /**
   * TODO(mateo) rename to engagement_rate (migration in 2 steps)
   *
   * @generated from field: float engagement = 4;
   */
  engagement = 0;

  /**
   * INSTA only for now
   *
   * @generated from field: bool engagement_rate_estimated = 10;
   */
  engagementRateEstimated = false;

  /**
   * @generated from field: int64 emv = 9;
   */
  emv = protoInt64.zero;

  /**
   * @generated from field: int64 impressions = 8;
   */
  impressions = protoInt64.zero;

  constructor(data?: PartialMessage<GenerikInfoCard_Stats>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.GenerikInfoCard.Stats";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "avg_likes", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 2, name: "avg_views", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 3, name: "avg_reshares", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 6, name: "avg_clicks", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 7, name: "avg_comments", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 4, name: "engagement", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 10, name: "engagement_rate_estimated", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 9, name: "emv", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 8, name: "impressions", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GenerikInfoCard_Stats {
    return new GenerikInfoCard_Stats().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GenerikInfoCard_Stats {
    return new GenerikInfoCard_Stats().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GenerikInfoCard_Stats {
    return new GenerikInfoCard_Stats().fromJsonString(jsonString, options);
  }

  static equals(a: GenerikInfoCard_Stats | PlainMessage<GenerikInfoCard_Stats> | undefined, b: GenerikInfoCard_Stats | PlainMessage<GenerikInfoCard_Stats> | undefined): boolean {
    return proto3.util.equals(GenerikInfoCard_Stats, a, b);
  }
}

/**
 * @generated from message librarian.proto.CreatorProfileCard
 */
export class CreatorProfileCard extends Message<CreatorProfileCard> {
  /**
   * @generated from field: librarian.proto.CreatorCardSnippet base_snippet = 1;
   */
  baseSnippet?: CreatorCardSnippet;

  /**
   * @generated from field: librarian.proto.CreatorRegisteredDetails registered_details = 23;
   */
  registeredDetails?: CreatorRegisteredDetails;

  /**
   * @generated from field: google.protobuf.StringValue email = 4;
   */
  email?: string;

  /**
   * @generated from field: common.proto.InfluencerCampaigns statuses = 10;
   */
  statuses?: InfluencerCampaigns;

  /**
   * @generated from field: common.proto.FavoritesLabels labels = 11;
   */
  labels?: FavoritesLabels;

  /**
   * @generated from field: repeated librarian.proto.GenerikInfoCard network_info = 17;
   */
  networkInfo: GenerikInfoCard[] = [];

  /**
   * @generated from field: librarian.proto.EstimatedPrices estimated = 15;
   */
  estimated?: EstimatedPrices;

  /**
   * @generated from field: repeated common.proto.StringWeight categories = 22;
   */
  categories: StringWeight[] = [];

  constructor(data?: PartialMessage<CreatorProfileCard>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.CreatorProfileCard";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "base_snippet", kind: "message", T: CreatorCardSnippet },
    { no: 23, name: "registered_details", kind: "message", T: CreatorRegisteredDetails },
    { no: 4, name: "email", kind: "message", T: StringValue },
    { no: 10, name: "statuses", kind: "message", T: InfluencerCampaigns },
    { no: 11, name: "labels", kind: "message", T: FavoritesLabels },
    { no: 17, name: "network_info", kind: "message", T: GenerikInfoCard, repeated: true },
    { no: 15, name: "estimated", kind: "message", T: EstimatedPrices },
    { no: 22, name: "categories", kind: "message", T: StringWeight, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreatorProfileCard {
    return new CreatorProfileCard().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreatorProfileCard {
    return new CreatorProfileCard().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreatorProfileCard {
    return new CreatorProfileCard().fromJsonString(jsonString, options);
  }

  static equals(a: CreatorProfileCard | PlainMessage<CreatorProfileCard> | undefined, b: CreatorProfileCard | PlainMessage<CreatorProfileCard> | undefined): boolean {
    return proto3.util.equals(CreatorProfileCard, a, b);
  }
}

/**
 * @generated from message librarian.proto.EstimatedPrices
 */
export class EstimatedPrices extends Message<EstimatedPrices> {
  /**
   * @generated from field: repeated librarian.proto.EstimatedPrices.EstimatedPrice price = 1;
   */
  price: EstimatedPrices_EstimatedPrice[] = [];

  constructor(data?: PartialMessage<EstimatedPrices>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.EstimatedPrices";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "price", kind: "message", T: EstimatedPrices_EstimatedPrice, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): EstimatedPrices {
    return new EstimatedPrices().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): EstimatedPrices {
    return new EstimatedPrices().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): EstimatedPrices {
    return new EstimatedPrices().fromJsonString(jsonString, options);
  }

  static equals(a: EstimatedPrices | PlainMessage<EstimatedPrices> | undefined, b: EstimatedPrices | PlainMessage<EstimatedPrices> | undefined): boolean {
    return proto3.util.equals(EstimatedPrices, a, b);
  }
}

/**
 * @generated from message librarian.proto.EstimatedPrices.EstimatedPrice
 */
export class EstimatedPrices_EstimatedPrice extends Message<EstimatedPrices_EstimatedPrice> {
  /**
   * @generated from field: common.proto.PostType post_type = 1;
   */
  postType = PostType.POST_TYPE_UNDEFINED;

  /**
   * @generated from field: int32 value = 2;
   */
  value = 0;

  constructor(data?: PartialMessage<EstimatedPrices_EstimatedPrice>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.EstimatedPrices.EstimatedPrice";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "post_type", kind: "enum", T: proto3.getEnumType(PostType) },
    { no: 2, name: "value", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): EstimatedPrices_EstimatedPrice {
    return new EstimatedPrices_EstimatedPrice().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): EstimatedPrices_EstimatedPrice {
    return new EstimatedPrices_EstimatedPrice().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): EstimatedPrices_EstimatedPrice {
    return new EstimatedPrices_EstimatedPrice().fromJsonString(jsonString, options);
  }

  static equals(a: EstimatedPrices_EstimatedPrice | PlainMessage<EstimatedPrices_EstimatedPrice> | undefined, b: EstimatedPrices_EstimatedPrice | PlainMessage<EstimatedPrices_EstimatedPrice> | undefined): boolean {
    return proto3.util.equals(EstimatedPrices_EstimatedPrice, a, b);
  }
}

/**
 * @generated from message librarian.proto.MentionCard
 */
export class MentionCard extends Message<MentionCard> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: string user_name = 2;
   */
  userName = "";

  /**
   * @generated from field: string profile_picture = 3;
   */
  profilePicture = "";

  /**
   * @generated from field: int32 count = 4;
   */
  count = 0;

  constructor(data?: PartialMessage<MentionCard>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.MentionCard";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "user_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "profile_picture", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): MentionCard {
    return new MentionCard().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): MentionCard {
    return new MentionCard().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): MentionCard {
    return new MentionCard().fromJsonString(jsonString, options);
  }

  static equals(a: MentionCard | PlainMessage<MentionCard> | undefined, b: MentionCard | PlainMessage<MentionCard> | undefined): boolean {
    return proto3.util.equals(MentionCard, a, b);
  }
}

/**
 * @generated from message librarian.proto.CreatorStatsCard
 */
export class CreatorStatsCard extends Message<CreatorStatsCard> {
  /**
   * @generated from field: librarian.proto.CreatorProfileCard profile = 1;
   */
  profile?: CreatorProfileCard;

  /**
   * @generated from field: repeated librarian.proto.NetworkComputedGenerikStats stats = 7;
   */
  stats: NetworkComputedGenerikStats[] = [];

  constructor(data?: PartialMessage<CreatorStatsCard>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.CreatorStatsCard";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "profile", kind: "message", T: CreatorProfileCard },
    { no: 7, name: "stats", kind: "message", T: NetworkComputedGenerikStats, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreatorStatsCard {
    return new CreatorStatsCard().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreatorStatsCard {
    return new CreatorStatsCard().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreatorStatsCard {
    return new CreatorStatsCard().fromJsonString(jsonString, options);
  }

  static equals(a: CreatorStatsCard | PlainMessage<CreatorStatsCard> | undefined, b: CreatorStatsCard | PlainMessage<CreatorStatsCard> | undefined): boolean {
    return proto3.util.equals(CreatorStatsCard, a, b);
  }
}

/**
 * Links ComputedGenerikStats with Network
 *
 * @generated from message librarian.proto.NetworkComputedGenerikStats
 */
export class NetworkComputedGenerikStats extends Message<NetworkComputedGenerikStats> {
  /**
   * @generated from field: common.proto.Network network = 1;
   */
  network = Network.NETWORK_UNKNOWN;

  /**
   * @generated from field: common.proto.ComputedGenerikStats stats = 2;
   */
  stats?: ComputedGenerikStats;

  /**
   * @generated from field: int64 estimated_reach = 3;
   */
  estimatedReach = protoInt64.zero;

  /**
   * @generated from field: int64 average_reach = 6;
   */
  averageReach = protoInt64.zero;

  /**
   * @generated from field: double average_coverage = 4;
   */
  averageCoverage = 0;

  /**
   * @generated from field: repeated librarian.proto.NetworkComputedGenerikStats.PostTypeImpressions post_types_impressions = 5;
   */
  postTypesImpressions: NetworkComputedGenerikStats_PostTypeImpressions[] = [];

  constructor(data?: PartialMessage<NetworkComputedGenerikStats>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.NetworkComputedGenerikStats";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "network", kind: "enum", T: proto3.getEnumType(Network) },
    { no: 2, name: "stats", kind: "message", T: ComputedGenerikStats },
    { no: 3, name: "estimated_reach", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 6, name: "average_reach", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 4, name: "average_coverage", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 5, name: "post_types_impressions", kind: "message", T: NetworkComputedGenerikStats_PostTypeImpressions, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): NetworkComputedGenerikStats {
    return new NetworkComputedGenerikStats().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): NetworkComputedGenerikStats {
    return new NetworkComputedGenerikStats().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): NetworkComputedGenerikStats {
    return new NetworkComputedGenerikStats().fromJsonString(jsonString, options);
  }

  static equals(a: NetworkComputedGenerikStats | PlainMessage<NetworkComputedGenerikStats> | undefined, b: NetworkComputedGenerikStats | PlainMessage<NetworkComputedGenerikStats> | undefined): boolean {
    return proto3.util.equals(NetworkComputedGenerikStats, a, b);
  }
}

/**
 * Insta only
 *
 * @generated from message librarian.proto.NetworkComputedGenerikStats.PostTypeImpressions
 */
export class NetworkComputedGenerikStats_PostTypeImpressions extends Message<NetworkComputedGenerikStats_PostTypeImpressions> {
  /**
   * @generated from field: common.proto.PostType post_type = 1;
   */
  postType = PostType.POST_TYPE_UNDEFINED;

  /**
   * This is average impressions
   *
   * @generated from field: int64 impressions = 2;
   */
  impressions = protoInt64.zero;

  /**
   * Impressions median
   *
   * @generated from field: int64 impressions_median = 3;
   */
  impressionsMedian = protoInt64.zero;

  constructor(data?: PartialMessage<NetworkComputedGenerikStats_PostTypeImpressions>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.NetworkComputedGenerikStats.PostTypeImpressions";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "post_type", kind: "enum", T: proto3.getEnumType(PostType) },
    { no: 2, name: "impressions", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "impressions_median", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): NetworkComputedGenerikStats_PostTypeImpressions {
    return new NetworkComputedGenerikStats_PostTypeImpressions().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): NetworkComputedGenerikStats_PostTypeImpressions {
    return new NetworkComputedGenerikStats_PostTypeImpressions().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): NetworkComputedGenerikStats_PostTypeImpressions {
    return new NetworkComputedGenerikStats_PostTypeImpressions().fromJsonString(jsonString, options);
  }

  static equals(a: NetworkComputedGenerikStats_PostTypeImpressions | PlainMessage<NetworkComputedGenerikStats_PostTypeImpressions> | undefined, b: NetworkComputedGenerikStats_PostTypeImpressions | PlainMessage<NetworkComputedGenerikStats_PostTypeImpressions> | undefined): boolean {
    return proto3.util.equals(NetworkComputedGenerikStats_PostTypeImpressions, a, b);
  }
}

