import { Influencer, InfluencerCasting } from '@frontend2/core';
import {
  Int64List,
  Network,
  StringList,
} from '@frontend2/proto/common/proto/common_pb';
import {
  AffiliateFilter,
  CreatorPoolRequestV2,
  FlexibleCreatorSelector,
  InfluencersCastingFilter,
} from '@frontend2/proto/librarian/proto/common_pb';

export interface InfluencersSelector {
  readonly influencers: Influencer[];
  readonly count: number;

  readonly flexibleSelector: FlexibleCreatorSelector;
}

export interface PlainTextInfluencerUploadData {
  readonly usernames: string[];
  readonly network: Network;
  readonly tagsToAdd: bigint[];
}

export function createPlainTextInfluencerUploadData(
  partial?: Partial<PlainTextInfluencerUploadData>,
): PlainTextInfluencerUploadData {
  return {
    usernames: partial?.usernames ?? [],
    network: partial?.network ?? Network.NETWORK_UNKNOWN,
    tagsToAdd: partial?.tagsToAdd ?? [],
  };
}

export function createInfluencersSelection(
  influencers: Influencer[],
): InfluencersSelector {
  return {
    influencers,
    count: influencers.length,
    flexibleSelector: new FlexibleCreatorSelector({
      selector: {
        case: 'manualList',
        value: new StringList({ values: influencers.map((i) => i.id) }),
      },
    }),
  };
}

export function createInfluencersCastingSelection(
  influencers: InfluencerCasting[],
): InfluencersSelector {
  return {
    influencers,
    count: influencers.length,
    flexibleSelector: new FlexibleCreatorSelector({
      selector: {
        case: 'influencerCastingIds',
        value: new Int64List({
          values: influencers.map((i) => i.castingInfluencerId),
        }),
      },
    }),
  };
}

export function createDirectorySelection(
  influencers: Influencer[],
  count: number,
  request: CreatorPoolRequestV2,
): InfluencersSelector {
  return {
    influencers,
    count,
    flexibleSelector: new FlexibleCreatorSelector({
      selector: {
        case: 'poolRequestV2',
        value: request,
      },
    }),
  };
}

export function createCastingSelection(
  influencers: Influencer[],
  count: number,
  request: InfluencersCastingFilter,
): InfluencersSelector {
  return {
    influencers,
    count,
    flexibleSelector: new FlexibleCreatorSelector({
      selector: {
        case: 'castingFilter',
        value: request,
      },
    }),
  };
}

export function createAffiliationSelection(
  influencers: Influencer[],
  count: number,
  request: AffiliateFilter,
): InfluencersSelector {
  return {
    influencers,
    count,
    flexibleSelector: new FlexibleCreatorSelector({
      selector: {
        case: 'affiliationFilter',
        value: request,
      },
    }),
  };
}

export type AddInfluencersMethod =
  | 'search-creator'
  | 'plain-text'
  | 'directory'
  | 'file-upload';

export interface AddInfluencersSelectionState {
  readonly influencers: Influencer[];
  readonly plainTextUploadData: PlainTextInfluencerUploadData;
  readonly selectedAddMethod?: AddInfluencersMethod;
}

export function createAddInfluencersSelectionState(
  partial?: Partial<AddInfluencersSelectionState>,
): AddInfluencersSelectionState {
  return {
    influencers: partial?.influencers ?? [],
    plainTextUploadData:
      partial?.plainTextUploadData ?? createPlainTextInfluencerUploadData(),
    selectedAddMethod: partial?.selectedAddMethod,
  };
}

export function getAddInfluencersSelectionStateCount(
  val: AddInfluencersSelectionState,
): number {
  switch (val.selectedAddMethod) {
    case 'directory':
    case 'search-creator':
      return val.influencers.length;
    case 'plain-text':
      return val.plainTextUploadData.usernames.length;
    default:
      return 0;
  }
  //TODO: add for all methods
}

export function addInfluencerMethodToReadable(
  method: AddInfluencersMethod,
): string {
  switch (method) {
    case 'search-creator':
      return $localize`Add via search`;
    case 'plain-text':
      return $localize`Copy-paste your list`;
    case 'directory':
      return $localize`Add from Directory`;
    case 'file-upload':
      return $localize`Import from file`;
  }
}

export function addInfluencerMethodIcon(method: AddInfluencersMethod): string {
  switch (method) {
    case 'search-creator':
      return 'search';
    case 'plain-text':
      return 'copy';
    case 'directory':
      return 'directory_nav';
    case 'file-upload':
      return 'download';
  }
}
