<lefty-form
  [label]="label()"
  [required]="required()"
  [helpText]="helpText()"
  [externalHelpLink]="externalHelpLink()"
  [helpLink]="helpLink()"
  [hintText]="hintText()"
  [errorMessage]="errorMessage"
>
  <div class="input-container">
    <lefty-form-number-input
      class="number-input"
      [disabled]="disabled()"
      [value]="numberValue()"
      (valueChange)="setNumberValue($event)"
    >
    </lefty-form-number-input>

    <lefty-currency-selector
      class="currency-select"
      [disabled]="disabled()"
      [value]="currency()"
      (valueChange)="setCurrencyValue($event)"
      [compactButton]="true"
    >
    </lefty-currency-selector>
  </div>
</lefty-form>
