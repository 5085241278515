import { Network } from '@frontend2/proto/common/proto/common_pb';
import {
  CreatorCardSnippet,
  CreatorProfileCard,
  CreatorRegisteredDetails,
  CreatorTiniestCard,
  EstimatedPrices,
  GenerikInfoCard,
} from '@frontend2/proto/librarian/proto/creators_pb';
import {
  CreatorAutocompleteResponse_CreatorAutocompleteMatch,
  GetCreatorStatsResponse,
} from '@frontend2/proto/librarian/proto/users_pb';

import { InfluencerStatus } from '@frontend2/proto/common/proto/campaign_pb';
import { CreatorPoolResponse } from '@frontend2/proto/librarian/proto/pool_pb';
import { GetCreatorBasicResponse } from '@frontend2/proto/librarian/proto/users_pb';
import { GenerikInfoCards, Networks } from '../utils';
import { isEmptyArray, isNil } from '../utils/common.helpers';
import { arrayToMap } from '../utils/iterables.helpers';
import { Influencer } from './influencer.models';

export function createInfluencer(
  idOrSnippet: string | CreatorCardSnippet,
  influencer?: Partial<Influencer>,
): Influencer {
  let id = '';
  let baseSnippet = influencer?.baseSnippet ?? new CreatorCardSnippet();

  if (typeof idOrSnippet === 'string') {
    id = idOrSnippet;
  } else {
    baseSnippet = idOrSnippet;
    id = baseSnippet.userId;
  }

  const username = influencer?.username ?? baseSnippet.userName;

  let followers = BigInt(0);
  let networks: Network[] = [];

  if (influencer?.networkInfos) {
    followers = [...influencer.networkInfos.values()]
      .map((i) => i ?? new GenerikInfoCard())
      .map((i) => i.count?.followersCount ?? BigInt(0))
      .reduce((a, b) => a + b, BigInt(0));
  } else if (influencer?.followers) {
    followers = influencer.followers;
  }

  if (influencer?.networkInfos) {
    networks = [...influencer.networkInfos.keys()];
  } else if (influencer?.networks) {
    networks = influencer.networks;
  }

  networks = Networks.sort(networks);

  return {
    id,
    username,
    baseSnippet,
    followers,
    networks,
    mainNetwork: isEmptyArray(networks) ? Network.NETWORK_UNKNOWN : networks[0],
    networkInfos:
      influencer?.networkInfos ?? new Map<Network, GenerikInfoCard>(),
    registeredDetails:
      influencer?.registeredDetails ?? new CreatorRegisteredDetails(),
    campaignStatuses: influencer?.campaignStatuses ?? [],
    isNotIngested: influencer?.isNotIngested ?? false,
    estimatedPrice: influencer?.estimatedPrice ?? new EstimatedPrices(),
  };
}

export function createInfluencerFromStatsResponse(
  creatorStats: GetCreatorStatsResponse,
): Influencer {
  return createInfluencer(creatorStats.creator?.profile?.baseSnippet ?? '', {
    networkInfos: arrayToMap(
      creatorStats.creator?.profile?.networkInfo ?? [],
      (val) => val.network,
    ),
  });
}

export function createInfluencerFromProfileCard(
  profile: CreatorProfileCard,
  influencer?: Partial<Influencer>,
): Influencer {
  const infos = profile.networkInfo ?? [];
  const estimatedPrices = profile.estimated;

  return createInfluencer(profile.baseSnippet ?? new CreatorCardSnippet(), {
    ...influencer,
    networkInfos: arrayToMap(infos, (val) => val.network),
    estimatedPrice: estimatedPrices,
  });
}

export function createInfluencerFromBasicResponse(
  response: GetCreatorBasicResponse,
  influencer?: Partial<Influencer>,
): Influencer {
  return createInfluencerFromProfileCard(
    response.creator ?? new CreatorProfileCard(),
    influencer,
  );
}

let ghostInfluencerCache: Influencer | undefined;

export function createGhostInfluencer(): Influencer {
  return (ghostInfluencerCache ??= createInfluencer(''));
}

export function isGhostInfluencer(influencer: Influencer): boolean {
  return influencer.id === '';
}

export function getInfluencerNetworkInfo(
  influencer: Influencer,
  network: Network,
): GenerikInfoCard {
  return influencer.networkInfos.get(network) ?? new GenerikInfoCard();
}

export function getInfluencerNetworksLinks(
  influencer: Influencer,
  apiHost: string,
): Map<Network, string> {
  return GenerikInfoCards.getNetworksLinks(
    influencer.networks.map((net) => getInfluencerNetworkInfo(influencer, net)),
    influencer.id,
    apiHost,
    {
      isNotIngested: influencer.isNotIngested,
    },
  );
}

export function influencerHasNetwork(
  influencer: Influencer,
  network: Network,
): boolean {
  return isNil(influencer.networkInfos.get(network)) === false;
}

export function createInfluencerAutocompleteResponse(
  response: CreatorAutocompleteResponse_CreatorAutocompleteMatch,
  influencer?: Partial<Influencer>,
): Influencer {
  const creator = response.creator ?? new CreatorTiniestCard();
  const networkInfos = new Map<Network, GenerikInfoCard>();

  response.networkInfo.forEach((n) => {
    networkInfos.set(n.network, n);
  });

  return createInfluencer(creator.userId, {
    ...influencer,
    ...creator,
    baseSnippet: new CreatorCardSnippet({ ...creator }),
    networkInfos: networkInfos,
  });
}

export function createInfluencerFromCreatorPoolResponse(
  poolResp: CreatorPoolResponse,
  influencer?: Partial<Influencer>,
): Influencer {
  const networkInfos = new Map<Network, GenerikInfoCard>();

  poolResp.networkInfo.forEach((n) => {
    networkInfos.set(n.network, n);
  });

  return createInfluencer(poolResp.baseSnippet?.userId ?? '', {
    ...influencer,
    baseSnippet: poolResp.baseSnippet,
    networkInfos: networkInfos,
  });
}

export function isInvitationAccepted(influencer: Influencer): boolean {
  return (
    influencer.registeredDetails.status === InfluencerStatus.INFLUENCER_ACCEPTED
  );
}

export function isInvitationRejected(influencer: Influencer): boolean {
  return (
    influencer.registeredDetails.status === InfluencerStatus.INFLUENCER_REJECTED
  );
}

export function isInvitationSent(influencer: Influencer): boolean {
  return (
    influencer.registeredDetails.status === InfluencerStatus.INFLUENCER_INVITED
  );
}

export function isInvitationNotSent(influencer: Influencer): boolean {
  return (
    influencer.registeredDetails.status === InfluencerStatus.INFLUENCER_PENDING
  );
}

export function toReadableStatus(status: InfluencerStatus): string {
  switch (status) {
    case InfluencerStatus.INFLUENCER_INVITED:
      return $localize`Invited`;
    case InfluencerStatus.INFLUENCER_REJECTED:
      return $localize`Rejected`;
    case InfluencerStatus.INFLUENCER_ACCEPTED:
      return $localize`Accepted`;
    default:
      return $localize`Not invited`;
  }
}
