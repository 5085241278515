// This file defines common protocol buffers.

// @generated by protoc-gen-es v1.10.0 with parameter "target=ts"
// @generated from file common/proto/common.proto (package common.proto, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Any, DoubleValue, FloatValue, Int32Value, Int64Value, Message, proto3, protoInt64, Timestamp } from "@bufbuild/protobuf";

/**
 * @generated from enum common.proto.SourceType
 */
export enum SourceType {
  /**
   * @generated from enum value: SOURCE_UNKNOWN = 0;
   */
  SOURCE_UNKNOWN = 0,

  /**
   * @generated from enum value: SOURCE_LEFTY = 1;
   */
  SOURCE_LEFTY = 1,

  /**
   * @generated from enum value: SOURCE_AUXILIARY = 2;
   */
  SOURCE_AUXILIARY = 2,

  /**
   * @generated from enum value: SOURCE_DIRECTORY = 3;
   */
  SOURCE_DIRECTORY = 3,

  /**
   * @generated from enum value: SOURCE_DISCOVER = 4;
   */
  SOURCE_DISCOVER = 4,

  /**
   * @generated from enum value: SOURCE_MANUAL = 5;
   */
  SOURCE_MANUAL = 5,
}
// Retrieve enum metadata with: proto3.getEnumType(SourceType)
proto3.util.setEnumType(SourceType, "common.proto.SourceType", [
  { no: 0, name: "SOURCE_UNKNOWN" },
  { no: 1, name: "SOURCE_LEFTY" },
  { no: 2, name: "SOURCE_AUXILIARY" },
  { no: 3, name: "SOURCE_DIRECTORY" },
  { no: 4, name: "SOURCE_DISCOVER" },
  { no: 5, name: "SOURCE_MANUAL" },
]);

/**
 * Note: never change index/order or remove values, it's stored in DB using Java enum ORDINAL.
 *
 * @generated from enum common.proto.AuthorPermission
 */
export enum AuthorPermission {
  /**
   * @generated from enum value: PERMISSION_UNDEFINED = 0;
   */
  PERMISSION_UNDEFINED = 0,

  /**
   * @generated from enum value: AUTHORIZED = 1;
   */
  AUTHORIZED = 1,

  /**
   * @generated from enum value: UNAUTHORIZED = 2;
   */
  UNAUTHORIZED = 2,

  /**
   * @generated from enum value: EXPIRED = 3;
   */
  EXPIRED = 3,

  /**
   * particular case: the permission exists but no token is associated with the author
   *
   * @generated from enum value: NO_TOKEN_FOUND = 4;
   */
  NO_TOKEN_FOUND = 4,
}
// Retrieve enum metadata with: proto3.getEnumType(AuthorPermission)
proto3.util.setEnumType(AuthorPermission, "common.proto.AuthorPermission", [
  { no: 0, name: "PERMISSION_UNDEFINED" },
  { no: 1, name: "AUTHORIZED" },
  { no: 2, name: "UNAUTHORIZED" },
  { no: 3, name: "EXPIRED" },
  { no: 4, name: "NO_TOKEN_FOUND" },
]);

/**
 * Note: never change index/order or remove values, it's stored in DB using Java enum ORDINAL.
 *
 * @generated from enum common.proto.RefreshStatus
 */
export enum RefreshStatus {
  /**
   * @generated from enum value: UNKNOWN = 0;
   */
  UNKNOWN = 0,

  /**
   * @generated from enum value: SUCCESS = 1;
   */
  SUCCESS = 1,

  /**
   * @generated from enum value: FAILURE = 2;
   */
  FAILURE = 2,
}
// Retrieve enum metadata with: proto3.getEnumType(RefreshStatus)
proto3.util.setEnumType(RefreshStatus, "common.proto.RefreshStatus", [
  { no: 0, name: "UNKNOWN" },
  { no: 1, name: "SUCCESS" },
  { no: 2, name: "FAILURE" },
]);

/**
 * @generated from enum common.proto.Interval
 */
export enum Interval {
  /**
   * @generated from enum value: INTERVAL_UNKNOWN = 0;
   */
  UNKNOWN = 0,

  /**
   * @generated from enum value: INTERVAL_DAY = 1;
   */
  DAY = 1,

  /**
   * @generated from enum value: INTERVAL_WEEK = 2;
   */
  WEEK = 2,

  /**
   * @generated from enum value: INTERVAL_MONTH = 3;
   */
  MONTH = 3,
}
// Retrieve enum metadata with: proto3.getEnumType(Interval)
proto3.util.setEnumType(Interval, "common.proto.Interval", [
  { no: 0, name: "INTERVAL_UNKNOWN" },
  { no: 1, name: "INTERVAL_DAY" },
  { no: 2, name: "INTERVAL_WEEK" },
  { no: 3, name: "INTERVAL_MONTH" },
]);

/**
 * Note: never change index/order or remove values, it's stored in DB using Java enum ORDINAL.
 *
 * @generated from enum common.proto.Network
 */
export enum Network {
  /**
   * @generated from enum value: NETWORK_UNKNOWN = 0;
   */
  NETWORK_UNKNOWN = 0,

  /**
   * @generated from enum value: INSTA = 1;
   */
  INSTA = 1,

  /**
   * @generated from enum value: YOUTUBE = 2;
   */
  YOUTUBE = 2,

  /**
   * @generated from enum value: TWEET = 3;
   */
  TWEET = 3,

  /**
   * @generated from enum value: TIK_TOK = 4;
   */
  TIK_TOK = 4,

  /**
   * @generated from enum value: FACEBOOK = 5;
   */
  FACEBOOK = 5,

  /**
   * @generated from enum value: WEIBO = 6;
   */
  WEIBO = 6,

  /**
   * @generated from enum value: WECHAT = 7;
   */
  WECHAT = 7,

  /**
   * @generated from enum value: RED = 8;
   */
  RED = 8,

  /**
   * @generated from enum value: DOUYIN = 9;
   */
  DOUYIN = 9,

  /**
   * @generated from enum value: SNAPCHAT = 10;
   */
  SNAPCHAT = 10,
}
// Retrieve enum metadata with: proto3.getEnumType(Network)
proto3.util.setEnumType(Network, "common.proto.Network", [
  { no: 0, name: "NETWORK_UNKNOWN" },
  { no: 1, name: "INSTA" },
  { no: 2, name: "YOUTUBE" },
  { no: 3, name: "TWEET" },
  { no: 4, name: "TIK_TOK" },
  { no: 5, name: "FACEBOOK" },
  { no: 6, name: "WEIBO" },
  { no: 7, name: "WECHAT" },
  { no: 8, name: "RED" },
  { no: 9, name: "DOUYIN" },
  { no: 10, name: "SNAPCHAT" },
]);

/**
 * Note: never change index/order or remove values, it's stored in DB using Java enum ORDINAL.
 *
 * @generated from enum common.proto.PostType
 */
export enum PostType {
  /**
   * @generated from enum value: POST_TYPE_UNDEFINED = 0;
   */
  POST_TYPE_UNDEFINED = 0,

  /**
   * @generated from enum value: IG_IMAGE = 1;
   */
  IG_IMAGE = 1,

  /**
   * @generated from enum value: IG_CAROUSEL = 2;
   */
  IG_CAROUSEL = 2,

  /**
   * @generated from enum value: IG_VIDEO = 3;
   */
  IG_VIDEO = 3,

  /**
   * @generated from enum value: IG_STORY = 4;
   */
  IG_STORY = 4,

  /**
   * @generated from enum value: YT_VIDEO = 5;
   */
  YT_VIDEO = 5,

  /**
   * @generated from enum value: TW_TEXT = 6;
   */
  TW_TEXT = 6,

  /**
   * @generated from enum value: TW_IMAGE = 7;
   */
  TW_IMAGE = 7,

  /**
   * @generated from enum value: TW_CAROUSEL = 8;
   */
  TW_CAROUSEL = 8,

  /**
   * @generated from enum value: TW_ANIMATED_GIF = 9;
   */
  TW_ANIMATED_GIF = 9,

  /**
   * @generated from enum value: TW_VIDEO = 10;
   */
  TW_VIDEO = 10,

  /**
   * @generated from enum value: TK_VIDEO = 11;
   */
  TK_VIDEO = 11,

  /**
   * @generated from enum value: TK_CAROUSEL = 30;
   */
  TK_CAROUSEL = 30,

  /**
   * @generated from enum value: TK_STORY = 33;
   */
  TK_STORY = 33,

  /**
   * @generated from enum value: FB_TEXT = 12;
   */
  FB_TEXT = 12,

  /**
   * @generated from enum value: FB_IMAGE = 13;
   */
  FB_IMAGE = 13,

  /**
   * @generated from enum value: FB_VIDEO = 14;
   */
  FB_VIDEO = 14,

  /**
   * @generated from enum value: FB_CAROUSEL = 15;
   */
  FB_CAROUSEL = 15,

  /**
   * @generated from enum value: WB_TEXT = 16;
   */
  WB_TEXT = 16,

  /**
   * @generated from enum value: WB_IMAGE = 17;
   */
  WB_IMAGE = 17,

  /**
   * @generated from enum value: WB_VIDEO = 18;
   */
  WB_VIDEO = 18,

  /**
   * @generated from enum value: WB_CAROUSEL = 19;
   */
  WB_CAROUSEL = 19,

  /**
   * @generated from enum value: WB_STORY = 24;
   */
  WB_STORY = 24,

  /**
   * @generated from enum value: WC_TEXT = 20;
   */
  WC_TEXT = 20,

  /**
   * @generated from enum value: WC_IMAGE = 21;
   */
  WC_IMAGE = 21,

  /**
   * @generated from enum value: WC_VIDEO = 22;
   */
  WC_VIDEO = 22,

  /**
   * @generated from enum value: WC_CAROUSEL = 23;
   */
  WC_CAROUSEL = 23,

  /**
   * @generated from enum value: RD_TEXT = 25;
   */
  RD_TEXT = 25,

  /**
   * @generated from enum value: RD_IMAGE = 26;
   */
  RD_IMAGE = 26,

  /**
   * @generated from enum value: RD_VIDEO = 27;
   */
  RD_VIDEO = 27,

  /**
   * @generated from enum value: RD_CAROUSEL = 28;
   */
  RD_CAROUSEL = 28,

  /**
   * @generated from enum value: DY_VIDEO = 29;
   */
  DY_VIDEO = 29,

  /**
   * @generated from enum value: SC_STORY = 31;
   */
  SC_STORY = 31,

  /**
   * @generated from enum value: SC_SPOTLIGHT = 32;
   */
  SC_SPOTLIGHT = 32,
}
// Retrieve enum metadata with: proto3.getEnumType(PostType)
proto3.util.setEnumType(PostType, "common.proto.PostType", [
  { no: 0, name: "POST_TYPE_UNDEFINED" },
  { no: 1, name: "IG_IMAGE" },
  { no: 2, name: "IG_CAROUSEL" },
  { no: 3, name: "IG_VIDEO" },
  { no: 4, name: "IG_STORY" },
  { no: 5, name: "YT_VIDEO" },
  { no: 6, name: "TW_TEXT" },
  { no: 7, name: "TW_IMAGE" },
  { no: 8, name: "TW_CAROUSEL" },
  { no: 9, name: "TW_ANIMATED_GIF" },
  { no: 10, name: "TW_VIDEO" },
  { no: 11, name: "TK_VIDEO" },
  { no: 30, name: "TK_CAROUSEL" },
  { no: 33, name: "TK_STORY" },
  { no: 12, name: "FB_TEXT" },
  { no: 13, name: "FB_IMAGE" },
  { no: 14, name: "FB_VIDEO" },
  { no: 15, name: "FB_CAROUSEL" },
  { no: 16, name: "WB_TEXT" },
  { no: 17, name: "WB_IMAGE" },
  { no: 18, name: "WB_VIDEO" },
  { no: 19, name: "WB_CAROUSEL" },
  { no: 24, name: "WB_STORY" },
  { no: 20, name: "WC_TEXT" },
  { no: 21, name: "WC_IMAGE" },
  { no: 22, name: "WC_VIDEO" },
  { no: 23, name: "WC_CAROUSEL" },
  { no: 25, name: "RD_TEXT" },
  { no: 26, name: "RD_IMAGE" },
  { no: 27, name: "RD_VIDEO" },
  { no: 28, name: "RD_CAROUSEL" },
  { no: 29, name: "DY_VIDEO" },
  { no: 31, name: "SC_STORY" },
  { no: 32, name: "SC_SPOTLIGHT" },
]);

/**
 * CONVENTION_WARNING there is code relying on the fact that they all start with exactly "INTERACTION_"
 *
 * @generated from enum common.proto.PostInteractionKind
 */
export enum PostInteractionKind {
  /**
   * @generated from enum value: POST_INTERACTION_KIND_UNDEFINED = 0;
   */
  POST_INTERACTION_KIND_UNDEFINED = 0,

  /**
   * @generated from enum value: INTERACTION_LIKE = 1;
   */
  INTERACTION_LIKE = 1,

  /**
   * @generated from enum value: INTERACTION_COMMENT = 2;
   */
  INTERACTION_COMMENT = 2,

  /**
   * @generated from enum value: INTERACTION_VIEW = 3;
   */
  INTERACTION_VIEW = 3,

  /**
   * @generated from enum value: INTERACTION_RESHARE = 4;
   */
  INTERACTION_RESHARE = 4,

  /**
   * @generated from enum value: INTERACTION_CLICK = 5;
   */
  INTERACTION_CLICK = 5,

  /**
   * @generated from enum value: INTERACTION_WOW = 6;
   */
  INTERACTION_WOW = 6,

  /**
   * @generated from enum value: INTERACTION_DISLIKE = 7;
   */
  INTERACTION_DISLIKE = 7,

  /**
   * @generated from enum value: INTERACTION_FAVORITE = 8;
   */
  INTERACTION_FAVORITE = 8,
}
// Retrieve enum metadata with: proto3.getEnumType(PostInteractionKind)
proto3.util.setEnumType(PostInteractionKind, "common.proto.PostInteractionKind", [
  { no: 0, name: "POST_INTERACTION_KIND_UNDEFINED" },
  { no: 1, name: "INTERACTION_LIKE" },
  { no: 2, name: "INTERACTION_COMMENT" },
  { no: 3, name: "INTERACTION_VIEW" },
  { no: 4, name: "INTERACTION_RESHARE" },
  { no: 5, name: "INTERACTION_CLICK" },
  { no: 6, name: "INTERACTION_WOW" },
  { no: 7, name: "INTERACTION_DISLIKE" },
  { no: 8, name: "INTERACTION_FAVORITE" },
]);

/**
 * @generated from enum common.proto.KeywordType
 */
export enum KeywordType {
  /**
   * @generated from enum value: KW_UNDEFINED = 0;
   */
  KW_UNDEFINED = 0,

  /**
   * @generated from enum value: KW_MENTION = 1;
   */
  KW_MENTION = 1,

  /**
   * @generated from enum value: KW_HASHTAG = 2;
   */
  KW_HASHTAG = 2,

  /**
   * @generated from enum value: KW_KEYWORD = 3;
   */
  KW_KEYWORD = 3,

  /**
   * @generated from enum value: KW_LOCATION = 4;
   */
  KW_LOCATION = 4,
}
// Retrieve enum metadata with: proto3.getEnumType(KeywordType)
proto3.util.setEnumType(KeywordType, "common.proto.KeywordType", [
  { no: 0, name: "KW_UNDEFINED" },
  { no: 1, name: "KW_MENTION" },
  { no: 2, name: "KW_HASHTAG" },
  { no: 3, name: "KW_KEYWORD" },
  { no: 4, name: "KW_LOCATION" },
]);

/**
 * Lists the possible mime type for the content of a media across all networks.
 * Sometimes the listing is 1:1 for a post type to a content type, but sometimes that is more subtle.
 *
 * For instance a insta media type IMAGE will always be content type IMAGE. But a story could be a
 * image story or a video story. And a video will list as assets both the videos at various resolutions,
 * and also some content that is image (eg screenshots).
 *
 * @generated from enum common.proto.ContentMimeType
 */
export enum ContentMimeType {
  /**
   * @generated from enum value: CONTENT_MIME_TYPE_UNDEFINED = 0;
   */
  UNDEFINED = 0,

  /**
   * @generated from enum value: CONTENT_MIME_TYPE_IMAGE = 1;
   */
  IMAGE = 1,

  /**
   * @generated from enum value: CONTENT_MIME_TYPE_VIDEO = 2;
   */
  VIDEO = 2,

  /**
   * @generated from enum value: CONTENT_MIME_TYPE_GIF = 3;
   */
  GIF = 3,
}
// Retrieve enum metadata with: proto3.getEnumType(ContentMimeType)
proto3.util.setEnumType(ContentMimeType, "common.proto.ContentMimeType", [
  { no: 0, name: "CONTENT_MIME_TYPE_UNDEFINED" },
  { no: 1, name: "CONTENT_MIME_TYPE_IMAGE" },
  { no: 2, name: "CONTENT_MIME_TYPE_VIDEO" },
  { no: 3, name: "CONTENT_MIME_TYPE_GIF" },
]);

/**
 * List of the possible Mime types of assets user can upload to the platform
 *
 * @generated from enum common.proto.AssetMimeType
 */
export enum AssetMimeType {
  /**
   * @generated from enum value: ASSET_UNKNOWN = 0;
   */
  ASSET_UNKNOWN = 0,

  /**
   * @generated from enum value: JPEG = 1;
   */
  JPEG = 1,

  /**
   * @generated from enum value: PNG = 2;
   */
  PNG = 2,
}
// Retrieve enum metadata with: proto3.getEnumType(AssetMimeType)
proto3.util.setEnumType(AssetMimeType, "common.proto.AssetMimeType", [
  { no: 0, name: "ASSET_UNKNOWN" },
  { no: 1, name: "JPEG" },
  { no: 2, name: "PNG" },
]);

/**
 * @generated from enum common.proto.MediaValueCalculatorMethod
 */
export enum MediaValueCalculatorMethod {
  /**
   * @generated from enum value: DEFAULT = 0;
   */
  DEFAULT = 0,

  /**
   * This method is used by Chanel and is inspired by Linkfluence formulas taking inot account engagements and number of followers.
   *
   * @generated from enum value: LINKFLUENCE = 1;
   */
  LINKFLUENCE = 1,

  /**
   * This method is used by Puig. It is the default method with an added time dimension.
   *
   * @generated from enum value: TIME = 2;
   */
  TIME = 2,

  /**
   * Specific for Clarins, it's their custom formula, so it's named after them, for them
   *
   * @generated from enum value: CLARINS = 3;
   */
  CLARINS = 3,
}
// Retrieve enum metadata with: proto3.getEnumType(MediaValueCalculatorMethod)
proto3.util.setEnumType(MediaValueCalculatorMethod, "common.proto.MediaValueCalculatorMethod", [
  { no: 0, name: "DEFAULT" },
  { no: 1, name: "LINKFLUENCE" },
  { no: 2, name: "TIME" },
  { no: 3, name: "CLARINS" },
]);

/**
 * @generated from enum common.proto.EmailLabel
 */
export enum EmailLabel {
  /**
   * @generated from enum value: UNDEFINED = 0;
   */
  UNDEFINED = 0,

  /**
   * @generated from enum value: DEMO_AUTO_REPLY = 1;
   */
  DEMO_AUTO_REPLY = 1,

  /**
   * @generated from enum value: NOTIFICATION = 2;
   */
  NOTIFICATION = 2,

  /**
   * @generated from enum value: CASE_STUDY = 3;
   */
  CASE_STUDY = 3,

  /**
   * @generated from enum value: IG_INVITATION = 4;
   */
  IG_INVITATION = 4,

  /**
   * @generated from enum value: SEEDING = 5;
   */
  SEEDING = 5,

  /**
   * @generated from enum value: COLLABORATOR_CASTING_INVITATION = 6;
   */
  COLLABORATOR_CASTING_INVITATION = 6,
}
// Retrieve enum metadata with: proto3.getEnumType(EmailLabel)
proto3.util.setEnumType(EmailLabel, "common.proto.EmailLabel", [
  { no: 0, name: "UNDEFINED" },
  { no: 1, name: "DEMO_AUTO_REPLY" },
  { no: 2, name: "NOTIFICATION" },
  { no: 3, name: "CASE_STUDY" },
  { no: 4, name: "IG_INVITATION" },
  { no: 5, name: "SEEDING" },
  { no: 6, name: "COLLABORATOR_CASTING_INVITATION" },
]);

/**
 * @generated from enum common.proto.CurrencyEnum
 */
export enum CurrencyEnum {
  /**
   * @generated from enum value: NA = 0;
   */
  NA = 0,

  /**
   * @generated from enum value: USD = 1;
   */
  USD = 1,

  /**
   * @generated from enum value: EUR = 2;
   */
  EUR = 2,

  /**
   * @generated from enum value: CAD = 3;
   */
  CAD = 3,

  /**
   * @generated from enum value: CHF = 4;
   */
  CHF = 4,

  /**
   * @generated from enum value: GBP = 5;
   */
  GBP = 5,

  /**
   * @generated from enum value: CNY = 6;
   */
  CNY = 6,

  /**
   * @generated from enum value: JPY = 7;
   */
  JPY = 7,

  /**
   * @generated from enum value: AUD = 8;
   */
  AUD = 8,

  /**
   * @generated from enum value: VND = 9;
   */
  VND = 9,

  /**
   * @generated from enum value: CZK = 10;
   */
  CZK = 10,

  /**
   * @generated from enum value: DKK = 11;
   */
  DKK = 11,

  /**
   * @generated from enum value: NOK = 12;
   */
  NOK = 12,

  /**
   * @generated from enum value: PLN = 13;
   */
  PLN = 13,

  /**
   * @generated from enum value: RUB = 14;
   */
  RUB = 14,

  /**
   * @generated from enum value: UAH = 15;
   */
  UAH = 15,

  /**
   * @generated from enum value: HKD = 16;
   */
  HKD = 16,

  /**
   * @generated from enum value: INR = 17;
   */
  INR = 17,

  /**
   * @generated from enum value: IDR = 18;
   */
  IDR = 18,

  /**
   * @generated from enum value: ILS = 19;
   */
  ILS = 19,

  /**
   * @generated from enum value: QAR = 20;
   */
  QAR = 20,

  /**
   * @generated from enum value: KRW = 21;
   */
  KRW = 21,

  /**
   * @generated from enum value: SGD = 22;
   */
  SGD = 22,

  /**
   * @generated from enum value: TWD = 23;
   */
  TWD = 23,

  /**
   * @generated from enum value: THB = 24;
   */
  THB = 24,

  /**
   * @generated from enum value: TRY = 25;
   */
  TRY = 25,

  /**
   * @generated from enum value: PHP = 26;
   */
  PHP = 26,

  /**
   * @generated from enum value: SEK = 27;
   */
  SEK = 27,

  /**
   * @generated from enum value: RSD = 28;
   */
  RSD = 28,

  /**
   * @generated from enum value: CLP = 29;
   */
  CLP = 29,

  /**
   * @generated from enum value: HUF = 32;
   */
  HUF = 32,

  /**
   * @generated from enum value: BRL = 33;
   */
  BRL = 33,

  /**
   * @generated from enum value: RON = 34;
   */
  RON = 34,

  /**
   * @generated from enum value: SAR = 35;
   */
  SAR = 35,

  /**
   * @generated from enum value: MXN = 36;
   */
  MXN = 36,

  /**
   * @generated from enum value: AED = 31;
   */
  AED = 31,

  /**
   * @generated from enum value: BND = 37;
   */
  BND = 37,

  /**
   * @generated from enum value: ZAR = 30;
   */
  ZAR = 30,

  /**
   * @generated from enum value: NZD = 38;
   */
  NZD = 38,

  /**
   * We can not create enum for ALL for now because the enum is used in enum Gender
   *  ALL = 39;
   *
   * @generated from enum value: ISK = 40;
   */
  ISK = 40,

  /**
   * @generated from enum value: BGN = 41;
   */
  BGN = 41,

  /**
   * @generated from enum value: MOP = 42;
   */
  MOP = 42,

  /**
   * @generated from enum value: ARS = 43;
   */
  ARS = 43,

  /**
   * @generated from enum value: LBP = 44;
   */
  LBP = 44,

  /**
   * @generated from enum value: XOF = 45;
   */
  XOF = 45,

  /**
   * @generated from enum value: MYR = 46;
   */
  MYR = 46,

  /**
   * @generated from enum value: MUR = 47;
   */
  MUR = 47,

  /**
   * @generated from enum value: KZT = 48;
   */
  KZT = 48,

  /**
   * @generated from enum value: EGP = 49;
   */
  EGP = 49,

  /**
   * @generated from enum value: COP = 50;
   */
  COP = 50,

  /**
   * @generated from enum value: LAK = 51;
   */
  LAK = 51,

  /**
   * @generated from enum value: KYD = 52;
   */
  KYD = 52,

  /**
   * @generated from enum value: AMD = 53;
   */
  AMD = 53,

  /**
   * @generated from enum value: PEN = 54;
   */
  PEN = 54,

  /**
   * @generated from enum value: NGN = 55;
   */
  NGN = 55,

  /**
   * @generated from enum value: GEL = 56;
   */
  GEL = 56,

  /**
   * @generated from enum value: KGS = 57;
   */
  KGS = 57,

  /**
   * @generated from enum value: MNT = 58;
   */
  MNT = 58,

  /**
   * @generated from enum value: AZN = 59;
   */
  AZN = 59,

  /**
   * @generated from enum value: BBD = 60;
   */
  BBD = 60,

  /**
   * @generated from enum value: UZS = 61;
   */
  UZS = 61,

  /**
   * @generated from enum value: LKR = 62;
   */
  LKR = 62,

  /**
   * @generated from enum value: AOA = 63;
   */
  AOA = 63,

  /**
   * @generated from enum value: MAD = 64;
   */
  MAD = 64,

  /**
   * @generated from enum value: MDL = 65;
   */
  MDL = 65,

  /**
   * @generated from enum value: BWP = 66;
   */
  BWP = 66,

  /**
   * @generated from enum value: AWG = 67;
   */
  AWG = 67,

  /**
   * @generated from enum value: DOP = 68;
   */
  DOP = 68,

  /**
   * @generated from enum value: MVR = 69;
   */
  MVR = 69,

  /**
   * @generated from enum value: UGX = 70;
   */
  UGX = 70,

  /**
   * @generated from enum value: GTQ = 72;
   */
  GTQ = 72,

  /**
   * @generated from enum value: KHR = 73;
   */
  KHR = 73,

  /**
   * @generated from enum value: AFN = 74;
   */
  AFN = 74,

  /**
   * @generated from enum value: DZD = 75;
   */
  DZD = 75,

  /**
   * @generated from enum value: XCD = 76;
   */
  XCD = 76,

  /**
   * @generated from enum value: BSD = 77;
   */
  BSD = 77,

  /**
   * @generated from enum value: BHD = 78;
   */
  BHD = 78,

  /**
   * @generated from enum value: BDT = 79;
   */
  BDT = 79,

  /**
   * @generated from enum value: BYN = 80;
   */
  BYN = 80,

  /**
   * @generated from enum value: BZD = 81;
   */
  BZD = 81,

  /**
   * @generated from enum value: BMD = 82;
   */
  BMD = 82,

  /**
   * @generated from enum value: BTN = 83;
   */
  BTN = 83,

  /**
   * @generated from enum value: BOB = 84;
   */
  BOB = 84,

  /**
   * @generated from enum value: BAM = 85;
   */
  BAM = 85,

  /**
   * @generated from enum value: BIF = 86;
   */
  BIF = 86,

  /**
   * @generated from enum value: CVE = 87;
   */
  CVE = 87,

  /**
   * @generated from enum value: XAF = 88;
   */
  XAF = 88,

  /**
   * @generated from enum value: CLF = 89;
   */
  CLF = 89,

  /**
   * @generated from enum value: KMF = 90;
   */
  KMF = 90,

  /**
   * @generated from enum value: CDF = 91;
   */
  CDF = 91,

  /**
   * @generated from enum value: CRC = 92;
   */
  CRC = 92,

  /**
   * @generated from enum value: HRK = 93;
   */
  HRK = 93,

  /**
   * @generated from enum value: CUP = 94;
   */
  CUP = 94,

  /**
   * @generated from enum value: CUC = 95;
   */
  CUC = 95,

  /**
   * @generated from enum value: ANG = 96;
   */
  ANG = 96,

  /**
   * @generated from enum value: DJF = 97;
   */
  DJF = 97,

  /**
   * @generated from enum value: SVC = 98;
   */
  SVC = 98,

  /**
   * @generated from enum value: ERN = 99;
   */
  ERN = 99,

  /**
   * @generated from enum value: SZL = 100;
   */
  SZL = 100,

  /**
   * @generated from enum value: ETB = 101;
   */
  ETB = 101,

  /**
   * @generated from enum value: FKP = 102;
   */
  FKP = 102,

  /**
   * @generated from enum value: FJD = 103;
   */
  FJD = 103,

  /**
   * @generated from enum value: XPF = 104;
   */
  XPF = 104,

  /**
   * @generated from enum value: GMD = 105;
   */
  GMD = 105,

  /**
   * @generated from enum value: GHS = 106;
   */
  GHS = 106,

  /**
   * @generated from enum value: GIP = 107;
   */
  GIP = 107,

  /**
   * @generated from enum value: GNF = 108;
   */
  GNF = 108,

  /**
   * @generated from enum value: GYD = 109;
   */
  GYD = 109,

  /**
   * @generated from enum value: HTG = 110;
   */
  HTG = 110,

  /**
   * @generated from enum value: HNL = 111;
   */
  HNL = 111,

  /**
   * @generated from enum value: XDR = 112;
   */
  XDR = 112,

  /**
   * @generated from enum value: IRR = 113;
   */
  IRR = 113,

  /**
   * @generated from enum value: IQD = 114;
   */
  IQD = 114,

  /**
   * @generated from enum value: JMD = 115;
   */
  JMD = 115,

  /**
   * @generated from enum value: JOD = 116;
   */
  JOD = 116,

  /**
   * @generated from enum value: KES = 117;
   */
  KES = 117,

  /**
   * @generated from enum value: KPW = 118;
   */
  KPW = 118,

  /**
   * @generated from enum value: KWD = 119;
   */
  KWD = 119,

  /**
   * @generated from enum value: LSL = 120;
   */
  LSL = 120,

  /**
   * @generated from enum value: LRD = 121;
   */
  LRD = 121,

  /**
   * @generated from enum value: LYD = 122;
   */
  LYD = 122,

  /**
   * @generated from enum value: MKD = 123;
   */
  MKD = 123,

  /**
   * @generated from enum value: MGA = 124;
   */
  MGA = 124,

  /**
   * @generated from enum value: MWK = 125;
   */
  MWK = 125,

  /**
   * @generated from enum value: MRU = 126;
   */
  MRU = 126,

  /**
   * @generated from enum value: MZN = 127;
   */
  MZN = 127,

  /**
   * @generated from enum value: MMK = 128;
   */
  MMK = 128,

  /**
   * @generated from enum value: NAD = 129;
   */
  NAD = 129,

  /**
   * @generated from enum value: NPR = 130;
   */
  NPR = 130,

  /**
   * @generated from enum value: NIO = 131;
   */
  NIO = 131,

  /**
   * @generated from enum value: OMR = 132;
   */
  OMR = 132,

  /**
   * @generated from enum value: PKR = 133;
   */
  PKR = 133,

  /**
   * @generated from enum value: PAB = 134;
   */
  PAB = 134,

  /**
   * @generated from enum value: PGK = 135;
   */
  PGK = 135,

  /**
   * @generated from enum value: PYG = 136;
   */
  PYG = 136,

  /**
   * @generated from enum value: RWF = 137;
   */
  RWF = 137,

  /**
   * @generated from enum value: SHP = 138;
   */
  SHP = 138,

  /**
   * @generated from enum value: WST = 139;
   */
  WST = 139,

  /**
   * @generated from enum value: SCR = 140;
   */
  SCR = 140,

  /**
   * @generated from enum value: SLL = 141;
   */
  SLL = 141,

  /**
   * @generated from enum value: SBD = 142;
   */
  SBD = 142,

  /**
   * @generated from enum value: SOS = 143;
   */
  SOS = 143,

  /**
   * @generated from enum value: SDG = 144;
   */
  SDG = 144,

  /**
   * @generated from enum value: SRD = 145;
   */
  SRD = 145,

  /**
   * @generated from enum value: SYP = 146;
   */
  SYP = 146,

  /**
   * @generated from enum value: TJS = 147;
   */
  TJS = 147,

  /**
   * @generated from enum value: TZS = 148;
   */
  TZS = 148,

  /**
   * @generated from enum value: TOP = 149;
   */
  TOP = 149,

  /**
   * @generated from enum value: TTD = 150;
   */
  TTD = 150,

  /**
   * @generated from enum value: TND = 151;
   */
  TND = 151,

  /**
   * @generated from enum value: TMT = 152;
   */
  TMT = 152,

  /**
   * @generated from enum value: UYU = 153;
   */
  UYU = 153,

  /**
   * @generated from enum value: VUV = 154;
   */
  VUV = 154,

  /**
   * @generated from enum value: VES = 155;
   */
  VES = 155,

  /**
   * @generated from enum value: YER = 156;
   */
  YER = 156,

  /**
   * @generated from enum value: ZMW = 157;
   */
  ZMW = 157,

  /**
   * @generated from enum value: ZWL = 158;
   */
  ZWL = 158,

  /**
   * @generated from enum value: XAU = 159;
   */
  XAU = 159,

  /**
   * @generated from enum value: XAG = 160;
   */
  XAG = 160,

  /**
   * @generated from enum value: BYR = 161;
   */
  BYR = 161,

  /**
   * @generated from enum value: LVL = 162;
   */
  LVL = 162,

  /**
   * @generated from enum value: LTL = 163;
   */
  LTL = 163,

  /**
   * @generated from enum value: STD = 164;
   */
  STD = 164,

  /**
   * @generated from enum value: VEF = 165;
   */
  VEF = 165,

  /**
   * @generated from enum value: ZMK = 166;
   */
  ZMK = 166,
}
// Retrieve enum metadata with: proto3.getEnumType(CurrencyEnum)
proto3.util.setEnumType(CurrencyEnum, "common.proto.CurrencyEnum", [
  { no: 0, name: "NA" },
  { no: 1, name: "USD" },
  { no: 2, name: "EUR" },
  { no: 3, name: "CAD" },
  { no: 4, name: "CHF" },
  { no: 5, name: "GBP" },
  { no: 6, name: "CNY" },
  { no: 7, name: "JPY" },
  { no: 8, name: "AUD" },
  { no: 9, name: "VND" },
  { no: 10, name: "CZK" },
  { no: 11, name: "DKK" },
  { no: 12, name: "NOK" },
  { no: 13, name: "PLN" },
  { no: 14, name: "RUB" },
  { no: 15, name: "UAH" },
  { no: 16, name: "HKD" },
  { no: 17, name: "INR" },
  { no: 18, name: "IDR" },
  { no: 19, name: "ILS" },
  { no: 20, name: "QAR" },
  { no: 21, name: "KRW" },
  { no: 22, name: "SGD" },
  { no: 23, name: "TWD" },
  { no: 24, name: "THB" },
  { no: 25, name: "TRY" },
  { no: 26, name: "PHP" },
  { no: 27, name: "SEK" },
  { no: 28, name: "RSD" },
  { no: 29, name: "CLP" },
  { no: 32, name: "HUF" },
  { no: 33, name: "BRL" },
  { no: 34, name: "RON" },
  { no: 35, name: "SAR" },
  { no: 36, name: "MXN" },
  { no: 31, name: "AED" },
  { no: 37, name: "BND" },
  { no: 30, name: "ZAR" },
  { no: 38, name: "NZD" },
  { no: 40, name: "ISK" },
  { no: 41, name: "BGN" },
  { no: 42, name: "MOP" },
  { no: 43, name: "ARS" },
  { no: 44, name: "LBP" },
  { no: 45, name: "XOF" },
  { no: 46, name: "MYR" },
  { no: 47, name: "MUR" },
  { no: 48, name: "KZT" },
  { no: 49, name: "EGP" },
  { no: 50, name: "COP" },
  { no: 51, name: "LAK" },
  { no: 52, name: "KYD" },
  { no: 53, name: "AMD" },
  { no: 54, name: "PEN" },
  { no: 55, name: "NGN" },
  { no: 56, name: "GEL" },
  { no: 57, name: "KGS" },
  { no: 58, name: "MNT" },
  { no: 59, name: "AZN" },
  { no: 60, name: "BBD" },
  { no: 61, name: "UZS" },
  { no: 62, name: "LKR" },
  { no: 63, name: "AOA" },
  { no: 64, name: "MAD" },
  { no: 65, name: "MDL" },
  { no: 66, name: "BWP" },
  { no: 67, name: "AWG" },
  { no: 68, name: "DOP" },
  { no: 69, name: "MVR" },
  { no: 70, name: "UGX" },
  { no: 72, name: "GTQ" },
  { no: 73, name: "KHR" },
  { no: 74, name: "AFN" },
  { no: 75, name: "DZD" },
  { no: 76, name: "XCD" },
  { no: 77, name: "BSD" },
  { no: 78, name: "BHD" },
  { no: 79, name: "BDT" },
  { no: 80, name: "BYN" },
  { no: 81, name: "BZD" },
  { no: 82, name: "BMD" },
  { no: 83, name: "BTN" },
  { no: 84, name: "BOB" },
  { no: 85, name: "BAM" },
  { no: 86, name: "BIF" },
  { no: 87, name: "CVE" },
  { no: 88, name: "XAF" },
  { no: 89, name: "CLF" },
  { no: 90, name: "KMF" },
  { no: 91, name: "CDF" },
  { no: 92, name: "CRC" },
  { no: 93, name: "HRK" },
  { no: 94, name: "CUP" },
  { no: 95, name: "CUC" },
  { no: 96, name: "ANG" },
  { no: 97, name: "DJF" },
  { no: 98, name: "SVC" },
  { no: 99, name: "ERN" },
  { no: 100, name: "SZL" },
  { no: 101, name: "ETB" },
  { no: 102, name: "FKP" },
  { no: 103, name: "FJD" },
  { no: 104, name: "XPF" },
  { no: 105, name: "GMD" },
  { no: 106, name: "GHS" },
  { no: 107, name: "GIP" },
  { no: 108, name: "GNF" },
  { no: 109, name: "GYD" },
  { no: 110, name: "HTG" },
  { no: 111, name: "HNL" },
  { no: 112, name: "XDR" },
  { no: 113, name: "IRR" },
  { no: 114, name: "IQD" },
  { no: 115, name: "JMD" },
  { no: 116, name: "JOD" },
  { no: 117, name: "KES" },
  { no: 118, name: "KPW" },
  { no: 119, name: "KWD" },
  { no: 120, name: "LSL" },
  { no: 121, name: "LRD" },
  { no: 122, name: "LYD" },
  { no: 123, name: "MKD" },
  { no: 124, name: "MGA" },
  { no: 125, name: "MWK" },
  { no: 126, name: "MRU" },
  { no: 127, name: "MZN" },
  { no: 128, name: "MMK" },
  { no: 129, name: "NAD" },
  { no: 130, name: "NPR" },
  { no: 131, name: "NIO" },
  { no: 132, name: "OMR" },
  { no: 133, name: "PKR" },
  { no: 134, name: "PAB" },
  { no: 135, name: "PGK" },
  { no: 136, name: "PYG" },
  { no: 137, name: "RWF" },
  { no: 138, name: "SHP" },
  { no: 139, name: "WST" },
  { no: 140, name: "SCR" },
  { no: 141, name: "SLL" },
  { no: 142, name: "SBD" },
  { no: 143, name: "SOS" },
  { no: 144, name: "SDG" },
  { no: 145, name: "SRD" },
  { no: 146, name: "SYP" },
  { no: 147, name: "TJS" },
  { no: 148, name: "TZS" },
  { no: 149, name: "TOP" },
  { no: 150, name: "TTD" },
  { no: 151, name: "TND" },
  { no: 152, name: "TMT" },
  { no: 153, name: "UYU" },
  { no: 154, name: "VUV" },
  { no: 155, name: "VES" },
  { no: 156, name: "YER" },
  { no: 157, name: "ZMW" },
  { no: 158, name: "ZWL" },
  { no: 159, name: "XAU" },
  { no: 160, name: "XAG" },
  { no: 161, name: "BYR" },
  { no: 162, name: "LVL" },
  { no: 163, name: "LTL" },
  { no: 164, name: "STD" },
  { no: 165, name: "VEF" },
  { no: 166, name: "ZMK" },
]);

/**
 * when applying a sort to a request, should it be sort ASC or DESC.
 * DESC is default because it makes sense in most cases (eg: sort by followers, you want the greatest firsts).
 *
 * @generated from enum common.proto.SortDirection
 */
export enum SortDirection {
  /**
   * @generated from enum value: DESC = 0;
   */
  DESC = 0,

  /**
   * @generated from enum value: ASC = 1;
   */
  ASC = 1,
}
// Retrieve enum metadata with: proto3.getEnumType(SortDirection)
proto3.util.setEnumType(SortDirection, "common.proto.SortDirection", [
  { no: 0, name: "DESC" },
  { no: 1, name: "ASC" },
]);

/**
 * @generated from enum common.proto.UTMValueType
 */
export enum UTMValueType {
  /**
   * @generated from enum value: TYPE_UNKOWN = 0;
   */
  TYPE_UNKOWN = 0,

  /**
   * @generated from enum value: TYPE_INFLUENCER_NAME = 1;
   */
  TYPE_INFLUENCER_NAME = 1,

  /**
   * @generated from enum value: TYPE_CAMPAIGN_NAME = 2;
   */
  TYPE_CAMPAIGN_NAME = 2,
}
// Retrieve enum metadata with: proto3.getEnumType(UTMValueType)
proto3.util.setEnumType(UTMValueType, "common.proto.UTMValueType", [
  { no: 0, name: "TYPE_UNKOWN" },
  { no: 1, name: "TYPE_INFLUENCER_NAME" },
  { no: 2, name: "TYPE_CAMPAIGN_NAME" },
]);

/**
 * Note: never change index/order or remove values, it's stored in DB using Java enum ORDINAL.
 *
 * @generated from enum common.proto.UTMType
 */
export enum UTMType {
  /**
   * Don't use
   *
   * @generated from enum value: UTM_UNKNOWN = 0;
   */
  UTM_UNKNOWN = 0,

  /**
   * @generated from enum value: UTM_CAMPAIGN = 1;
   */
  UTM_CAMPAIGN = 1,

  /**
   * @generated from enum value: UTM_MEDIUM = 2;
   */
  UTM_MEDIUM = 2,

  /**
   * @generated from enum value: UTM_SOURCE = 3;
   */
  UTM_SOURCE = 3,

  /**
   * @generated from enum value: UTM_CONTENT = 4;
   */
  UTM_CONTENT = 4,

  /**
   * @generated from enum value: UTM_TERM = 5;
   */
  UTM_TERM = 5,

  /**
   * @generated from enum value: UTM_CUSTOM = 6;
   */
  UTM_CUSTOM = 6,
}
// Retrieve enum metadata with: proto3.getEnumType(UTMType)
proto3.util.setEnumType(UTMType, "common.proto.UTMType", [
  { no: 0, name: "UTM_UNKNOWN" },
  { no: 1, name: "UTM_CAMPAIGN" },
  { no: 2, name: "UTM_MEDIUM" },
  { no: 3, name: "UTM_SOURCE" },
  { no: 4, name: "UTM_CONTENT" },
  { no: 5, name: "UTM_TERM" },
  { no: 6, name: "UTM_CUSTOM" },
]);

/**
 * @generated from enum common.proto.ApplicationSetting
 */
export enum ApplicationSetting {
  /**
   * Don't use
   *
   * @generated from enum value: SETTING_UNKNOWN = 0;
   */
  SETTING_UNKNOWN = 0,

  /**
   * @generated from enum value: APP_BANNER = 1;
   */
  APP_BANNER = 1,

  /**
   * @generated from enum value: GIOVANNI_COUNT = 2;
   */
  GIOVANNI_COUNT = 2,

  /**
   * @generated from enum value: MIN_STORIES_POLLING_SLEEP_MS = 3;
   */
  MIN_STORIES_POLLING_SLEEP_MS = 3,

  /**
   * @generated from enum value: MIN_STORIES_POLLING_RAND_PART_MS = 4;
   */
  MIN_STORIES_POLLING_RAND_PART_MS = 4,

  /**
   * Used by TiktokPostDownloadJob.
   * How far to travel back for posts to "repair", this is typically updated to the last successful run of the job.
   * Not really a setting per se, but it's very convenient to have it here.
   * For readability, it is persisted as an ISO8601StringWithOrWithoutMillis.
   *
   * @generated from enum value: TIKTOK_DOWNLOAD_JOB_LIMIT_DATE = 5;
   */
  TIKTOK_DOWNLOAD_JOB_LIMIT_DATE = 5,

  /**
   * If it is unset, the retriever will just use its regular values.
   * If set, it will complete with the maximal threads up to this value. The value must parse as a valid int.
   *
   * This is the total value of threads to reach, not additional threads.
   *
   * Warning: it doesn't make sense to set this above the supervisor option -max_stories_threads which will be honored no
   * matter what...
   *
   * @generated from enum value: FORCED_TOTAL_STORIES_THREADS_PER_MACHINE = 6;
   */
  FORCED_TOTAL_STORIES_THREADS_PER_MACHINE = 6,

  /**
   * @generated from enum value: AUXILIARY_POLLING_SLEEP_MS = 8;
   */
  AUXILIARY_POLLING_SLEEP_MS = 8,

  /**
   * @generated from enum value: AUXILIARY2_POLLING_SLEEP_MS = 7;
   */
  AUXILIARY2_POLLING_SLEEP_MS = 7,

  /**
   * @generated from enum value: SNAPCHAT_ACCESS_TOKEN = 9;
   */
  SNAPCHAT_ACCESS_TOKEN = 9,

  /**
   * @generated from enum value: SNAPCHAT_ACCESS_TOKEN_EXPIRY_DATE = 10;
   */
  SNAPCHAT_ACCESS_TOKEN_EXPIRY_DATE = 10,
}
// Retrieve enum metadata with: proto3.getEnumType(ApplicationSetting)
proto3.util.setEnumType(ApplicationSetting, "common.proto.ApplicationSetting", [
  { no: 0, name: "SETTING_UNKNOWN" },
  { no: 1, name: "APP_BANNER" },
  { no: 2, name: "GIOVANNI_COUNT" },
  { no: 3, name: "MIN_STORIES_POLLING_SLEEP_MS" },
  { no: 4, name: "MIN_STORIES_POLLING_RAND_PART_MS" },
  { no: 5, name: "TIKTOK_DOWNLOAD_JOB_LIMIT_DATE" },
  { no: 6, name: "FORCED_TOTAL_STORIES_THREADS_PER_MACHINE" },
  { no: 8, name: "AUXILIARY_POLLING_SLEEP_MS" },
  { no: 7, name: "AUXILIARY2_POLLING_SLEEP_MS" },
  { no: 9, name: "SNAPCHAT_ACCESS_TOKEN" },
  { no: 10, name: "SNAPCHAT_ACCESS_TOKEN_EXPIRY_DATE" },
]);

/**
 * @generated from enum common.proto.Gender
 */
export enum Gender {
  /**
   * @generated from enum value: GENDER_UNDEFINED = 0;
   */
  GENDER_UNDEFINED = 0,

  /**
   * @generated from enum value: MALE = 1;
   */
  MALE = 1,

  /**
   * @generated from enum value: FEMALE = 2;
   */
  FEMALE = 2,

  /**
   * @generated from enum value: ALL = 3;
   */
  ALL = 3,

  /**
   * @generated from enum value: OTHER = 4;
   */
  OTHER = 4,
}
// Retrieve enum metadata with: proto3.getEnumType(Gender)
proto3.util.setEnumType(Gender, "common.proto.Gender", [
  { no: 0, name: "GENDER_UNDEFINED" },
  { no: 1, name: "MALE" },
  { no: 2, name: "FEMALE" },
  { no: 3, name: "ALL" },
  { no: 4, name: "OTHER" },
]);

/**
 * The kind of an influencer is not the same as an influencer category: for instance you could be a MAGAZINE kind,
 * talking about category BEAUTY.
 *
 * @generated from enum common.proto.InfluencerKind
 */
export enum InfluencerKind {
  /**
   * @generated from enum value: INFLUENCER_KIND_UNKNOWN = 0;
   */
  UNKNOWN = 0,

  /**
   * Influencer/Blogger that created their fame online.
   *
   * @generated from enum value: INFLUENCER_KIND_DIGITAL_NATIVE = 1;
   */
  DIGITAL_NATIVE = 1,

  /**
   * Cristiano, Keira Knightley, etc
   *
   * @generated from enum value: INFLUENCER_KIND_VIP = 2;
   */
  VIP = 2,

  /**
   * Vogue, etc
   *
   * @generated from enum value: INFLUENCER_KIND_MEDIA = 3;
   */
  MEDIA = 3,

  /**
   * Guerlain, Dior, etc...
   *
   * @generated from enum value: INFLUENCER_KIND_BUSINESS = 4;
   */
  BUSINESS = 4,

  /**
   * dump for all that do not fall into a good category.
   *
   * @generated from enum value: INFLUENCER_KIND_MISCELLANEOUS = 5;
   */
  MISCELLANEOUS = 5,
}
// Retrieve enum metadata with: proto3.getEnumType(InfluencerKind)
proto3.util.setEnumType(InfluencerKind, "common.proto.InfluencerKind", [
  { no: 0, name: "INFLUENCER_KIND_UNKNOWN" },
  { no: 1, name: "INFLUENCER_KIND_DIGITAL_NATIVE" },
  { no: 2, name: "INFLUENCER_KIND_VIP" },
  { no: 3, name: "INFLUENCER_KIND_MEDIA" },
  { no: 4, name: "INFLUENCER_KIND_BUSINESS" },
  { no: 5, name: "INFLUENCER_KIND_MISCELLANEOUS" },
]);

/**
 * @generated from enum common.proto.SensitiveTopic
 */
export enum SensitiveTopic {
  /**
   * @generated from enum value: SENSITIVE_TOPIC_UNKNOWN = 0;
   */
  UNKNOWN = 0,

  /**
   * @generated from enum value: SENSITIVE_TOPIC_DRUGS = 1;
   */
  DRUGS = 1,

  /**
   * @generated from enum value: SENSITIVE_TOPIC_POLITICS = 2;
   */
  POLITICS = 2,

  /**
   * @generated from enum value: SENSITIVE_TOPIC_RELIGION = 3;
   */
  RELIGION = 3,

  /**
   * @generated from enum value: SENSITIVE_TOPIC_SWEARWORDS = 4;
   */
  SWEARWORDS = 4,

  /**
   * @generated from enum value: SENSITIVE_TOPIC_WORLD_CONFLICTS = 5;
   */
  WORLD_CONFLICTS = 5,

  /**
   * @generated from enum value: SENSITIVE_TOPIC_ALCOHOL = 6;
   */
  ALCOHOL = 6,

  /**
   * @generated from enum value: SENSITIVE_TOPIC_MEDICINE = 7;
   */
  MEDICINE = 7,
}
// Retrieve enum metadata with: proto3.getEnumType(SensitiveTopic)
proto3.util.setEnumType(SensitiveTopic, "common.proto.SensitiveTopic", [
  { no: 0, name: "SENSITIVE_TOPIC_UNKNOWN" },
  { no: 1, name: "SENSITIVE_TOPIC_DRUGS" },
  { no: 2, name: "SENSITIVE_TOPIC_POLITICS" },
  { no: 3, name: "SENSITIVE_TOPIC_RELIGION" },
  { no: 4, name: "SENSITIVE_TOPIC_SWEARWORDS" },
  { no: 5, name: "SENSITIVE_TOPIC_WORLD_CONFLICTS" },
  { no: 6, name: "SENSITIVE_TOPIC_ALCOHOL" },
  { no: 7, name: "SENSITIVE_TOPIC_MEDICINE" },
]);

/**
 * @generated from enum common.proto.ChanelPBLabel
 */
export enum ChanelPBLabel {
  /**
   * @generated from enum value: CHANEL_PB_ACTOR_ACTRESS = 0;
   */
  CHANEL_PB_ACTOR_ACTRESS = 0,

  /**
   * @generated from enum value: CHANEL_PB_ELITE_MODEL = 1;
   */
  CHANEL_PB_ELITE_MODEL = 1,

  /**
   * @generated from enum value: CHANEL_PB_MUSICIAN = 2;
   */
  CHANEL_PB_MUSICIAN = 2,

  /**
   * @generated from enum value: CHANEL_PB_ATHLETE = 3;
   */
  CHANEL_PB_ATHLETE = 3,

  /**
   * @generated from enum value: CHANEL_PB_REALITY_STAR = 4;
   */
  CHANEL_PB_REALITY_STAR = 4,

  /**
   * @generated from enum value: CHANEL_PB_INFLUENCER = 5;
   */
  CHANEL_PB_INFLUENCER = 5,

  /**
   * @generated from enum value: CHANEL_PB_MAGAZINE = 6;
   */
  CHANEL_PB_MAGAZINE = 6,

  /**
   * @generated from enum value: CHANEL_PB_NEWSPAPER = 7;
   */
  CHANEL_PB_NEWSPAPER = 7,

  /**
   * @generated from enum value: CHANEL_PB_JOURNALIST_EDITOR = 8;
   */
  CHANEL_PB_JOURNALIST_EDITOR = 8,

  /**
   * @generated from enum value: CHANEL_PB_EDITORIAL_PHOTOGRAPHER = 9;
   */
  CHANEL_PB_EDITORIAL_PHOTOGRAPHER = 9,

  /**
   * @generated from enum value: CHANEL_PB_RETAILER = 10;
   */
  CHANEL_PB_RETAILER = 10,

  /**
   * @generated from enum value: CHANEL_PB_RESELLER = 11;
   */
  CHANEL_PB_RESELLER = 11,

  /**
   * @generated from enum value: CHANEL_PB_DESIGNER = 12;
   */
  CHANEL_PB_DESIGNER = 12,

  /**
   * @generated from enum value: CHANEL_PB_STYLIST = 13;
   */
  CHANEL_PB_STYLIST = 13,

  /**
   * @generated from enum value: CHANEL_PB_BUSINESSMAN = 14;
   */
  CHANEL_PB_BUSINESSMAN = 14,

  /**
   * @generated from enum value: CHANEL_PB_MAKEUP_ARTIST = 15;
   */
  CHANEL_PB_MAKEUP_ARTIST = 15,

  /**
   * @generated from enum value: CHANEL_PB_NAIL_ARTIST_MANICURIST = 16;
   */
  CHANEL_PB_NAIL_ARTIST_MANICURIST = 16,

  /**
   * @generated from enum value: CHANEL_PB_PERFUME_EXPERT = 17;
   */
  CHANEL_PB_PERFUME_EXPERT = 17,

  /**
   * @generated from enum value: CHANEL_PB_DERMATOLOGIST = 18;
   */
  CHANEL_PB_DERMATOLOGIST = 18,

  /**
   * @generated from enum value: CHANEL_PB_SCIENTIFIC_EXPERT = 19;
   */
  CHANEL_PB_SCIENTIFIC_EXPERT = 19,

  /**
   * @generated from enum value: CHANEL_PB_SKINCARE_EXPERT_WELLNESS_EXPERT = 20;
   */
  CHANEL_PB_SKINCARE_EXPERT_WELLNESS_EXPERT = 20,

  /**
   * @generated from enum value: CHANEL_PB_HAIR_STYLIST = 21;
   */
  CHANEL_PB_HAIR_STYLIST = 21,

  /**
   * @generated from enum value: CHANEL_PB_CULTURAL_PARTNER = 22;
   */
  CHANEL_PB_CULTURAL_PARTNER = 22,

  /**
   * @generated from enum value: CHANEL_PB_ARTIST = 23;
   */
  CHANEL_PB_ARTIST = 23,

  /**
   * @generated from enum value: CHANEL_PB_HOSPITALITY = 24;
   */
  CHANEL_PB_HOSPITALITY = 24,

  /**
   * @generated from enum value: CHANEL_PB_OTHER = 25;
   */
  CHANEL_PB_OTHER = 25,
}
// Retrieve enum metadata with: proto3.getEnumType(ChanelPBLabel)
proto3.util.setEnumType(ChanelPBLabel, "common.proto.ChanelPBLabel", [
  { no: 0, name: "CHANEL_PB_ACTOR_ACTRESS" },
  { no: 1, name: "CHANEL_PB_ELITE_MODEL" },
  { no: 2, name: "CHANEL_PB_MUSICIAN" },
  { no: 3, name: "CHANEL_PB_ATHLETE" },
  { no: 4, name: "CHANEL_PB_REALITY_STAR" },
  { no: 5, name: "CHANEL_PB_INFLUENCER" },
  { no: 6, name: "CHANEL_PB_MAGAZINE" },
  { no: 7, name: "CHANEL_PB_NEWSPAPER" },
  { no: 8, name: "CHANEL_PB_JOURNALIST_EDITOR" },
  { no: 9, name: "CHANEL_PB_EDITORIAL_PHOTOGRAPHER" },
  { no: 10, name: "CHANEL_PB_RETAILER" },
  { no: 11, name: "CHANEL_PB_RESELLER" },
  { no: 12, name: "CHANEL_PB_DESIGNER" },
  { no: 13, name: "CHANEL_PB_STYLIST" },
  { no: 14, name: "CHANEL_PB_BUSINESSMAN" },
  { no: 15, name: "CHANEL_PB_MAKEUP_ARTIST" },
  { no: 16, name: "CHANEL_PB_NAIL_ARTIST_MANICURIST" },
  { no: 17, name: "CHANEL_PB_PERFUME_EXPERT" },
  { no: 18, name: "CHANEL_PB_DERMATOLOGIST" },
  { no: 19, name: "CHANEL_PB_SCIENTIFIC_EXPERT" },
  { no: 20, name: "CHANEL_PB_SKINCARE_EXPERT_WELLNESS_EXPERT" },
  { no: 21, name: "CHANEL_PB_HAIR_STYLIST" },
  { no: 22, name: "CHANEL_PB_CULTURAL_PARTNER" },
  { no: 23, name: "CHANEL_PB_ARTIST" },
  { no: 24, name: "CHANEL_PB_HOSPITALITY" },
  { no: 25, name: "CHANEL_PB_OTHER" },
]);

/**
 * Note: never change index/order or remove values, it's stored in DB using Java enum ORDINAL.
 *
 * @generated from enum common.proto.DemoRequestSource
 */
export enum DemoRequestSource {
  /**
   * @generated from enum value: DEMO_SOURCE_UNDEFINED = 0;
   */
  DEMO_SOURCE_UNDEFINED = 0,

  /**
   * @generated from enum value: DEMO_SOURCE_SOCIAL_MEDIA = 1;
   */
  DEMO_SOURCE_SOCIAL_MEDIA = 1,

  /**
   * @generated from enum value: DEMO_SOURCE_FRIEND_REFERRAL = 2;
   */
  DEMO_SOURCE_FRIEND_REFERRAL = 2,

  /**
   * @generated from enum value: DEMO_SOURCE_FORMER_LEFTY_USER = 3;
   */
  DEMO_SOURCE_FORMER_LEFTY_USER = 3,

  /**
   * @generated from enum value: DEMO_SOURCE_LINKEDIN = 4;
   */
  DEMO_SOURCE_LINKEDIN = 4,

  /**
   * @generated from enum value: DEMO_SOURCE_OTHER = 5;
   */
  DEMO_SOURCE_OTHER = 5,

  /**
   * @generated from enum value: DEMO_SOURCE_INSTAGRAM = 6;
   */
  DEMO_SOURCE_INSTAGRAM = 6,

  /**
   * @generated from enum value: DEMO_SOURCE_EMAIL = 7;
   */
  DEMO_SOURCE_EMAIL = 7,
}
// Retrieve enum metadata with: proto3.getEnumType(DemoRequestSource)
proto3.util.setEnumType(DemoRequestSource, "common.proto.DemoRequestSource", [
  { no: 0, name: "DEMO_SOURCE_UNDEFINED" },
  { no: 1, name: "DEMO_SOURCE_SOCIAL_MEDIA" },
  { no: 2, name: "DEMO_SOURCE_FRIEND_REFERRAL" },
  { no: 3, name: "DEMO_SOURCE_FORMER_LEFTY_USER" },
  { no: 4, name: "DEMO_SOURCE_LINKEDIN" },
  { no: 5, name: "DEMO_SOURCE_OTHER" },
  { no: 6, name: "DEMO_SOURCE_INSTAGRAM" },
  { no: 7, name: "DEMO_SOURCE_EMAIL" },
]);

/**
 * Note: never change index/order or remove values, it's stored in DB using Java enum ORDINAL.
 *
 * @generated from enum common.proto.DemoRequestUtmSource
 */
export enum DemoRequestUtmSource {
  /**
   * @generated from enum value: DEMO_UTM_SOURCE_UNDEFINED = 0;
   */
  DEMO_UTM_SOURCE_UNDEFINED = 0,

  /**
   * @generated from enum value: DEMO_UTM_SOURCE_GOOGLE_ADS = 1;
   */
  DEMO_UTM_SOURCE_GOOGLE_ADS = 1,

  /**
   * @generated from enum value: DEMO_UTM_SOURCE_LINKEDIN_ADS = 2;
   */
  DEMO_UTM_SOURCE_LINKEDIN_ADS = 2,

  /**
   * @generated from enum value: DEMO_UTM_SOURCE_INSTAGRAM_ADS = 3;
   */
  DEMO_UTM_SOURCE_INSTAGRAM_ADS = 3,

  /**
   * @generated from enum value: DEMO_UTM_SOURCE_NEWSLETTER = 4;
   */
  DEMO_UTM_SOURCE_NEWSLETTER = 4,
}
// Retrieve enum metadata with: proto3.getEnumType(DemoRequestUtmSource)
proto3.util.setEnumType(DemoRequestUtmSource, "common.proto.DemoRequestUtmSource", [
  { no: 0, name: "DEMO_UTM_SOURCE_UNDEFINED" },
  { no: 1, name: "DEMO_UTM_SOURCE_GOOGLE_ADS" },
  { no: 2, name: "DEMO_UTM_SOURCE_LINKEDIN_ADS" },
  { no: 3, name: "DEMO_UTM_SOURCE_INSTAGRAM_ADS" },
  { no: 4, name: "DEMO_UTM_SOURCE_NEWSLETTER" },
]);

/**
 * Note: never change index/order or remove values, it's stored in DB using Java enum ORDINAL.
 *
 * @generated from enum common.proto.LogicOperator
 */
export enum LogicOperator {
  /**
   * @generated from enum value: UNKNOWN_OPERATOR = 0;
   */
  UNKNOWN_OPERATOR = 0,

  /**
   * @generated from enum value: AND = 1;
   */
  AND = 1,

  /**
   * @generated from enum value: OR = 2;
   */
  OR = 2,
}
// Retrieve enum metadata with: proto3.getEnumType(LogicOperator)
proto3.util.setEnumType(LogicOperator, "common.proto.LogicOperator", [
  { no: 0, name: "UNKNOWN_OPERATOR" },
  { no: 1, name: "AND" },
  { no: 2, name: "OR" },
]);

/**
 * @generated from message common.proto.GenerikKeyword
 */
export class GenerikKeyword extends Message<GenerikKeyword> {
  /**
   * @generated from field: common.proto.KeywordType keyword_type = 1;
   */
  keywordType = KeywordType.KW_UNDEFINED;

  /**
   * @generated from field: string value = 2;
   */
  value = "";

  /**
   * This is used for type INSTA_LOCATION only. In which case 'value' above is the location id and this field is the
   * user-friendly name.
   *
   * @generated from field: string display_name = 3;
   */
  displayName = "";

  constructor(data?: PartialMessage<GenerikKeyword>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.GenerikKeyword";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "keyword_type", kind: "enum", T: proto3.getEnumType(KeywordType) },
    { no: 2, name: "value", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "display_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GenerikKeyword {
    return new GenerikKeyword().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GenerikKeyword {
    return new GenerikKeyword().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GenerikKeyword {
    return new GenerikKeyword().fromJsonString(jsonString, options);
  }

  static equals(a: GenerikKeyword | PlainMessage<GenerikKeyword> | undefined, b: GenerikKeyword | PlainMessage<GenerikKeyword> | undefined): boolean {
    return proto3.util.equals(GenerikKeyword, a, b);
  }
}

/**
 * @generated from message common.proto.NetworkList
 */
export class NetworkList extends Message<NetworkList> {
  /**
   * @generated from field: repeated common.proto.Network networks = 1;
   */
  networks: Network[] = [];

  constructor(data?: PartialMessage<NetworkList>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.NetworkList";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "networks", kind: "enum", T: proto3.getEnumType(Network), repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): NetworkList {
    return new NetworkList().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): NetworkList {
    return new NetworkList().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): NetworkList {
    return new NetworkList().fromJsonString(jsonString, options);
  }

  static equals(a: NetworkList | PlainMessage<NetworkList> | undefined, b: NetworkList | PlainMessage<NetworkList> | undefined): boolean {
    return proto3.util.equals(NetworkList, a, b);
  }
}

/**
 * This is the proto representation of a MediaId. It shall not be manipulated
 * manually other than MediaId's toProto() and fromProto().
 *
 * @generated from message common.proto.MediaIdPb
 */
export class MediaIdPb extends Message<MediaIdPb> {
  /**
   * @generated from field: common.proto.Network network = 1;
   */
  network = Network.NETWORK_UNKNOWN;

  /**
   * @generated from field: string raw_post_id = 2;
   */
  rawPostId = "";

  /**
   * @generated from field: string raw_user_id = 3;
   */
  rawUserId = "";

  constructor(data?: PartialMessage<MediaIdPb>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.MediaIdPb";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "network", kind: "enum", T: proto3.getEnumType(Network) },
    { no: 2, name: "raw_post_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "raw_user_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): MediaIdPb {
    return new MediaIdPb().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): MediaIdPb {
    return new MediaIdPb().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): MediaIdPb {
    return new MediaIdPb().fromJsonString(jsonString, options);
  }

  static equals(a: MediaIdPb | PlainMessage<MediaIdPb> | undefined, b: MediaIdPb | PlainMessage<MediaIdPb> | undefined): boolean {
    return proto3.util.equals(MediaIdPb, a, b);
  }
}

/**
 * @generated from message common.proto.MediaIds
 */
export class MediaIds extends Message<MediaIds> {
  /**
   * @generated from field: repeated common.proto.MediaIdPb ids = 1;
   */
  ids: MediaIdPb[] = [];

  constructor(data?: PartialMessage<MediaIds>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.MediaIds";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "ids", kind: "message", T: MediaIdPb, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): MediaIds {
    return new MediaIds().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): MediaIds {
    return new MediaIds().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): MediaIds {
    return new MediaIds().fromJsonString(jsonString, options);
  }

  static equals(a: MediaIds | PlainMessage<MediaIds> | undefined, b: MediaIds | PlainMessage<MediaIds> | undefined): boolean {
    return proto3.util.equals(MediaIds, a, b);
  }
}

/**
 * @generated from message common.proto.MediaPerformance
 */
export class MediaPerformance extends Message<MediaPerformance> {
  /**
   * @generated from field: int64 impressions = 1;
   */
  impressions = protoInt64.zero;

  /**
   * @generated from field: int64 reach = 2;
   */
  reach = protoInt64.zero;

  /**
   * @generated from field: int64 emv = 3;
   */
  emv = protoInt64.zero;

  /**
   * @generated from field: float engagement_rate = 6;
   */
  engagementRate = 0;

  /**
   * interactions
   *
   * @generated from field: int64 engagements = 5;
   */
  engagements = protoInt64.zero;

  /**
   * whether it's coming from real data or not, like insights
   *
   * @generated from field: bool real_data = 100;
   */
  realData = false;

  constructor(data?: PartialMessage<MediaPerformance>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.MediaPerformance";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "impressions", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "reach", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "emv", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 6, name: "engagement_rate", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 5, name: "engagements", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 100, name: "real_data", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): MediaPerformance {
    return new MediaPerformance().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): MediaPerformance {
    return new MediaPerformance().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): MediaPerformance {
    return new MediaPerformance().fromJsonString(jsonString, options);
  }

  static equals(a: MediaPerformance | PlainMessage<MediaPerformance> | undefined, b: MediaPerformance | PlainMessage<MediaPerformance> | undefined): boolean {
    return proto3.util.equals(MediaPerformance, a, b);
  }
}

/**
 * Hostname and process name (process name usually comes from supervisor, which runs all our services).
 * This pair uniquely identifies a process in the lefty cluster.
 *
 * @generated from message common.proto.HostProcess
 */
export class HostProcess extends Message<HostProcess> {
  /**
   * @generated from field: string host_name = 1;
   */
  hostName = "";

  /**
   * @generated from field: string process_name = 2;
   */
  processName = "";

  constructor(data?: PartialMessage<HostProcess>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.HostProcess";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "host_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "process_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): HostProcess {
    return new HostProcess().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): HostProcess {
    return new HostProcess().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): HostProcess {
    return new HostProcess().fromJsonString(jsonString, options);
  }

  static equals(a: HostProcess | PlainMessage<HostProcess> | undefined, b: HostProcess | PlainMessage<HostProcess> | undefined): boolean {
    return proto3.util.equals(HostProcess, a, b);
  }
}

/**
 * @generated from message common.proto.HostProcesses
 */
export class HostProcesses extends Message<HostProcesses> {
  /**
   * @generated from field: repeated common.proto.HostProcess host_processes = 1;
   */
  hostProcesses: HostProcess[] = [];

  constructor(data?: PartialMessage<HostProcesses>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.HostProcesses";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "host_processes", kind: "message", T: HostProcess, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): HostProcesses {
    return new HostProcesses().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): HostProcesses {
    return new HostProcesses().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): HostProcesses {
    return new HostProcesses().fromJsonString(jsonString, options);
  }

  static equals(a: HostProcesses | PlainMessage<HostProcesses> | undefined, b: HostProcesses | PlainMessage<HostProcesses> | undefined): boolean {
    return proto3.util.equals(HostProcesses, a, b);
  }
}

/**
 * @generated from message common.proto.ApplicationApiKey
 */
export class ApplicationApiKey extends Message<ApplicationApiKey> {
  /**
   * @generated from field: string client_id = 2;
   */
  clientId = "";

  /**
   * @generated from field: string client_secret = 3;
   */
  clientSecret = "";

  constructor(data?: PartialMessage<ApplicationApiKey>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.ApplicationApiKey";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 2, name: "client_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "client_secret", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ApplicationApiKey {
    return new ApplicationApiKey().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ApplicationApiKey {
    return new ApplicationApiKey().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ApplicationApiKey {
    return new ApplicationApiKey().fromJsonString(jsonString, options);
  }

  static equals(a: ApplicationApiKey | PlainMessage<ApplicationApiKey> | undefined, b: ApplicationApiKey | PlainMessage<ApplicationApiKey> | undefined): boolean {
    return proto3.util.equals(ApplicationApiKey, a, b);
  }
}

/**
 * Information about when a binary was built.
 *
 * @generated from message common.proto.BinaryBuildInfo
 */
export class BinaryBuildInfo extends Message<BinaryBuildInfo> {
  /**
   * git commit at which the binary was built.
   *
   * @generated from field: string git_commit = 1;
   */
  gitCommit = "";

  /**
   * time at which the computing binary was built.
   *
   * @generated from field: google.protobuf.Timestamp build_time = 2;
   */
  buildTime?: Timestamp;

  constructor(data?: PartialMessage<BinaryBuildInfo>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.BinaryBuildInfo";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "git_commit", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "build_time", kind: "message", T: Timestamp },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): BinaryBuildInfo {
    return new BinaryBuildInfo().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): BinaryBuildInfo {
    return new BinaryBuildInfo().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): BinaryBuildInfo {
    return new BinaryBuildInfo().fromJsonString(jsonString, options);
  }

  static equals(a: BinaryBuildInfo | PlainMessage<BinaryBuildInfo> | undefined, b: BinaryBuildInfo | PlainMessage<BinaryBuildInfo> | undefined): boolean {
    return proto3.util.equals(BinaryBuildInfo, a, b);
  }
}

/**
 * Origin of a computation (for logging and debugging)
 *
 * @generated from message common.proto.ComputationOrigin
 */
export class ComputationOrigin extends Message<ComputationOrigin> {
  /**
   * hostname of the machine that did the computation
   *
   * @generated from field: string host_name = 1;
   */
  hostName = "";

  /**
   * processname that did the computation
   *
   * @generated from field: string process_name = 2;
   */
  processName = "";

  /**
   * information about the binary used for the computation.
   *
   * @generated from field: common.proto.BinaryBuildInfo build_info = 3;
   */
  buildInfo?: BinaryBuildInfo;

  constructor(data?: PartialMessage<ComputationOrigin>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.ComputationOrigin";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "host_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "process_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "build_info", kind: "message", T: BinaryBuildInfo },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ComputationOrigin {
    return new ComputationOrigin().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ComputationOrigin {
    return new ComputationOrigin().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ComputationOrigin {
    return new ComputationOrigin().fromJsonString(jsonString, options);
  }

  static equals(a: ComputationOrigin | PlainMessage<ComputationOrigin> | undefined, b: ComputationOrigin | PlainMessage<ComputationOrigin> | undefined): boolean {
    return proto3.util.equals(ComputationOrigin, a, b);
  }
}

/**
 * Proto representing a file that can be for instance downloaded,
 * emailed, etc...
 *
 * @generated from message common.proto.FileProto
 */
export class FileProto extends Message<FileProto> {
  /**
   * File name, for instance for emailing attachments or file download the server
   * can specify what will be the filename on the downloader's device (unless they change it).
   *
   * It should have appropriate extension.
   *
   * @generated from field: string name = 1;
   */
  name = "";

  /**
   * @generated from field: bytes content = 2;
   */
  content = new Uint8Array(0);

  /**
   * @generated from field: string content_type = 3;
   */
  contentType = "";

  constructor(data?: PartialMessage<FileProto>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.FileProto";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "content", kind: "scalar", T: 12 /* ScalarType.BYTES */ },
    { no: 3, name: "content_type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FileProto {
    return new FileProto().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FileProto {
    return new FileProto().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FileProto {
    return new FileProto().fromJsonString(jsonString, options);
  }

  static equals(a: FileProto | PlainMessage<FileProto> | undefined, b: FileProto | PlainMessage<FileProto> | undefined): boolean {
    return proto3.util.equals(FileProto, a, b);
  }
}

/**
 * Message for sending mail.
 *
 * @generated from message common.proto.Email
 */
export class Email extends Message<Email> {
  /**
   * @generated from field: common.proto.Recipient from = 1;
   */
  from?: Recipient;

  /**
   * @generated from field: common.proto.Recipient reply_to = 2;
   */
  replyTo?: Recipient;

  /**
   * @generated from field: repeated common.proto.Recipient to = 3;
   */
  to: Recipient[] = [];

  /**
   * @generated from field: repeated common.proto.Recipient cc = 4;
   */
  cc: Recipient[] = [];

  /**
   * @generated from field: repeated common.proto.Recipient bcc = 5;
   */
  bcc: Recipient[] = [];

  /**
   * @generated from field: string subject = 6;
   */
  subject = "";

  /**
   * @generated from field: string content = 7;
   */
  content = "";

  /**
   * @generated from field: repeated common.proto.FileProto attachment = 8;
   */
  attachment: FileProto[] = [];

  /**
   * @generated from field: string template_id = 9;
   */
  templateId = "";

  /**
   * Defined labels in proto are to define main ones, but we also want custom labels, for example for our seeding campaigns.
   *
   * @generated from field: repeated string label = 10;
   */
  label: string[] = [];

  /**
   * @generated from field: repeated common.proto.Email.DynamicTemplateData dynamic_template_data = 12;
   */
  dynamicTemplateData: Email_DynamicTemplateData[] = [];

  constructor(data?: PartialMessage<Email>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.Email";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "from", kind: "message", T: Recipient },
    { no: 2, name: "reply_to", kind: "message", T: Recipient },
    { no: 3, name: "to", kind: "message", T: Recipient, repeated: true },
    { no: 4, name: "cc", kind: "message", T: Recipient, repeated: true },
    { no: 5, name: "bcc", kind: "message", T: Recipient, repeated: true },
    { no: 6, name: "subject", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "content", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "attachment", kind: "message", T: FileProto, repeated: true },
    { no: 9, name: "template_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 10, name: "label", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 12, name: "dynamic_template_data", kind: "message", T: Email_DynamicTemplateData, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Email {
    return new Email().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Email {
    return new Email().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Email {
    return new Email().fromJsonString(jsonString, options);
  }

  static equals(a: Email | PlainMessage<Email> | undefined, b: Email | PlainMessage<Email> | undefined): boolean {
    return proto3.util.equals(Email, a, b);
  }
}

/**
 * @generated from message common.proto.Email.DynamicTemplateData
 */
export class Email_DynamicTemplateData extends Message<Email_DynamicTemplateData> {
  /**
   * @generated from field: string key = 1;
   */
  key = "";

  /**
   * @generated from field: string value = 2;
   */
  value = "";

  constructor(data?: PartialMessage<Email_DynamicTemplateData>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.Email.DynamicTemplateData";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "key", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "value", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Email_DynamicTemplateData {
    return new Email_DynamicTemplateData().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Email_DynamicTemplateData {
    return new Email_DynamicTemplateData().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Email_DynamicTemplateData {
    return new Email_DynamicTemplateData().fromJsonString(jsonString, options);
  }

  static equals(a: Email_DynamicTemplateData | PlainMessage<Email_DynamicTemplateData> | undefined, b: Email_DynamicTemplateData | PlainMessage<Email_DynamicTemplateData> | undefined): boolean {
    return proto3.util.equals(Email_DynamicTemplateData, a, b);
  }
}

/**
 * @generated from message common.proto.Recipient
 */
export class Recipient extends Message<Recipient> {
  /**
   * @generated from field: string name = 1;
   */
  name = "";

  /**
   * @generated from field: string email = 2;
   */
  email = "";

  constructor(data?: PartialMessage<Recipient>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.Recipient";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Recipient {
    return new Recipient().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Recipient {
    return new Recipient().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Recipient {
    return new Recipient().fromJsonString(jsonString, options);
  }

  static equals(a: Recipient | PlainMessage<Recipient> | undefined, b: Recipient | PlainMessage<Recipient> | undefined): boolean {
    return proto3.util.equals(Recipient, a, b);
  }
}

/**
 * @generated from message common.proto.Int32Range
 */
export class Int32Range extends Message<Int32Range> {
  /**
   * @generated from field: int32 min = 1;
   */
  min = 0;

  /**
   * @generated from field: int32 max = 2;
   */
  max = 0;

  constructor(data?: PartialMessage<Int32Range>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.Int32Range";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "min", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "max", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Int32Range {
    return new Int32Range().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Int32Range {
    return new Int32Range().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Int32Range {
    return new Int32Range().fromJsonString(jsonString, options);
  }

  static equals(a: Int32Range | PlainMessage<Int32Range> | undefined, b: Int32Range | PlainMessage<Int32Range> | undefined): boolean {
    return proto3.util.equals(Int32Range, a, b);
  }
}

/**
 * @generated from message common.proto.Int32RangeWeight
 */
export class Int32RangeWeight extends Message<Int32RangeWeight> {
  /**
   * @generated from field: int32 min = 1;
   */
  min = 0;

  /**
   * @generated from field: int32 max = 2;
   */
  max = 0;

  /**
   * 0 <= weigth <= 1
   *
   * @generated from field: float weight = 3;
   */
  weight = 0;

  constructor(data?: PartialMessage<Int32RangeWeight>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.Int32RangeWeight";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "min", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "max", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "weight", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Int32RangeWeight {
    return new Int32RangeWeight().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Int32RangeWeight {
    return new Int32RangeWeight().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Int32RangeWeight {
    return new Int32RangeWeight().fromJsonString(jsonString, options);
  }

  static equals(a: Int32RangeWeight | PlainMessage<Int32RangeWeight> | undefined, b: Int32RangeWeight | PlainMessage<Int32RangeWeight> | undefined): boolean {
    return proto3.util.equals(Int32RangeWeight, a, b);
  }
}

/**
 * @generated from message common.proto.Int64Range
 */
export class Int64Range extends Message<Int64Range> {
  /**
   * @generated from field: int64 min = 1;
   */
  min = protoInt64.zero;

  /**
   * @generated from field: int64 max = 2;
   */
  max = protoInt64.zero;

  constructor(data?: PartialMessage<Int64Range>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.Int64Range";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "min", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "max", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Int64Range {
    return new Int64Range().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Int64Range {
    return new Int64Range().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Int64Range {
    return new Int64Range().fromJsonString(jsonString, options);
  }

  static equals(a: Int64Range | PlainMessage<Int64Range> | undefined, b: Int64Range | PlainMessage<Int64Range> | undefined): boolean {
    return proto3.util.equals(Int64Range, a, b);
  }
}

/**
 * @generated from message common.proto.FloatRange
 */
export class FloatRange extends Message<FloatRange> {
  /**
   * @generated from field: float min = 1;
   */
  min = 0;

  /**
   * @generated from field: float max = 2;
   */
  max = 0;

  constructor(data?: PartialMessage<FloatRange>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.FloatRange";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "min", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 2, name: "max", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FloatRange {
    return new FloatRange().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FloatRange {
    return new FloatRange().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FloatRange {
    return new FloatRange().fromJsonString(jsonString, options);
  }

  static equals(a: FloatRange | PlainMessage<FloatRange> | undefined, b: FloatRange | PlainMessage<FloatRange> | undefined): boolean {
    return proto3.util.equals(FloatRange, a, b);
  }
}

/**
 * @generated from message common.proto.DoubleRange
 */
export class DoubleRange extends Message<DoubleRange> {
  /**
   * @generated from field: double min = 1;
   */
  min = 0;

  /**
   * @generated from field: double max = 2;
   */
  max = 0;

  constructor(data?: PartialMessage<DoubleRange>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.DoubleRange";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "min", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 2, name: "max", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DoubleRange {
    return new DoubleRange().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DoubleRange {
    return new DoubleRange().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DoubleRange {
    return new DoubleRange().fromJsonString(jsonString, options);
  }

  static equals(a: DoubleRange | PlainMessage<DoubleRange> | undefined, b: DoubleRange | PlainMessage<DoubleRange> | undefined): boolean {
    return proto3.util.equals(DoubleRange, a, b);
  }
}

/**
 * @generated from message common.proto.StringCount
 */
export class StringCount extends Message<StringCount> {
  /**
   * @generated from field: string value = 1;
   */
  value = "";

  /**
   * @generated from field: int32 count = 2;
   */
  count = 0;

  constructor(data?: PartialMessage<StringCount>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.StringCount";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "value", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): StringCount {
    return new StringCount().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): StringCount {
    return new StringCount().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): StringCount {
    return new StringCount().fromJsonString(jsonString, options);
  }

  static equals(a: StringCount | PlainMessage<StringCount> | undefined, b: StringCount | PlainMessage<StringCount> | undefined): boolean {
    return proto3.util.equals(StringCount, a, b);
  }
}

/**
 * @generated from message common.proto.StringWeight
 */
export class StringWeight extends Message<StringWeight> {
  /**
   * @generated from field: string value = 1;
   */
  value = "";

  /**
   * 0 <= weigth <= 1
   *
   * @generated from field: float weight = 2;
   */
  weight = 0;

  constructor(data?: PartialMessage<StringWeight>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.StringWeight";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "value", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "weight", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): StringWeight {
    return new StringWeight().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): StringWeight {
    return new StringWeight().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): StringWeight {
    return new StringWeight().fromJsonString(jsonString, options);
  }

  static equals(a: StringWeight | PlainMessage<StringWeight> | undefined, b: StringWeight | PlainMessage<StringWeight> | undefined): boolean {
    return proto3.util.equals(StringWeight, a, b);
  }
}

/**
 * @generated from message common.proto.StringList
 */
export class StringList extends Message<StringList> {
  /**
   * @generated from field: repeated string values = 1;
   */
  values: string[] = [];

  constructor(data?: PartialMessage<StringList>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.StringList";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "values", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): StringList {
    return new StringList().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): StringList {
    return new StringList().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): StringList {
    return new StringList().fromJsonString(jsonString, options);
  }

  static equals(a: StringList | PlainMessage<StringList> | undefined, b: StringList | PlainMessage<StringList> | undefined): boolean {
    return proto3.util.equals(StringList, a, b);
  }
}

/**
 * @generated from message common.proto.Int32List
 */
export class Int32List extends Message<Int32List> {
  /**
   * @generated from field: repeated int32 values = 1;
   */
  values: number[] = [];

  constructor(data?: PartialMessage<Int32List>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.Int32List";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "values", kind: "scalar", T: 5 /* ScalarType.INT32 */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Int32List {
    return new Int32List().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Int32List {
    return new Int32List().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Int32List {
    return new Int32List().fromJsonString(jsonString, options);
  }

  static equals(a: Int32List | PlainMessage<Int32List> | undefined, b: Int32List | PlainMessage<Int32List> | undefined): boolean {
    return proto3.util.equals(Int32List, a, b);
  }
}

/**
 * @generated from message common.proto.Int64List
 */
export class Int64List extends Message<Int64List> {
  /**
   * @generated from field: repeated int64 values = 1;
   */
  values: bigint[] = [];

  constructor(data?: PartialMessage<Int64List>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.Int64List";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "values", kind: "scalar", T: 3 /* ScalarType.INT64 */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Int64List {
    return new Int64List().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Int64List {
    return new Int64List().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Int64List {
    return new Int64List().fromJsonString(jsonString, options);
  }

  static equals(a: Int64List | PlainMessage<Int64List> | undefined, b: Int64List | PlainMessage<Int64List> | undefined): boolean {
    return proto3.util.equals(Int64List, a, b);
  }
}

/**
 * @generated from message common.proto.BoolList
 */
export class BoolList extends Message<BoolList> {
  /**
   * @generated from field: repeated bool values = 1;
   */
  values: boolean[] = [];

  constructor(data?: PartialMessage<BoolList>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.BoolList";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "values", kind: "scalar", T: 8 /* ScalarType.BOOL */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): BoolList {
    return new BoolList().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): BoolList {
    return new BoolList().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): BoolList {
    return new BoolList().fromJsonString(jsonString, options);
  }

  static equals(a: BoolList | PlainMessage<BoolList> | undefined, b: BoolList | PlainMessage<BoolList> | undefined): boolean {
    return proto3.util.equals(BoolList, a, b);
  }
}

/**
 * @generated from message common.proto.TimestampedInt32
 */
export class TimestampedInt32 extends Message<TimestampedInt32> {
  /**
   * @generated from field: int32 value = 1;
   */
  value = 0;

  /**
   * @generated from field: google.protobuf.Timestamp timestamp = 2;
   */
  timestamp?: Timestamp;

  constructor(data?: PartialMessage<TimestampedInt32>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.TimestampedInt32";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "value", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "timestamp", kind: "message", T: Timestamp },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TimestampedInt32 {
    return new TimestampedInt32().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TimestampedInt32 {
    return new TimestampedInt32().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TimestampedInt32 {
    return new TimestampedInt32().fromJsonString(jsonString, options);
  }

  static equals(a: TimestampedInt32 | PlainMessage<TimestampedInt32> | undefined, b: TimestampedInt32 | PlainMessage<TimestampedInt32> | undefined): boolean {
    return proto3.util.equals(TimestampedInt32, a, b);
  }
}

/**
 * @generated from message common.proto.TimestampedFloat
 */
export class TimestampedFloat extends Message<TimestampedFloat> {
  /**
   * @generated from field: google.protobuf.Timestamp timestamp = 1;
   */
  timestamp?: Timestamp;

  /**
   * @generated from field: float value = 2;
   */
  value = 0;

  constructor(data?: PartialMessage<TimestampedFloat>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.TimestampedFloat";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "timestamp", kind: "message", T: Timestamp },
    { no: 2, name: "value", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TimestampedFloat {
    return new TimestampedFloat().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TimestampedFloat {
    return new TimestampedFloat().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TimestampedFloat {
    return new TimestampedFloat().fromJsonString(jsonString, options);
  }

  static equals(a: TimestampedFloat | PlainMessage<TimestampedFloat> | undefined, b: TimestampedFloat | PlainMessage<TimestampedFloat> | undefined): boolean {
    return proto3.util.equals(TimestampedFloat, a, b);
  }
}

/**
 * @generated from message common.proto.Forex
 */
export class Forex extends Message<Forex> {
  /**
   * @generated from field: map<string, float> values = 1;
   */
  values: { [key: string]: number } = {};

  constructor(data?: PartialMessage<Forex>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.Forex";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "values", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "scalar", T: 2 /* ScalarType.FLOAT */} },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Forex {
    return new Forex().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Forex {
    return new Forex().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Forex {
    return new Forex().fromJsonString(jsonString, options);
  }

  static equals(a: Forex | PlainMessage<Forex> | undefined, b: Forex | PlainMessage<Forex> | undefined): boolean {
    return proto3.util.equals(Forex, a, b);
  }
}

/**
 * The most basic form of pagination: a start and a size...
 *
 * @generated from message common.proto.BasicPagination
 */
export class BasicPagination extends Message<BasicPagination> {
  /**
   * @generated from field: int32 from = 1;
   */
  from = 0;

  /**
   * @generated from field: int32 size = 2;
   */
  size = 0;

  constructor(data?: PartialMessage<BasicPagination>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.BasicPagination";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "from", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "size", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): BasicPagination {
    return new BasicPagination().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): BasicPagination {
    return new BasicPagination().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): BasicPagination {
    return new BasicPagination().fromJsonString(jsonString, options);
  }

  static equals(a: BasicPagination | PlainMessage<BasicPagination> | undefined, b: BasicPagination | PlainMessage<BasicPagination> | undefined): boolean {
    return proto3.util.equals(BasicPagination, a, b);
  }
}

/**
 * @generated from message common.proto.TokenPagination
 */
export class TokenPagination extends Message<TokenPagination> {
  /**
   * @generated from field: int32 size = 1;
   */
  size = 0;

  /**
   * @generated from field: string page_token = 2;
   */
  pageToken = "";

  constructor(data?: PartialMessage<TokenPagination>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.TokenPagination";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "size", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "page_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TokenPagination {
    return new TokenPagination().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TokenPagination {
    return new TokenPagination().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TokenPagination {
    return new TokenPagination().fromJsonString(jsonString, options);
  }

  static equals(a: TokenPagination | PlainMessage<TokenPagination> | undefined, b: TokenPagination | PlainMessage<TokenPagination> | undefined): boolean {
    return proto3.util.equals(TokenPagination, a, b);
  }
}

/**
 * @generated from message common.proto.Mention
 */
export class Mention extends Message<Mention> {
  /**
   * @generated from field: string user_id = 1;
   */
  userId = "";

  /**
   * @generated from field: string username = 2;
   */
  username = "";

  constructor(data?: PartialMessage<Mention>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.Mention";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "user_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "username", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Mention {
    return new Mention().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Mention {
    return new Mention().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Mention {
    return new Mention().fromJsonString(jsonString, options);
  }

  static equals(a: Mention | PlainMessage<Mention> | undefined, b: Mention | PlainMessage<Mention> | undefined): boolean {
    return proto3.util.equals(Mention, a, b);
  }
}

/**
 * @generated from message common.proto.GenerikMentionCard
 */
export class GenerikMentionCard extends Message<GenerikMentionCard> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: string user_name = 2;
   */
  userName = "";

  /**
   * @generated from field: string profile_picture = 4;
   */
  profilePicture = "";

  /**
   * @generated from field: int32 count = 3;
   */
  count = 0;

  constructor(data?: PartialMessage<GenerikMentionCard>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.GenerikMentionCard";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "user_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "profile_picture", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GenerikMentionCard {
    return new GenerikMentionCard().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GenerikMentionCard {
    return new GenerikMentionCard().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GenerikMentionCard {
    return new GenerikMentionCard().fromJsonString(jsonString, options);
  }

  static equals(a: GenerikMentionCard | PlainMessage<GenerikMentionCard> | undefined, b: GenerikMentionCard | PlainMessage<GenerikMentionCard> | undefined): boolean {
    return proto3.util.equals(GenerikMentionCard, a, b);
  }
}

/**
 * @generated from message common.proto.StatValue
 */
export class StatValue extends Message<StatValue> {
  /**
   * @generated from field: google.protobuf.FloatValue avg = 1;
   */
  avg?: number;

  /**
   * @generated from field: google.protobuf.Int32Value min = 2;
   */
  min?: number;

  /**
   * @generated from field: google.protobuf.Int32Value max = 3;
   */
  max?: number;

  /**
   * as of 06/2021, those (and the LongStatValue counterpart below) are computed on the past 3 months of data.
   *
   * @generated from field: google.protobuf.FloatValue recent_avg = 7;
   */
  recentAvg?: number;

  /**
   * @generated from field: google.protobuf.Int32Value recent_min = 8;
   */
  recentMin?: number;

  /**
   * @generated from field: google.protobuf.Int32Value recent_max = 9;
   */
  recentMax?: number;

  constructor(data?: PartialMessage<StatValue>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.StatValue";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "avg", kind: "message", T: FloatValue },
    { no: 2, name: "min", kind: "message", T: Int32Value },
    { no: 3, name: "max", kind: "message", T: Int32Value },
    { no: 7, name: "recent_avg", kind: "message", T: FloatValue },
    { no: 8, name: "recent_min", kind: "message", T: Int32Value },
    { no: 9, name: "recent_max", kind: "message", T: Int32Value },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): StatValue {
    return new StatValue().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): StatValue {
    return new StatValue().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): StatValue {
    return new StatValue().fromJsonString(jsonString, options);
  }

  static equals(a: StatValue | PlainMessage<StatValue> | undefined, b: StatValue | PlainMessage<StatValue> | undefined): boolean {
    return proto3.util.equals(StatValue, a, b);
  }
}

/**
 * @generated from message common.proto.LongStatValue
 */
export class LongStatValue extends Message<LongStatValue> {
  /**
   * @generated from field: google.protobuf.DoubleValue avg = 1;
   */
  avg?: number;

  /**
   * @generated from field: google.protobuf.Int64Value min = 2;
   */
  min?: bigint;

  /**
   * @generated from field: google.protobuf.Int64Value max = 3;
   */
  max?: bigint;

  /**
   * @generated from field: google.protobuf.DoubleValue recent_avg = 7;
   */
  recentAvg?: number;

  /**
   * @generated from field: google.protobuf.Int64Value recent_min = 8;
   */
  recentMin?: bigint;

  /**
   * @generated from field: google.protobuf.Int64Value recent_max = 9;
   */
  recentMax?: bigint;

  constructor(data?: PartialMessage<LongStatValue>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.LongStatValue";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "avg", kind: "message", T: DoubleValue },
    { no: 2, name: "min", kind: "message", T: Int64Value },
    { no: 3, name: "max", kind: "message", T: Int64Value },
    { no: 7, name: "recent_avg", kind: "message", T: DoubleValue },
    { no: 8, name: "recent_min", kind: "message", T: Int64Value },
    { no: 9, name: "recent_max", kind: "message", T: Int64Value },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): LongStatValue {
    return new LongStatValue().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): LongStatValue {
    return new LongStatValue().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): LongStatValue {
    return new LongStatValue().fromJsonString(jsonString, options);
  }

  static equals(a: LongStatValue | PlainMessage<LongStatValue> | undefined, b: LongStatValue | PlainMessage<LongStatValue> | undefined): boolean {
    return proto3.util.equals(LongStatValue, a, b);
  }
}

/**
 * @generated from message common.proto.Timeseries
 */
export class Timeseries extends Message<Timeseries> {
  /**
   * @generated from field: string name = 1;
   */
  name = "";

  /**
   * @generated from field: repeated common.proto.TimestampedFloat values = 2;
   */
  values: TimestampedFloat[] = [];

  constructor(data?: PartialMessage<Timeseries>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.Timeseries";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "values", kind: "message", T: TimestampedFloat, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Timeseries {
    return new Timeseries().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Timeseries {
    return new Timeseries().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Timeseries {
    return new Timeseries().fromJsonString(jsonString, options);
  }

  static equals(a: Timeseries | PlainMessage<Timeseries> | undefined, b: Timeseries | PlainMessage<Timeseries> | undefined): boolean {
    return proto3.util.equals(Timeseries, a, b);
  }
}

/**
 * a float value, with an indicator whether it came from a custom or from a default
 * value
 *
 * @generated from message common.proto.CustomizableFloat
 */
export class CustomizableFloat extends Message<CustomizableFloat> {
  /**
   * @generated from field: float value = 1;
   */
  value = 0;

  /**
   * @generated from field: bool is_default = 2;
   */
  isDefault = false;

  constructor(data?: PartialMessage<CustomizableFloat>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.CustomizableFloat";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "value", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 2, name: "is_default", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CustomizableFloat {
    return new CustomizableFloat().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CustomizableFloat {
    return new CustomizableFloat().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CustomizableFloat {
    return new CustomizableFloat().fromJsonString(jsonString, options);
  }

  static equals(a: CustomizableFloat | PlainMessage<CustomizableFloat> | undefined, b: CustomizableFloat | PlainMessage<CustomizableFloat> | undefined): boolean {
    return proto3.util.equals(CustomizableFloat, a, b);
  }
}

/**
 * @generated from message common.proto.BeanstalkdEnv
 */
export class BeanstalkdEnv extends Message<BeanstalkdEnv> {
  /**
   * @generated from field: int32 max_retries = 1;
   */
  maxRetries = 0;

  /**
   * @generated from field: google.protobuf.Any content = 2;
   */
  content?: Any;

  constructor(data?: PartialMessage<BeanstalkdEnv>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.BeanstalkdEnv";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "max_retries", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "content", kind: "message", T: Any },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): BeanstalkdEnv {
    return new BeanstalkdEnv().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): BeanstalkdEnv {
    return new BeanstalkdEnv().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): BeanstalkdEnv {
    return new BeanstalkdEnv().fromJsonString(jsonString, options);
  }

  static equals(a: BeanstalkdEnv | PlainMessage<BeanstalkdEnv> | undefined, b: BeanstalkdEnv | PlainMessage<BeanstalkdEnv> | undefined): boolean {
    return proto3.util.equals(BeanstalkdEnv, a, b);
  }
}

/**
 * @generated from message common.proto.BeanstalkdStatus
 */
export class BeanstalkdStatus extends Message<BeanstalkdStatus> {
  /**
   * @generated from field: int32 code = 1;
   */
  code = 0;

  /**
   * @generated from field: string message = 2;
   */
  message = "";

  constructor(data?: PartialMessage<BeanstalkdStatus>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.BeanstalkdStatus";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "code", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "message", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): BeanstalkdStatus {
    return new BeanstalkdStatus().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): BeanstalkdStatus {
    return new BeanstalkdStatus().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): BeanstalkdStatus {
    return new BeanstalkdStatus().fromJsonString(jsonString, options);
  }

  static equals(a: BeanstalkdStatus | PlainMessage<BeanstalkdStatus> | undefined, b: BeanstalkdStatus | PlainMessage<BeanstalkdStatus> | undefined): boolean {
    return proto3.util.equals(BeanstalkdStatus, a, b);
  }
}

/**
 * @generated from message common.proto.ComputedGenerikStats
 */
export class ComputedGenerikStats extends Message<ComputedGenerikStats> {
  /**
   * @generated from field: common.proto.LongStatValue likes = 1;
   */
  likes?: LongStatValue;

  /**
   * @generated from field: common.proto.LongStatValue dislikes = 13;
   */
  dislikes?: LongStatValue;

  /**
   * @generated from field: common.proto.LongStatValue comments = 2;
   */
  comments?: LongStatValue;

  /**
   * @generated from field: common.proto.LongStatValue views = 3;
   */
  views?: LongStatValue;

  /**
   * @generated from field: common.proto.LongStatValue shares = 4;
   */
  shares?: LongStatValue;

  /**
   * @generated from field: common.proto.LongStatValue clicks = 17;
   */
  clicks?: LongStatValue;

  /**
   * @generated from field: common.proto.LongStatValue wows = 18;
   */
  wows?: LongStatValue;

  /**
   * In this proto, the values are the number of seconds between 2 posts.
   *
   * @generated from field: common.proto.StatValue posting_frequency = 5;
   */
  postingFrequency?: StatValue;

  /**
   * @generated from field: repeated common.proto.StringCount top_tags = 6;
   */
  topTags: StringCount[] = [];

  /**
   * Note(christian) These 2 fields are not to be persisted or read by backend code.
   * They are only present to serve to frontend, it is an abuse that we are sharing the same message for cassandra
   * persistence and frontend communication.
   *
   * The mentions/mentioning that are served to frontend come from dedicated lefty.medu and lefty.mingu tables.
   * The TopMentions object that acts as a cache of username -> id when we index them, is at creator.meta_facts.
   *
   * @generated from field: repeated common.proto.GenerikMentionCard top_mentions = 7;
   */
  topMentions: GenerikMentionCard[] = [];

  /**
   * @generated from field: repeated common.proto.GenerikMentionCard top_mentioning = 16;
   */
  topMentioning: GenerikMentionCard[] = [];

  /**
   * @generated from field: google.protobuf.Timestamp last_post = 8;
   */
  lastPost?: Timestamp;

  /**
   * @generated from field: google.protobuf.Timestamp stats_compute_time = 9;
   */
  statsComputeTime?: Timestamp;

  /**
   * Insta specific data
   *
   * @generated from field: common.proto.LongStatValue sponsored_likes = 10;
   */
  sponsoredLikes?: LongStatValue;

  /**
   * @generated from field: common.proto.LongStatValue sponsored_comments_count = 11;
   */
  sponsoredCommentsCount?: LongStatValue;

  /**
   * @generated from field: common.proto.LongStatValue sponsored_posting_frequency = 12;
   */
  sponsoredPostingFrequency?: LongStatValue;

  /**
   * @generated from field: google.protobuf.FloatValue engagement_score = 14;
   */
  engagementScore?: number;

  /**
   * @generated from field: google.protobuf.Int32Value count_sponsored_posts = 15;
   */
  countSponsoredPosts?: number;

  /**
   * @generated from field: google.protobuf.FloatValue saturation_rate = 19;
   */
  saturationRate?: number;

  constructor(data?: PartialMessage<ComputedGenerikStats>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.ComputedGenerikStats";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "likes", kind: "message", T: LongStatValue },
    { no: 13, name: "dislikes", kind: "message", T: LongStatValue },
    { no: 2, name: "comments", kind: "message", T: LongStatValue },
    { no: 3, name: "views", kind: "message", T: LongStatValue },
    { no: 4, name: "shares", kind: "message", T: LongStatValue },
    { no: 17, name: "clicks", kind: "message", T: LongStatValue },
    { no: 18, name: "wows", kind: "message", T: LongStatValue },
    { no: 5, name: "posting_frequency", kind: "message", T: StatValue },
    { no: 6, name: "top_tags", kind: "message", T: StringCount, repeated: true },
    { no: 7, name: "top_mentions", kind: "message", T: GenerikMentionCard, repeated: true },
    { no: 16, name: "top_mentioning", kind: "message", T: GenerikMentionCard, repeated: true },
    { no: 8, name: "last_post", kind: "message", T: Timestamp },
    { no: 9, name: "stats_compute_time", kind: "message", T: Timestamp },
    { no: 10, name: "sponsored_likes", kind: "message", T: LongStatValue },
    { no: 11, name: "sponsored_comments_count", kind: "message", T: LongStatValue },
    { no: 12, name: "sponsored_posting_frequency", kind: "message", T: LongStatValue },
    { no: 14, name: "engagement_score", kind: "message", T: FloatValue },
    { no: 15, name: "count_sponsored_posts", kind: "message", T: Int32Value },
    { no: 19, name: "saturation_rate", kind: "message", T: FloatValue },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ComputedGenerikStats {
    return new ComputedGenerikStats().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ComputedGenerikStats {
    return new ComputedGenerikStats().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ComputedGenerikStats {
    return new ComputedGenerikStats().fromJsonString(jsonString, options);
  }

  static equals(a: ComputedGenerikStats | PlainMessage<ComputedGenerikStats> | undefined, b: ComputedGenerikStats | PlainMessage<ComputedGenerikStats> | undefined): boolean {
    return proto3.util.equals(ComputedGenerikStats, a, b);
  }
}

/**
 * @generated from message common.proto.PrivateInfo
 */
export class PrivateInfo extends Message<PrivateInfo> {
  /**
   * This is true if and only if the user was private last time Lefty tried to access it.
   * Obviously it may not be the ground truth if we haven't polled it in a while.
   *
   * @generated from field: bool is_private = 1;
   */
  isPrivate = false;

  /**
   * This is when we marked the user as private.
   * We track that time so if it's too long ago we may decide to check whether they changed back to public...
   *
   * @generated from field: google.protobuf.Timestamp last_seen_private = 2;
   */
  lastSeenPrivate?: Timestamp;

  constructor(data?: PartialMessage<PrivateInfo>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.PrivateInfo";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "is_private", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 2, name: "last_seen_private", kind: "message", T: Timestamp },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): PrivateInfo {
    return new PrivateInfo().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): PrivateInfo {
    return new PrivateInfo().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): PrivateInfo {
    return new PrivateInfo().fromJsonString(jsonString, options);
  }

  static equals(a: PrivateInfo | PlainMessage<PrivateInfo> | undefined, b: PrivateInfo | PlainMessage<PrivateInfo> | undefined): boolean {
    return proto3.util.equals(PrivateInfo, a, b);
  }
}

/**
 * @generated from message common.proto.UTMValue
 */
export class UTMValue extends Message<UTMValue> {
  /**
   * @generated from oneof common.proto.UTMValue.value
   */
  value: {
    /**
     * @generated from field: common.proto.UTMValueType type = 1;
     */
    value: UTMValueType;
    case: "type";
  } | {
    /**
     * @generated from field: int64 creator_field_id = 4;
     */
    value: bigint;
    case: "creatorFieldId";
  } | {
    /**
     * @generated from field: string text = 3;
     */
    value: string;
    case: "text";
  } | { case: undefined; value?: undefined } = { case: undefined };

  constructor(data?: PartialMessage<UTMValue>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.UTMValue";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "type", kind: "enum", T: proto3.getEnumType(UTMValueType), oneof: "value" },
    { no: 4, name: "creator_field_id", kind: "scalar", T: 3 /* ScalarType.INT64 */, oneof: "value" },
    { no: 3, name: "text", kind: "scalar", T: 9 /* ScalarType.STRING */, oneof: "value" },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UTMValue {
    return new UTMValue().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UTMValue {
    return new UTMValue().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UTMValue {
    return new UTMValue().fromJsonString(jsonString, options);
  }

  static equals(a: UTMValue | PlainMessage<UTMValue> | undefined, b: UTMValue | PlainMessage<UTMValue> | undefined): boolean {
    return proto3.util.equals(UTMValue, a, b);
  }
}

/**
 * @generated from message common.proto.UTMValueList
 */
export class UTMValueList extends Message<UTMValueList> {
  /**
   * @generated from field: repeated common.proto.UTMValue values = 1;
   */
  values: UTMValue[] = [];

  constructor(data?: PartialMessage<UTMValueList>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.UTMValueList";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "values", kind: "message", T: UTMValue, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UTMValueList {
    return new UTMValueList().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UTMValueList {
    return new UTMValueList().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UTMValueList {
    return new UTMValueList().fromJsonString(jsonString, options);
  }

  static equals(a: UTMValueList | PlainMessage<UTMValueList> | undefined, b: UTMValueList | PlainMessage<UTMValueList> | undefined): boolean {
    return proto3.util.equals(UTMValueList, a, b);
  }
}

/**
 * @generated from message common.proto.UTMParameter
 */
export class UTMParameter extends Message<UTMParameter> {
  /**
   * @generated from field: common.proto.UTMType type = 1;
   */
  type = UTMType.UTM_UNKNOWN;

  /**
   * @generated from field: common.proto.UTMValueList values = 2;
   */
  values?: UTMValueList;

  constructor(data?: PartialMessage<UTMParameter>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.UTMParameter";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "type", kind: "enum", T: proto3.getEnumType(UTMType) },
    { no: 2, name: "values", kind: "message", T: UTMValueList },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UTMParameter {
    return new UTMParameter().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UTMParameter {
    return new UTMParameter().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UTMParameter {
    return new UTMParameter().fromJsonString(jsonString, options);
  }

  static equals(a: UTMParameter | PlainMessage<UTMParameter> | undefined, b: UTMParameter | PlainMessage<UTMParameter> | undefined): boolean {
    return proto3.util.equals(UTMParameter, a, b);
  }
}

/**
 * @generated from message common.proto.UTMParameterList
 */
export class UTMParameterList extends Message<UTMParameterList> {
  /**
   * @generated from field: repeated common.proto.UTMParameter parameters = 1;
   */
  parameters: UTMParameter[] = [];

  constructor(data?: PartialMessage<UTMParameterList>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.UTMParameterList";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "parameters", kind: "message", T: UTMParameter, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UTMParameterList {
    return new UTMParameterList().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UTMParameterList {
    return new UTMParameterList().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UTMParameterList {
    return new UTMParameterList().fromJsonString(jsonString, options);
  }

  static equals(a: UTMParameterList | PlainMessage<UTMParameterList> | undefined, b: UTMParameterList | PlainMessage<UTMParameterList> | undefined): boolean {
    return proto3.util.equals(UTMParameterList, a, b);
  }
}

/**
 * Simple representation of a given day, at 12PM UTC
 *
 * @generated from message common.proto.DayDate
 */
export class DayDate extends Message<DayDate> {
  /**
   * Always 12PM UTC of that day
   * Represents seconds of UTC time since Unix epoch
   * 1970-01-01T00:00:00Z. Must be from 0001-01-01T00:00:00Z to
   * 9999-12-31T23:59:59Z inclusive.
   *
   * @generated from field: int64 seconds = 1;
   */
  seconds = protoInt64.zero;

  constructor(data?: PartialMessage<DayDate>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.DayDate";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "seconds", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DayDate {
    return new DayDate().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DayDate {
    return new DayDate().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DayDate {
    return new DayDate().fromJsonString(jsonString, options);
  }

  static equals(a: DayDate | PlainMessage<DayDate> | undefined, b: DayDate | PlainMessage<DayDate> | undefined): boolean {
    return proto3.util.equals(DayDate, a, b);
  }
}

/**
 * @generated from message common.proto.NetworkFollowersRange
 */
export class NetworkFollowersRange extends Message<NetworkFollowersRange> {
  /**
   * @generated from field: common.proto.Network network = 1;
   */
  network = Network.NETWORK_UNKNOWN;

  /**
   * @generated from field: common.proto.Int32Range followers_range = 2;
   */
  followersRange?: Int32Range;

  constructor(data?: PartialMessage<NetworkFollowersRange>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.NetworkFollowersRange";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "network", kind: "enum", T: proto3.getEnumType(Network) },
    { no: 2, name: "followers_range", kind: "message", T: Int32Range },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): NetworkFollowersRange {
    return new NetworkFollowersRange().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): NetworkFollowersRange {
    return new NetworkFollowersRange().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): NetworkFollowersRange {
    return new NetworkFollowersRange().fromJsonString(jsonString, options);
  }

  static equals(a: NetworkFollowersRange | PlainMessage<NetworkFollowersRange> | undefined, b: NetworkFollowersRange | PlainMessage<NetworkFollowersRange> | undefined): boolean {
    return proto3.util.equals(NetworkFollowersRange, a, b);
  }
}

/**
 * @generated from message common.proto.NetworkEngagementRangeV2
 */
export class NetworkEngagementRangeV2 extends Message<NetworkEngagementRangeV2> {
  /**
   * @generated from field: common.proto.Network network = 1;
   */
  network = Network.NETWORK_UNKNOWN;

  /**
   * @generated from field: common.proto.DoubleRange engagement_range = 2;
   */
  engagementRange?: DoubleRange;

  constructor(data?: PartialMessage<NetworkEngagementRangeV2>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.NetworkEngagementRangeV2";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "network", kind: "enum", T: proto3.getEnumType(Network) },
    { no: 2, name: "engagement_range", kind: "message", T: DoubleRange },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): NetworkEngagementRangeV2 {
    return new NetworkEngagementRangeV2().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): NetworkEngagementRangeV2 {
    return new NetworkEngagementRangeV2().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): NetworkEngagementRangeV2 {
    return new NetworkEngagementRangeV2().fromJsonString(jsonString, options);
  }

  static equals(a: NetworkEngagementRangeV2 | PlainMessage<NetworkEngagementRangeV2> | undefined, b: NetworkEngagementRangeV2 | PlainMessage<NetworkEngagementRangeV2> | undefined): boolean {
    return proto3.util.equals(NetworkEngagementRangeV2, a, b);
  }
}

/**
 * @generated from message common.proto.GenderWeight
 */
export class GenderWeight extends Message<GenderWeight> {
  /**
   * @generated from field: common.proto.Gender gender = 1;
   */
  gender = Gender.GENDER_UNDEFINED;

  /**
   * 0 <= weigth <= 1
   *
   * @generated from field: float weight = 2;
   */
  weight = 0;

  constructor(data?: PartialMessage<GenderWeight>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.GenderWeight";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "gender", kind: "enum", T: proto3.getEnumType(Gender) },
    { no: 2, name: "weight", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GenderWeight {
    return new GenderWeight().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GenderWeight {
    return new GenderWeight().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GenderWeight {
    return new GenderWeight().fromJsonString(jsonString, options);
  }

  static equals(a: GenderWeight | PlainMessage<GenderWeight> | undefined, b: GenderWeight | PlainMessage<GenderWeight> | undefined): boolean {
    return proto3.util.equals(GenderWeight, a, b);
  }
}

/**
 * it concern only instagram network
 *
 * @generated from message common.proto.RelogGiovanniRequest
 */
export class RelogGiovanniRequest extends Message<RelogGiovanniRequest> {
  /**
   * @generated from field: int64 giovanni_id = 1;
   */
  giovanniId = protoInt64.zero;

  /**
   * @generated from field: bool is_new_rogue = 2;
   */
  isNewRogue = false;

  constructor(data?: PartialMessage<RelogGiovanniRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.RelogGiovanniRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "giovanni_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "is_new_rogue", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RelogGiovanniRequest {
    return new RelogGiovanniRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RelogGiovanniRequest {
    return new RelogGiovanniRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): RelogGiovanniRequest {
    return new RelogGiovanniRequest().fromJsonString(jsonString, options);
  }

  static equals(a: RelogGiovanniRequest | PlainMessage<RelogGiovanniRequest> | undefined, b: RelogGiovanniRequest | PlainMessage<RelogGiovanniRequest> | undefined): boolean {
    return proto3.util.equals(RelogGiovanniRequest, a, b);
  }
}

/**
 * @generated from message common.proto.Header
 */
export class Header extends Message<Header> {
  /**
   * @generated from field: string name = 1;
   */
  name = "";

  /**
   * @generated from field: string value = 2;
   */
  value = "";

  constructor(data?: PartialMessage<Header>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.Header";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "value", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Header {
    return new Header().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Header {
    return new Header().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Header {
    return new Header().fromJsonString(jsonString, options);
  }

  static equals(a: Header | PlainMessage<Header> | undefined, b: Header | PlainMessage<Header> | undefined): boolean {
    return proto3.util.equals(Header, a, b);
  }
}

/**
 * @generated from message common.proto.Headers
 */
export class Headers extends Message<Headers> {
  /**
   * @generated from field: repeated common.proto.Header headers = 1;
   */
  headers: Header[] = [];

  constructor(data?: PartialMessage<Headers>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.Headers";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "headers", kind: "message", T: Header, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Headers {
    return new Headers().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Headers {
    return new Headers().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Headers {
    return new Headers().fromJsonString(jsonString, options);
  }

  static equals(a: Headers | PlainMessage<Headers> | undefined, b: Headers | PlainMessage<Headers> | undefined): boolean {
    return proto3.util.equals(Headers, a, b);
  }
}

