import { APP_INITIALIZER, Injectable, isDevMode } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { JsonObject, JsonValue } from '@bufbuild/protobuf';
import { Casting } from '@frontend2/proto/librarian/proto/casting_pb';
import {
  CreatorCardSnippet,
  CreatorProfileCard,
} from '@frontend2/proto/librarian/proto/creators_pb';
import {
  BehaviorSubject,
  Observable,
  filter,
  firstValueFrom,
  fromEvent,
  map,
} from 'rxjs';
import { Disposable } from '../async';
import { isNotNil } from '../utils/common.helpers';
import { isNotEmptyString } from '../utils/strings.helpers';
import { LeftyIframeDataSyncable } from './lefty_data_syncable.service';
import {
  IFRAME_WINDOW_ID_KEY,
  IframeEvents,
  IframeProtoEvents,
  IframeRequests,
  LeftyDialogVisibleEvent,
  LeftyIframeMessage,
  LeftyIframePositionEvent,
  LeftyNavigateEvent,
  LeftyRouteNotFoundEvent,
  LeftySyncDataEvent,
  ManageInfluencerIframeEvent,
  ManagementType,
  TaskProgressUpdateEvent,
} from './lefty_iframe.models';

function isLeftyWindowMessage(event: MessageEvent): boolean {
  return event.data['type'] === 'lefty';
}

export const IS_IN_IFRAME = window.parent !== window;

// use by IFRAME APP to communicate with HOST APP
@Injectable({ providedIn: 'root' })
export class LeftyParentAppBridge extends Disposable {
  readonly message$ = fromEvent<MessageEvent>(window, 'message').pipe(
    filter(isLeftyWindowMessage),
    filter((event) => event.origin !== window.location.origin),
  );

  private _windowId?: string;
  private _binded$ = new BehaviorSubject(false);

  private _locationOrigin?: string;

  get locationOrigin(): string | undefined {
    return this._locationOrigin;
  }

  constructor() {
    super();

    if (IS_IN_IFRAME) {
      window.document.body.classList.add('lefty-app-iframe');
    }

    if (IS_IN_IFRAME) {
      this.message$.subscribe((msg) => {
        if (isDevMode()) {
          console.log(`RECEIVE from HOST: ${msg.data.name} ${msg.data.data}`);
        } else {
          console.log(`RECEIVE from HOST: ${msg.data.name}`);
        }
      });
    }

    this._syncAutodismiss();

    this.replaceUrl$
      .pipe(takeUntilDestroyed())
      .subscribe(({ url }) => window.location.replace(url));
  }

  static readonly INITIALIZER = {
    provide: APP_INITIALIZER,
    useFactory: (
      parentAppBridge: LeftyParentAppBridge,
    ): (() => Promise<void>) => {
      return () => parentAppBridge._initialize();
    },
    multi: true,
    deps: [LeftyParentAppBridge],
  };

  private async _initialize(): Promise<void> {
    if (IS_IN_IFRAME) {
      return new Promise((resolve) => {
        this._bindIframe$.subscribe((event) => {
          this._windowId = event.windowId;
          this._locationOrigin = event.locationOrigin;
          this._emitInitialized();
          this._binded$.next(true);
          resolve();
        });
      });
    }
  }

  private _syncAutodismiss(): void {
    // subscribe to HOST app autodismiss event (click ouside dialog/popup)
    // and propagate it here to close popup or dialog if any
    // the simplest way to do it is to click on body
    this.disposer.add(
      this.autodismiss$.subscribe({
        next: () => document.body.click(),
      }),
    );
  }

  private emit<T>(
    name: string,
    data?: T,
    extras?: {
      requestId?: number;
      // Tags to be logged in console, in release mode
      // We can't log the full event data in production.
      // For security reasons. But also sometimes it's too big.
      // But we still want to log some infos in order to debug in Sentry/Datadog
      logTags?: Record<string, string | number | boolean | undefined>;
    },
  ): void {
    if (IS_IN_IFRAME === false) {
      return;
    }

    if (isDevMode()) {
      console.log(`EMIT to HOST: ${name}`, data);
    } else {
      let logTags = extras?.logTags ?? {};

      logTags = {
        ...logTags,
        windowId: this._windowId,
        requestId: extras?.requestId,
      };

      console.log(`EMIT to HOST: ${name}`, logTags);
    }

    const msg = {
      type: 'lefty',
      name,
      data,
      ...extras,
      // sign event with window id so, the host can recognize which iframe
      // send the event
      [IFRAME_WINDOW_ID_KEY]: this._windowId,
    };

    window.parent.postMessage(msg, '*');
  }

  private emitEvent<Name extends keyof IframeEvents>(
    name: Name,
    data?: IframeEvents[Name],
    logTags?: Record<string, string | number | boolean | undefined>,
  ): void {
    this.emit(name, data, { logTags });
  }

  emitEventBus(name: string, data: JsonValue): void {
    this.emit('EventBus.' + name, data);
  }

  emitAddInfluencersTo(
    type: ManagementType,
    data?: ManageInfluencerIframeEvent,
  ): void {
    this.emit('AddTo.' + type, data);
  }

  emitRemoveInfluencersFrom(
    type: ManagementType,
    data?: ManageInfluencerIframeEvent,
  ): void {
    this.emit('RemoveFrom.' + type, data);
  }

  emitInfluencersAddedTo(
    type: ManagementType,
    data?: ManageInfluencerIframeEvent,
  ): void {
    this.emit('InfluencersAddedTo.' + type, data);
  }

  emitBroncoProgressEvent(
    name: string,
    progress: TaskProgressUpdateEvent,
  ): void {
    this.emit('BroncoProgress.' + name, progress);
  }

  on<Name extends keyof IframeEvents>(
    name: Name,
  ): Observable<IframeEvents[Name]> {
    return this.message$.pipe(
      filter((event) => event.data?.name === name),
      map((event) => event.data as LeftyIframeMessage<IframeEvents[Name]>),
      map((msg) => msg.data),
    );
  }

  onProto<Name extends keyof IframeProtoEvents>(
    name: Name,
    decoder: (val: JsonObject) => IframeProtoEvents[Name],
  ): Observable<IframeProtoEvents[Name]> {
    return this.message$.pipe(
      filter((event) => event.data?.name === name),
      map((event) => event.data as LeftyIframeMessage<JsonObject>),
      map((msg) => msg.data),
      map((data) => decoder(data)),
    );
  }

  private onResponse<Name extends keyof IframeRequests>(
    requestId: number,
    name: Name,
  ): Observable<IframeRequests[Name]['response']> {
    return this.message$.pipe(
      filter((event) => event.data?.name === `${name}.response`),
      filter((event) => event.data?.requestId === requestId),
      map(
        (event) =>
          event.data as LeftyIframeMessage<IframeRequests[Name]['response']>,
      ),
      map((msg) => msg.data),
    );
  }

  private emitRequest<Name extends keyof IframeRequests>(
    requestId: number,
    name: Name,
    data?: IframeRequests[Name]['request'],
    logTags?: Record<string, string | number | boolean | undefined>,
  ): void {
    this.emit(`${name}.request`, data, { requestId, logTags });
  }

  private async _waitBindingToParentApp(): Promise<void> {
    await firstValueFrom(
      this._binded$.pipe(filter((binded) => binded === true)),
    );
  }

  private _requestIdCount = 0;

  private async emitRequestAndWaitResponse<Name extends keyof IframeRequests>(
    name: Name,
    data?: IframeRequests[Name]['request'],
    logTags?: Record<string, string | number | boolean | undefined>,
  ): Promise<IframeRequests[Name]['response']> {
    await this._waitBindingToParentApp();

    const requestId = this._requestIdCount++;
    const response = firstValueFrom(this.onResponse(requestId, name));

    this.emitRequest(requestId, name, data, logTags);

    return response;
  }

  private _emitInitialized(): void {
    this.emitEvent('LeftyInitialized');
  }

  emitRouteNotFound(event: LeftyRouteNotFoundEvent): void {
    this.emitEvent('LeftyRouteNotFound', event, {
      path: event.path,
    });
  }

  navigate(event: LeftyNavigateEvent): void {
    this.emitEvent('LeftyNavigate', event, {
      path: event.url,
    });
  }

  emitDialogVisible(event: LeftyDialogVisibleEvent): void {
    window.document.body.classList.toggle('dialog-visible', event.visible);
    this.emitEvent('LeftyDialogVisible', event, {
      visible: event.visible,
    });
  }

  private _emitDataSync(event: LeftySyncDataEvent): void {
    this.emitEvent('LeftySyncData', event, {
      syncName: event.syncName,
    });
  }

  private readonly _dataSync$ = this.on('LeftySyncData');

  readonly replaceUrl$ = this.on('LeftyReplaceUrl');

  readonly autodismiss$ = this.on('LeftyAutoDismiss');

  // Receive event from parent to initiate connection
  // contain the iframe id
  private readonly _bindIframe$ = this.on('LeftyBindIframe');

  getIframePosition(): Promise<LeftyIframePositionEvent> {
    return this.emitRequestAndWaitResponse('LeftyIframePosition');
  }

  _getData(name: string): Promise<LeftySyncDataEvent> {
    return this.emitRequestAndWaitResponse(
      'LeftyGetData',
      // event
      { syncName: name },
      // logTags
      { syncName: name },
    );
  }

  private readonly _syncedDataServices: {
    [key: string]: LeftyIframeDataSyncable<unknown>;
  } = {};

  private readonly _syncedData: {
    [key: string]: unknown;
  } = {};

  private _handleServiceDataChange<T>(
    service: LeftyIframeDataSyncable<T>,
    data: T,
  ): void {
    const name = service.syncName;

    if (this._syncedData[name] === data) {
      return;
    }

    this._emitDataSync({
      syncName: service.syncName,
      jsonData: service.convertToJson(data),
    });
  }

  private _handleParentServiceDataChange<T>(
    service: LeftyIframeDataSyncable<T>,
    jsonData: string,
  ): void {
    const name = service.syncName;

    const data = service.convertFromJson(jsonData);
    this._syncedData[name] = data;
    service.syncData(data);
  }

  async syncDataService<T>(service: LeftyIframeDataSyncable<T>): Promise<void> {
    const name = service.syncName;

    if (isNotNil(this._syncedDataServices[name])) {
      return;
    }
    this._syncedDataServices[name] = service;

    service.dataToSync$.pipe(takeUntilDestroyed()).subscribe((data) => {
      this._handleServiceDataChange(service, data);
    });

    this._dataSync$
      .pipe(
        filter((event) => event.syncName === name),
        takeUntilDestroyed(),
      )
      .subscribe((event) => {
        this._handleParentServiceDataChange<T>(service, event.jsonData);
      });

    const initialData = await this._getData(name);
    if (isNotEmptyString(initialData.jsonData)) {
      this._handleParentServiceDataChange<T>(service, initialData.jsonData);
    }
  }

  readonly preselectInfluencersForGifts$ = this.on(
    'PreselectInfluencersForGifts',
  ).pipe(
    map((event) => {
      return {
        influencers: event.influencers.map((e) => new CreatorCardSnippet(e)),
      };
    }),
  );

  readonly convertCastingToCampaign$ = this.onProto(
    'ConvertCastingToCampaign',
    Casting.fromJson,
  );

  readonly requestInfluencerMergeNetworks$ = this.onProto(
    'RequestInfluencerMergeNetworks',
    CreatorProfileCard.fromJson,
  );
}
