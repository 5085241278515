<div class="container">
  @for (influencer of influencersToShow(); track $index) {
    <profile-picture
      [creator]="influencer"
      class="influencer-item"
      [leftyTooltip]="influencer.userName"
      [showTooltipIf]="withTooltip()"
    ></profile-picture>
  } @empty {
    <p>-</p>
  }

  @if (moreItemsCount() > 0) {
    <div
      class="more-items body-1 influencer-item"
      [leftyTooltip]="moreItemsTooltip()"
      [showTooltipIf]="withTooltip()"
    >
      {{ moreItemsCount() }}+
    </div>
  }
</div>
