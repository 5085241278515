import {
  ChangeDetectionStrategy,
  Component,
  output,
  signal,
} from '@angular/core';
import { LeftyButtonDirective } from '../../lefty-button-directive/lefty-button.directive';
import { LeftyButtonListItemComponent } from '../../lefty-button-list/lefty-button-list-item/lefty-button-list-item.component';
import { LeftyButtonListComponent } from '../../lefty-button-list/lefty-button-list.component';
import { LeftyDialogComponent } from '../../lefty-dialog/lefty-dialog.component';
import { DialogBase } from '../../lefty-dialog/lefty-dialog.helpers';
import {
  AddInfluencersMethod,
  addInfluencerMethodIcon,
  addInfluencerMethodToReadable,
} from '../influencers-management.models';

@Component({
  selector: 'method-selection-dialog',
  templateUrl: 'method-selection-dialog.component.html',
  styleUrls: ['./method-selection-dialog.component.scss'],
  providers: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    LeftyDialogComponent,
    LeftyButtonDirective,
    LeftyButtonListComponent,
    LeftyButtonListComponent,
    LeftyButtonListItemComponent,
  ],
})
export class MethodSelectionDialogComponent extends DialogBase {
  readonly addMethods = signal<AddInfluencersMethod[]>([]);

  readonly methodSelected$ = output<AddInfluencersMethod>();

  readonly dialogDismissed$ = output<void>();

  openWith(methods: AddInfluencersMethod[]): void {
    this.addMethods.set(methods);
    this.open();
  }

  addInfluencerMethodsToReadable(method: AddInfluencersMethod): string {
    return addInfluencerMethodToReadable(method);
  }

  addInfluencerMethodsIcon(method: AddInfluencersMethod): string {
    return addInfluencerMethodIcon(method);
  }

  selectMethod(method: AddInfluencersMethod): void {
    this.methodSelected$.emit(method);
    this.close();
  }

  override close(): void {
    super.close();
    this.addMethods.set([]);
  }
}
