import {
  ChangeDetectionStrategy,
  Component,
  computed,
  inject,
  Input,
  input,
  OnInit,
  Output,
  signal,
} from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  ValidationErrors,
  Validators,
  ɵFormGroupValue,
} from '@angular/forms';
import { injectLibrarianLocalClient } from '@frontend2/api';
import {
  googleLocationRenderer,
  isEqual,
  isNil,
  isNotEmptyString,
  isNotNil,
} from '@frontend2/core';
import { AddressFieldProto } from '@frontend2/proto/librarian/proto/entity_fields_pb';
import {
  AutocompleteLocationRequest,
  AutofillAddressRequest,
  GoogleLocation,
} from '@frontend2/proto/librarian/proto/local_pb';
import { distinctUntilChanged, map } from 'rxjs';
import { CountriesCache } from '../../cache/countries.service';
import { CountryDialCodeCache } from '../../cache/dial-codes.service';
import { showToastException } from '../../error-handler';
import { LeftyValidators } from '../../form-validators.helpers';
import { LeftyFormAutocompleteComponent } from '../../lefty-form-autocomplete/lefty-form-autocomplete.component';
import { LeftyFormInputComponent } from '../../lefty-form-input/lefty-form-input.component';
import { LeftyPhoneNumberInputComponent } from '../../lefty-phone-number-input/lefty-phone-number-input.component';
import {
  createPhone,
  parsePhoneString,
  Phone,
  toPhoneNumberString,
} from '../../lefty-phone-number-input/lefty-phone-number.helpers';
import { injectToastManager } from '../../toast/toast.service';
import { LeftyComponent } from '../../utils';

interface FormControlValue {
  readonly fullName: FormControl<string>;
  readonly phone: FormControl<Phone>;
  readonly company: FormControl<string>;
  readonly address: FormControl<GoogleLocation | null>;
  readonly address2: FormControl<string>;
  readonly city: FormControl<string>;
  readonly province: FormControl<string>;
  readonly postalCode: FormControl<string>;
  readonly country: FormControl<string>;
  readonly email: FormControl<string>;
}

type FormValue = ɵFormGroupValue<FormControlValue>;

@Component({
  selector: 'lefty-address-field-form',
  templateUrl: './lefty-address-field-form.component.html',
  styleUrls: ['./lefty-address-field-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    LeftyFormInputComponent,
    LeftyFormAutocompleteComponent,
    LeftyPhoneNumberInputComponent,
  ],
})
export class LeftyAddressFieldFormComponent
  extends LeftyComponent
  implements OnInit
{
  private readonly _librarianLocal = injectLibrarianLocalClient();
  private readonly toastManager = injectToastManager();

  readonly countriesCache = inject(CountriesCache);
  readonly dialCodesCache = inject(CountryDialCodeCache);

  readonly countries = computed(() =>
    Object.values(this.countriesCache.cachedData()),
  );

  readonly countriesNames = computed(() =>
    this.countries().map((c) => c.country),
  );

  readonly addressAutocompleteOptions = signal<GoogleLocation[]>([]);
  readonly autocompleteLoading = signal(false);
  readonly addressDetailLoading = signal(false);
  readonly addressInputText = signal('');
  readonly isPhoneRequired = input(false);
  dialCode = signal('33');

  readonly formModel = new FormGroup<FormControlValue>({
    fullName: new FormControl('', {
      validators: Validators.required,
      nonNullable: true,
    }),
    phone: new FormControl(createPhone(), {
      validators: (control): ValidationErrors | null => {
        return this._phoneValidator(control);
      },
      nonNullable: true,
    }),
    company: new FormControl('', {
      nonNullable: true,
    }),
    address: new FormControl(null, {
      validators: Validators.required,
      nonNullable: true,
    }),
    address2: new FormControl('', {
      nonNullable: true,
    }),
    city: new FormControl('', {
      validators: Validators.required,
      nonNullable: true,
    }),
    province: new FormControl('', {
      nonNullable: true,
    }),
    postalCode: new FormControl('', {
      validators: Validators.required,
      nonNullable: true,
    }),
    country: new FormControl('', {
      validators: Validators.required,
      nonNullable: true,
    }),
    email: new FormControl('', {
      validators: [LeftyValidators.emailValidator, Validators.required],
      nonNullable: true,
    }),
  });

  private _toAddressProto(formValue: FormValue): AddressFieldProto {
    return new AddressFieldProto({
      fullName: formValue.fullName ?? '',
      phone: toPhoneNumberString(formValue.phone ?? createPhone()),
      company: formValue.company ?? '',
      address: this.addressInputText(),
      address2: formValue.address2 ?? '',
      city: formValue.city ?? '',
      province: formValue.province ?? '',
      postalCode: formValue.postalCode ?? '',
      country: formValue.country ?? '',
      email: formValue.email ?? '',
    });
  }

  private _toAddressFormValue(proto: AddressFieldProto): FormValue {
    return {
      fullName: proto.fullName,
      phone: parsePhoneString(proto.phone),
      company: proto.company,
      address: new GoogleLocation({ locationName: proto.address }),
      address2: proto.address2,
      city: proto.city,
      province: proto.province,
      postalCode: proto.postalCode,
      country: proto.country,
      email: proto.email,
    };
  }

  private _populateFormModel(newAddress: AddressFieldProto): void {
    const formValue = this._toAddressFormValue(newAddress);
    this.addressInputText.set(newAddress.address);
    if (isEqual(formValue, this.formModel.value)) {
      return;
    }
    this.formModel.reset(formValue);
  }

  async searchForAddressAutocomplete(val: string): Promise<void> {
    if (isNotEmptyString(val)) {
      try {
        this.autocompleteLoading.set(true);
        const resp = await this._librarianLocal.autocompleteLocations(
          new AutocompleteLocationRequest({
            query: val,
            getFullAddressesSuggestions: true,
          }),
        );

        this.addressAutocompleteOptions.set(resp.location);
      } catch (e) {
        showToastException(this.toastManager, e);
      } finally {
        this.autocompleteLoading.set(false);
      }
    }
  }

  addressAutocompleteRenderer(val: GoogleLocation): string {
    return googleLocationRenderer(val);
  }

  autofillPhoneNumber(val: string | undefined): void {
    if (isNotNil(val)) {
      const countryCode = this.countries().find(
        (x) => x.country === val,
      )?.alpha2;

      const countryDialCode = this.dialCodesCache.getPhonePrefixByCountryCode(
        countryCode ?? '',
      )?.dial_code;

      if (isNotNil(countryDialCode)) {
        this.dialCode.set(countryDialCode);
      }
    }
  }

  async autofillAddressForm(val: GoogleLocation | undefined): Promise<void> {
    if (isNil(val)) {
      this.formModel.patchValue({ address: undefined });
      return;
    }
    this.addressDetailLoading.set(true);
    const resp = await this._librarianLocal.autofillAddressAPI(
      new AutofillAddressRequest({ googleLocationId: val.googleId }),
    );
    this.addressDetailLoading.set(false);
    const address = resp.address;
    this.addressInputText.set(address?.address ?? '');

    this.formModel.patchValue({
      city: address?.city,
      province: address?.province,
      postalCode: address?.postalCode,
      country: address?.country,
    });
  }

  private _phoneValidator(control: AbstractControl): ValidationErrors | null {
    if (this.isPhoneRequired()) {
      const validation = LeftyValidators.phoneRequiredValidator(control);
      if (isNotNil(validation)) {
        return validation;
      }
    }

    return LeftyValidators.phoneNumberValidator(control);
  }

  @Output()
  readonly isValid$ = this.formModel.statusChanges.pipe(
    map((status) => status === 'VALID'),
    distinctUntilChanged(),
  );

  @Output()
  readonly addressChange = this.formModel.valueChanges.pipe(
    map((formValue) => this._toAddressProto(formValue)),
    distinctUntilChanged<AddressFieldProto>(AddressFieldProto.equals),
  );

  @Input()
  set address(val: AddressFieldProto) {
    this._populateFormModel(val);
    this.autofillPhoneNumber(val.country);
  }

  ngOnInit(): void {
    this.countriesCache.load();
  }
}
