<lefty-dialog
  [autoDismissable]="true"
  [hideFooter]="true"
  size="small"
  [(visible)]="visible"
  (close$)="close()"
  (closedWithCloseButtonOrDismiss$)="dialogDismissed$.emit()"
>
  <h3
    title
    i18n
  >
    Add influencers
  </h3>

  <lefty-button-list>
    @for (method of addMethods(); track $index) {
      <lefty-button-list-item
        class="add-method"
        (click)="selectMethod(method)"
        [label]="addInfluencerMethodsToReadable(method)"
      >
        <button
          leftyButton="primary"
          [icon]="addInfluencerMethodsIcon(method)"
          list-item-icon
        ></button>
      </lefty-button-list-item>
    }
  </lefty-button-list>
</lefty-dialog>
