import { inject, InjectionToken } from '@angular/core';
import { Client, createClient } from '@connectrpc/connect';
import { LibrarianDiscover } from '@frontend2/proto/librarian/proto/discover_connect';
import { GRPC_TRANSPORT } from './grpc.service';

export type LibrarianDiscoverClient = Client<typeof LibrarianDiscover>;

const factory = (): LibrarianDiscoverClient =>
  createClient(LibrarianDiscover, inject(GRPC_TRANSPORT));

export const LibrarianDiscoverClientProvider = new InjectionToken(
  'LibrarianDiscoverClient',
  {
    factory,
  },
);

export function injectLibrarianDiscoverClient(): LibrarianDiscoverClient {
  return inject(LibrarianDiscoverClientProvider);
}
