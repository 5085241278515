import { ChangeDetectionStrategy, Component, input } from '@angular/core';

@Component({
  selector: 'lefty-button-list-item',
  templateUrl: 'lefty-button-list-item.component.html',
  styleUrls: ['./lefty-button-list-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [],
})
export class LeftyButtonListItemComponent {
  readonly label = input('');
  readonly caption = input('');
}
