import { inject, Injectable } from '@angular/core';
import { StringValue } from '@bufbuild/protobuf';
import {
  injectLibrarianAffiliationClient,
  LimitationsService,
} from '@frontend2/api';
import { UploadAffiliatesRequest } from '@frontend2/proto/librarian/proto/affiliation_pb';
import { PlainTextInfluencerUploadData } from '../influencers-management.models';
import { InfluencersUploadToTargetState } from './influencer-upload.models';
import { InfluencerUploadService } from './influencer-upload.service';

@Injectable({ providedIn: 'root' })
export class AffiliationUploadService extends InfluencerUploadService {
  readonly librarianAffiliation = injectLibrarianAffiliationClient();
  readonly limitation = inject(LimitationsService);

  buildErrorMessage(e: number): string {
    if (this.limitation.hasReachedCampaignLimitStatus(e)) {
      return $localize`You reached the maximum number of affiliates.`;
    }
    return '';
  }

  override async doUploadRequest(
    uploadData: PlainTextInfluencerUploadData,
    targetId: bigint,
  ): Promise<StringValue> {
    return this.librarianAffiliation.uploadAffiliates(
      new UploadAffiliatesRequest({
        programId: targetId,
        names: uploadData.usernames,
        network: uploadData.network,
        tagsToAdd: uploadData.tagsToAdd,
      }),
    );
  }

  onUploadDone(state: InfluencersUploadToTargetState): void {
    this.leftyEvents.emit('affiliates_uploaded', state);

    this.parentAppBridge.emitInfluencersAddedTo('Affiliation');
  }
}
