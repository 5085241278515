import { Injectable, computed } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Empty } from '@bufbuild/protobuf';
import { injectLibrarianAffiliationClient } from '@frontend2/api';
import { addOrUpdateInArray } from '@frontend2/core';
import {
  GetProgramsResponse,
  Program,
} from '@frontend2/proto/librarian/proto/affiliation_pb';
import { IframeSyncedCacheBloc } from '../bloc';
import { injectLeftyEventsBus } from '../events/events.service';

@Injectable({ providedIn: 'root' })
export class AffiliationProgramsCache extends IframeSyncedCacheBloc<GetProgramsResponse> {
  private readonly librarianAffiliation = injectLibrarianAffiliationClient();
  readonly alfredEvents = injectLeftyEventsBus();

  constructor() {
    super(new GetProgramsResponse());

    this.alfredEvents
      .on('created_program')
      .pipe(takeUntilDestroyed())
      .subscribe((p) => this.addOrEditProgram(p));

    this.alfredEvents
      .on('edited_program')
      .pipe(takeUntilDestroyed())
      .subscribe((p) => this.addOrEditProgram(p));
  }

  readonly hasProgram = computed(() => this.programs().length > 0);

  readonly programs = computed(() => this.cachedData().programs);

  readonly notArchivedPrograms = computed(() =>
    this.programs().filter((p) => p.archived === false),
  );

  override async fetch(): Promise<GetProgramsResponse> {
    return await this.librarianAffiliation.getProgramsAPI(new Empty());
  }

  override get syncName(): string {
    return 'affiliation_programs';
  }
  override convertToJson(obj: GetProgramsResponse): string {
    return JSON.stringify(obj);
  }
  override convertFromJson(jsonString: string): GetProgramsResponse {
    return GetProgramsResponse.fromJsonString(jsonString);
  }

  addOrEditProgram(program: Program): void {
    const programs = this.cachedData().programs;
    const newPrograms = addOrUpdateInArray(programs, program, {
      predicate: (p) => p.programId === program.programId,
      addToBeginning: true,
    });
    this.updateCache(new GetProgramsResponse({ programs: newPrograms }));
  }
}
