import { Component, inject } from '@angular/core';
import { LeftyAuthBloc } from '@frontend2/api';
import { FirebaseBloc } from '@frontend2/ui';

@Component({
  selector: 'olympe-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class OlympeAppComponent {
  constructor() {
    inject(FirebaseBloc).subscribeUserSignin(inject(LeftyAuthBloc).state$);
  }
}
