import {
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { NgModule } from '@angular/core';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { getAuth, provideAuth } from '@angular/fire/auth';
import { getDatabase, provideDatabase } from '@angular/fire/database';
import { getFirestore, provideFirestore } from '@angular/fire/firestore';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { API_HOST, LeftyAuthModule } from '@frontend2/api';
import { LeftyParentAppBridge } from '@frontend2/core';
import { LeftyErrorHandlerModule, UiModule } from '@frontend2/ui';
import { environment } from '../environments/environment';
import { OlympeAppComponent } from './app.component';
import { appRoutes } from './app.routes';
import {
  OlympeEmptyRouteComponent,
  OlympeNotFoundRouteComponent,
} from './not-found.route';

@NgModule({
  declarations: [
    OlympeAppComponent,
    OlympeNotFoundRouteComponent,
    OlympeEmptyRouteComponent,
  ],
  imports: [
    BrowserModule,
    LeftyErrorHandlerModule,
    UiModule,
    LeftyAuthModule,
    RouterModule.forRoot(appRoutes, {
      // The app is inserted inside an iframe (inside Alfred Dart)
      // and it works better when router use 'hash' path (/#/campaigns ...)
      // (it does reload the app each time we change the iframe URL)
      useHash: true,
    }),
  ],
  providers: [
    { provide: API_HOST, useValue: environment.apiHost },
    LeftyParentAppBridge.INITIALIZER,
    provideHttpClient(withInterceptorsFromDi()),
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideFirestore(() => getFirestore()),
    provideAuth(() => getAuth()),
    provideDatabase(() => getDatabase()),
  ],
  bootstrap: [OlympeAppComponent],
})
export class OlympeAppModule {}
