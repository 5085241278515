// @generated by protoc-gen-es v1.10.0 with parameter "target=ts"
// @generated from file librarian/proto/registered_author_product_requests.proto (package librarian.proto, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { BoolValue, DoubleValue, Int64Value, Message, proto3, protoInt64, StringValue, Timestamp } from "@bufbuild/protobuf";
import { ProductVariantPb, ProductWithInfoPb } from "./products_pb";
import { AddressFieldProto } from "./entity_fields_pb";
import { BasicPagination, CurrencyEnum, SortDirection } from "../../common/proto/common_pb";
import { LandingPagePb } from "./landing_pages_pb";

/**
 * @generated from enum librarian.proto.RegisteredAuthorProductRequestStatus
 */
export enum RegisteredAuthorProductRequestStatus {
  /**
   * @generated from enum value: REGISTERED_AUTHOR_PRODUCT_REQUEST_STATUS_UNKNOWN = 0;
   */
  UNKNOWN = 0,

  /**
   * @generated from enum value: REGISTERED_AUTHOR_PRODUCT_REQUEST_STATUS_PENDING = 1;
   */
  PENDING = 1,

  /**
   * @generated from enum value: REGISTERED_AUTHOR_PRODUCT_REQUEST_STATUS_APPROVED = 2;
   */
  APPROVED = 2,

  /**
   * @generated from enum value: REGISTERED_AUTHOR_PRODUCT_REQUEST_STATUS_REJECTED = 3;
   */
  REJECTED = 3,

  /**
   * @generated from enum value: REGISTERED_AUTHOR_PRODUCT_REQUEST_STATUS_SENT = 4;
   */
  SENT = 4,

  /**
   * @generated from enum value: REGISTERED_AUTHOR_PRODUCT_REQUEST_STATUS_TO_COMPLETE = 5;
   */
  TO_COMPLETE = 5,
}
// Retrieve enum metadata with: proto3.getEnumType(RegisteredAuthorProductRequestStatus)
proto3.util.setEnumType(RegisteredAuthorProductRequestStatus, "librarian.proto.RegisteredAuthorProductRequestStatus", [
  { no: 0, name: "REGISTERED_AUTHOR_PRODUCT_REQUEST_STATUS_UNKNOWN" },
  { no: 1, name: "REGISTERED_AUTHOR_PRODUCT_REQUEST_STATUS_PENDING" },
  { no: 2, name: "REGISTERED_AUTHOR_PRODUCT_REQUEST_STATUS_APPROVED" },
  { no: 3, name: "REGISTERED_AUTHOR_PRODUCT_REQUEST_STATUS_REJECTED" },
  { no: 4, name: "REGISTERED_AUTHOR_PRODUCT_REQUEST_STATUS_SENT" },
  { no: 5, name: "REGISTERED_AUTHOR_PRODUCT_REQUEST_STATUS_TO_COMPLETE" },
]);

/**
 * @generated from enum librarian.proto.RegisteredAuthorProductRequestsSort
 */
export enum RegisteredAuthorProductRequestsSort {
  /**
   * @generated from enum value: REGISTERED_AUTHOR_PRODUCT_REQUEST_SORT_UNKNOWN = 0;
   */
  REGISTERED_AUTHOR_PRODUCT_REQUEST_SORT_UNKNOWN = 0,

  /**
   * @generated from enum value: REGISTERED_AUTHOR_PRODUCT_REQUEST_SORT_STATUS = 1;
   */
  REGISTERED_AUTHOR_PRODUCT_REQUEST_SORT_STATUS = 1,

  /**
   * @generated from enum value: REGISTERED_AUTHOR_PRODUCT_REQUEST_SORT_INFLUENCER_NAME = 2;
   */
  REGISTERED_AUTHOR_PRODUCT_REQUEST_SORT_INFLUENCER_NAME = 2,

  /**
   * @generated from enum value: REGISTERED_AUTHOR_PRODUCT_REQUEST_SORT_LANDING_PAGE = 3;
   */
  REGISTERED_AUTHOR_PRODUCT_REQUEST_SORT_LANDING_PAGE = 3,

  /**
   * @generated from enum value: REGISTERED_AUTHOR_PRODUCT_REQUEST_SORT_TOTAL_AMOUNT = 4;
   */
  REGISTERED_AUTHOR_PRODUCT_REQUEST_SORT_TOTAL_AMOUNT = 4,

  /**
   * @generated from enum value: REGISTERED_AUTHOR_PRODUCT_REQUEST_SORT_CREATION_DATE = 5;
   */
  REGISTERED_AUTHOR_PRODUCT_REQUEST_SORT_CREATION_DATE = 5,
}
// Retrieve enum metadata with: proto3.getEnumType(RegisteredAuthorProductRequestsSort)
proto3.util.setEnumType(RegisteredAuthorProductRequestsSort, "librarian.proto.RegisteredAuthorProductRequestsSort", [
  { no: 0, name: "REGISTERED_AUTHOR_PRODUCT_REQUEST_SORT_UNKNOWN" },
  { no: 1, name: "REGISTERED_AUTHOR_PRODUCT_REQUEST_SORT_STATUS" },
  { no: 2, name: "REGISTERED_AUTHOR_PRODUCT_REQUEST_SORT_INFLUENCER_NAME" },
  { no: 3, name: "REGISTERED_AUTHOR_PRODUCT_REQUEST_SORT_LANDING_PAGE" },
  { no: 4, name: "REGISTERED_AUTHOR_PRODUCT_REQUEST_SORT_TOTAL_AMOUNT" },
  { no: 5, name: "REGISTERED_AUTHOR_PRODUCT_REQUEST_SORT_CREATION_DATE" },
]);

/**
 * @generated from message librarian.proto.ProductVariantQuantityPb
 */
export class ProductVariantQuantityPb extends Message<ProductVariantQuantityPb> {
  /**
   * @generated from field: librarian.proto.ProductVariantPb product_variant = 1;
   */
  productVariant?: ProductVariantPb;

  /**
   * @generated from field: int32 quantity = 2;
   */
  quantity = 0;

  /**
   * @generated from field: string asset_url = 3 [deprecated = true];
   * @deprecated
   */
  assetUrl = "";

  /**
   * @generated from field: librarian.proto.ProductWithInfoPb product = 4;
   */
  product?: ProductWithInfoPb;

  constructor(data?: PartialMessage<ProductVariantQuantityPb>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.ProductVariantQuantityPb";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "product_variant", kind: "message", T: ProductVariantPb },
    { no: 2, name: "quantity", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "asset_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "product", kind: "message", T: ProductWithInfoPb },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ProductVariantQuantityPb {
    return new ProductVariantQuantityPb().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ProductVariantQuantityPb {
    return new ProductVariantQuantityPb().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ProductVariantQuantityPb {
    return new ProductVariantQuantityPb().fromJsonString(jsonString, options);
  }

  static equals(a: ProductVariantQuantityPb | PlainMessage<ProductVariantQuantityPb> | undefined, b: ProductVariantQuantityPb | PlainMessage<ProductVariantQuantityPb> | undefined): boolean {
    return proto3.util.equals(ProductVariantQuantityPb, a, b);
  }
}

/**
 * @generated from message librarian.proto.RegisteredAuthorProductRequestApplicantPb
 */
export class RegisteredAuthorProductRequestApplicantPb extends Message<RegisteredAuthorProductRequestApplicantPb> {
  /**
   * @generated from field: string applicant_id = 1;
   */
  applicantId = "";

  /**
   * @generated from field: string applicant_name = 2;
   */
  applicantName = "";

  /**
   * @generated from field: string profile_pic_url = 3;
   */
  profilePicUrl = "";

  /**
   * @generated from field: string applicant_email = 4;
   */
  applicantEmail = "";

  constructor(data?: PartialMessage<RegisteredAuthorProductRequestApplicantPb>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.RegisteredAuthorProductRequestApplicantPb";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "applicant_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "applicant_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "profile_pic_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "applicant_email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RegisteredAuthorProductRequestApplicantPb {
    return new RegisteredAuthorProductRequestApplicantPb().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RegisteredAuthorProductRequestApplicantPb {
    return new RegisteredAuthorProductRequestApplicantPb().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): RegisteredAuthorProductRequestApplicantPb {
    return new RegisteredAuthorProductRequestApplicantPb().fromJsonString(jsonString, options);
  }

  static equals(a: RegisteredAuthorProductRequestApplicantPb | PlainMessage<RegisteredAuthorProductRequestApplicantPb> | undefined, b: RegisteredAuthorProductRequestApplicantPb | PlainMessage<RegisteredAuthorProductRequestApplicantPb> | undefined): boolean {
    return proto3.util.equals(RegisteredAuthorProductRequestApplicantPb, a, b);
  }
}

/**
 * @generated from message librarian.proto.RegisteredAuthorProductRequestPb
 */
export class RegisteredAuthorProductRequestPb extends Message<RegisteredAuthorProductRequestPb> {
  /**
   * @generated from field: int64 request_id = 1;
   */
  requestId = protoInt64.zero;

  /**
   * @generated from field: int64 landing_page_id = 2;
   */
  landingPageId = protoInt64.zero;

  /**
   * @generated from field: repeated librarian.proto.ProductVariantQuantityPb products_variants = 3;
   */
  productsVariants: ProductVariantQuantityPb[] = [];

  /**
   * @generated from field: librarian.proto.AddressFieldProto address = 4;
   */
  address?: AddressFieldProto;

  /**
   * @generated from field: librarian.proto.RegisteredAuthorProductRequestStatus status = 5;
   */
  status = RegisteredAuthorProductRequestStatus.UNKNOWN;

  /**
   * @generated from field: google.protobuf.DoubleValue total_amount = 6;
   */
  totalAmount?: number;

  /**
   * @generated from field: google.protobuf.Timestamp creation_date = 7;
   */
  creationDate?: Timestamp;

  /**
   * @generated from field: librarian.proto.RegisteredAuthorProductRequestApplicantPb applicant = 8;
   */
  applicant?: RegisteredAuthorProductRequestApplicantPb;

  /**
   * @generated from field: string landing_page_name = 9;
   */
  landingPageName = "";

  /**
   * @generated from field: common.proto.CurrencyEnum currency = 10;
   */
  currency = CurrencyEnum.NA;

  /**
   * @generated from field: int64 ecommerce_store_id = 11;
   */
  ecommerceStoreId = protoInt64.zero;

  /**
   * @generated from field: string shipping_method = 12;
   */
  shippingMethod = "";

  /**
   * @generated from field: google.protobuf.Int64Value gift_id = 13;
   */
  giftId?: bigint;

  constructor(data?: PartialMessage<RegisteredAuthorProductRequestPb>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.RegisteredAuthorProductRequestPb";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "request_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "landing_page_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "products_variants", kind: "message", T: ProductVariantQuantityPb, repeated: true },
    { no: 4, name: "address", kind: "message", T: AddressFieldProto },
    { no: 5, name: "status", kind: "enum", T: proto3.getEnumType(RegisteredAuthorProductRequestStatus) },
    { no: 6, name: "total_amount", kind: "message", T: DoubleValue },
    { no: 7, name: "creation_date", kind: "message", T: Timestamp },
    { no: 8, name: "applicant", kind: "message", T: RegisteredAuthorProductRequestApplicantPb },
    { no: 9, name: "landing_page_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 10, name: "currency", kind: "enum", T: proto3.getEnumType(CurrencyEnum) },
    { no: 11, name: "ecommerce_store_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 12, name: "shipping_method", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 13, name: "gift_id", kind: "message", T: Int64Value },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RegisteredAuthorProductRequestPb {
    return new RegisteredAuthorProductRequestPb().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RegisteredAuthorProductRequestPb {
    return new RegisteredAuthorProductRequestPb().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): RegisteredAuthorProductRequestPb {
    return new RegisteredAuthorProductRequestPb().fromJsonString(jsonString, options);
  }

  static equals(a: RegisteredAuthorProductRequestPb | PlainMessage<RegisteredAuthorProductRequestPb> | undefined, b: RegisteredAuthorProductRequestPb | PlainMessage<RegisteredAuthorProductRequestPb> | undefined): boolean {
    return proto3.util.equals(RegisteredAuthorProductRequestPb, a, b);
  }
}

/**
 * @generated from message librarian.proto.RegisteredAuthorInfluencerRequestPb
 */
export class RegisteredAuthorInfluencerRequestPb extends Message<RegisteredAuthorInfluencerRequestPb> {
  /**
   * @generated from field: repeated librarian.proto.ProductVariantQuantityPb products_variants = 1;
   */
  productsVariants: ProductVariantQuantityPb[] = [];

  /**
   * @generated from field: librarian.proto.AddressFieldProto address = 2;
   */
  address?: AddressFieldProto;

  /**
   * @generated from field: librarian.proto.RegisteredAuthorProductRequestStatus status = 3;
   */
  status = RegisteredAuthorProductRequestStatus.UNKNOWN;

  constructor(data?: PartialMessage<RegisteredAuthorInfluencerRequestPb>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.RegisteredAuthorInfluencerRequestPb";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "products_variants", kind: "message", T: ProductVariantQuantityPb, repeated: true },
    { no: 2, name: "address", kind: "message", T: AddressFieldProto },
    { no: 3, name: "status", kind: "enum", T: proto3.getEnumType(RegisteredAuthorProductRequestStatus) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RegisteredAuthorInfluencerRequestPb {
    return new RegisteredAuthorInfluencerRequestPb().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RegisteredAuthorInfluencerRequestPb {
    return new RegisteredAuthorInfluencerRequestPb().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): RegisteredAuthorInfluencerRequestPb {
    return new RegisteredAuthorInfluencerRequestPb().fromJsonString(jsonString, options);
  }

  static equals(a: RegisteredAuthorInfluencerRequestPb | PlainMessage<RegisteredAuthorInfluencerRequestPb> | undefined, b: RegisteredAuthorInfluencerRequestPb | PlainMessage<RegisteredAuthorInfluencerRequestPb> | undefined): boolean {
    return proto3.util.equals(RegisteredAuthorInfluencerRequestPb, a, b);
  }
}

/**
 * @generated from message librarian.proto.RegisteredAuthorsProductRequestsFilter
 */
export class RegisteredAuthorsProductRequestsFilter extends Message<RegisteredAuthorsProductRequestsFilter> {
  /**
   * @generated from field: repeated string registered_authors_ids = 1;
   */
  registeredAuthorsIds: string[] = [];

  /**
   * @generated from field: repeated librarian.proto.RegisteredAuthorProductRequestStatus statuses = 2;
   */
  statuses: RegisteredAuthorProductRequestStatus[] = [];

  /**
   * @generated from field: repeated int64 product_ids = 3;
   */
  productIds: bigint[] = [];

  /**
   * @generated from field: repeated int64 landing_page_ids = 4;
   */
  landingPageIds: bigint[] = [];

  /**
   * @generated from field: string applicant_name = 5;
   */
  applicantName = "";

  constructor(data?: PartialMessage<RegisteredAuthorsProductRequestsFilter>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.RegisteredAuthorsProductRequestsFilter";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "registered_authors_ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 2, name: "statuses", kind: "enum", T: proto3.getEnumType(RegisteredAuthorProductRequestStatus), repeated: true },
    { no: 3, name: "product_ids", kind: "scalar", T: 3 /* ScalarType.INT64 */, repeated: true },
    { no: 4, name: "landing_page_ids", kind: "scalar", T: 3 /* ScalarType.INT64 */, repeated: true },
    { no: 5, name: "applicant_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RegisteredAuthorsProductRequestsFilter {
    return new RegisteredAuthorsProductRequestsFilter().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RegisteredAuthorsProductRequestsFilter {
    return new RegisteredAuthorsProductRequestsFilter().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): RegisteredAuthorsProductRequestsFilter {
    return new RegisteredAuthorsProductRequestsFilter().fromJsonString(jsonString, options);
  }

  static equals(a: RegisteredAuthorsProductRequestsFilter | PlainMessage<RegisteredAuthorsProductRequestsFilter> | undefined, b: RegisteredAuthorsProductRequestsFilter | PlainMessage<RegisteredAuthorsProductRequestsFilter> | undefined): boolean {
    return proto3.util.equals(RegisteredAuthorsProductRequestsFilter, a, b);
  }
}

/**
 * @generated from message librarian.proto.GetRegisteredAuthorsProductRequestsRequest
 */
export class GetRegisteredAuthorsProductRequestsRequest extends Message<GetRegisteredAuthorsProductRequestsRequest> {
  /**
   * @generated from field: librarian.proto.RegisteredAuthorsProductRequestsFilter filter = 1;
   */
  filter?: RegisteredAuthorsProductRequestsFilter;

  /**
   * @generated from field: librarian.proto.RegisteredAuthorProductRequestsSort sort = 2;
   */
  sort = RegisteredAuthorProductRequestsSort.REGISTERED_AUTHOR_PRODUCT_REQUEST_SORT_UNKNOWN;

  /**
   * @generated from field: common.proto.SortDirection sort_direction = 3;
   */
  sortDirection = SortDirection.DESC;

  /**
   * @generated from field: common.proto.BasicPagination pagination = 4;
   */
  pagination?: BasicPagination;

  constructor(data?: PartialMessage<GetRegisteredAuthorsProductRequestsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.GetRegisteredAuthorsProductRequestsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "filter", kind: "message", T: RegisteredAuthorsProductRequestsFilter },
    { no: 2, name: "sort", kind: "enum", T: proto3.getEnumType(RegisteredAuthorProductRequestsSort) },
    { no: 3, name: "sort_direction", kind: "enum", T: proto3.getEnumType(SortDirection) },
    { no: 4, name: "pagination", kind: "message", T: BasicPagination },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetRegisteredAuthorsProductRequestsRequest {
    return new GetRegisteredAuthorsProductRequestsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetRegisteredAuthorsProductRequestsRequest {
    return new GetRegisteredAuthorsProductRequestsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetRegisteredAuthorsProductRequestsRequest {
    return new GetRegisteredAuthorsProductRequestsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetRegisteredAuthorsProductRequestsRequest | PlainMessage<GetRegisteredAuthorsProductRequestsRequest> | undefined, b: GetRegisteredAuthorsProductRequestsRequest | PlainMessage<GetRegisteredAuthorsProductRequestsRequest> | undefined): boolean {
    return proto3.util.equals(GetRegisteredAuthorsProductRequestsRequest, a, b);
  }
}

/**
 * @generated from message librarian.proto.GetRegisteredAuthorsProductRequestsResponse
 */
export class GetRegisteredAuthorsProductRequestsResponse extends Message<GetRegisteredAuthorsProductRequestsResponse> {
  /**
   * @generated from field: repeated librarian.proto.RegisteredAuthorProductRequestPb product_request = 1;
   */
  productRequest: RegisteredAuthorProductRequestPb[] = [];

  /**
   * @generated from field: int32 total_hits = 2;
   */
  totalHits = 0;

  constructor(data?: PartialMessage<GetRegisteredAuthorsProductRequestsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.GetRegisteredAuthorsProductRequestsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "product_request", kind: "message", T: RegisteredAuthorProductRequestPb, repeated: true },
    { no: 2, name: "total_hits", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetRegisteredAuthorsProductRequestsResponse {
    return new GetRegisteredAuthorsProductRequestsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetRegisteredAuthorsProductRequestsResponse {
    return new GetRegisteredAuthorsProductRequestsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetRegisteredAuthorsProductRequestsResponse {
    return new GetRegisteredAuthorsProductRequestsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetRegisteredAuthorsProductRequestsResponse | PlainMessage<GetRegisteredAuthorsProductRequestsResponse> | undefined, b: GetRegisteredAuthorsProductRequestsResponse | PlainMessage<GetRegisteredAuthorsProductRequestsResponse> | undefined): boolean {
    return proto3.util.equals(GetRegisteredAuthorsProductRequestsResponse, a, b);
  }
}

/**
 * @generated from message librarian.proto.StatusCountPb
 */
export class StatusCountPb extends Message<StatusCountPb> {
  /**
   * @generated from field: librarian.proto.RegisteredAuthorProductRequestStatus status = 1;
   */
  status = RegisteredAuthorProductRequestStatus.UNKNOWN;

  /**
   * @generated from field: int32 count = 2;
   */
  count = 0;

  constructor(data?: PartialMessage<StatusCountPb>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.StatusCountPb";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "status", kind: "enum", T: proto3.getEnumType(RegisteredAuthorProductRequestStatus) },
    { no: 2, name: "count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): StatusCountPb {
    return new StatusCountPb().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): StatusCountPb {
    return new StatusCountPb().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): StatusCountPb {
    return new StatusCountPb().fromJsonString(jsonString, options);
  }

  static equals(a: StatusCountPb | PlainMessage<StatusCountPb> | undefined, b: StatusCountPb | PlainMessage<StatusCountPb> | undefined): boolean {
    return proto3.util.equals(StatusCountPb, a, b);
  }
}

/**
 * @generated from message librarian.proto.InitRegisteredAuthorProductRequestsResponse
 */
export class InitRegisteredAuthorProductRequestsResponse extends Message<InitRegisteredAuthorProductRequestsResponse> {
  /**
   * @generated from field: repeated librarian.proto.RegisteredAuthorProductRequestApplicantPb applicants = 1;
   */
  applicants: RegisteredAuthorProductRequestApplicantPb[] = [];

  /**
   * @generated from field: repeated librarian.proto.ProductVariantPb products = 2;
   */
  products: ProductVariantPb[] = [];

  /**
   * @generated from field: repeated librarian.proto.LandingPagePb landing_pages = 3;
   */
  landingPages: LandingPagePb[] = [];

  /**
   * @generated from field: repeated librarian.proto.StatusCountPb status_count = 4;
   */
  statusCount: StatusCountPb[] = [];

  constructor(data?: PartialMessage<InitRegisteredAuthorProductRequestsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.InitRegisteredAuthorProductRequestsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "applicants", kind: "message", T: RegisteredAuthorProductRequestApplicantPb, repeated: true },
    { no: 2, name: "products", kind: "message", T: ProductVariantPb, repeated: true },
    { no: 3, name: "landing_pages", kind: "message", T: LandingPagePb, repeated: true },
    { no: 4, name: "status_count", kind: "message", T: StatusCountPb, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): InitRegisteredAuthorProductRequestsResponse {
    return new InitRegisteredAuthorProductRequestsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): InitRegisteredAuthorProductRequestsResponse {
    return new InitRegisteredAuthorProductRequestsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): InitRegisteredAuthorProductRequestsResponse {
    return new InitRegisteredAuthorProductRequestsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: InitRegisteredAuthorProductRequestsResponse | PlainMessage<InitRegisteredAuthorProductRequestsResponse> | undefined, b: InitRegisteredAuthorProductRequestsResponse | PlainMessage<InitRegisteredAuthorProductRequestsResponse> | undefined): boolean {
    return proto3.util.equals(InitRegisteredAuthorProductRequestsResponse, a, b);
  }
}

/**
 * @generated from message librarian.proto.UpdateRegisteredAuthorProductRequestStatusRequest
 */
export class UpdateRegisteredAuthorProductRequestStatusRequest extends Message<UpdateRegisteredAuthorProductRequestStatusRequest> {
  /**
   * @generated from field: int64 request_id = 1;
   */
  requestId = protoInt64.zero;

  /**
   * @generated from field: librarian.proto.RegisteredAuthorProductRequestStatus status = 2;
   */
  status = RegisteredAuthorProductRequestStatus.UNKNOWN;

  constructor(data?: PartialMessage<UpdateRegisteredAuthorProductRequestStatusRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.UpdateRegisteredAuthorProductRequestStatusRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "request_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "status", kind: "enum", T: proto3.getEnumType(RegisteredAuthorProductRequestStatus) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateRegisteredAuthorProductRequestStatusRequest {
    return new UpdateRegisteredAuthorProductRequestStatusRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateRegisteredAuthorProductRequestStatusRequest {
    return new UpdateRegisteredAuthorProductRequestStatusRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateRegisteredAuthorProductRequestStatusRequest {
    return new UpdateRegisteredAuthorProductRequestStatusRequest().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateRegisteredAuthorProductRequestStatusRequest | PlainMessage<UpdateRegisteredAuthorProductRequestStatusRequest> | undefined, b: UpdateRegisteredAuthorProductRequestStatusRequest | PlainMessage<UpdateRegisteredAuthorProductRequestStatusRequest> | undefined): boolean {
    return proto3.util.equals(UpdateRegisteredAuthorProductRequestStatusRequest, a, b);
  }
}

/**
 * @generated from message librarian.proto.SendRegisteredAuthorProductRequestRequest
 */
export class SendRegisteredAuthorProductRequestRequest extends Message<SendRegisteredAuthorProductRequestRequest> {
  /**
   * @generated from field: int64 request_id = 1;
   */
  requestId = protoInt64.zero;

  /**
   * @generated from field: google.protobuf.StringValue shipping_method = 2;
   */
  shippingMethod?: string;

  /**
   * @generated from field: google.protobuf.BoolValue split_request = 3;
   */
  splitRequest?: boolean;

  /**
   * @generated from field: google.protobuf.BoolValue external_shop_synchronization = 4;
   */
  externalShopSynchronization?: boolean;

  /**
   * @generated from field: librarian.proto.AddressFieldProto address = 5;
   */
  address?: AddressFieldProto;

  /**
   * @generated from field: google.protobuf.StringValue discount_code_reason = 6;
   */
  discountCodeReason?: string;

  constructor(data?: PartialMessage<SendRegisteredAuthorProductRequestRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.SendRegisteredAuthorProductRequestRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "request_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "shipping_method", kind: "message", T: StringValue },
    { no: 3, name: "split_request", kind: "message", T: BoolValue },
    { no: 4, name: "external_shop_synchronization", kind: "message", T: BoolValue },
    { no: 5, name: "address", kind: "message", T: AddressFieldProto },
    { no: 6, name: "discount_code_reason", kind: "message", T: StringValue },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SendRegisteredAuthorProductRequestRequest {
    return new SendRegisteredAuthorProductRequestRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SendRegisteredAuthorProductRequestRequest {
    return new SendRegisteredAuthorProductRequestRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SendRegisteredAuthorProductRequestRequest {
    return new SendRegisteredAuthorProductRequestRequest().fromJsonString(jsonString, options);
  }

  static equals(a: SendRegisteredAuthorProductRequestRequest | PlainMessage<SendRegisteredAuthorProductRequestRequest> | undefined, b: SendRegisteredAuthorProductRequestRequest | PlainMessage<SendRegisteredAuthorProductRequestRequest> | undefined): boolean {
    return proto3.util.equals(SendRegisteredAuthorProductRequestRequest, a, b);
  }
}

/**
 * @generated from message librarian.proto.SendRegisteredAuthorProductRequestResponse
 */
export class SendRegisteredAuthorProductRequestResponse extends Message<SendRegisteredAuthorProductRequestResponse> {
  /**
   * @generated from field: repeated librarian.proto.RegisteredAuthorProductRequestPb requests = 1;
   */
  requests: RegisteredAuthorProductRequestPb[] = [];

  constructor(data?: PartialMessage<SendRegisteredAuthorProductRequestResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.SendRegisteredAuthorProductRequestResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "requests", kind: "message", T: RegisteredAuthorProductRequestPb, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SendRegisteredAuthorProductRequestResponse {
    return new SendRegisteredAuthorProductRequestResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SendRegisteredAuthorProductRequestResponse {
    return new SendRegisteredAuthorProductRequestResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SendRegisteredAuthorProductRequestResponse {
    return new SendRegisteredAuthorProductRequestResponse().fromJsonString(jsonString, options);
  }

  static equals(a: SendRegisteredAuthorProductRequestResponse | PlainMessage<SendRegisteredAuthorProductRequestResponse> | undefined, b: SendRegisteredAuthorProductRequestResponse | PlainMessage<SendRegisteredAuthorProductRequestResponse> | undefined): boolean {
    return proto3.util.equals(SendRegisteredAuthorProductRequestResponse, a, b);
  }
}

