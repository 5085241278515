import {
  ChangeDetectionStrategy,
  Component,
  computed,
  inject,
  input,
  output,
  signal,
} from '@angular/core';
import { LeftyAuthBloc } from '@frontend2/api';
import { isNotEmptyString } from '@frontend2/core';
import { Network } from '@frontend2/proto/common/proto/common_pb';
import { NetworkConfigs } from '../../config/config.service';
import { LeftyButtonDirective } from '../../lefty-button-directive/lefty-button.directive';
import { LeftyDialogComponent } from '../../lefty-dialog/lefty-dialog.component';
import { DialogBase } from '../../lefty-dialog/lefty-dialog.helpers';
import { LeftyFormTextareaComponent } from '../../lefty-form-textarea/lefty-form-textarea.component';
import { NetworkFormSelectComponent } from '../../selector/network-form-select/network-form-select.component';
import {
  createPlainTextInfluencerUploadData,
  PlainTextInfluencerUploadData,
} from '../influencers-management.models';

@Component({
  selector: 'plain-text-method-dialog',
  templateUrl: 'plain-text-method-dialog.component.html',
  styleUrls: ['./plain-text-method-dialog.component.scss'],
  providers: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    LeftyDialogComponent,
    NetworkFormSelectComponent,
    LeftyFormTextareaComponent,
    LeftyButtonDirective,
  ],
})
export class PlainTextMethodDialogComponent extends DialogBase {
  private readonly _auth = inject(LeftyAuthBloc);
  private readonly networkConfigs = inject(NetworkConfigs);

  readonly defaultNetwork = Network.INSTA;

  readonly network = signal(this.defaultNetwork);

  readonly saved$ = output<PlainTextInfluencerUploadData>();

  readonly back$ = output<void>();

  readonly dialogDismissed$ = output<void>();

  readonly submitButtonText = input($localize`Save`);

  readonly textareaPlaceholder = computed(
    () => this.networkConfigs.of(this.network()).uploadPlaceholder,
  );

  readonly textareaVal = signal('');

  get availableNetworks(): Network[] {
    return this._auth.allowedNetworks();
  }

  resetState(): void {
    this.network.set(this.defaultNetwork);
    this.textareaVal.set('');
  }

  async save(): Promise<void> {
    const names = this.textareaVal().split('\n').filter(isNotEmptyString);
    this.saved$.emit(
      createPlainTextInfluencerUploadData({
        usernames: names,
        network: this.network(),
      }),
    );
    this.close();
  }

  back(): void {
    this.close();
    this.back$.emit();
  }

  override close(): void {
    this.resetState();
    super.close();
  }

  dismissed(): void {
    this.resetState();
    this.dialogDismissed$.emit();
  }
}
