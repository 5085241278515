// @generated by protoc-gen-connect-es v1.6.1 with parameter "target=ts"
// @generated from file librarian/proto/epson.proto (package epson, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { PrintCastingReq, PrintDirectoryReportReq, PrintInfluencerReq } from "./epson_pb";
import { MethodKind, StringValue } from "@bufbuild/protobuf";

/**
 * This service generate PDF using and Headless browser
 * with Puppeteer
 *
 * Implementation of the PDF is on the Epson Angular APP
 * hosted on https://print.lefty.io
 *
 * Each endpoint take user credentials as request (+ filters if any)
 * and return the PDF download url
 *
 * @generated from service epson.Epson
 */
export const Epson = {
  typeName: "epson.Epson",
  methods: {
    /**
     * @generated from rpc epson.Epson.PrintInfluencerProfile
     */
    printInfluencerProfile: {
      name: "PrintInfluencerProfile",
      I: PrintInfluencerReq,
      O: StringValue,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc epson.Epson.PrintDirectoryReport
     */
    printDirectoryReport: {
      name: "PrintDirectoryReport",
      I: PrintDirectoryReportReq,
      O: StringValue,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc epson.Epson.PrintCasting
     */
    printCasting: {
      name: "PrintCasting",
      I: PrintCastingReq,
      O: StringValue,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc epson.Epson.PrintCastingForecast
     */
    printCastingForecast: {
      name: "PrintCastingForecast",
      I: PrintCastingReq,
      O: StringValue,
      kind: MethodKind.Unary,
    },
  }
} as const;

