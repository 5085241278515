import {
  ChangeDetectionStrategy,
  Component,
  computed,
  inject,
} from '@angular/core';
import { LeftyAuthBloc } from '@frontend2/api';
import { isNil } from '@frontend2/core';
import { CurrencyEnum } from '@frontend2/proto/common/proto/common_pb';
import { LeftyControlValueAccessor } from '../form';
import { LeftyCurrencySelectorComponent } from '../lefty-currency-selector/lefty-currency-selector.component';
import { LeftyFormNumberInputComponent } from '../lefty-form-input/lefty-form-number-input.component';
import { LeftyFormComponent } from '../lefty-form/lefty-form.component';
import { CurrencyValue } from './lefty-currency-value.models';

@Component({
  selector: 'lefty-currency-value-input',
  templateUrl: 'lefty-currency-value-input.component.html',
  styleUrls: ['lefty-currency-value-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    LeftyFormComponent,
    LeftyFormNumberInputComponent,
    LeftyCurrencySelectorComponent,
  ],
})
export class LeftyCurrencyValueInputComponent extends LeftyControlValueAccessor<CurrencyValue> {
  readonly auth = inject(LeftyAuthBloc);

  readonly numberValue = computed(() => this.value()?.value);
  readonly currency = computed(
    () => this.value()?.currency ?? this.auth.$currency(),
  );

  setNumberValue(val: number | undefined): void {
    if (isNil(val)) {
      this.setValueAndNotify(undefined);
    } else {
      this.setValueAndNotify({ currency: this.currency(), value: val });
    }
  }

  setCurrencyValue(val: CurrencyEnum | undefined): void {
    val = val ?? this.auth.$currency();
    const numberValue = this.numberValue() ?? 0;

    this.setValueAndNotify({ currency: val, value: numberValue });
  }

  override isValidType(obj: unknown): obj is CurrencyValue {
    if (typeof obj !== 'object' || obj === null) {
      return false;
    }

    return 'currency' in obj && 'value' in obj;
  }
}
