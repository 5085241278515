<div
  class="dialog-background"
  (click)="clickBackground()"
></div>

<div
  class="dialog-card"
  [style.margin-left.px]="dialogOffsetLeft"
>
  <header
    class="dialog-header"
    *ngIf="hideHeader === false"
  >
    <div class="dialog-title">
      <div class="dialog-title-text">
        <ng-content select="[title]"></ng-content>
      </div>

      <lefty-icon
        class="close-dialog"
        *ngIf="hideCloseButton === false"
        (click)="closeWithCloseButtonOrDismiss()"
        icon="close"
      ></lefty-icon>
    </div>

    <ng-content select="[description]"></ng-content>
  </header>

  <ng-content select="[subHeader]"></ng-content>

  <section
    class="dialog-content"
    #content
  >
    <ng-content></ng-content>
  </section>

  <footer
    *ngIf="hideFooter === false"
    class="dialog-footer"
  >
    <ng-content select="[footer]"></ng-content>
  </footer>
</div>
