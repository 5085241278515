import { Routes } from '@angular/router';
import { IS_IN_IFRAME } from '@frontend2/core';
import {
  OlympeEmptyRouteComponent,
  OlympeNotFoundRouteComponent,
} from './not-found.route';
import { NOT_FOUND_PATH } from './routes.constants';

export const appRoutes: Routes = [
  // TODO: workspaces route
  // TODO: campaigns route
  // TODO: influencers route
  // TODO: posts route
  // TODO: reports route
];

if (IS_IN_IFRAME === false) {
  appRoutes.push({
    path: NOT_FOUND_PATH,
    component: OlympeNotFoundRouteComponent,
  });

  // when not in iframe, redirect 404 to `/not_found`
  appRoutes.push({
    path: '**',
    redirectTo: `/${NOT_FOUND_PATH}`,
    pathMatch: 'full',
  });
} else {
  // when in iframe, the url may be not found in Alfred2 but exist in Alfred1
  // so we must keep the url and not redirect to `/not_found`
  appRoutes.push({
    path: '**',
    component: OlympeEmptyRouteComponent,
    pathMatch: 'full',
    data: {
      // we mark this route as the not found page
      // so we can notify the parent app, that we did not find matching route
      notFoundPage: true,
    },
  });
}
