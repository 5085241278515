<img
  class="lefty-logo"
  src="lefty-images/lefty-logo-white.svg"
/>
<div class="left-panel">
  <div class="img-container">
    <img
      class="not-found-img"
      src="lefty-images/not_found.png"
    />
  </div>
</div>
<div class="right-panel">
  <div class="content">
    <h1 i18n>Oooh…don’t be so sad.</h1>
    <p i18n>
      It’s just a 404 error! You can go back, or contact us if you need a hand.
    </p>
    <a
      class="button primary"
      routerLink="/"
      i18n
    >
      Go back
    </a>
  </div>
</div>
