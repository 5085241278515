import {
  AfterViewChecked,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  inject,
  input,
  Renderer2,
  ViewContainerRef,
} from '@angular/core';
import { isNil } from '@frontend2/core';
import { LeftyIconComponent } from '../icon/icon.component';
import { LeftyListComponent } from '../lefty-list/lefty-list.component';

@Component({
  selector: 'lefty-button-list',
  templateUrl: 'lefty-button-list.component.html',
  styleUrls: ['./lefty-button-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [LeftyListComponent],
})
export class LeftyButtonListComponent implements AfterViewChecked {
  private readonly elementRef = inject(ElementRef);
  private readonly viewContainerRef = inject(ViewContainerRef);
  private readonly renderer = inject(Renderer2);

  readonly hideRightArrow = input(false);

  ngAfterViewChecked(): void {
    // Add icons dynamically if needed
    const hostElement = this.elementRef.nativeElement;
    const listItems: NodeListOf<Element> = hostElement.querySelectorAll(
      'lefty-button-list-item',
    );

    if (this.hideRightArrow() === false) {
      listItems.forEach((item) => {
        if (isNil(item.querySelector('.right-list-icon'))) {
          const iconContainer = this._createIconComponent();
          this.renderer.addClass(iconContainer, 'icon-container');

          this.renderer.appendChild(item, iconContainer);
        }
      });
    }
  }

  private _createIconComponent(): ElementRef<LeftyIconComponent> {
    const comp =
      this.viewContainerRef.createComponent<LeftyIconComponent>(
        LeftyIconComponent,
      );
    comp.instance.icon = 'arrow_right';
    comp.instance.size = 'large';
    this.renderer.addClass(comp.location.nativeElement, 'right-list-icon');
    return comp.location.nativeElement;
  }
}
