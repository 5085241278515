<lefty-dialog
  [(visible)]="visible"
  class="small hide-header"
  [showScrollBorder]="true"
  (closedWithCloseButtonOrDismiss$)="closedWithoutSave$.emit()"
  [autoDismissable]="true"
  (close$)="close()"
>
  @if (isEdit()) {
    <h3
      title
      i18n
    >
      edit "{{ currentProgram().name }}"
    </h3>
  } @else {
    <h3
      title
      i18n
    >
      Add program
    </h3>
  }

  <form [formGroup]="formModel">
    <div class="form-container">
      <lefty-form-input
        label="Program name"
        i18n-label
        formControlName="name"
      ></lefty-form-input>

      <lefty-duration-input
        label="Cookie duration"
        i18n-label
        formControlName="cookieDuration"
      ></lefty-duration-input>

      <lefty-form
        label="Commission"
        i18n-label
      >
        <lefty-radio-group
          class="outline commission-group"
          [(selected)]="commissionType"
        >
          @for (item of commissionTypes; track $index) {
            <lefty-radio [value]="item">
              <div class="radio-item">
                {{ readableAffiliationProgramCommissionType(item) }}
                @if (item === commissionType()) {
                  @if (isPercentage()) {
                    <lefty-form-number-input
                      suffix="%"
                      formControlName="percentageValue"
                    >
                    </lefty-form-number-input>
                  } @else if (isFlatFee()) {
                    <lefty-currency-value-input
                      formControlName="flatFeeValue"
                    ></lefty-currency-value-input>
                  }
                }
              </div>
            </lefty-radio>
          }
        </lefty-radio-group>
      </lefty-form>
    </div>
  </form>

  <ng-container footer>
    <button
      i18n
      leftyButton="secondary"
      (click)="cancel()"
      [disabled]="loading()"
    >
      Cancel
    </button>
    <button
      i18n
      leftyButton="primary"
      (click)="submit()"
      [disabled]="loading() || formModel.invalid"
      [loading]="loading()"
    >
      Save
    </button>
  </ng-container>
</lefty-dialog>
