import { inject, Injectable } from '@angular/core';
import { Message } from '@bufbuild/protobuf';
import {
  EventBus,
  isNotNil,
  LeftyEventDataType,
  LeftyParentAppBridge,
} from '@frontend2/core';
import { Program } from '@frontend2/proto/librarian/proto/affiliation_pb';
import { Campaign } from '@frontend2/proto/librarian/proto/campaigns_pb';
import { EntityField } from '@frontend2/proto/librarian/proto/common_pb';
import { EntityFieldValue } from '@frontend2/proto/librarian/proto/entity_fields_pb';
import { EntityLabel } from '@frontend2/proto/librarian/proto/entity_labels_pb';
import { AddAffiliateModel } from '../affiliation/affiliates.models';
import { AddToCampaignModel } from '../campaigns/campaigns.models';
import { InfluencersUploadToTargetState } from '../influencers-management/influencer-upload.services/influencer-upload.models';

export type LeftyEvents = {
  create_label: EntityLabel;
  edit_label: EntityLabel;
  entity_field_updated: EntityField;
  entity_field_created: EntityField;
  update_entity_field_value: EntityFieldValue;
  remove_campaign: Campaign;
  archive_campaign: Campaign;
  unarchive_campaign: Campaign;
  pin_campaign: Campaign;
  unpin_campaign: Campaign;
  create_campaign: Campaign;
  update_campaign: Campaign;
  influencer_uploaded_to_campaign: InfluencersUploadToTargetState;
  influencer_added_to_campaign: AddToCampaignModel;
  created_program: Program;
  edited_program: Program;
  affiliates_added: AddAffiliateModel;
  affiliates_uploaded: InfluencersUploadToTargetState;
};

@Injectable()
export class LeftyEventsBus<T extends LeftyEvents> extends EventBus<T> {
  readonly parentAppBridge = inject(LeftyParentAppBridge, {
    optional: true,
  });

  override emit<Name extends keyof T>(
    name: Name,
    event: T[Name],
    args?: { emitter?: unknown; emitToParentApp?: boolean },
  ): void {
    super.emit(name, event, args);

    const eventData = event as LeftyEventDataType;

    if (args?.emitToParentApp && isNotNil(this.parentAppBridge)) {
      this.parentAppBridge.emitEventBus(
        name as string,
        eventData instanceof Message ? eventData.toJson() : eventData,
      );
    }
  }
}

export function injectLeftyEventsBus(): LeftyEventsBus<LeftyEvents> {
  return inject(LeftyEventsBus);
}
